import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: black;
    padding: 40px 20px;

    @media screen and (max-width: 768px) {
        padding: 30px 15px;
    }
`

export const InfoContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`

export const InfoHeader = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: white;
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`

export const Header = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: white;
    margin-top: 10px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: 12px;
    }
`

export const BorderBottom = styled.div`
    border-bottom: 1px solid #000000;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
`

export const FooterBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const FooterBottomInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 13px;
`