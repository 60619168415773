import React, { useState, useRef, lazy, Suspense } from 'react'

import {
    expressInterest
} from '../BodyApi.js'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body
} from '../../Modal/EditModalElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'

import {
    InfoContainer,
    LegalInfo,
    InterestAmountHeader,
    InterestAmountWrapper,
    InterestAmountButtonWrapper,
    InterestAmountButton,
    InterestAmountSymbol,
    InterestAmount,
    SubmitWrapper,
    CancelButton,
    SubmitButton,
    ToggleContainer,
    ToggleText,
    ToggleWrapper,
    Notch,
    ActiveText,
    InactiveText,
    SuccessContainer,
    SuccessMsg   
} from './ExpressedInterestElements'

import { IoClose } from 'react-icons/io5'

const Lottie = lazy(() => import('lottie-react'))

const ExpressedInterestModal = ({ 
    loggedInUser, 
    projectInfo, 
    setShowGaugeInterestModal,
    expressedInterest,
    setExpressedInterest,
    expressedInterestId,
    setExpressedInterestId, 
    expressedInterestAmount,
    setExpressedInterestAmount
}) => {
    const [interestAmount, setInterestAmount] = useState(100)
    const [customInterestAmount, setCustomInterestAmount] = useState(0)
    const [isAnonymous, setIsAnonymous] = useState(false)
    const [receiveEmail, setReceiveEmail] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)
    const [animationData, setAnimationData] = useState(null)

    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''   
            setShowGaugeInterestModal(false)
        }
    }

    const handleCloseModal = () => {
        document.body.style.overflow = 'auto'
        setShowGaugeInterestModal(false)
    }

    const handleExpressInterest = async () => {
        setIsSubmitting(true)
        let payload

        if (interestAmount === 'custom') {
            payload = {
                artist: projectInfo.project.artist._id,
                project: projectInfo.project._id,
                user: loggedInUser.id,
                amount: customInterestAmount,
                isAnonymous: isAnonymous,
                receiveEmail: receiveEmail
            }
        } else {
            payload = {
                artist: projectInfo.project.artist._id,
                project: projectInfo.project._id,
                user: loggedInUser.id,
                amount: interestAmount,
                isAnonymous: isAnonymous,
                receiveEmail: receiveEmail
            }
        }

        const response = await expressInterest(
            payload
        )

        if (response) {
            setIsSubmitting(false)
            setExpressedInterest(true)
            setExpressedInterestId(response._id)
            setExpressedInterestAmount(response.amount)
            setShowSuccessMsg(true)

            // Load animation data only when needed
            import('./animation.json').then(module => setAnimationData(module.default))
        }
    }

    const selectedStyle = {
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: '1px solid #4CAF50'
    }

    const legalInfo = "*No money or other consideration is being solicited, and if sent in response, will not be accepted. No sales of securities will be made or commitment to purchase accepted until qualification of the offering statement by the Securities and Exchange Commission and approval of any other required government or regulatory agency and only through an intermediary's platform. An indication of interest made by a prospective investor is non-binding and involves no obligation or commitment of any kind."

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal style={{ width: 500 }}>
                    <Header>
                        <HeaderText>Express Interest</HeaderText>
                        <div onClick={handleCloseModal} style={{ cursor: 'pointer' }} >
                            <IoClose size={25} />
                        </div>
                    </Header>
                    {!isSubmitting && !showSuccessMsg ? (
                        <Body style={{ padding: 20 }}>
                            <InfoContainer>
                                <p>What is the most you would be interested in investing into this project?</p>
                            </InfoContainer>
                            <InterestAmountButtonWrapper>
                                <InterestAmountButton style={interestAmount === 100 ? selectedStyle : {}} onClick={() => setInterestAmount(100)}>$100</InterestAmountButton>
                                <InterestAmountButton style={interestAmount === 500 ? selectedStyle : {}} onClick={() => setInterestAmount(500)}>$500</InterestAmountButton>
                                <InterestAmountButton style={interestAmount === 1000 ? selectedStyle : {}} onClick={() => setInterestAmount(1000)}>$1000</InterestAmountButton>
                                <InterestAmountButton style={interestAmount === 5000 ? selectedStyle : {}} onClick={() => setInterestAmount(5000)}>$5000</InterestAmountButton>
                                <InterestAmountButton style={interestAmount === 10000 ? selectedStyle : {}} onClick={() => setInterestAmount(10000)}>$10,000</InterestAmountButton>
                                <InterestAmountButton style={interestAmount === 25000 ? selectedStyle : {}} onClick={() => setInterestAmount(25000)}>$25,000</InterestAmountButton>
                                <InterestAmountButton style={interestAmount === 'custom' ? selectedStyle : {}} onClick={() => setInterestAmount('custom')}>Custom</InterestAmountButton>
                            </InterestAmountButtonWrapper>
                            {interestAmount === 'custom' && (
                                <>
                                    <InterestAmountHeader>Expressed interest in dollars</InterestAmountHeader>
                                    <InterestAmountWrapper>
                                        <InterestAmountSymbol>$</InterestAmountSymbol>
                                        <InterestAmount
                                            onChange={(e) => setCustomInterestAmount(e.target.value)}
                                            type="number"
                                            placeholder="Minimum $100"
                                        />
                                    </InterestAmountWrapper>
                                </>
                            )}
                            <LegalInfo>{legalInfo}</LegalInfo>
                            <ToggleContainer>
                                <ToggleText>I want my expressed interest to remain anonymous</ToggleText>
                                <ToggleWrapper
                                    isActive={isAnonymous}
                                    onClick={() => setIsAnonymous(!isAnonymous)}
                                >
                                    {isAnonymous && <ActiveText>Yes</ActiveText>}
                                    <Notch isActive={isAnonymous}></Notch>
                                    {!isAnonymous && <InactiveText>No</InactiveText>}
                                </ToggleWrapper>
                            </ToggleContainer>
                            <ToggleContainer>
                                <ToggleText>I want to receive an email notification when this project goes live</ToggleText>
                                <ToggleWrapper
                                    isActive={receiveEmail}
                                    onClick={() => setReceiveEmail(!receiveEmail)}
                                >
                                    {receiveEmail && <ActiveText>Yes</ActiveText>}
                                    <Notch isActive={receiveEmail}></Notch>
                                    {!receiveEmail && <InactiveText>No</InactiveText>}
                                </ToggleWrapper>
                            </ToggleContainer>
                            <SubmitWrapper>
                                <CancelButton>Cancel</CancelButton>
                                <SubmitButton onClick={handleExpressInterest}>Submit</SubmitButton>
                            </SubmitWrapper>
                        </Body>
                    ) : showSuccessMsg ? (
                        <Body>
                            <SuccessContainer>
                                <SuccessMsg>
                                    Your interest has been successfully submitted! You can view this project in your investment profile page.
                                </SuccessMsg>
                                <Suspense fallback={<div>Loading animation...</div>}>
                                    {animationData && (
                                        <Lottie 
                                            loop={false}
                                            animationData={animationData} 
                                            style={{ width: 200, height: 200 }} 
                                        />
                                    )}
                                </Suspense>
                                <SubmitWrapper>
                                    <CancelButton onClick={handleCloseModal} >Close</CancelButton>
                                </SubmitWrapper>
                            </SuccessContainer>
                        </Body>
                    ) : (
                        <Body style={{ height: 470 }}>
                            <LoadContainer>
                                <div style={{position: "relative"}}>
                                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                                    </OuterSpinner>
                                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                    </Spinner>
                                </div>
                            </LoadContainer>
                        </Body>
                    )}
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default ExpressedInterestModal