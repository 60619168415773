import React, { useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentInfoValue } from '../helpers/reducerHelper'
import { isFieldInvalid } from '../helpers/isFieldInvalid'

import DragBar from '../Components/DragBar'
import CustomTipInput from '../Components/CustomTipInput'
import FinancialInfoNonAcc from '../Components/FinancialInfoNonAcc'
import FinancialnfoAcc from '../Components/FinancialnfoAcc'

import { 
    StepWrapper, 
    Step, 
    StepMobile,
    InputWrapper, 
    MoneyInputWrapper,
    MoneyInput,
    MoneySymbol,
    InputLabel,
    QuestionBubble,
    PerkWrapper,
    PerkInfo,
    PerkInfoDiv,
    SmallCheckMark,
    PerksContainer,
    InvestContainer,
    InvestorLimitHeader,
    InvestorLimit,
    InvestLimitAmount,
    TipTitle,
    TipInfo,
    CustomButton,
    InvestBottomBorder,
    QuestionInfoBox,
    QuestionInfo
} from '../ProjectInvestElements'

import { PiGiftLight, PiMinusLight } from 'react-icons/pi'
import { BsCheckLg } from 'react-icons/bs'
import { BiErrorAlt } from 'react-icons/bi'

function StepTwo ({ state, dispatch, currentStop, setCurrentStop, perks, invalidDOB, setInvalidDOB }) {
    const [questionBubbleHover, setQuestionBubbleHover] = useState(false)
    const [questionBubblePosition, setQuestionBubblePosition] = useState('top')
    const [isCustomTip, setIsCustomTip] = useState(false)
    const [isValidInvestment, setIsValidInvestment] = useState(true)
    const [hoveredPerkIndex, setHoveredPerkIndex] = useState(null)

    const questionBubbleRef = useRef(null)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    if (!state || !state.store) {
        return null // or return some fallback UI
    }  

    const isAccreditedValue = getCurrentInfoValue('isAccredited', state)
    // const investmentAmountValue = getCurrentInfoValue('Investment Amount', state)

    const handleChange = (e) => {
        // Remove all non-digits and replace any leading zeros
        const rawValue = e.target.value.replace(/[^\d]/g, '')
        if (rawValue === '') {
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Investment Amount' } })
            setIsValidInvestment(true)
            return
        }
        // Remove leading zeros
        const cleanedValue = rawValue.replace(/^0+/g, '')

        if (Number(cleanedValue) < 100 && e.target.value.length > 0) {
            setIsValidInvestment(false)
        } else {
            setIsValidInvestment(true)
        }

        // Convert to number, then back to string and add commas
        const formattedValue = Number(cleanedValue).toLocaleString('en-US')
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'Investment Amount' } })
    }

    const handleInputChange = (value, label) => {
        const rawValue = value.replace(/[^\d]/g, '')
        if (rawValue === '') {
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label } })
            return
        }
        const cleanedValue = rawValue.replace(/^0+/g, '')

        const formattedValue = Number(cleanedValue).toLocaleString('en-US')
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label } })
    }

    const handleYearlyIncomeChange = (e) => {
        handleInputChange(e.target.value, 'Yearly Income')
    }
    
    const handleNetWorthChange = (e) => {
        handleInputChange(e.target.value, 'Net Worth')
    }

    const calculateInvestLimit = (netWorth, yearlyIncome) => {
        const baseLimit = 2500
        const threshold = 124000
        let limit = baseLimit
    
        const greaterValue = Math.max(netWorth, yearlyIncome)
    
        if (netWorth < threshold || yearlyIncome < threshold) {
            limit = Math.max(limit, 0.05 * greaterValue)
        } else if (netWorth >= threshold && yearlyIncome >= threshold) {
            limit = Math.min(0.10 * greaterValue, threshold)
        }
    
        return Math.floor(limit)
    }
    
    const investLimitAmount = '$' + calculateInvestLimit(Number(getCurrentInfoValue('Net Worth', state).replace(/,/g, '')), Number(getCurrentInfoValue('Yearly Income', state).replace(/,/g, ''))).toLocaleString('en-US')   
    
    const investmentAmountValue = getCurrentInfoValue('Investment Amount', state)
    const cleanInvestmentAmount = Number(investmentAmountValue.replace(/,/g, ''))

    const formatAmount = (amount) => {
        if (amount >= 1000000) {
            return (amount / 1000000) + 'M'
        } else if (amount >= 1000) {
            return (amount / 1000) + 'K'
        }
        return amount.toString()
    }

    let perksMapping

    if (perks) {
        perksMapping = perks.map((perk, index) => (
            <React.Fragment key={index}>
                <PerkWrapper onMouseEnter={() => setHoveredPerkIndex(index)} onMouseLeave={() => setHoveredPerkIndex(null)}>
                    {hoveredPerkIndex === index && 
                        <PerkInfoDiv style={isTabletOrMobile && index === 0 ? { left: -60 } : {}}>
                            {perk.header} - {perk.details}
                        </PerkInfoDiv>
                    }
                    {cleanInvestmentAmount >= perk.amount && <SmallCheckMark><BsCheckLg size={8}/></SmallCheckMark>}
                    <PiGiftLight size={30} color={cleanInvestmentAmount >= perk.amount ? undefined : 'grey'} />
                    <PerkInfo style={cleanInvestmentAmount >= perk.amount ? undefined : { color: 'grey' }}>${formatAmount(perk.amount)}</PerkInfo>
                </PerkWrapper>
                {index !== perks.length - 1 && (
                    <div style={{ marginBottom: 8 }}>
                        <PiMinusLight size={20} style={{ margin: '0 10px' }} />
                    </div>
                )}
            </React.Fragment>
        ))
    }

    let styles

    if (isTabletOrMobile) {
        styles = { top: '-375px', left: '80px' }
    } else {
        styles = questionBubblePosition === 'bottom' ? { top: '-350px', left: '310px' } : { top: '5px', left: '310px' }
    }

    return (
        <StepWrapper>
            {isDesktopOrLaptop &&
                <Step><div style={{ marginRight: 20 }}>2.</div>Investment Amount</Step>
            }
            {isTabletOrMobile &&
                <StepMobile>2. Investment Amount</StepMobile>
            }
            <InputWrapper style={isTabletOrMobile ? { marginLeft: 0 } : {}}>
                <InputLabel isInvalid={isFieldInvalid('Investment Amount', state) || !isValidInvestment}>Amount to invest (USD)</InputLabel>
                <MoneyInputWrapper>
                    <MoneySymbol>$</MoneySymbol>
                    <MoneyInput 
                        isInvalid={isFieldInvalid('Investment Amount', state) || !isValidInvestment}
                        placeholder='minimum $100'
                        type='text'
                        value={getCurrentInfoValue('Investment Amount', state)}
                        onChange={handleChange}
                        onKeyPress={(e) => !/\d/.test(e.key) && e.preventDefault()}
                    />
                </MoneyInputWrapper>
                {!isValidInvestment && <InputLabel style={{ color: 'red', display: 'flex', alignItems: 'center' }}><BiErrorAlt color='red' size={16} style={{ marginRight: 5 }}/> The minimum investment is $100 </InputLabel>}
            </InputWrapper>
            <InputWrapper style={isTabletOrMobile ? { marginLeft: 0, marginBottom: 20 } : { marginBottom: 20, minWidth: 475 }}>
                <TipTitle>Tip Muugen Invest Services</TipTitle>
                <TipInfo>Muugen Invest offers a 0% platform fee for both creators and investors. We&apos;re committed to serving the community, and operate thanks to the support of your donations.</TipInfo>
                {isCustomTip 
                ?
                    <CustomTipInput state={state} dispatch={dispatch} setIsCustomTip={setIsCustomTip} />
                :
                    <>
                        <DragBar state={state} dispatch={dispatch} currentStop={currentStop} setCurrentStop={setCurrentStop} />
                        <CustomButton onClick={() => setIsCustomTip(true)} >Enter custom amount</CustomButton>
                    </>
                }
                <TipInfo>Your tip does not contribute to your yearly investment limit.</TipInfo>
            </InputWrapper>
            <InvestContainer style={isTabletOrMobile ? { marginLeft: 0 } : { minWidth: 475 }}>
                <InvestorLimitHeader>To continue, we need to ask the financial information below.</InvestorLimitHeader>
                {isAccreditedValue
                ?
                    <FinancialnfoAcc state={state} dispatch={dispatch} />
                :
                    <FinancialInfoNonAcc state={state} handleYearlyIncomeChange={handleYearlyIncomeChange} handleNetWorthChange={handleNetWorthChange} />
                }
                <InvestorLimit>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        Maximum amount you can invest per year 
                        <QuestionBubble
                            ref={questionBubbleRef}
                            onMouseEnter={() => {
                                const rect = questionBubbleRef.current.getBoundingClientRect()
                                const screenHeight = window.innerHeight || document.documentElement.clientHeight
                            
                                if (rect.top > screenHeight / 2) {
                                    // Bubble is in the bottom half of the screen
                                    setQuestionBubblePosition('bottom')
                                } else {
                                    // Bubble is in the top half of the screen
                                    setQuestionBubblePosition('top')
                                }
                                setQuestionBubbleHover(true)
                            }}
                            onMouseLeave={() => setQuestionBubbleHover(false)}
                        >?</QuestionBubble>
                        {questionBubbleHover && (
                            <QuestionInfoBox style={styles} >
                                <div style={{ fontSize: 15, fontWeight: 700, marginBottom: 10 }}>Regulation Crowdfunding: SEC Investment Limits</div>
                                <QuestionInfo style={{ fontSize: 13 }}>1. If your annual income or net worth is below $124,000:</QuestionInfo>
                                <ul style={{ marginTop: 5 }}>
                                    <li style={{ marginLeft: 10 }}>You can invest the greater of $2,500 or 5% of the greater of your annual income or net worth over 12 months.</li>
                                </ul>
                                <QuestionInfo style={{ fontSize: 13 }}>2. If both your annual income and net worth are $124,000 or above:</QuestionInfo>
                                <ul style={{ marginTop: 5 }}>
                                    <li style={{ marginLeft: 10 }}>You can invest up to 10% of the greater of your annual income or net worth.</li>
                                    <li style={{ marginLeft: 10, marginTop: 5 }}>Your total investment cannot exceed $124,000 over 12 months.</li>
                                </ul>
                                <QuestionInfo style={{ fontSize: 13 }}>
                                    3. Your income and net worth can be jointly calculated with your spouse, but the combined investment limit must not be exceeded.
                                </QuestionInfo>
                                <QuestionInfo style={{ fontSize: 13 }}>
                                    4. Investment limits are for every 12-month period. This applies to all Regulation Crowdfunding offerings you invest in during the 12-month period, not just this one.
                                </QuestionInfo>
                                <QuestionInfo style={{ fontSize: 13 }}>
                                    5. Accredited investors have no set limit.
                                </QuestionInfo>
                            </QuestionInfoBox>                        
                        )}
                    </div>
                    <InvestLimitAmount>{isAccreditedValue ? 'Unlimited' : investLimitAmount}</InvestLimitAmount>
                </InvestorLimit>
                <InvestBottomBorder />
            </InvestContainer>
            <PerksContainer style={isTabletOrMobile ? { marginLeft: 0 } : { minWidth: 475 }}>
                {perksMapping}
            </PerksContainer>
        </StepWrapper>
    )
}

export default StepTwo