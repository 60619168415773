import React from 'react'

const SearchContext = React.createContext({
    isLoading: false,
    isError: false,
    setIsLoading: () => {},
    setIsError: () => {},
    page: 1,
    setPage: () => {},
    limit: 1,
    setLimit: () => {},
    totalPages: 1,
    setTotalPages: () => {},
    genreOneSelect: 'Any Genre',
    setGenreOneSelect: () => {},
    genreTwoSelect: 'Any Genre',
    setGenreTwoSelect: () => {},
    budgetSelect: 'Any budget',
    setBudgetSelect: () => {},
    fundingSelect: 'Any funding status',
    setFundingSelect: () => {},
    sortSelect: 'Popularity',
    setSortSelect: () => {},
    searchTerm: '',
    setSearchTerm: () => {},
    durationSelect: 'Any Duration',
    setDurationSelect: () => {}
})

export default SearchContext