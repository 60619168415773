import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

export const Nav = styled.nav`
    position: ${({isScrolled}) => isScrolled ? 'fixed' : 'absolute'};
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: /* 0.5rem calc((100vw - 1000px) / 2) */ 20px;
    background-color: transparent; /* Add the color of your choice */;
    z-index: 10;
    transition: 0.3s ease;
`

export const Separator = styled.div`
  height: 24px;
  width: 1px;
  background-color: #fff;
  margin: 0 15px;
`

export const NavDropdown = styled.div`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`

export const NavLink = styled(Link)`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    min-width: 70px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
        font-size: 12px;
        padding: 6px 8px;
    }
`

export const PseudoLink = styled.div`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px; 

    @media screen and (max-width: 768px) {
        display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #000; // Set the background color for the mobile menu
        padding: 20px;
        box-sizing: border-box;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: #256ce1;
    padding: 10px 22px;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-left: 24px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606; /*ffd56b 010606*/
    }
`

export const Button = styled.button`
    width: 100px;
    padding: 13px;
    background-color: transparent; /*#28df99 #d8c292 */
    border: 1px solid white;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        background: #212121;
        color: #FAF9F6;
        border: 1px solid #212121;
    }
`

export const IconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    background: transparent;

    &:hover {
        background-color: #20262E;
    }

    &:active {
        background-color: black; // Change to a slightly different color when clicked
    }

    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }
`

export const NotificationWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
`

export const NotificationNumber = styled.div`
    position: absolute;
    font-family: 'DM Sans', sans-serif;
    font-size: 10px;
    top: 3px;
    left: 20px;
    background-color: white;
    color: black;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

export const SearchButton = styled.button`
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center; 
    background: none; 
    border: none;
    color: white;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }
`

export const SearchInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 50%;

    @media screen and (max-width: 768px) {
        flex: 1;
        margin: 0 10px;
        max-width: 50%;
    }
`

export const SearchInput = styled.input`
    width: 100%;
    border: none;
    border-bottom: 1px solid white;
    background: transparent;
    color: white;
    font-size: 14px;
    outline: none;
    padding-bottom: 3px;

    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
        color: white;
    }

    @media screen and (max-width: 768px) {
        width: calc(100% - 23px);
    }
`

export const Menu = styled.div`
    position: fixed;
    background: white;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100vh;
    right: ${({ show }) => (show ? '0' : '-100%')};
    left: ${({ show }) => (show ? '0' : '-300px')};
    top: 0;
    padding-top: 50px;
    transition: left 0.5s ease;
    box-shadow: ${({ show }) => (show ? '2px 0 5px rgba(0, 0, 0, 0.1)' : 'none')};
    z-index: 1000;
`

export const ExitWrapper = styled.div`
    position: absolute;
    top: 10px; /* Add padding from the top */
    right: 10px; /* Add padding from the right */
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #686D76;

    &:hover {
        color: black;
    }
`

export const MenuItem = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    color: #686D76;

    &:hover {
        color: black;
        background: #F7F7F7;
    }
`

export const MenuTitle = styled.div`
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
`

export const SearchResultsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 150px;
    width: 90%;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 1000;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 100%;
    }
`

export const NextContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100%;
    border: none;
    background: white;
    cursor: pointer;
    border-radius: 3px;

    @media screen and (max-width: 958px) {
        min-width: 50px;
    }

    @media screen and (max-width: 768px) {
        height: 50px;
        width: 100%;
    }
`

export const LeftContainer = styled(NextContainer)`
    &:hover {
        background: #eee;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
`

export const RightContainer = styled(NextContainer)`
    &:hover {
        background: #eee;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
`

export const RotatedLeftIcon = styled(IoIosArrowBack)`
    @media screen and (max-width: 768px) {
        transform: rotate(90deg);
    }
`

export const RotatedRightIcon = styled(IoIosArrowForward)`
    @media screen and (max-width: 768px) {
        transform: rotate(90deg);
    }
`

export const ResultsWrapper = styled.div`
    border-radius: 3px;
    width: 100%;
    height: 100%;
    display: flex;
`

export const SearchResult = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;

    @media screen and (max-width: 768px) {
        height: 100px;
    }
    
    &:hover{
        background: #eee;
    }
`

export const HeaderText = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 90%;
`

export const SubHeaderText = styled.div`
    font-size: 12px;
    font-weight: 300;
    width: 80%;
`

const Type = styled.div`
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    padding: 5px;
`

export const InvestorType = styled(Type)`
    background: #D8EFD3;
    /* color: #55AD9B; */
`

export const ProjectType = styled(Type)`
    background: #FCDC94;
    /* color: #EF9C66; */
`

export const ArtistType = styled(Type)`
    background: #A0DEFF;
    /* color: #5AB2FF; */
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${({ show }) => (show ? 'block' : 'none')};
`