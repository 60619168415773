import React, { useRef } from 'react'
import { NEW, DELETE } from '../../../../../constants' 
import { Wrapper, HoveredDiv } from './DeleteModalElements'
import { FiTrash } from 'react-icons/fi'
import { useOutsideClick } from '../../../../../helpers/useOutsideClick'

function DeleteModal({ teamMembers, setTeamMembers, index, deleteArray, setDeleteArray }) {
  const modalRef = useRef(null)
  // useOutsideClick(modalRef, teamMembers, setTeamMembers, index)

  const handleOutsideClick = () => {
    const tempArray = [...teamMembers]
    if (tempArray[index]) {
      tempArray[index].showDeleteModal = false
    }
    setTeamMembers(tempArray)
  }
  
  useOutsideClick(modalRef, handleOutsideClick)

  const handleDeleteMember = (index) => {
    const tempArray = [...teamMembers]
    const tempDelete = [...deleteArray]
    if (tempArray[index].status !== NEW) {
      tempArray[index].index = index
      tempDelete.push(tempArray[index])
      tempArray[index].status = DELETE
      setDeleteArray(tempDelete)
    }
    tempArray.splice(index, 1)
    setTeamMembers(tempArray)
  }

  return (
    <Wrapper ref={modalRef}>
      <HoveredDiv onClick={() => handleDeleteMember(index)}>
        <FiTrash style={{ marginRight: 10 }} />
        Delete
      </HoveredDiv>
    </Wrapper>
  )
}

export default DeleteModal
