import styled from 'styled-components'


// Logged in user's profile elements
export const UserProfileBarContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    background: #F5F5F5;
    border-bottom: 1px solid #D8D9DA;
`

export const OptionButton = styled.button`
    height: 60px;
    background-color: transparent;
    border: none;
    color: ${props => props.selected ? 'black' : 'grey'};
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    border-bottom: ${props => props.selected ? '2px solid black' : 'none'};

    &:hover {
        color: black;
    }
`