import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import UserProfileModal from '../../../NonMainNav/UserProfileModal'

import { 
    IconWrapper,
} from '../../../NonMainNav/NonMainNavElements'

import {
    Next,
    Back
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    Logo,
    LeftRightWrapper,
    LeftHalfPage,
    RightHalfContainer,
    RightHalfPage,
    NextArea,
    SignupLogin,
    InputAbsoluteWrapper,
} from '../../CreateProjectElements'

import LeftHalfInfo from '../../LeftHalfInfo'
import InputFields from './InputFields'
import MobileView from '../../MobileView'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { PiUserCircleThin } from 'react-icons/pi'
import { FaUserCircle } from 'react-icons/fa'

function VideoEmbed({ 
    iconWrapperRef,
    modalRef,
    showProfileModal,
    setShowProfileModal,
    setLoggedInUser,
    handleNavigateHome,
    setCurrentStep, 
    videoEmbed, 
    setVideoEmbed 
}) { 
    const [isInvalid, setIsInvalid] = useState(false)
    const [validationError, setValidationError] = useState('')

    const handleNext = () => {
        if (!videoEmbed || validationError) {
            setIsInvalid(true)
        } else 
            setCurrentStep(4)
    }

    const StepThreeDescription = 'Add a video to your project that describes/shows your project.'
    const StepThreeDescriptionTwo = "You can add a video embed link from YouTube, Vimeo, or Dailymotion. Be sure you're using the embed link and not the share link. Only include the <iframe> tag and no other description elements like a <p> element."

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 801px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 800px)'
    })

    const mobileDescription = 'Add a video to your project that describes/shows your project.'

    return (
        <>
        {isDesktopOrLaptop &&
            <LeftRightWrapper>
                <LeftHalfPage>
                    <Logo onClick={handleNavigateHome} >muugen</Logo>
                    <LeftHalfInfo step={3} title={'Video Embed'} description={StepThreeDescription} descriptionTwo={StepThreeDescriptionTwo} />
                </LeftHalfPage>
                <RightHalfPage>
                <SignupLogin>
                    <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                        {!showProfileModal ? <PiUserCircleThin color='black' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='black' size={25} style={{ cursor: 'pointer' }} />}
                    </IconWrapper>
                    </SignupLogin>
                    {showProfileModal && <div style={{ position: 'absolute', right: 5 }}><UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} setLoggedInUser={setLoggedInUser} /></div>}
                    <RightHalfContainer style={{ paddingBottom: 125 }}>
                        <InputAbsoluteWrapper>
                            <InputFields 
                                isInvalid={isInvalid} 
                                setIsInvalid={setIsInvalid} 
                                videoEmbed={videoEmbed} 
                                setVideoEmbed={setVideoEmbed} 
                                validationError={validationError} 
                                setValidationError={setValidationError}
                            />
                        </InputAbsoluteWrapper>
                    </RightHalfContainer>
                <NextArea>
                    <Back onClick={() => setCurrentStep(2)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} /> Cover Photo</Back>
                    <Next onClick={handleNext} style={{ width: 170 }} >Next: Funding</Next>
                </NextArea>
                </RightHalfPage>
            </LeftRightWrapper>
        }
        {isTabletOrMobile && 
            <MobileView step={3} title={'Video Embed'} description={mobileDescription} handleNext={handleNext} setCurrentStep={setCurrentStep} >
                <InputFields 
                    isInvalid={isInvalid} 
                    setIsInvalid={setIsInvalid} 
                    videoEmbed={videoEmbed} 
                    setVideoEmbed={setVideoEmbed}
                    validationError={validationError} 
                    setValidationError={setValidationError}
                />
            </MobileView>
        }
        </>
    )
}   

export default VideoEmbed