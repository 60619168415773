import styled from 'styled-components'
import { PrimaryColor } from '../../../../colors'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    position: relative;
`

export const SectionSelect = styled.div`
    position: sticky;
    top: 100px;
    width: 80px;
    margin-right: 30px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const SectionSelectButton = styled.button`
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    background: transparent;
    color: grey;
    border: none;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;
    margin-bottom: 5px;

    &:hover {
        color: ${PrimaryColor};
    }

    &:focus {
        outline: none;
    }
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const HeaderWrapper = styled.div`
    display: flex;
    height: auto;
`

export const Header = styled.div`
    font-size: 20px;
    margin-top: 15px;
    font-weight: bold;
`

export const Budget = styled.div`
    font-size: 16px;
`

export const EditButton = styled.button`
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: black;
    border-radius: 4px;
    border: 1px solid black;
    cursor: pointer;
    margin-bottom: 5px;
`

export const NoInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    background: #EEEEEE;
    border-radius: 5px;
`

export const ProfileWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const ProfilePhoto = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
    border-radius: 50%;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const ProfilePhotoOverlay = styled.div`
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const Photo = styled.img`
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    /* object-position: center top; adjust to show the desired area of the image */
`

export const Name = styled.input`
    padding: 10px;
    height: 50px;
    width: 200px;
`

export const Role = styled.input`
    padding: 10px;
    margin-left: 20px;
    height: 50px;
    width: 150px;
` 

export const Description = styled.textarea`
    padding: 10px;
    margin-top: 20px;
    height: 100px;
    width: 100%;
    resize: none;
`

export const Add = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const IconCircleDiv = styled.div`
    height: 30px;
    width: 30px;
    margin-left: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;

    &:hover {
        background: #EEEEEE;
    }
`

export const ShowMore = styled.button`
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #19A7CE;
    border: none;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 10px;

    &:hover {
        background: #EEEEEE;
        border: 1px solid #EEEEEE;
        border-radius: 4px;
    }
`