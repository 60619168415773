import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import UserProfileModal from '../../../NonMainNav/UserProfileModal'

import { 
    IconWrapper
} from '../../../NonMainNav/NonMainNavElements'

import {
    Next,
    Back
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    Logo,
    LeftRightWrapper,
    LeftHalfPage,
    RightHalfContainer,
    RightHalfPage,
    NextArea,
    SignupLogin,
    InputAbsoluteWrapper,
} from '../../CreateProjectElements'

import LeftHalfInfo from '../../LeftHalfInfo'
import InputFields from './InputFields'
import MoblieView from '../../MobileView'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { PiUserCircleThin } from 'react-icons/pi'
import { FaUserCircle } from 'react-icons/fa'

function Funding({ 
    iconWrapperRef,
    modalRef,
    setShowProfileModal,
    setLoggedInUser,
    showProfileModal,
    handleNavigateHome,
    setCurrentStep, 
    fundingAmount, 
    setFundingAmount, 
    equityAmount, 
    setEquityAmount, 
    bonusReturn, 
    setBonusReturn,
    projectCategory,
    setProjectCategory,
    projectFundingType,
    setProjectFundingType
}) {
    const [isInvalid, setIsInvalid] = useState(false)
    const [isEquityInvalid, setIsEquityInvalid] = useState(false)
    const [equityTooHigh, setEquityTooHigh] = useState(false)

    const handleNext = () => {
        let invalidInput = false
    
        if (fundingAmount === '' || fundingAmount === '0') {
            setIsInvalid(true)
            invalidInput = true
        }
    
        if (equityAmount === '' || equityAmount === '0' || equityAmount === '00' || equityAmount === '000') {
            setIsEquityInvalid(true)
            invalidInput = true
        }
    
        if (equityAmount > 100) {
            setIsEquityInvalid(true)
            setEquityTooHigh(true)
            invalidInput = true
        }
    
        // Only run if the other conditions didn't pass
        if (!invalidInput && Number(fundingAmount.replace(/,/g, ""))) {
            setCurrentStep(5)
        }
    }
    
    const StepFourDescription = 'Set a funding goal and equity stake for your project. The funding goal is the amount you need to raise in order to complete your project. Equity stake refers to the proportion of shares given to investors, which ultimately determines how the profits will be shared among all parties involved. This can be changed at any time before you launch your campaign.'
    const StepFourDescriptionTwo = 'The maximum amount allowed for REG CF projects is $5,000,000. For larger amounts (REG A Tier One, REG A Tier Two), please contact us via email.'

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 801px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 800px)'
    })

    const mobileDescription = 'Set a funding goal and equity stake for your project. This can be changed at any time before you launch your campaign.'

    return (
        <>
            {isDesktopOrLaptop &&
                <LeftRightWrapper>
                    <LeftHalfPage>
                        <Logo onClick={handleNavigateHome}>muugen</Logo>
                        <LeftHalfInfo step={4} title={'Funding'} description={StepFourDescription} descriptionTwo={StepFourDescriptionTwo} />
                    </LeftHalfPage>
                    <RightHalfPage>
                        <SignupLogin>
                            <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                                {!showProfileModal ? <PiUserCircleThin color='black' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='black' size={25} style={{ cursor: 'pointer' }} />}
                            </IconWrapper>
                        </SignupLogin>
                        {showProfileModal && <div style={{ position: 'absolute', right: 5 }}><UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} setLoggedInUser={setLoggedInUser} /></div>}
                        <RightHalfContainer style={{ paddingBottom: 80 }} >
                            <InputAbsoluteWrapper style={{ top: -40 }} >
                                <InputFields 
                                    projectCategory={projectCategory}
                                    setProjectCategory={setProjectCategory}
                                    projectFundingType={projectFundingType}
                                    setProjectFundingType={setProjectFundingType}
                                    fundingAmount={fundingAmount} 
                                    setFundingAmount={setFundingAmount} 
                                    equityAmount={equityAmount}
                                    setEquityAmount={setEquityAmount}
                                    isInvalid={isInvalid} 
                                    setIsInvalid={setIsInvalid} 
                                    isEquityInvalid={isEquityInvalid}
                                    setIsEquityInvalid={setIsEquityInvalid}
                                    equityTooHigh={equityTooHigh}
                                    setEquityTooHigh={setEquityTooHigh}
                                />
                            </InputAbsoluteWrapper>
                        </RightHalfContainer>
                        <NextArea>
                            <Back onClick={() => setCurrentStep(3)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} /> Video Link</Back>
                            <Next onClick={handleNext} style={{ width: 170 }} >Next: Schedule</Next>
                        </NextArea>
                    </RightHalfPage>
                </LeftRightWrapper>
            }
            {isTabletOrMobile &&
                <MoblieView step={4} title={'Funding'} description={mobileDescription} handleNext={handleNext} setCurrentStep={setCurrentStep} >
                    <InputFields 
                        projectCategory={projectCategory}
                        setProjectCategory={setProjectCategory}
                        projectFundingType={projectFundingType}
                        setProjectFundingType={setProjectFundingType}
                        fundingAmount={fundingAmount} 
                        setFundingAmount={setFundingAmount} 
                        equityAmount={equityAmount}
                        setEquityAmount={setEquityAmount}
                        bonusReturn={bonusReturn}
                        setBonusReturn={setBonusReturn}
                        isInvalid={isInvalid} 
                        setIsInvalid={setIsInvalid} 
                        isEquityInvalid={isEquityInvalid} 
                        setIsEquityInvalid={setIsEquityInvalid} 
                        equityTooHigh={equityTooHigh}
                        setEquityTooHigh={setEquityTooHigh}
                    />
                </MoblieView>
            }
        </>
    )
}   

export default Funding