import React from 'react'

import { DotPulse, LoadingContainer, LoadingText } from './UploadModalElements'
import { ModalBg, Modal } from '../../Modal/EditModalElements.js'

function UploadModal({ uploadStatus, setUploadStatus }) {


    return (
        <ModalBg>
            <Modal style={{ width: 250, height: 250, top: '30%' }}>
                <LoadingContainer>
                    <LoadingText>Updating</LoadingText>
                    <DotPulse />
                </LoadingContainer>
            </Modal>
        </ModalBg>
    )
}

export default UploadModal