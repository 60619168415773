import React from 'react'
import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: absolute;
  top: -70px;
  transform: translateX(-50%);
  width: 325px;
  left: 100px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
`

const InfoModal = ({ isVisible, children }) => {
  if (!isVisible) return null

  return <ModalWrapper>{children}</ModalWrapper>
}

export default InfoModal