import styled from 'styled-components'

export const UserProfileWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    padding-top: 60px;
    padding-left: 60px;
    padding-bottom: 60px;

    @media only screen and (max-width: 1044px) {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const UserProfileContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 1200px;

    @media only screen and (max-width: 1044px) {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const UserInfo = styled.div`
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    @media only screen and (max-width: 1044px) {
        margin-left: 20px;
    }
`

export const ProjectsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media only screen and (max-width: 1044px) {
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 20px; /* Maintain the same gap between items */
    }
`

export const Project = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px; // Full width or desired width
    position: relative; // Needed to position the content inside absolutely
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        padding-top: 56.25%; // 9 / 16 = 0.5625 or 56.25%
        // This creates a pseudo-element that maintains our 16:9 aspect ratio
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 2px;
        // This ensures that the content inside the div is positioned correctly
        // and fills the entire space.
    }
`

export const ProjectImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ProjectTitle = styled.h1`
    font-size: 18px;
    margin-bottom: 12px;
    font-weight: 700;
`

export const ProjectDescription = styled.p`
    font-size: 15px;
`

export const ProjectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ProjectName = styled.h1`
    font-size: 15px;
    margin-top: 3px;
    width: 300px;
    font-weight: 500;
`

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 625px;

    @media only screen and (max-width: 1044px) {
        width: 100%;
    }
`

export const ProjectGenreWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const ProjectGenre = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 6px 12px;
    border-radius: 5px;
    background-color: lightgray;
    color: black;
    font-size: 14px;
    font-weight: 500;
`

export const AboutUnderline = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin-top: 10px;
`

//CARD
export const CardWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const UserCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 2px;
    transition: all 0.3s ease;
    width: 300px;
    padding: 20px;

    @media only screen and (max-width: 1044px) {
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        border: none;
    }
`

export const UserCardNoImageContainer = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
`

export const UserCardImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
`

export const UserCardName = styled.h1`
    text-align: center;
    font-size: 1.25rem;
    margin-top: 12px;
`

export const UserMiniBio = styled.p`
    font-size: 14px;
    margin-top: 6px;
`

export const UserRoles = styled.p`
    font-size: 12px;
    margin-top: 6px;
`

export const FollowButton = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  cursor: pointer;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

    &.unfollow:hover::after {
        content: 'Unfollow';
    }

    &.unfollow:hover {
        color: transparent;
    }

    &.unfollow:hover::after {
        color: initial;
        position: absolute;
    }

  ${props => props.className === 'follow' && `
    background-color: black;
    color: white;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  `}

  ${props => props.className === 'unfollow' && `
    background-color: #DCDCDC;
    color: black;

    &:hover {
      background-color: lightgray;
    }
  `}
`

//ABOUT CARD
export const AboutCardContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 300px;
    padding: 20px;

    @media only screen and (max-width: 1044px) {
        width: 100%;
        align-items: space-between;
        border: none;
    }
`

export const AboutInfo = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    @media only screen and (max-width: 1044px) {
        width: 100%;
        font-weight: bold;
        font-size: 16px;
    }
`