import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import { PrimaryColor, PrimaryHoverColor } from '../../colors'

export const Nav = styled.nav`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    /* padding: 0.5rem calc((78vw - 1000px) / 2); */
    z-index: 10;
    background: white;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)); */
`

export const NavButton = styled.button`
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    background: none;
    border: none;

    &:hover {
        text-decoration: underline;
    }
`

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;


@media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer
}`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavDropdown = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    /* margin-right: 24px; */

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: #256ce1;
    /* padding: 10px 22px; */
    color: white; /*#fff*/
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    /* margin-left: 24px; */

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606; /*ffd56b 010606*/
    }
`

export const Button = styled.button`
    width: 100px;
    /* padding: 13px; */
    background-color: transparent; /*#28df99 #d8c292 */
    border: 1px solid #DFDFDE;
    color: black; /*#212121 */
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        background: #212121;
        color: #FAF9F6;
        border: 1px solid #212121;
    }
`

export const IconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    background: transparent;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:hover {
        background-color: #F2F2F2;
    }

    &:active {
        background-color: #E0E0E0; // Change to a slightly different color when clicked
    }

    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`

export const NotificationWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
`

export const NotificationNumber = styled.div`
    position: absolute;
    font-size: 10px;
    top: 3px;
    left: 20px;
    background-color: black;
    color: white;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

export const SearchInput = styled.input`
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    background: transparent;
    color: black;
    font-size: 15px;
    outline: none;
    padding-bottom: 3px;

    @media screen and (max-width: 768px) {
        font-size: 14px;
        padding: 5px 0;
    }
`

export const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

export const SignupButton = styled(Link)`
    border: none;
    background: ${PrimaryColor};
    border-radius: 2px;
    color: white;
    font-weight: 300;
    padding: 7px;
    font-size: 12px;
    margin-right: 10px;
    text-decoration: none;

    &:hover {
        background: ${PrimaryHoverColor};
    }
`