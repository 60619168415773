import styled from 'styled-components'

export const AwardsContainer = styled.div`
    margin-top: 8px;
    width: 95%;
    height: 100%;
    display: table;
`

export const AwardTable = styled.table`
    border: 1px solid #eeeeee;
    width: 100%;
    border-collapse: collapse;
`

export const TableBody = styled.tbody`
    box-sizing: border-box;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
`

export const TableRow = styled.tr`
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
`

export const YearTableCell = styled.td`
    border: 1px solid #eeeeee;
    padding: 8px 10px;
    width: 40px;
`

export const OutcomeTableCell = styled.td`
    border: 1px solid #eeeeee;
    padding: 8px 10px;
    width: 150px;
`

export const DescriptionTableCell = styled.td`
    border: 1px solid #eeeeee;
    padding: 8px 10px;
`

export const OutcomeText = styled.div`
    font-size: 17px;
`

export const EventText = styled.div`
    font-size: 12px;
    color: #cdc9c3;
`

export const AwardType = styled.div`
    font-size: 15px;
`

export const Film = styled.div`
    font-size: 13px;
`