import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'

import {
    AdminArtistsContainer,
    Table,
    Th,
    Td,
} from '../../components/AdminArtists/AdminArtistsElements'

import {
    SearchBar,
} from '../../components/AdminProjects/AdminProjectElements'

import { MdOutlineArrowRightAlt } from "react-icons/md"

import AdminNavbar from '../../components/Admin/AdminNavbar'

const AdminAcceptRejectList = () => {
    const [artists, setArtists] = useState([])
    const [displayArtists, setDisplayArtists] = useState([])

    useEffect(() => {
        // Fetch all artists from the database
        // Display the artists in the table
        // Add functionality to accept or reject artists
        const fetchArtists = async () => {
            try {
                const response =
                await axios.get(`${API_URL}/admin/artist-status-records`, { withCredentials: true })
                setArtists(response.data)
                setDisplayArtists(response.data)
            } catch (e) {
                console.log(e)
            }
        }

        fetchArtists()
    }, [])

    const handleArtistSearch = (e) => {
        const searchValue = e.target.value.toLowerCase()
        if (searchValue) {
            // Filter based on the search input across first name, last name, or full name
            const filteredArtists = artists.filter(artist => {
                // Combine first name and last name for a full name search
                const fullName = `${artist.artistFirstName.toLowerCase()} ${artist.artistLastName.toLowerCase()}`
                return fullName.includes(searchValue) || 
                       artist.artistFirstName.toLowerCase().includes(searchValue) ||
                       artist.artistLastName.toLowerCase().includes(searchValue)
            })
            setDisplayArtists(filteredArtists) // Update displayed artists with filtered list
        } else {
            // If search input is cleared, display all artists
            setDisplayArtists(artists)
        }
    }

    const artistMapping = displayArtists.map((artist, index) => {
        return (
            <tr key={index}>
                <Td>{artist.artistFirstName} {artist.artistLastName}</Td>
                <Td>{artist.user}</Td>
                <Td>{artist.artistEmail}</Td>
                <Td>{artist.artistType}</Td>
                <Td>{artist.linkToWork}</Td>
                <Td>{artist.portfolioLink}</Td>
                <Td>{artist.accepted ? 'accepted' : 'rejected'}</Td>
            </tr>
        )
    })

    return (
        <AdminArtistsContainer>
            <AdminNavbar />
            <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Artist Accept/Reject List</h1>
            </div>
            <SearchBar onChange={handleArtistSearch} placeholder="Search for artists by name" />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <MdOutlineArrowRightAlt style={{ fontSize: 50, color: 'black', cursor: 'pointer', float: 'right' }} />
            </div>
            <Table>
            <thead>
                <tr>
                    <Th>Artist Name</Th>
                    <Th>User ID</Th>
                    <Th>Email</Th>
                    <Th>Artist Type</Th>
                    <Th>Link to Work</Th>
                    <Th>Portfolio/Website</Th>
                    <Th>Accept/Reject</Th>
                </tr>
            </thead>
            <tbody>
                {artists ? artistMapping : <tr><Td colSpan="10" style={{ textAlign: 'center' }}>No artists</Td></tr>}
            </tbody>
        </Table>
        </AdminArtistsContainer>
    )
}

export default AdminAcceptRejectList