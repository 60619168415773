import React, { useCallback, useState } from 'react'
import { DATABASE, NEW, CHANGE } from '../../../../../constants'
import { 
    addCastApi, 
    editCastApi, 
    deleteCastApi,
    deleteImagesApi, 
    multipleImagesApi 
} from '../detailsApi'
import TeamCard from '../TeamCard'
import CastMemberCard from './CastMemberCard'

import { 
    HeaderWrapper, 
    NoInfo,
} from '../ProjectDetailElements'

import { EditButton } from '../../Perks/PerkElements'
import { AiFillEdit } from 'react-icons/ai'

const Cast = ({ project, setProjectInfo, isUploading, setIsUploading, loggedInUser }) => {
    const [editCast, setEditCast] = useState(false)
    const [cast, setCast] = useState([{ imageFileName: '', previewFileName: '', name: '', role: '', description: '', imageFile: [], index: 0, status: NEW }])
    const [imageFiles, setImageFiles] = useState([])
    const [deleteArray, setDeleteArray] = useState([])

    const { _id, slug } = project

    const handleEditClick = () => {
        if (!editCast) {
            setEditCast(true)
    
            if (project.cast.length > 0) {
                setCast(
                    project.cast.map((obj, index) => ({
                        ...obj,
                        imageFile: [],
                        status: DATABASE,
                        index: index,
                    })),
                )
            }
        } else {
            setEditCast(false)
        }
    }

    const handleImageSelected = useCallback((file, index) => {
        setCast(prevCast => prevCast.map((member, i) =>
          i === index ? {
            ...member,
            imageFile: [file].map(f => Object.assign(f, { preview: URL.createObjectURL(f) })),
            previewFileName: file.path,
          } : member
        ))
      
        if (cast[index].status === CHANGE) {
          setImageFiles(prevImageFiles => {
            const updatedImageFiles = [...prevImageFiles]
            const existingIndex = updatedImageFiles.findIndex(imageFile => imageFile.path === cast[index].imageFileName)
            if (existingIndex !== -1) {
              updatedImageFiles[existingIndex] = file
            } else {
              updatedImageFiles.push(file)
            }
            return updatedImageFiles
          })
        } else {
          setImageFiles(prevImageFiles => [...prevImageFiles, file])
        }
    }, [setImageFiles, setCast, cast])

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault()
        setIsUploading(true)

        // Filter out team members that have been changed or added
        const filteredChange = cast.filter(member => member.status === CHANGE)

        const filteredNew = cast.reduce((acc, member, index) => {
            if (member.status === NEW) {
              const newMember = { ...member, index }
              acc.push(newMember)
            }
            return acc
          }, [])

        let updatedCast = []
          
        // If there are changes to existing team members, send a request to update them
        if (filteredChange.length > 0) {
            try {
                if (imageFiles.length > 0) {
                      try {
                        const fileNamesToDelete =  filteredChange.map(change => change.oldImageName)

                        await deleteImagesApi(slug, fileNamesToDelete)
                      } catch (error) {
                        console.log(error)
                      }
                }

                const response = await editCastApi(_id, filteredChange, slug)

                if (response.data) {
                    updatedCast = [...response.data]
                }
            } catch (error) {  
                console.log(error)
            }
        }

        if (deleteArray.length > 0) {  
            try {
                const response = await deleteCastApi(deleteArray, slug, _id)

                if (response.data) {
                    updatedCast = [...response.data]
                }

                setDeleteArray([])
            } catch (error) {
                console.log(error)
            }
        }

        // If there are new cast members, send a request to add them
        if (filteredNew.length > 0) {
            try {
                const response = await addCastApi(_id, filteredNew, slug)
                if (response.data) {
                    updatedCast = [...response.data]
                }
            } catch (error) {
                console.log(error)
            }
        }

        // If there are images to be uploaded, send a request to upload them
        if (imageFiles.length > 0) {
            let formData = new FormData()

            imageFiles.forEach(image => formData.append('images', image, image.name))

            try {
                await multipleImagesApi(slug, formData)
            } catch (error) {
                console.log(error)
            }
            setImageFiles([])
        }

        setProjectInfo(prevProjectInfo => {
            return {
                ...prevProjectInfo,
                project: {
                    ...prevProjectInfo.project,
                    cast: updatedCast
                }
            }
        })
        setIsUploading(false)
        setEditCast(false)
    }, [cast, deleteArray, imageFiles, slug, _id, setProjectInfo, setIsUploading])

    let castAddMapping

    if (project) {
        castAddMapping = cast.map((member, index) => {
            return (
                <div key={index}>
                    <CastMemberCard 
                        project={project}
                        editCast={editCast}
                        cast={cast}
                        setCast={setCast}
                        member={member}
                        index={index}
                        deleteArray={deleteArray}
                        setDeleteArray={setDeleteArray}
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles}
                        handleImageSelected={handleImageSelected}
                    />
                </div>
            )
        })
    }

    const castMapping = project?.cast?.map((member, index) => {
        return (
            //MUST CHANGE KEY
            <div key={index}>
                <TeamCard 
                    project={project} 
                    imageFileName={member?.imageFileName} 
                    name={member?.name} 
                    role={member?.role} 
                    description={member?.description} 
                />
            </div>
        )
    })

    const renderTeam = editCast
        ? castAddMapping
        : project && project.cast.length > 0
        ? castMapping
        : <NoInfo>No team members have been added yet.</NoInfo>

    return (
        <div>
            <HeaderWrapper style={{ display: 'flex' }}>
            {(loggedInUser?.artist === project?.artist._id || project?.authorizedCollaborators?.includes(loggedInUser?.id)) ? (
                <EditButton onClick={handleEditClick}>
                    <AiFillEdit size={20} style={{ marginRight: 3 }} />
                    {!editCast ? 'Edit Cast' : 'Cancel'}
                </EditButton>
            ) : ''}
                {editCast ? <EditButton style={{ marginLeft: 5 }} onClick={handleSubmit}>Save and Publish</EditButton> : null}
            </HeaderWrapper>
            {renderTeam}
        </div>
    )
}

export default React.memo(Cast)