import styled from 'styled-components'

export const PictureContainer = styled.div`
    height: 150px;
    width: 150px;
    border-radius: 5px;
    /* border-radius: 50%; */
    border: 2px solid white;
    background-color: #eee;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
        margin-top: 30px;
        height: 80px;
        width: 80px;
    }
`

export const Picture = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`