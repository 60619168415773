import React, { useState, useEffect } from 'react'
import DOMPurify from 'dompurify'
import { getScriptApi, deleteMediaApi, deleteScriptApi } from './MediaApis'

import { ThreeDots } from 'react-loader-spinner'

import { 
    Wrapper, 
    MediaWrapper,
    ScriptMediaContainer,
    DeleteLayer,
    ScriptContainer,
    IFrameContainer
} from './ProjectMediaElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'
import { NoInfo } from '../Details/ProjectDetailElements'
import { EditButton } from '../Perks/PerkElements'

import MediaModal from './MediaModal'

import { AiOutlineDelete } from 'react-icons/ai'

function ProjectMedia({ project, setProjectInfo, loggedInUser }) {
    const [isLoading, setIsLoading] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false)
    const [media, setMedia] = useState([])
    const [script, setScript] = useState([])
    const [isHovered, setIsHovered] = useState(-1)
    const [isScriptHovered, setIsScriptHovered] = useState(-1)
    const [scriptLoading, setScriptLoading] = useState(false)

    useEffect(() => {
        if (media?.length === 0) {
        //check if the media is a script or a video
            const scriptArray = project?.mediaElement?.filter(element => element.isScript)
            const mediaArray = project?.mediaElement?.filter(element => !element.isScript)
            setScript(scriptArray)
            setMedia(mediaArray)

            setIsLoading(false)
        }

    // eslint-disable-next-line
    }, [isLoading, project?.mediaElement])

    const handleScriptClick = async (index) => {
        setScriptLoading(true)
        try {
            const response = await getScriptApi(project, index, script[index].fileName)
            setScriptLoading(false)

            const file = new Blob([response.data], { type: 'application/pdf' })

            const fileURL = URL.createObjectURL(file)

            window.open(fileURL, '_blank')
        } catch (error) {
            console.log(error)
        } finally {
            setScriptLoading(false)
        }
    }

    const handleDeleteElement = async (elementId) => {
        // Add a confirmation dialog
        const confirmDelete = window.confirm("Are you sure you want to delete this media element?")
    
        // If the user confirms deletion, proceed with the deletion process
        if (confirmDelete) {
            const response = await deleteMediaApi(project._id, elementId)

            if (response.status === 200) {
                // Remove the deleted media element from the media array
                const updatedEmbeds = media.filter(element => element._id !== elementId)
                const updatedMedia = project?.mediaElement?.filter((element) => element._id !== elementId)
    
                // Update the media state
                setMedia(updatedEmbeds)

                // Update the project state
                setProjectInfo(prevProjectInfo => {
                    return {
                      ...prevProjectInfo,
                      project: {
                        ...prevProjectInfo.project,
                        mediaElement: updatedMedia
                      }
                    }
                })
            }
        }
    }

    const handleDeletePdf = async (mediaId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this pdf?")
        if (confirmDelete) {
          // Find the media element based on the mediaId
          const mediaElement = script.find((element) => element._id === mediaId)
      
          if (mediaElement) {
            // If the media element is a script, delete the script
            await deleteScriptApi(project.slug, mediaElement.fileName)
      
            const response = await deleteMediaApi(project._id, mediaId)
      
            if (response.status === 200) {
              // Remove the deleted media element from the media array
              const updatedMedia = project.mediaElement.filter((element) => element._id !== mediaId)
              const updatedScript = script.filter(element => element._id !== mediaId)

              setScript(updatedScript)
      
              // Update the project state
              setProjectInfo((prevProjectInfo) => {
                return {
                  ...prevProjectInfo,
                  project: {
                    ...prevProjectInfo.project,
                    mediaElement: updatedMedia,
                  },
                }
              })
            }
          } else {
            console.log("Media element not found")
          }
        }
    }

    const mediaElements = Array.isArray(media) && media.map((element, index) => {
        let sanitizedIframeCode = DOMPurify.sanitize(element.fileName, {
            ALLOWED_TAGS: ['iframe'],
            ALLOWED_ATTR: ['src', 'title', 'frameborder', 'allow', 'allowfullscreen', 'referrerpolicy'],
            ALLOW_UNKNOWN_PROTOCOLS: true,
        })

        // Extract the src attribute value from the sanitized iframe code
        const srcMatch = sanitizedIframeCode.match(/src="([^"]+)"/)
        const srcValue = srcMatch ? srcMatch[1] : ''

        return (
                <div key={element._id} style={{ position: 'relative' }} onMouseEnter={() => setIsHovered(index)} onMouseLeave={() => setIsHovered(-1)}>
                    <DeleteLayer 
                        onClick={() => handleDeleteElement(element._id)} 
                        style={isHovered === index && loggedInUser?.artist === project?.artist?._id
                        ? 
                        { opacity: 1, zIndex: 50, position: 'absolute', bottom: 10, left: 10 } 
                        : 
                        { opacity: 0, zIndex: 50, position: 'absolute' }} >
                        <AiOutlineDelete color='white' />
                    </DeleteLayer>
                <div
                    style={{
                        position: 'relative',
                        paddingBottom: '56.25%', // 16:9 aspect ratio
                        height: 0,
                        overflow: 'hidden',
                        maxWidth: '100%',
                        width: '100%',
                        marginBottom: 10,
                    }}
                >
                    <IFrameContainer
                        src={srcValue}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></IFrameContainer>
                </div>
                </div>
        )
    })

    const scriptElements = Array.isArray(script) && script.map((element, index) => {
        return (
            <ScriptMediaContainer key={element._id} style={{ marginTop: 0, position: 'relative' }} onMouseEnter={() => setIsScriptHovered(index)} onMouseLeave={() => setIsScriptHovered(-1)}>
                <React.Fragment>
                    {scriptLoading && index === isScriptHovered ?
                    <div style={{ position: 'absolute', top: 20, left: '35%' }}>
                        <ThreeDots color='black' />
                    </div>
                    : null
                    }
                    <ScriptContainer onClick={() => handleScriptClick(index)} >
                        {element.title}
                    </ScriptContainer>
                    <DeleteLayer 
                        onClick={() => handleDeletePdf(element._id)} 
                        style={isScriptHovered === index && loggedInUser?.artist?.slug === project?.artist?.slug 
                        ? 
                            { opacity: 1, zIndex: 11 } 
                        : 
                            { opacity: 0, zIndex: 11 }} 

                    >
                        <AiOutlineDelete color='white' />
                    </DeleteLayer>
                </React.Fragment>
            </ScriptMediaContainer>
        )
    })

    return (
        <Wrapper>
            {isLoading 
            ? 
                <LoadContainer>
                    <div style={{position: "relative"}}>
                        <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                            <OuterPath cx="25" cy="25" r="20" fill="none" />
                        </OuterSpinner>
                        <Spinner width="50" height="50" viewBox="0 0 50 50">
                            <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                        </Spinner>
                    </div>
                </LoadContainer> 
            :
            <>
                {showEditModal && <MediaModal setShowEditModal={setShowEditModal} project={project} setProjectInfo={setProjectInfo} media={media} setMedia={setMedia} setScript={setScript} />}
                {(loggedInUser?.artist === project?.artist._id || project?.authorizedCollaborators?.includes(loggedInUser?.id)) && (
                    <EditButton onClick={() => setShowEditModal(true)}>
                        Add Media
                    </EditButton>
                )}
                {media?.length > 0 && <div style={{ marginBottom: 5, fontWeight: 'bold' }}>Videos</div>}
                {
                    media?.length > 0 && mediaElements
                }
                {script?.length > 0 && <div style={{ marginBottom: 5, marginTop: 30, fontWeight: 'bold' }}>PDFs</div>}
                <MediaWrapper>
                    {
                        script?.length > 0 && scriptElements
                    }
                </MediaWrapper>
                {media?.length === 0 && script?.length === 0 && <NoInfo>No media elements added yet.</NoInfo> }
            </>
            }
        </Wrapper>
    )
}

export default ProjectMedia