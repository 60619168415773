import styled from 'styled-components'

export const Wrapper = styled.div`
    /* position: sticky;
    position: -webkit-sticky;  */
    user-select: none;
    /* background: white; */
    top: 120px;
    /* bottom: 0; */
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* border: 1px solid #D8D9DA; */
    border-radius: 3px;
    z-index: 101;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;

    transition: 0.25s ease-in-out;
`

export const Header = styled.div`
    font-size: 22px;
    margin-bottom: 5px;
    font-family: 'DM Sans', sans-serif;
`

export const CreatorContainer = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: #B2B2B2;
    margin-bottom: 10px;
    display: flex;
`

export const CreatorHeader = styled.div`
    font-family: 'DM Sans', sans-serif;
`

export const Creator = styled.a`
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;

    &:hover {
        color: black;
        text-decoration: underline;
    }
`

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;

`

export const ProgressBar = styled.div`
    position: relative;
    height: 5px;
    width: 100%;
    background: #EEEEEE;
    margin-bottom: 10px;
`

export const Progress = styled.div`
    position: absolute;
    background: #68B984;
    height: 5px;
    width: 50%;
`

export const ProgressHeader = styled.div`
    font-size: 22px;
    margin-bottom: 5px;
    margin-right: 4px;
`

export const ProgressSubHeader = styled.div`
    font-size: 11px;
    color: gray;
    margin-bottom: 5px;
`

export const Backers = styled.div`
    margin-top: 5px;
    font-size: 22px;
`

export const InvestButton = styled.button`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    border: none;
    background: black;
    color: white;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`

export const SocialWrapper = styled.div`
    cursor: pointer;
    width: auto;
    color: gray;
    display: inline-block;

    &:hover {
        color: black;
        transform: translateY(-1px);
    }
`
