import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { searchGaugingInterest } from '../discoverApi'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@tanstack/react-query'
import SearchContext from '../SearchContext'

import {
    FilterWrapper,
    DropdownHeader,
} from '../DiscoverElements'

import { genres, budget } from '../options'

import Dropdown from './Dropdown'

const GaugingInterest = ({ 
    searchTerm, 
    setSearchTerm,
    applyFiltersAndSearch, 
    selected, 
    setSelected, 
    projects, 
    searchedProjects, 
    setSearchedProjects, 
}) => {
    const navigate = useNavigate()

    const { 
        setIsLoading, 
        setIsError, 
        page, 
        setPage,
        limit, 
        setTotalPages,
        genreOneSelect,
        setGenreOneSelect,
        genreTwoSelect,
        setGenreTwoSelect,
        budgetSelect,
        setBudgetSelect,
        sortSelect,
        setSortSelect 
    } = useContext(SearchContext)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 765px)' })

    const useSearchGaugingInterest = (payload, page, limit) => {
        return useQuery({
            queryKey: ['searchGaugingInterest', payload, page, limit],
            queryFn: () => searchGaugingInterest(payload, page, limit),
            enabled: Object.values(payload).some(val => val !== ''),
        })
    }

    const { data, isLoading, isError } = useSearchGaugingInterest({
        genreOneSelect,
        genreTwoSelect,
        budgetSelect,
        sortSelect,
        searchTerm,
        page,
        limit
    })

    useEffect(() => {
        setIsLoading(isLoading)
        setIsError(isError)
    }, [isLoading, isError, setIsLoading, setIsError])

    useEffect(() => {
        if (data) {
            setSearchedProjects(data.data.projects || [])
            // Handle pagination metadata
            const { totalPages: totalPagesFromResponse } = data.data
            setTotalPages(totalPagesFromResponse)
        }
    }, [data, setSearchedProjects, setTotalPages])

    const constructUrl = () => {
        const queryParams = {
            selected,
            genreOneSelect,
            genreTwoSelect,
            budgetSelect,
            sortSelect,
            searchTerm,
            page,
            limit,
        }
    
        return `/discover?${queryString.stringify(queryParams)}`
    }

    useEffect(() => {
        const url = constructUrl()
        navigate(url)

        //eslint-disable-next-line
    }, [selected, genreOneSelect, genreTwoSelect, budgetSelect, sortSelect, searchTerm, page, limit])

    const handleSearch = () => {
        setPage(1)
    }

    return (
        <>
        {!isTabletOrMobile ?
        <FilterWrapper>
            <DropdownHeader>
                Discover&nbsp;
                {
                    <>
                    <Dropdown
                        options={genres} 
                        defaultOption={genreOneSelect}
                        isGenre={true}
                        className='firstContainer'
                        select={genreOneSelect}
                        setSelect={(value) => {
                            setGenreOneSelect(value)
                            handleSearch()
                        }}
                        type='genreOne'
                    />
                    &nbsp;
                    <Dropdown 
                        options={genres} 
                        defaultOption={genreTwoSelect}
                        isGenre={true}
                        className='secondContainer'
                        select={genreTwoSelect}
                        setSelect={(value) => {
                            setGenreTwoSelect(value)
                            handleSearch()
                        }}
                        type='genreTwo'
                    />
                    </>
                }&nbsp;
                films that have &nbsp;
                {
                    <Dropdown 
                        options={budget} 
                        defaultOption={budgetSelect}
                        className='fourthContainer'
                        select={budgetSelect}
                        setSelect={(value) => {
                            setBudgetSelect(value)
                            handleSearch()
                        }}
                        type='budget'
                    />
                } and are sorted by &nbsp;
                {
                    <Dropdown 
                        options={["Popularity", "Newest", "Most Interest", "Most Backers"]} 
                        defaultOption={sortSelect}
                        className='fifthContainer'
                        select={sortSelect}
                        setSelect={(value) => {
                            setSortSelect(value)
                            handleSearch()
                        }}
                        type='sort'
                    />
                }
            </DropdownHeader>
        </FilterWrapper>
        :
        <div style={{ lineHeight: 3.25 }}>
            <Dropdown
                options={genres} 
                defaultOption={genreOneSelect}
                isGenre={true}
                className='firstContainer'
                select={genreOneSelect}
                setSelect={(value) => {
                    setGenreOneSelect(value)
                    handleSearch()
                }}
                type='genreOne'
            />
                    &nbsp;&nbsp;
            <Dropdown 
                options={genres} 
                defaultOption={genreTwoSelect}
                isGenre={true}
                className='secondContainer'
                select={genreTwoSelect}
                setSelect={(value) => {
                    setGenreTwoSelect(value)
                    handleSearch()
                }}
                type='genreTwo'
            />&nbsp;&nbsp;
            <Dropdown 
                options={budget} 
                defaultOption={budgetSelect}
                className='fourthContainer'
                select={budgetSelect}
                setSelect={(value) => {
                    setBudgetSelect(value)
                    handleSearch()
                }}
                type='budget'
            />&nbsp;&nbsp;
            <Dropdown 
                options={["Popularity", "Newest", "Most Interest", "Most Backers"]} 
                defaultOption={sortSelect}
                className='fifthContainer'
                select={sortSelect}
                setSelect={(value) => {
                    setSortSelect(value)
                    handleSearch()
                }}
                type='sort'
            />
        </div>
        }
        </>
    )
}

export default GaugingInterest