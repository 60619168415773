import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import LoadingComponent from '../../LoadingAsset'
import { API_URL } from '../../../config'
import { useQuery } from '@tanstack/react-query'
import { 
    SearchResultsContainer,
    LeftContainer,
    RightContainer,
    ResultsWrapper,
    SearchResult,
    HeaderText,
    SubHeaderText,
    InvestorType,
    ProjectType,
    ArtistType,
    RotatedLeftIcon,
    RotatedRightIcon
} from '../NavbarElements'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const fetchSearchResults = async ({ query, page }) => {
    const response = await axios.post(`${API_URL}/home/search`, { query, page })
    return response.data
}

const SearchResults = ({ searchQuery, page, setPage, searchResultsRef }) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ['searchResults', { query: searchQuery, page }],
        queryFn: () => fetchSearchResults({ query: searchQuery, page }),
        keepPreviousData: true,
    })

    const navigate = useNavigate()

    const totalPages = Math.ceil(data?.totalCount / 3)

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1)
    }

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1)
    }

    const addEllipsis = (str, maxLength = 50) => {
        if (str?.length <= maxLength) {
            return str
        }

        if (!str)
            return ''

        return str?.slice(0, maxLength) + '...'
    }

    const handleResultClick = (type, item) => {
        if (item.type === 'project') 
            navigate(`/project-profile/${item._id}`)
        else if (item.type === 'user')
            navigate(`/user-profile/${item.username}`)
        else if (item.type === 'artist')
            navigate(`/artist-profile/${item.slug}`)
    }

    if (isLoading) return (
        <SearchResultsContainer>
            <LeftContainer onClick={handlePreviousPage} disabled={page === 1}><IoIosArrowBack size={25} /></LeftContainer>
            <LoadingComponent />
            <RightContainer onClick={handleNextPage} disabled={page === totalPages}><IoIosArrowForward size={25} /></RightContainer>
        </SearchResultsContainer>
    )

    if (isError) return (
        <SearchResultsContainer>
            <LeftContainer onClick={handlePreviousPage} disabled={page === 1}><IoIosArrowBack size={25} /></LeftContainer>
            <div style={{ fontWeight: 'bold', fontSize: 20 }}>There was an Error. Please refresh and try again.</div>
            <RightContainer onClick={handleNextPage} disabled={page === totalPages}><IoIosArrowForward size={25} /></RightContainer>
        </SearchResultsContainer>
    )

    return (
        <SearchResultsContainer ref={searchResultsRef} >
            <LeftContainer onClick={handlePreviousPage} disabled={page === 1}><RotatedLeftIcon size={25} /></LeftContainer>
            {data && data?.items?.map((item, index) => (
                <ResultsWrapper key={item._id} onClick={() => handleResultClick(item.type, item)}>
                    <SearchResult>
                        <HeaderText>{addEllipsis(item?.projectName) || addEllipsis(item?.displayName) || addEllipsis(item?.artistName) || addEllipsis(item?.username)}</HeaderText>
                        <SubHeaderText>{addEllipsis(item?.logline) || addEllipsis(item?.miniBio) || addEllipsis(item?.artistMiniBio)}</SubHeaderText>
                        {item.type === 'project' && <ProjectType>Project</ProjectType>}
                        {item.type === 'user' && <InvestorType>Investor</InvestorType>}
                        {item.type === 'artist' && <ArtistType>Artist</ArtistType>}
                    </SearchResult>
                </ResultsWrapper>
            ))}
            <RightContainer onClick={handleNextPage} disabled={page === totalPages}><RotatedRightIcon size={25} /></RightContainer>
        </SearchResultsContainer>
    )
}

export default SearchResults