import React from 'react'

import { 
    UserProfileBarContainer,
    OptionButton,
} from './UserProfileBarElements'

import { LuFolderClosed } from 'react-icons/lu'
import { AiOutlineHeart } from 'react-icons/ai'
import { LiaHandPointUpSolid } from 'react-icons/lia'
import { FiUser } from 'react-icons/fi'
import { PiGearSix } from 'react-icons/pi'

const UserProfileBar = ({ selectedOption, setSelectedOption }) => {
    return (
        <UserProfileBarContainer>
            <OptionButton
                onClick={() => setSelectedOption('portfolio')} 
                selected={selectedOption === 'portfolio'}
            >
                <LuFolderClosed size='16px' style={{ marginRight: 8 }}/> Portfolio
            </OptionButton>
            <OptionButton
                onClick={() => setSelectedOption('interest')} 
                selected={selectedOption === 'interest'}
            >
                <LiaHandPointUpSolid size='19px' style={{ marginRight: 8 }}/>Expressed Interest
            </OptionButton>
            <OptionButton
                onClick={() => setSelectedOption('followers')} 
                selected={selectedOption === 'followers'}
            >
                <FiUser size='18px' style={{ marginRight: 8 }} /> Followers
            </OptionButton>
            <OptionButton
                onClick={() => setSelectedOption('watchlist')}
                selected={selectedOption === 'watchlist'}
            >
                <AiOutlineHeart size='18px' style={{ marginRight: 8 }} /> Watch List
            </OptionButton>
            <OptionButton
                onClick={() => setSelectedOption('account')} 
                selected={selectedOption === 'account'}
            >
                <PiGearSix size='18px' style={{ marginRight: 8 }} /> Account
            </OptionButton>
        </UserProfileBarContainer>
    )
}

export default UserProfileBar