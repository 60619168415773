import React from 'react'
import {
    Wrapper,
    BoxBottom,
    Divider,
    OptionContainer,
    Option,
    OptionWrapper,
    Halo,
} from './ProfileInfoElements'

function ProfileInfo({
    isProjects,
    isVideos,
    isBio,
    handleProjectsClick,
    handleVideosClick,
    handleBioClick,
}) {
    const projectHalo = isProjects ? <Halo /> : ''
    const videoHalo = isVideos ? <Halo /> : ''
    const bioHalo = isBio ? <Halo /> : ''

    return (
        <Wrapper>
            <Divider />
            <OptionContainer>
                <OptionWrapper onClick={handleProjectsClick}>
                    {projectHalo}
                    <Option className={isProjects ? 'selected' : 'unselected'}>Projects</Option>
                </OptionWrapper>
                <OptionWrapper onClick={handleBioClick} >
                    {bioHalo}
                    <Option className={isBio ? 'selected' : 'unselected'}>About</Option>
                </OptionWrapper>
                <OptionWrapper onClick={handleVideosClick}>
                    {videoHalo}
                    <Option className={isVideos ? 'selected' : 'unselected'}>Videos</Option>
                </OptionWrapper>
            </OptionContainer>
            <BoxBottom></BoxBottom>
        </Wrapper>
    )
}

export default ProfileInfo
