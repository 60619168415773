import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    MobileWrapper,
    Logo,
    Step,
    MobileTitle,
    MobileButton,
    MobileBackButton,
    MobileDescription,
} from './CreateProjectElements'

function MobileView({ children, step, title, description, handleNext, setCurrentStep }) {
    const navigate = useNavigate()

    const handleNavigateHome = () => {
        navigate('/')
    }

    return (
        <MobileWrapper>
            <Logo onClick={handleNavigateHome}>muugen</Logo>
            <Step style={{ marginTop: '100px' }}>{step} of 6</Step>
            <MobileTitle>{title}</MobileTitle>
            <MobileDescription>{description}</MobileDescription>

                {children}

            <MobileButton onClick={handleNext} >Next</MobileButton>
            {step > 1 && <MobileBackButton onClick={() => setCurrentStep(prev => prev - 1)} >Back</MobileBackButton>}
        </MobileWrapper>
    )
}

export default MobileView