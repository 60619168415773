import React from 'react'

import SignupComponent from '../components/Signup'

function Signup() {
    return (
        <div>
            <SignupComponent />
        </div>
    )
}

export default Signup
