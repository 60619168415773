import React from 'react'
import Footer from './components/Home/Footer'

const Layout = ({ children, showFooter = true }) => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh' 
    }}>
      <main style={{ flex: 1 }}>
        {children}
      </main>
      {showFooter && <Footer />}
    </div>
  )
}

export default Layout