import React, { useState, useEffect } from 'react'
import { getCurrentInfoValue } from '../helpers/reducerHelper'

import { 
    MoneyInputWrapper, 
    MoneySymbol, 
    MoneyInput,
    InputWrapper,
    InputLabel,
    CustomButton
} from '../ProjectInvestElements'

import { BiErrorAlt } from 'react-icons/bi'

function CustomTipInput({ state, dispatch, setIsCustomTip }) {
    const [lastValidValue, setLastValidValue] = useState('')
    const [invalidTip, setInvalidTip] = useState(false)

    useEffect(() => {
        const investmentValueString = getCurrentInfoValue('Investment Amount', state)
        let investmentValue = parseFloat(investmentValueString.replace(/,/g, '')) // Convert string with commas to number

        // Check for NaN and reset to 0 if necessary
        if (isNaN(investmentValue)) {
            investmentValue = 0
        }

        const tipValue = parseFloat(getCurrentInfoValue('Tip Amount', state).replace(/,/g, ''))
        const maxTipValue = 0.25 * investmentValue

        if (tipValue > maxTipValue) {
            setInvalidTip(true)
        } else {
            setInvalidTip(false)
        }
    }, [state])

    const handleInput = (e) => {
        // Regular expression that matches valid numbers (e.g., 123, 123.45)
        const validNumberRegex = /^\d*(\.\d{0,2})?$/
    
        if (validNumberRegex.test(e.target.value)) {
            // Allow the input, but notify the user if it's too high
            setLastValidValue(e.target.value)
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Tip Amount' } })
        } else {
            e.target.value = lastValidValue  // Reset to the last valid value if current input is invalid
        }
    }    

    const handleReturnToDefault = () => {
        setIsCustomTip(false)
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '0.00', label: 'Tip Amount' } })
    }
    
    return (
        <InputWrapper style={{ marginLeft: 0, marginTop: 20 }}>
            <InputLabel>Tip Amount</InputLabel>
            <MoneyInputWrapper>
                <MoneySymbol>$</MoneySymbol>
                <MoneyInput
                    placeholder='0.00'
                    isInvalid={invalidTip}
                    type='text'
                    pattern="\d*\.?\d*"
                    style={{ width: 200 }}
                    onChange={handleInput}
                />
            </MoneyInputWrapper>
            {invalidTip && <InputLabel style={{ color: 'red', display: 'flex', alignItems: 'center', marginBottom: 5 }}><BiErrorAlt color='red' size={16} style={{ marginRight: 5 }}/> Tip can&apos;t exceed 25% of Investment Amount.</InputLabel>}
            <CustomButton onClick={handleReturnToDefault} >Return to default</CustomButton>
        </InputWrapper>
    )
}

export default CustomTipInput
