import React, { useEffect, useState, lazy, Suspense } from 'react'
import { CLOUDFRONT_URL } from '../../../config'

import { 
    Wrapper, 
    EditButton,
    Title,
    SubtitleBar,
    Logline,
    ImageWrapper,
    ButtonWrapper,
    CreatedBy,
    FlexCenterWrapper,
    ImageContainer,
} from './HeaderElements' 

import { FaPlay } from 'react-icons/fa'
import { PiFilmScriptThin } from "react-icons/pi"

const EditModal = lazy(() => import('../Modal'))
const VideoPlayModal = lazy(() => import('../../ArtistProfile/Videos/VideoPlayModal.js'))

function Header({ projectInfo, setProjectInfo, loggedInUser }) {
    const [embedSrc, setEmbedSrc] = useState('')
    const [showPlayModal, setShowPlayModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [coverImage, setCoverImage] = useState('')

    function extractIframeSrc(iframeString) {
        const srcMatch = iframeString.match(/src="([^"]*)/i)
        if (srcMatch && srcMatch[1]) {
            return srcMatch[1]
        }
        return ''
    }

    useEffect(() => {
        const videoEmbedSrc = extractIframeSrc(projectInfo.project ? projectInfo.project.mainVideoEmbed : '')

        setEmbedSrc(videoEmbedSrc)

        setCoverImage(`${CLOUDFRONT_URL}/${projectInfo?.project?.coverImagePath}`)
    }, [embedSrc, projectInfo])

    const handleShowPlayModal = () => {
        document.body.style.overflow = 'hidden'
        setShowPlayModal(true)
    }

    const handleShowEditModal = () => {
        document.body.style.overflow = 'hidden'
        setShowModal(true)
    }

    return (
        <Wrapper>
            <Suspense>
                {showPlayModal && <VideoPlayModal setShowPlayModal={setShowPlayModal} embedSrc={embedSrc} />}
                {showModal && 
                    <EditModal 
                        setShowModal={setShowModal} 
                        projectInfo={projectInfo} 
                        setProjectInfo={setProjectInfo} 
                        loggedInUser={loggedInUser}
                    />
                }
            </Suspense>
            <ImageContainer>
                <ImageWrapper src={coverImage} alt='Movie Cover' />
            </ImageContainer>
            <FlexCenterWrapper>
                <Title>{projectInfo.project ? projectInfo?.project?.projectName.toUpperCase() : ''}</Title>
                <CreatedBy>CREATED BY <b>{projectInfo?.project?.artist?.artistName.toUpperCase()}</b></CreatedBy>
                <SubtitleBar>
                    <div>
                        {projectInfo?.project?.primaryGenre}
                        {projectInfo?.project?.secondaryGenre ? `/${projectInfo?.project?.secondaryGenre}` : ''}
                    </div>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                        <PiFilmScriptThin size={20} style={{ marginRight: 3 }}/> 120 pages
                    </div>
                </SubtitleBar>
                <Logline>{projectInfo.project ? projectInfo.project.logline : ''}</Logline>
                <ButtonWrapper onClick={handleShowPlayModal} >
                    <FaPlay size={30} />
                </ButtonWrapper>
                {(loggedInUser?.artist === projectInfo?.project?.artist._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && <EditButton onClick={handleShowEditModal}>Edit Profile</EditButton>}
            </FlexCenterWrapper>
        </Wrapper>
    )
}

export default Header