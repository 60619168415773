// ROUTER CONSTANTS
export const NEW = 'NEW'
export const EDITED = 'EDITED'
export const DATABASE = 'DATABASE'
export const CHANGE = 'CHANGE'
export const CHANGE_IMAGE = 'CHANGE_IMAGE'
export const DELETE = 'DELETE'

//DETAILS
export const CAST = 'cast'
export const TEAM = 'team'
export const BUDGET = 'budget'
export const RISKS = 'risks'
export const OFFERINGS = 'offerings'
export const SCROLL_HEIGHT = 110

