import React, { useState } from 'react'
import { AWS_URL } from '../../../../config'

import { 
    ProjectWrapper, 
    Info,
    InfoWrapper,
    BottomInfo,
    ProjectImageWrapper,
    ProjectImage,
    ImageOverlay,
    ImageText
} from './ProjectElements'

const Project = ({ 
    projectName,
    amount,
    msg, 
    date, 
    src, 
    strike, 
    option, 
    addMsg, 
    color 
}) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <ProjectWrapper
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            <InfoWrapper>
                <Info>{projectName}</Info>
                <Info style={strike ? { textDecoration: 'line-through', color: 'grey' } : {}} >${amount}</Info>
            </InfoWrapper>
            <ProjectImageWrapper>
                <ProjectImage src={`${AWS_URL}${src}`} />
                <ImageOverlay isVisible={isHovered}>
                    <ImageText>45% funded</ImageText>
                </ImageOverlay>
            </ProjectImageWrapper>
            <BottomInfo>
                    <div style={{ fontFamily: "'DM sans', serif" }}>{msg} {date}</div>
                    <div style={{ fontFamily: "'DM sans', serif", color, fontWeight: '500' }}>{addMsg}</div>
            </BottomInfo>
        </ProjectWrapper>
    )
}

export default Project