import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
    BodySection
} from '../ProjectProfile/Modal/EditModalElements'

import {
    EditTitle,
    EditDescription,
    SelectionBody,
    ModalInput,
    ButtonWrapper,
    Button,
    SaveButton
} from './AdminProjectElements'

import { IoClose } from 'react-icons/io5'
import { IoChevronBack } from 'react-icons/io5'

const ProjectModal = ({ index, project, setAllProjects, setDisplayProjects, setShowProjectModal }) => {
    const [currentSection, setCurrentSection] = useState('')
    const [editedProject, setEditedProject] = useState({})
    const [buttonValue, setButtonValue] = useState('')

    // New states for date selection
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1) // JavaScript months are 0-indexed
    const [selectedDay, setSelectedDay] = useState(new Date().getDate())
    const [daysInMonth, setDaysInMonth] = useState(31)

    // New states for time selection
    const [selectedHour, setSelectedHour] = useState(new Date().getHours())
    const [selectedMinute, setSelectedMinute] = useState(new Date().getMinutes())
    const [selectedPeriod, setSelectedPeriod] = useState('AM')

    useEffect(() => {
        setEditedProject(project)
        setButtonValue(project.projectStatus)
        if (project.campaignEndDate) {
            const dateTime = new Date(project.campaignEndDate)
            setSelectedYear(dateTime.getFullYear())
            setSelectedMonth(dateTime.getMonth() + 1)
            setSelectedDay(dateTime.getDate())
    
            // Adjust for 12-hour format
            const hours = dateTime.getHours()
            const minutes = dateTime.getMinutes()
            setSelectedHour(hours % 12 === 0 ? 12 : hours % 12)
            setSelectedMinute(minutes < 10 ? `0${minutes}` : minutes) // Ensure minutes are two digits
            setSelectedPeriod(hours >= 12 ? 'PM' : 'AM')
        }
    }, [project])

    useEffect(() => {
        const updateDaysInMonth = () => {
            const days = new Date(selectedYear, selectedMonth, 0).getDate()
            setDaysInMonth(days)
        }

        updateDaysInMonth()
    }, [selectedMonth, selectedYear])

    const handleSubmit = async () => {
        let payload = {
            ...editedProject
        }
    
        try {
            const response = await axios.post(`${API_URL}/admin/edit-project`, payload, { withCredentials: true })
            console.log(response.data)
    
            // Update current project with edited project
            setAllProjects(prev => {
                const newProjects = [...prev]
                newProjects[index] = response.data
                return newProjects
            })
    
            setDisplayProjects(prev => {
                const newProjects = [...prev]
                newProjects[index] = response.data
                return newProjects
            })
    
            alert('Project updated successfully!')
            setShowProjectModal(false)
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Display the specific error message from the server
                alert(error.response.data.error)
            } else {
                // Handle other types of errors
                console.error('An error occurred while updating the project:', error)
                alert('An error occurred while updating the project. Please try again.')
            }
        }
    }

    // const handleSubmit = async () => {
    //     let payload = {}

    //     // Use editedProject for payload
    //     payload = {
    //         ...editedProject
    //     }

    //     try {
    //         const response = await axios.post(`${API_URL}/admin/edit-project`, payload, { withCredentials: true })
    //         console.log(response.data)
    //         //update current project with edited project
    //         setAllProjects(prev => {
    //             const newProjects = [...prev]
    //             newProjects[index] = response.data
    //             return newProjects
    //         })

    //         setDisplayProjects(prev => {
    //             const newProjects = [...prev]
    //             newProjects[index] = response.data
    //             return newProjects
    //         })
            
    //         alert('Project updated successfully!')
    //         setShowProjectModal(false)
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    const handleDateChange = (value, type) => {
        // Convert the incoming value to a number since it will be received as a string from the select element
        const numericValue = Number(value)
    
        // Calculate the new date based on the type of change (year, month, or day)
        let newDate
        if (type === 'year') {
            newDate = new Date(numericValue, selectedMonth - 1, selectedDay)
            setSelectedYear(numericValue)
        } else if (type === 'month') {
            // Adjust for JavaScript's 0-indexed months
            newDate = new Date(selectedYear, numericValue - 1, selectedDay)
            setSelectedMonth(numericValue)
            // Update days in the selected month
            const days = new Date(selectedYear, numericValue, 0).getDate()
            setDaysInMonth(days)
            // Adjust selected day if it exceeds the new max days in the updated month
            if (selectedDay > days) {
                setSelectedDay(days)
                newDate.setDate(days)
            }
        } else if (type === 'day') {
            newDate = new Date(selectedYear, selectedMonth - 1, numericValue)
            setSelectedDay(numericValue)
        }
    
        // Update the edited project's campaign end date
        setEditedProject(prev => ({
            ...prev,
            campaignEndDate: newDate.toISOString()
        }))
    }

    const yearOptions = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map(year => (
        <option key={year} value={year}>
            {year}
        </option>
    ))

    const monthOptions = Array.from({ length: 12 }, (_, i) => (
        <option key={i + 1} value={i + 1}>
            {new Date(0, i).toLocaleString('en-US', { month: 'long' })}
        </option>
    ))

    const dayOptions = Array.from({ length: daysInMonth }, (_, i) => (
        <option key={i + 1} value={i + 1}>
            {i + 1}
        </option>
    ))

    const campaignEndDateSection = (
        <SelectionBody>
            <div style={{ display: 'flex', gap: '10px' }}>
                <select value={selectedMonth} onChange={(e) => handleDateChange(e.target.value, 'month')}>
                    {monthOptions}
                </select>
                <select value={selectedDay} onChange={(e) => handleDateChange(e.target.value, 'day')}>
                    {dayOptions}
                </select>
                <select value={selectedYear} onChange={(e) => handleDateChange(e.target.value, 'year')}>
                    {yearOptions}
                </select>
            </div>
            <ButtonWrapper>
                <SaveButton onClick={() => setCurrentSection('')} className='cancel'>Cancel</SaveButton>
                <SaveButton onClick={handleSubmit}>Save</SaveButton>
            </ButtonWrapper>
        </SelectionBody>
    )

    const handleTimeChange = (value, type) => {
        let hour = selectedHour
        let minute = selectedMinute
    
        if (type === 'hour') {
            hour = value
            setSelectedHour(value)
        } else if (type === 'minute') {
            minute = value
            setSelectedMinute(value)
        } else if (type === 'period') {
            // Convert 12-hour back to 24-hour format
            if (value === 'PM' && parseInt(hour) < 12) {
                hour = parseInt(hour) + 12
            } else if (value === 'AM' && parseInt(hour) === 12) {
                hour = 0
            }
            setSelectedPeriod(value)
        }
    
        const newTime = new Date(selectedYear, selectedMonth - 1, selectedDay, hour, minute);
        setEditedProject(prev => ({
            ...prev,
            campaignEndDate: newTime.toISOString()
        }))
    }

    const hourOptions = Array.from({ length: 24 }, (_, i) => (
        <option key={i} value={i}>
            {i}
        </option>
    ))

    const minuteOptions = Array.from({ length: 60 }, (_, i) => (
        // Ensure minutes are two digits
        <option key={i} value={i < 10 ? `0${i}` : i}>
            {i < 10 ? `0${i}` : i}
        </option>
    ))

    const campaignEndTimeSection = (
        <SelectionBody>
            <div style={{ display: 'flex', gap: '10px' }}>
            <select value={selectedHour} onChange={(e) => handleTimeChange(e.target.value, 'hour')}>
                {hourOptions}
            </select>
            :
            <select value={selectedMinute} onChange={(e) => handleTimeChange(e.target.value, 'minute')}>
                {minuteOptions}
            </select>
            <select value={selectedPeriod} onChange={(e) => handleTimeChange(e.target.value, 'period')}>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select>
            </div>
            <ButtonWrapper>
                <SaveButton onClick={() => setCurrentSection('')} className='cancel'>Cancel</SaveButton>
                <SaveButton onClick={handleSubmit}>Save</SaveButton>
            </ButtonWrapper>
        </SelectionBody>
    )

    const handleInputChange = (e, field) => {
        setEditedProject(prev => ({
            ...prev,
            [field]: e.target.value
        }))
    }

    const handleButtonClick = (value) => {
        setButtonValue(value)
        setEditedProject(prev => ({
            ...prev,
            projectStatus: value
        }))
    }

    const readableDate = (date) => {
        const newDate = new Date(date)
        return newDate.toLocaleDateString("en-US", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }

    return (
        <Wrapper>
            <ModalBg>
                <Modal>
                    <Header>
                        <HeaderText 
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={() => setCurrentSection('')}
                        >
                            {currentSection && <IoChevronBack />} {project._id}
                        </HeaderText>
                        <IoClose 
                            onClick={() => setShowProjectModal(false)}
                            size={25} 
                            style={{ cursor: 'pointer' }} 
                        />
                    </Header>
                    <Body>
                        {!currentSection ?
                        <>
                            <BodySection onClick={() => setCurrentSection('projectName')}>
                                Project Name
                            </BodySection>
                            <BodySection onClick={() => setCurrentSection('projectStatus')}>
                                Project Status
                            </BodySection>
                            <BodySection onClick={() => setCurrentSection('targetFundAmount')}>
                                Target Fund Amount
                            </BodySection>
                            <BodySection onClick={() => setCurrentSection('equityGiven')}>
                                Equity Given
                            </BodySection>
                            <BodySection onClick={() => setCurrentSection('campaignEndDate')}>
                                Campaign End Date
                            </BodySection>
                            <BodySection onClick={() => setCurrentSection('campaignEndTime')}>
                                Campaign End Time
                            </BodySection>
                        </>
                        :
                        currentSection === 'projectName' ?
                        <SelectionBody>
                            <EditTitle>Project Name</EditTitle>
                            <EditDescription>{project.projectName}</EditDescription>
                            <ModalInput 
                                type="text" 
                                value={editedProject.projectName || ''}
                                onChange={(e) => handleInputChange(e, 'projectName')}
                            />
                            <ButtonWrapper>
                                <SaveButton onClick={() => setCurrentSection('')} className='cancel'>Cancel</SaveButton>
                                <SaveButton onClick={handleSubmit}>Save</SaveButton>
                            </ButtonWrapper>
                        </SelectionBody>
                        :
                        currentSection === 'projectStatus' ?
                        <SelectionBody>
                            <EditTitle>Project Status</EditTitle>
                            <EditDescription>{project.projectStatus}</EditDescription>
                            <ButtonWrapper>
                                <Button style={buttonValue === 'DRAFT' ? { background: 'black', color: 'white' } : {}} onClick={() => handleButtonClick('DRAFT')}>DRAFT</Button>
                                <Button style={buttonValue === 'GAUGING_INTEREST' ? { background: 'black', color: 'white' } : {}} onClick={() => handleButtonClick('GAUGING_INTEREST')}>GAUGING_INTEREST</Button>
                                <Button style={buttonValue === 'FUNDING' ? { background: 'black', color: 'white' } : {}} onClick={() => handleButtonClick('FUNDING')}>FUNDING</Button>
                                <Button style={buttonValue === 'PAUSED' ? { background: 'black', color: 'white' } : {}} onClick={() => handleButtonClick('PAUSED')}>PAUSED</Button>
                                <Button style={buttonValue === 'COMPLETED' ? { background: 'black', color: 'white' } : {}} onClick={() => handleButtonClick('COMPLETED')}>COMPLETED</Button>
                                <Button style={buttonValue === 'CANCELLED' ? { background: 'black', color: 'white' } : {}} onClick={() => handleButtonClick('CANCELLED')}>CANCELLED</Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <SaveButton onClick={() => setCurrentSection('')}  className='cancel'>Cancel</SaveButton>
                                <SaveButton onClick={handleSubmit}>Save</SaveButton>
                            </ButtonWrapper>
                        </SelectionBody>
                        :
                        currentSection === 'targetFundAmount' ?
                        <SelectionBody>
                            <EditTitle>Target Fund Amount</EditTitle>
                            <EditDescription>${project.targetFundAmount}</EditDescription>
                            <ModalInput 
                                value={editedProject.targetFundAmount || ''}
                                type="text" 
                                onChange={(e) => handleInputChange(e, 'targetFundAmount')}
                            /> 
                            <ButtonWrapper>
                                <SaveButton onClick={() => setCurrentSection('')}  className='cancel'>Cancel</SaveButton>
                                <SaveButton onClick={handleSubmit}>Save</SaveButton>
                            </ButtonWrapper>
                        </SelectionBody>
                        :
                        currentSection === 'equityGiven' ?
                        <SelectionBody>
                            <EditTitle>Equity Given</EditTitle>
                            <EditDescription>{project.equityGiven}%</EditDescription>
                            <ModalInput 
                                value={editedProject.equityGiven || ''}
                                type="text" 
                                onChange={(e) => handleInputChange(e, 'equityGiven')}
                            />
                            <ButtonWrapper>
                                <SaveButton onClick={() => setCurrentSection('')}  className='cancel'>Cancel</SaveButton>
                                <SaveButton onClick={handleSubmit}>Save</SaveButton>
                            </ButtonWrapper>
                        </SelectionBody>
                        :
                        currentSection === 'campaignEndDate' ?
                        <SelectionBody>
                            <EditTitle>Campaign End Date</EditTitle>
                            <EditDescription>{readableDate(project.campaignEndDate)}</EditDescription>
                            {campaignEndDateSection}
                        </SelectionBody>
                        :
                        currentSection === 'campaignEndTime' ?
                        <SelectionBody>
                            <EditTitle>Campaign End Time</EditTitle>
                            {campaignEndTimeSection}
                        </SelectionBody>
                        :
                        null
                        }
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default ProjectModal