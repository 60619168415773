import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getPost } from '../components/Posts/PostsApi'
import { useMediaQuery } from 'react-responsive'

import {
  Wrapper,
  PostContainer,
  PostCoverPhoto,
  PostCreationDate,
  ContentContainer,
  Header,
  AuthorContainer,
  AuthorPhoto,
  AuthorName,
  PostContentBlock,
  Photo
} from '../components/Posts/PostElements'

import NonMainNavBar from '../components/NonMainNav'
import { formatReadableDate } from '../helpers/readableDate'
import { PiArrowBendUpLeftBold } from "react-icons/pi"

const Post = ({ loggedInUser }) => {
  const { slug } = useParams()
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const isMobileBackButton = useMediaQuery({ maxWidth: 1050 })

  // Use useQuery to fetch the post data
  const { data: post, isLoading, error } = useQuery({
    queryKey: ['post', slug],
    queryFn: () => getPost(slug)
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error loading post</div>
  }

  const handleNavigate = () => {
    navigate('/posts')
  }

  return (
    <div>
      <NonMainNavBar loggedInUser={loggedInUser} />
      <Wrapper style={isMobile ? { padding: '20px' } : {}}>
        <PostContainer style={{ 
          flexDirection: 'column', 
          position: 'relative',
          width: isMobile ? '100%' : 'auto'
        }}>
          {!isMobileBackButton && (
          <div onClick={handleNavigate} style={{ 
            position: 'absolute', 
            top: 10, 
            left: -90, 
            display: 'flex', 
            alignItems: 'center', 
            color: '#80C4E9', 
            cursor: 'pointer' 
          }}>
            <PiArrowBendUpLeftBold size={20} />
            <span style={{ marginLeft: 5, fontWeight: '500' }}>Back</span>
          </div>
        )}
          <PostCoverPhoto 
            src={post?.coverPhoto} 
            alt="Cover" 
            style={isMobile ? { width: '100%', height: 'auto' } : {}}
          />
        </PostContainer>
        <ContentContainer style={isMobile ? { width: '100%', padding: '0' } : {}}>
          {isMobileBackButton && (
            <div onClick={handleNavigate} style={{ 
              display: 'flex', 
              alignItems: 'center', 
              color: '#80C4E9', 
              cursor: 'pointer',
              marginBottom: '20px'
            }}>
              <PiArrowBendUpLeftBold size={20} />
              <span style={{ marginLeft: 5, fontWeight: '500' }}>Back</span>
            </div>
          )}
          <PostCreationDate style={{ 
            fontSize: isMobile ? 13 : 15, 
            color: '#C7C8CC', 
            cursor: 'default' 
          }}>
            {formatReadableDate(post?.createdAt)}
          </PostCreationDate>
          <Header style={{ 
            marginTop: 10, 
            fontSize: isMobile ? 24 : 35, 
            cursor: 'default' 
          }}>
            {post?.title}
          </Header>
          <AuthorContainer style={{ marginTop: 20, cursor: 'default' }}>
            <AuthorPhoto 
              src={post?.author?.image} 
              alt='Author Profile Photo'
              style={isMobile ? { width: '30px', height: '30px' } : {}}
            />
            <AuthorName style={{ 
              fontSize: isMobile ? 13 : 15, 
              fontWeight: 300 
            }}>
              {post?.author?.firstName} {post?.author?.lastName}
            </AuthorName>
          </AuthorContainer>
          {post?.content.map((contentItem, index) => {
            if (contentItem.type === 'editor') {
              return (
                <div key={contentItem._id} style={{ fontFamily: '"Merriweather", serif' }}>
                  <PostContentBlock
                    style={{
                      marginTop: index === 0 ? 40 : 20,
                      fontSize: isMobile ? '14px' : '16px'
                    }}
                    dangerouslySetInnerHTML={{ __html: contentItem.value }}
                  />
                </div>
              )
            }
            if (contentItem.type === 'image') {
              return (
                <Photo 
                  key={index} 
                  src={contentItem.value} 
                  alt="Content Image" 
                  style={isMobile ? { width: '100%', height: 'auto' } : {}}
                />
              )
            }
            return null
          })}
        </ContentContainer>
      </Wrapper>
    </div>
  )
}

export default Post