import React, { useState } from 'react'

import {
    AccountContainer,
    OptionSection,
    OptionInfo,
    VerificationStatus,
} from '../AccountElements'

import { BiInfoCircle } from 'react-icons/bi'

import LimitModal from './LimitModal'

const InvestorLimits = ({ investmentInfo, setInvestmentInfo }) => {
    const [showModal, setShowModal] = useState(false)
    const [activeModal, setActiveModal] = useState(null)

    const handleModalOpen = (modalType) => {
        setActiveModal(modalType)
        setShowModal(true)
    }

    const calculateInvestorLimit = (annualIncome, netWorth) => {
        const LIMIT = 124000
        const MIN_INVESTMENT = 2500
        
        // 1. If your annual income or net worth is below $124,000:
        if (annualIncome < LIMIT || netWorth < LIMIT) {
            const greaterValue = Math.max(annualIncome, netWorth)
            return Math.max(MIN_INVESTMENT, 0.05 * greaterValue)
        }
        // 2. If both your annual income and net worth are $124,000 or above:
        else {
            const greaterValue = Math.max(annualIncome, netWorth)
            return Math.min(0.1 * greaterValue, LIMIT)
        }
    }

    return (
        <AccountContainer>
            {showModal && <LimitModal modalType={activeModal} setShowModal={setShowModal} investmentInfo={investmentInfo} setInvestmentInfo={setInvestmentInfo} />}
            <OptionSection onClick={() => handleModalOpen('Legal Investment Limit')}>
                <OptionInfo>Legal Investment Limit</OptionInfo>
                <OptionInfo>${parseInt(calculateInvestorLimit(investmentInfo?.annualIncome, investmentInfo?.netWorth)).toLocaleString() !== isNaN ? parseInt(calculateInvestorLimit(investmentInfo?.annualIncome, investmentInfo?.netWorth)).toLocaleString() : 0} / yr</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Annual Income')}>
                <OptionInfo>Annual Income</OptionInfo>
                <OptionInfo>${investmentInfo?.annualIncome}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Net Worth')}>
                <OptionInfo>Net Worth</OptionInfo>
                <OptionInfo>${investmentInfo?.netWorth}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Accredited Status')}>
                <OptionInfo>Accredited Status</OptionInfo>
                <OptionInfo>{!investmentInfo?.accreditationStatus ? 'Unaccredited' : 'Accredited'}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Verification Status')} style={{ borderBottom: 'none' }}>
                <OptionInfo>Verification Status</OptionInfo>
                <OptionInfo style={{ color: '#2563eb' }}>Pending Investment</OptionInfo>
            </OptionSection>
            <VerificationStatus>
                <OptionInfo style={{ maxWidth: '100%' }} className="verification-message">
                    <BiInfoCircle size={17} color="#2563eb" />
                    Verification happens automatically when you make your first investment.
                    <a href="#">Learn More</a>
                </OptionInfo>
            </VerificationStatus>
        </AccountContainer>
    )
}

export default InvestorLimits