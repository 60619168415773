import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    Navbar,
    NavButton
} from './AdminElements'

import { logout } from './AdminApi'

const AdminNavbar = () => {
    const navigate = useNavigate()

    const handleLogout = () => {
        logout()
        navigate('/admin/login')
    }

    const handleHomeClick = () => {
        navigate('/admin/dashboard')
    }

    const handleWaitlistClick = () => {
        navigate('/admin/artist-waitlist')
    }

    const handleArtistAcceptanceClick = () => {
        navigate('/admin/artist-acceptance')
    }

    const handleProjectsClick = () => {
        navigate('/admin/projects')
    }

    const handleArtistClick = () => {
        navigate('/admin/artists')
    }

    const handleCreatePostClick = () => {
        navigate('/admin/create-post')
    }

    const handlePostsClick = () => {
        navigate('/admin/posts')
    }

    return (
        <Navbar>
            <h1 style={{ cursor: 'pointer' }} onClick={handleHomeClick} >Muugen Admin</h1>
            <div>
                <NavButton onClick={handleCreatePostClick} >Create Post</NavButton>
                <NavButton onClick={handlePostsClick} >Posts</NavButton>
                <NavButton onClick={handleArtistClick} >Artists</NavButton>
                <NavButton onClick={handleProjectsClick} >Projects</NavButton>
                <NavButton onClick={handleWaitlistClick} >Artist Waitlist</NavButton>
                <NavButton onClick={handleArtistAcceptanceClick} >Artist Acceptance</NavButton>
                <NavButton onClick={handleLogout} >Logout</NavButton>
            </div>
        </Navbar>
    )
}

export default AdminNavbar