import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'

import {
    AdminArtistsContainer,
    Table,
    Th,
    Td,
} from '../../components/AdminArtists/AdminArtistsElements'

import AdminNavbar from '../../components/Admin/AdminNavbar'

import {
    SearchBar,
    Button
} from '../../components/AdminProjects/AdminProjectElements'

import { MdOutlineArrowRightAlt } from 'react-icons/md'

const AdminArtist = () => {
    const [artists, setArtists] = useState([])
    const [displayArtists, setDisplayArtists] = useState([])

    useEffect(() => {
        // Fetch all artists from the database
        // Display the artists in the table
        // Add functionality to accept or reject artists
        const fetchArtists = async () => {
            try {
                const response =
                await axios.get(`${API_URL}/admin/artists`, { withCredentials: true })
                setArtists(response.data)
                setDisplayArtists(response.data)
            } catch (e) {
                console.log(e)
            }
        }

        fetchArtists()
    }, [])

    const handleArtistSearch = (e) => {
        const searchValue = e.target.value.toLowerCase()
        if (searchValue) {
            // Filter based on the search input
            const filteredArtists = artists.filter(artist => 
                artist.artistName.toLowerCase().includes(searchValue)
            )
            setDisplayArtists(filteredArtists) // Update displayed artists with filtered list
        } else {
            setDisplayArtists(artists)
            // If search input is cleared, display all artists
        }
    }

    const handlePause = async (id) => {
        // Prompt the admin to confirm the pause action
        if (window.confirm('Are you sure you want to pause this artist?'))
        // Pause the artist
        try {
            const response = await axios.post(`${API_URL}/admin/pause-artist/${id}`, {}, { withCredentials: true })

            if (response.data.paused)
                alert('Artist has been unpaused.')
            else
                alert('Artist has been paused.')

            const updatedArtists = artists.map(artist => {
                if (artist._id === id) {
                    artist.paused = !artist.paused
                }
                return artist
            })

            setArtists(updatedArtists)
            setDisplayArtists(updatedArtists)
        } catch (e) {
            console.log(e)
        }
    }

    const handleDelete = async (id) => {
        window.confirm('Are you sure you want to delete this artist? You cannot undo this action.')
        // Delete the artist
        try {
            const response = await axios.post(`${API_URL}/admin/delete-artist`, { artistId: id }, { withCredentials: true })

            alert(response.data.message)
        } catch (e) {
            console.log(e)
        }
    }

    const artistMapping = displayArtists.map((artist, index) => {
        return (
            <tr key={index}>
                <Td>{artist.artistName}</Td>
                <Td>{artist._id}</Td>
                <Td>{artist.artistEmail}</Td>
                <Td>{artist.artistType}</Td>
                <Td>
                    <Button onClick={() => handlePause(artist._id)}>{artist.paused ? 'Unpause' : 'Pause'}</Button>
                </Td>
                <Td>
                    <Button onClick={() => handleDelete(artist._id)}>Delete</Button>
                </Td>
            </tr>
        )
    })

    return (
        <AdminArtistsContainer>
            <AdminNavbar />
            <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Artists</h1>
            </div>
            <SearchBar onChange={handleArtistSearch} placeholder="Search for artists by name" />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <MdOutlineArrowRightAlt style={{ fontSize: 50, color: 'black', cursor: 'pointer', float: 'right' }} />
            </div>
            <Table>
                <thead>
                    <tr>
                        <Th>Artist Name</Th>
                        <Th>Artist ID</Th>
                        <Th>Email</Th>
                        <Th>Artist Type</Th>
                        <Th>Pause Artist</Th>
                        <Th>Delete Artist</Th>
                    </tr>
                </thead>
                <tbody>
                    {artistMapping}
                </tbody>
            </Table>
        </AdminArtistsContainer>
    )
}

export default AdminArtist