import React from 'react'
import { Link } from 'react-router-dom'

import { 
    Wrapper, 
    NoInfo,
    ButtonWrapper, 
    Button
} from './ArtistProjectsElements'

import ProjectCard from '../ProjectCard'
import { MdAddCircleOutline } from 'react-icons/md'

function ArtistProjects({ projects, loggedInUser, artistId }) {
    let renderProjects
    
    if (projects !== undefined) {
        renderProjects = projects.map(project => {
            return (
                <React.Fragment key={project._id} >
                    <ProjectCard 
                        projectId={project._id}
                        targetFundAmount={project.targetFundAmount}
                        currentFunds={project.currentFunds}
                        totalExpressedInterest={project.totalExpressedInterest}
                        image={project.coverImagePath} 
                        name={project.projectName} 
                        projectStatus={project.projectStatus}
                    />
                </React.Fragment>
            )
        })
    }

    return (
        <Wrapper>
            <ButtonWrapper style={{ marginTop: 20 }}>
                {
                loggedInUser?.artist === artistId ?
                    <Link style={{ textDecoration: 'none' }} to='/create-project' >
                        <Button style={{ width: 200 }}>
                            <MdAddCircleOutline size={20} />
                            <div style={{ marginLeft: 6 }}>Create new Project</div>
                        </Button>
                    </Link>
                :
                ''
                }
            </ButtonWrapper>
            <React.Fragment >
                {projects?.length > 0 ?
                    renderProjects
                :
                    <NoInfo>
                        This creator doesn&apos;t currently have any projects. 
                    </NoInfo>
                }
            </React.Fragment>
        </Wrapper>
    )
}

export default ArtistProjects
