import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../authContext'

const ProtectedArtistRoute = ({ children }) => {
  const { loggedInUser, isLoading } = useContext(AuthContext)
  const location = useLocation()

  // Add loading check
  if (isLoading) {
    return <div>Loading...</div> // Or your loading component
  }

  if (!loggedInUser?.loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (!loggedInUser?.artist) {
    return <Navigate to="/artist-registry" state={{ from: location }} replace />
  }

  return children
}

export default ProtectedArtistRoute