import styled from 'styled-components'

export const AccountContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const ProfilePictureDrop = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #000000; 
    cursor: pointer;
    margin-top: 20px;
`

export const ProfilePicture = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
`

export const PictureDropInfo = styled.div`
    margin-top: 10px;
    width: 300px;
    font-size: 14px;
    color: #000000;
    font-family: 'DM sans', sans-serif;
    text-align: center;
`

export const OptionSection = styled.div`
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    min-width: 630px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;

    &:hover {
        background-color: #f2f2f2;
    }

    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`

export const OptionInfo = styled.div`
    font-size: 16px;
    color: #000000;
    font-family: 'DM sans', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    
    &.verification-message {
        font-size: 14px;
        color: #374151;
        display: flex;
        align-items: center;
        gap: 8px;
        
        a {
            color: #2563eb;
            margin-left: 4px;
            text-decoration: none;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
`

export const PrivacyCheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const PrivacyCheckboxLabel = styled.label`
    font-size: 13px;
    color: #000000;
    font-family: 'DM sans', sans-serif;
`

export const PrivacyCheckboxInput = styled.input`
    width: 15px;
    height: 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    background: red;

    &:checked {
        background-color: red;
    }
`

export const VerificationStatus = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 5px;
    height: 50px;
    background: rgba(37, 99, 235, 0.1);  // Light blue background
    color: #1e40af;  // Darker blue text
`

export const VerifyButton = styled.div`
    height: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`

export const NotificationTitle = styled.div`
    font-size: 20px;
    color: #000000;
    font-family: 'DM sans', sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const ToggleWrapper = styled.div`
    width: 50px;
    height: 15px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: ${({ toggled }) => (toggled ? 'black' : '#e6e6e6')}; 
    transition: background 0.2s;
`

export const ToggleCircle = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: transform 0.2s;
    transform: ${({ toggled }) => (toggled ? 'translateX(28px)' : 'translateX(0px)')};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`

export const UserContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: #eee;
`

