import styled from 'styled-components'

export const Wrapper = styled.section`
    padding-bottom: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const NoInfo = styled.div`
    background-color: #f5f5f5;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
    height: 300px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    width: 70%; 
    margin-bottom: 10px;

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`

export const Button = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    padding: 5px;
    background-color: white;
    cursor: pointer;
    border: 1px solid lightgrey;

    text-decoration: none;
    /*display: inline-block; */
    border-radius: 3px;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        border: 1px solid black;
    }
`
