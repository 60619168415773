import styled from 'styled-components'

export const Modal = styled.div`
    width: 250px;
    z-index: 100;
    position: absolute;
    top: 50px;
    right: 5px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`

export const Header = styled.div`
    display: flex;
    font-family: 'DM Sans', sans-serif;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #D8D9DA;
`

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #D8D9DA;
    
    &:last-child {
        border-bottom: none;
    }
`

export const Section = styled.div`
    display: flex;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    /* padding: 10px 0; */
    cursor: pointer;

    &:hover {
        background: #F5F5F5;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
`