import React, { useState, useEffect, useCallback } from 'react'
import { NEW } from '../../../../../constants'
import BudgetModal from '../BudgetModal'
import { PieChart } from 'react-minimal-pie-chart'
import { 
    ChartContainer, 
    LegendContainer, 
    LegendItem, 
    ColorCode,
} from './BudgetElements'
import { NoInfo } from '../ProjectDetailElements'

import { HeaderWrapper } from '../ProjectDetailElements'
import { EditButton } from '../../Perks/PerkElements'
import { AiFillEdit } from 'react-icons/ai'

const initialBudgetArray = [{ title: '', value: 0, color: '', status: NEW }]

const Budget = ({ 
    project, 
    setProjectInfo, 
    showBudgetModal, 
    setShowBudgetModal, 
    loggedInUser 
}) => {
    const [budgetArray, setBudgetArray] = useState(initialBudgetArray)
    const [chartData, setChartData] = useState([])
    const [activeIndex, setActiveIndex] = useState(-1)
    const [timeoutId, setTimeoutId] = useState(null)
    const [isSubmitFired, setIsSubmitFired] = useState(false)

    useEffect(() => {
        if (project) {
            setChartData((prevData) => [...project.budgetAllocation])
        }
    }, [project])

    const handleShowModal = () => {
        document.body.style.overflow = 'hidden'
        setShowBudgetModal(true)
    }

    const handleMouseOver = useCallback((index) => {
        setActiveIndex(index)
        clearTimeout(timeoutId)
    }, [timeoutId])

    const handleMouseOut = useCallback(() => {
        const id = setTimeout(() => {
          setActiveIndex(-1)
        }, 100) // set delay time (in milliseconds)
        setTimeoutId(id)
    }, [])

    return (
        <div>
            <HeaderWrapper>
            {(loggedInUser?.artist === project?.artist._id || project?.authorizedCollaborators?.includes(loggedInUser?.id)) && (
                <EditButton onClick={handleShowModal}>
                    <AiFillEdit size={20} style={{ marginRight: 3 }} />
                    Edit Budget
                </EditButton>
            )}
            </HeaderWrapper>
            {showBudgetModal ? <BudgetModal setChartData={(newChartData) => setChartData((prevData) => [...newChartData])} setProjectInfo={setProjectInfo} budgetArray={budgetArray} setBudgetArray={setBudgetArray} project={project} setShowModal={setShowBudgetModal} isSubmitFired={isSubmitFired} setIsSubmitFired={setIsSubmitFired} /> : null}
            {project.budgetAllocation.length > 0 ?
            <ChartContainer>
                <PieChart
                    data={chartData.map((dataEntry, index) => ({ ...dataEntry, index }))}
                    animate={true}
                    radius={40}
                    lineWidth={50}
                    activeIndex={activeIndex}
                    onMouseOver={(data, index) => {
                    if (index !== activeIndex) {
                        handleMouseOver(index)
                    }
                    }}
                    label={({ x, y, dx, dy, dataEntry }) => activeIndex === dataEntry.index ? (
                        <text x={x} y={y} dx={dx} dy={dy} key={dataEntry.index} style={{ fontSize: '4px', textAnchor: 'middle' }}>
                            <tspan x={x} y={y} dx={dx} dy={dy}>{dataEntry.title}</tspan>
                            <tspan x={x} y={y+5} dx={dx} dy={dy}>{`$${dataEntry.value}`}</tspan>
                        </text>
                    ) : null
                    }
                    labelStyle={{ fontSize: '2px' }}
                    labelPosition={0}
                    onMouseOut={handleMouseOut}
                    segmentsStyle={{ cursor: 'pointer' }}
                    fill="#8884d8"
                />
                <LegendContainer>
                    {chartData.map((data, index) => {
                        return (
                            <LegendItem key={data._id ? data._id : `new-${index}`} style={index > 0 ? { marginTop: 5 } : {}}>
                                <ColorCode style={{ background: data.color }} />
                                <div>{data.title}</div>
                            </LegendItem>
                        )
                    })}
                </LegendContainer>
            </ChartContainer>
            :
            <NoInfo>
                No budget information.
            </NoInfo>
            }
        </div>
    )
}

export default React.memo(Budget)




