import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../../../config'


import { 
    ModalBg, 
    Modal,
    Header,
    HeaderText,
    Body,
    ModalTitleInfo, 
    Input,
    ButtonWrapper,
    CancelButton,
    SaveButton
} from '../Modal/ModalElements'

import { IoClose } from 'react-icons/io5'

const InfoModal = ({ modalType, setShowModal, investmentInfo, setInvestmentInfo }) => {
    const [inputValue, setInputValue] = useState('')
    const [inputValue2, setInputValue2] = useState('')
    const [inputValue3, setInputValue3] = useState('')

    const modalRef = useRef()

    useEffect(() => {
        if (modalType === 'Full Name') {
            setInputValue(investmentInfo.legalFirstName || '')
            setInputValue2(investmentInfo.legalLastName || '')
        } else if (modalType === 'Birthdate') {
            // Check if investmentInfo.birthdate exists and is valid
            if (investmentInfo.birthdate) {
                const dateObject = new Date(investmentInfo.birthdate)
                
                // Check if the date is valid before accessing its components
                if (!isNaN(dateObject.getTime())) {
                    const year = dateObject.getFullYear()
                    const month = dateObject.getMonth() + 1 // Adding 1 since months are 0-indexed
                    const day = dateObject.getDate()

                    setInputValue(month.toString().padStart(2, '0'))
                    setInputValue2(day.toString().padStart(2, '0'))
                    setInputValue3(year.toString())
                } else {
                    // Set default empty values if date is invalid
                    setInputValue('')
                    setInputValue2('')
                    setInputValue3('')
                    console.warn('Invalid date received:', investmentInfo.birthdate)
                }
            } else {
                // Handle case where birthdate is undefined or null
                setInputValue('')
                setInputValue2('')
                setInputValue3('')
            }
        }
    }, [modalType, investmentInfo])

    const handleClose = (e) => {
        if (modalRef.current === e.target) {
            setShowModal(false)
        }
    }

    const handleSubmitFullName = async () => {
        try {
            const payload = {
                firstName: inputValue,
                lastName: inputValue2
            }

            if (!payload.firstName || !payload.lastName) {
                alert('First name and last name are required.')
                return
            }

            const response = await axios.post(`${API_URL}/investment-profile/update-legal-name/me`, payload, { withCredentials: true })
            setInvestmentInfo({...investmentInfo, legalFirstName: response.data.legalFirstName, legalLastName: response.data.legalLastName })
            setShowModal(false)
        } catch (e) {
            console.log(e)
        }
    }

    const handleBirthdate = (inputValue, setInputFunction) => {
        // Check if the inputValue consists of only digits.
        if (/^\d*$/.test(inputValue)) {
            setInputFunction(inputValue)
        }
    }

    const isValidDate = (month, day, year) => {
        // Convert month, day, and year to integers
        const intMonth = parseInt(month)
        const intDay = parseInt(day)
        const intYear = parseInt(year)
        
        // Create the date object
        const date = new Date(intYear, intMonth - 1, intDay)
        
        // Check the formatting
        if (month.length !== 2 || day.length !== 2 || year.length !== 4) {
            alert('Please provide the date in MM/DD/YYYY format.')
            return false
        }
    
        // Check if the provided values are valid numbers
        if (isNaN(intMonth) || isNaN(intDay) || isNaN(intYear)) {
            alert('Invalid date provided.')
            return false
        }
        
        // Ensure the constructed date matches the original values
        const isSameDate = date.getFullYear() === intYear && date.getMonth() + 1 === intMonth && date.getDate() === intDay
        
        if (!isSameDate) {
            alert('The date provided is not valid.')
            return false
        }
        
        // Check if the date is not in the future
        const currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)  // Set the current date time to midnight for a fair comparison
        const isNotFutureDate = date <= currentDate
        
        // Check if the date represents an age of at least 18 years
        const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())
        const isAtLeast18YearsOld = date <= eighteenYearsAgo
        
        if (!isNotFutureDate) {
            alert('Date cannot be in the future.')
            return false
        } else if (!isAtLeast18YearsOld) {
            alert('You must be at least 18 years old.')
            return false
        } 
        
        return true
    }    

    const handleSubmitBirthdate = async () => {
        try {
            if (!isValidDate(inputValue, inputValue2, inputValue3)) {
                return
            }
    
            const payload = {
                month: inputValue,
                day: inputValue2,
                year: inputValue3
            }
    
            const response = await axios.post(`${API_URL}/investment-profile/update-birthdate/me`, payload, { withCredentials: true })

            setInvestmentInfo({...investmentInfo, birthdate: response.data.birthdate })
            setShowModal(false)
        } catch (e) {
            console.log(e)
        }
    }
      
    return (
        <ModalBg ref={modalRef} onClick={handleClose} >
            <Modal>
                <Header>
                    <HeaderText>{modalType}</HeaderText>
                    <IoClose size={30} style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)} />
                </Header>
                <Body>
                    {modalType === 'Full Name' ?
                        <>
                            <ModalTitleInfo>What is your Full Name?</ModalTitleInfo>
                            <Input value={inputValue} placeholder='First Name' onChange={e => setInputValue(e.target.value)} />
                            <Input value={inputValue2} placeholder='Last Name' onChange={e => setInputValue2(e.target.value)} />
                        </>
                        :
                        modalType === 'Birthdate' ?
                        <>
                            <ModalTitleInfo>When were you born?</ModalTitleInfo>
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <Input 
                                    style={{ width: '20%' }} 
                                    maxLength={2}
                                    value={inputValue} 
                                    placeholder='MM' 
                                    onChange={e => handleBirthdate(e.target.value, setInputValue)} 
                                />
                                <Input 
                                    style={{ width: '20%' }} 
                                    maxLength={2}
                                    value={inputValue2} 
                                    placeholder='DD' 
                                    onChange={e => handleBirthdate(e.target.value, setInputValue2)} 
                                />
                                <Input 
                                    style={{ width: '40%' }} 
                                    maxLength={4}
                                    value={inputValue3} 
                                    placeholder='YYYY' 
                                    onChange={e => handleBirthdate(e.target.value, setInputValue3)} 
                                />
                            </div>
                        </>
                        :
                        modalType === 'Nationality' ?
                        <>
                            <ModalTitleInfo>What is your Nationality?</ModalTitleInfo>
                            <Input />
                        </>
                        :
                        null
                    }
                    <ButtonWrapper>
                        <CancelButton onClick={() => setShowModal(false)} >Cancel</CancelButton>
                        <SaveButton onClick={modalType === 'Birthdate' ? handleSubmitBirthdate : handleSubmitFullName} >Save</SaveButton>
                    </ButtonWrapper>
                </Body>
            </Modal>
        </ModalBg>
    )
}

export default InfoModal