import React from 'react'

import AdminNavbar from '../../components/Admin/AdminNavbar'
import PostComponent from '../../components/AdminPosts'

function AdminCreatePost() {
    return (
        <>
            <AdminNavbar />
            <PostComponent />
        </>
    )
}

export default AdminCreatePost