import React from 'react'

import {
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
    BodySection
} from '../ProjectProfile/Modal/EditModalElements.js'

import { IoClose } from 'react-icons/io5'
import { FaImage } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useDropzone } from 'react-dropzone'

const ImageOrEditorModal = ({ addEditor, addImages, setShowModal }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            addImages(acceptedFiles)
            setShowModal(false)
          // Handle file upload
        },
    })

    const handleAddEditorClick = () => {
        addEditor()
        setShowModal(false)
    }

    return (
        <ModalBg>
            <Modal>
                <Header>
                    <HeaderText>Choose Image or Text Editor</HeaderText>
                    <IoClose size={25} style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)}/>
                </Header>
                <Body>
                    <BodySection {...getRootProps()} >
                        <input {...getInputProps()} />
                        <FaImage style={{ marginRight: 10 }} size={20} />Image
                    </BodySection>
                    <BodySection onClick={handleAddEditorClick}><MdEdit style={{ marginRight: 10 }} size={20} /> Text Editor</BodySection>
                </Body>
            </Modal>
        </ModalBg>
    )
}

export default ImageOrEditorModal