import React from 'react'
import { useDropzone } from 'react-dropzone'

import { UploadContainer, ExitContainer } from '../../ProjectStory/StoryElements'

import { AiFillFileAdd } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io'

function ScriptDropzone({ scriptFiles, setScriptFiles, scriptError, setScriptError }) {
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf',
        onDrop: (acceptedFiles) => {
            setScriptError(false)
            setScriptFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    })

    const Script = scriptFiles.map(scriptFile => (
        <div key={scriptFile.name} style={{ height: '100px', width: '400px', marginTop: 10, display: 'flex', alignItems: 'center'}} >
            {scriptFile.name}
        </div>
    ))

    return (
        <React.Fragment>
            {scriptFiles.length === 0 ?
            <UploadContainer style={scriptError ? { borderColor: 'red' } : {}} {...getRootProps()} >
                <input {...getInputProps()} />
                <AiFillFileAdd size={20} color={scriptError ? 'red' : 'black'} />
                <div style={scriptError ? { fontSize: 10, color: 'red' } : { fontSize: 10 }}>Upload Script</div>
            </UploadContainer>
            :
            <div onClick={() => setScriptFiles([])} style={{ display: 'flex', cursor: 'pointer', position: 'relative' }}>
                <ExitContainer>
                    {/* <AiOutlineCloseCircle color='red' style={{ background: 'white' }} size={25}/> */}
                    <IoMdClose />
                </ExitContainer>
                {Script}
            </div>
            }
        </React.Fragment>
    )
}

export default ScriptDropzone