import React, { useContext } from 'react'
import { AuthContext } from '../../../../authContext'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import { DateTime } from 'luxon'
import { API_URL } from '../../../../config'
import { removeCommasFromNumber } from '../../../../helpers/numberConvert'

import UserProfileModal from '../../../NonMainNav/UserProfileModal'

import { 
    IconWrapper,
} from '../../../NonMainNav/NonMainNavElements'

import { 
    LeftHalfPage, 
    RightHalfPage,
    RightHalfContainer,
    InputAbsoluteWrapper,
    Logo,
    SignupLogin,
    NextArea,
} from '../../CreateProjectElements'

import {
    Back,
    Next
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { PiUserCircleThin } from 'react-icons/pi'
import { FaUserCircle } from 'react-icons/fa'

import LeftHalfInfo from '../../LeftHalfInfo'
import Fields from './Fields'
import MobileView from '../../MobileView'

function Review({ 
    iconWrapperRef,
    modalRef,
    setShowProfileModal,
    setLoggedInUser,
    showProfileModal,
    handleNavigateHome,
    setCurrentStep,
    projectName, 
    logline,
    scriptPages,
    primaryGenre, 
    secondaryGenre,
    coverImage,
    videoEmbed,
    fundingAmount,
    equityAmount,
    bonusReturn,
    isFixed,
    fundDuration,
    day,
    month,
    year,
    hour,
    minute,
    timeDesignation,
    projectCategory,
    projectFundingType
}) {
    const { loggedInUser } = useContext(AuthContext)
    const navigate = useNavigate()

    const ReviewDescription = 'Review your project details and make sure everything is correct before submitting. All except the funding duration and funding amount can be edited any time after submission. These can only be edited before the launch.'

    const ReviewDescriptionTwo = 'After you submit your project, you will be redirected to your project page. You can edit further project details from there. This includes the project story, details, and rewards. This is also where you will be able to launch your project.'

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 801px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 800px)'
    })

    const mobileDescription = 'Review your project details and make sure everything is correct before submitting. All except the funding duration and funding amount can be edited any time after submission. These can only be edited before the launch.'

    const handleGoToProject = async () => {
        let payload 

        const currentDate = DateTime.now()
        if (isFixed) {
            const futureDate = currentDate.plus({ days: Number(fundDuration) })

            payload = {
                creatorName: loggedInUser.username,
                projectName,
                logline,
                scriptPages,
                primaryGenre,
                secondaryGenre,
                coverImage: coverImage.path,
                videoEmbed,
                fundingAmount: removeCommasFromNumber(fundingAmount),
                equityGiven: equityAmount,
                bonusReturn,
                isFixed,
                campaignDuration: fundDuration,
                campaignEndDate:  futureDate.toISODate(),
                hour: '11',
                minute: '59',
                timeDesignation: 'PM'
            }
        }
        else {
            const endDate = new Date(year, month - 1, day)

            const diffTime = Math.abs(endDate - currentDate)
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

            payload = {
                creatorName: loggedInUser.username,
                projectName,
                logline,
                primaryGenre,
                secondaryGenre,
                coverImage: coverImage.path,
                videoEmbed,
                fundingAmount,
                equityGiven: equityAmount,
                bonusReturn,
                isFixed,
                campaignDuration: diffDays,
                campaignEndDate: endDate.toISOString(),
                day,
                month,
                year,
                hour,
                minute,
                timeDesignation,
                projectCategory,
                projectFundingType
            }
        }

        try {
            const response = await axios.post(`${API_URL}/create-project/add-project-info/me`, payload, { withCredentials: true })

            const coverFormData = new FormData()

            coverFormData.append('uploadCover', coverImage)

            await axios.post(`${API_URL}/create-project/add-project-cover/${response.data.projectId}`, coverFormData, { withCredentials: true })

            if (response.data.projectId) {
                navigate(`/project-profile/${response.data.projectId}`)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            {isDesktopOrLaptop &&
            <div style={{ display: 'flex', height: '100%' }}>
                <LeftHalfPage>
                    <Logo onClick={handleNavigateHome}>muugen</Logo>
                    <LeftHalfInfo step={6} title={'Review'} description={ReviewDescription} descriptionTwo={ReviewDescriptionTwo} />
                </LeftHalfPage>
                <RightHalfPage>
                <SignupLogin>
                        <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                            {!showProfileModal ? <PiUserCircleThin color='black' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='black' size={25} style={{ cursor: 'pointer' }} />}
                        </IconWrapper>
                        </SignupLogin>
                        {showProfileModal && <div style={{ position: 'absolute', right: 5 }}><UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} setLoggedInUser={setLoggedInUser} /></div>}
                    <RightHalfContainer style={{ paddingBottom: 150 }}>
                        <InputAbsoluteWrapper>
                            <Fields 
                                projectCategory={projectCategory}
                                projectFundingType={projectFundingType}
                                projectName={projectName}
                                logline={logline}
                                scriptPages={scriptPages}
                                primaryGenre={primaryGenre}
                                secondaryGenre={secondaryGenre}
                                coverImage={coverImage}
                                videoEmbed={videoEmbed}
                                fundingAmount={fundingAmount}
                                isFixed={isFixed}
                                fundDuration={fundDuration}
                                day={day}
                                month={month}
                                year={year}
                                hour={hour}
                                minute={minute}
                                timeDesignation={timeDesignation}
                            />
                        </InputAbsoluteWrapper>
                    </RightHalfContainer>
                    <NextArea>
                        <Back onClick={() => setCurrentStep(5)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} /> Funding</Back>
                        <Next onClick={handleGoToProject}>Go to Project</Next>
                    </NextArea>
                </RightHalfPage>
            </div>
            }
            {isTabletOrMobile &&
                <MobileView step={6} title={'Review'} description={mobileDescription} setCurrentStep={setCurrentStep} >
                    <Fields 
                        projectCategory={projectCategory}
                        projectFundingType={projectFundingType}
                        projectName={projectName}
                        logline={logline}
                        primaryGenre={primaryGenre}
                        secondaryGenre={secondaryGenre}
                        coverImage={coverImage}
                        videoEmbed={videoEmbed}
                        fundingAmount={fundingAmount}
                        isFixed={isFixed}
                        fundDuration={fundDuration}
                        day={day}
                        month={month}
                        year={year}
                        hour={hour}
                        minute={minute}
                        timeDesignation={timeDesignation}
                    />
                </MobileView>
            }
        </>
    )
}

export default Review