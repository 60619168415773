import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { followUser } from '../investmentProfileApi'
import { AWS_URL } from '../../../config'

import { 
    ProjectWrapper, 
    Info,
    InfoWrapper,
    ProjectImageWrapper,
    ProjectImage,
    ImageOverlay,
    BottomInfo,
    ImageText,
    UnfollowButton
} from '../Portfolio/Project/ProjectElements.js'

const Project = ({ project, setProjects }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [percentageFunded, setPercentageFunded] = useState(0)
    const [status, setStatus] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        let percentage

        if (project?.projectStatus === 'GAUGING_INTEREST') {
            percentage = (project?.totalExpressedInterest / project?.targetFundAmount) * 100
            setStatus('Gauging Interest')
        } else if (project?.projectStatus === 'FUNDING') {
            percentage = (project?.currentFunds / project?.targetFundAmount) * 100
            setStatus('Funding')
        }

        setPercentageFunded(Math.ceil(percentage))
    }, [project])

    let color

    if (project.projectStatus === 'GAUGING_INTEREST') {
        color = '#9E9FA5'
    } else if (project.projectStatus === 'FUNDING') {
        color = '#FFCD4B'
    } else if (project.projectStatus === 'FUNDED') {
        color = '#96C291'
    }

    const handleProjectClick = () => {
        navigate(`/project-profile/${project._id}`)
    }

    const handleUnfollow = async (event) => {
        event.stopPropagation()

        await followUser(project._id)
        setProjects((prevData) => prevData.filter((prevProject) => prevProject._id !== project._id))
    }

    const subtractDaysAndFormat = (dateString, daysToSubtract) => {
        // Parse the date string into a Date object
        const date = new Date(dateString)
      
        // Subtract the days
        date.setDate(date.getDate() - daysToSubtract)
      
        // Format the date. Adjust the options as needed for your desired format
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const formattedDate = date.toLocaleDateString('en-US', options)
      
        return formattedDate
    }

    return (
        <ProjectWrapper 
            style={{ height: '100%', width: '100%', paddingTop: 10 }}
            onClick={handleProjectClick} 
        >
            <InfoWrapper style={{ marginBottom: 5 }}>
                <Info>{project.projectName}</Info>
                <UnfollowButton onClick={handleUnfollow} >Unfollow</UnfollowButton>
            </InfoWrapper>
            <ProjectImageWrapper 
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)}
                style={{ height: '100%', width: '100%' }}
            >
                <ProjectImage style={{ height: '100%', width: '100%' }} src={`${AWS_URL}${project.coverImagePath}`} />
                <ImageOverlay style={{ height: '100%' }} isVisible={isHovered}>
                    <ImageText>{percentageFunded}% funded</ImageText>
                </ImageOverlay>
            </ProjectImageWrapper>
            <InfoWrapper>
                <BottomInfo>
                    <div style={{ fontFamily: "'DM sans', serif" }}>Created on {subtractDaysAndFormat(project.campaignEndDate, project.campaignDuration)}</div>
                    <div style={{ fontFamily: "'DM sans', serif", color }}>{status}</div>
                </BottomInfo>
            </InfoWrapper>
        </ProjectWrapper>
    )

}

export default Project