import axios from 'axios'
import { API_URL } from '../../config'

export const post = async (title, content, postType, tags, coverPhoto) => {
  const formData = new FormData()
  formData.append('title', title)
  formData.append('postType', postType)
  formData.append('tags', tags)

  // Append the cover photo file
  if (coverPhoto) {
    formData.append('coverPhoto', coverPhoto)
  }

  let imageIndex = 0 // Counter for image files

  content.forEach((item, index) => {
    if (item.type === 'image') {
      formData.append('images', item.file) // Assuming `item.file` is the File object
      item.fileIndex = imageIndex; // Set fileIndex to image counter
      imageIndex++ // Increment image counter
    }
    formData.append(`content[${index}]`, JSON.stringify(item)) // Serialize the item
  })

  const response = await axios.post(`${API_URL}/posts/new-post`, formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response
}