import React, { useRef, useState, useEffect } from 'react'
import { handleMoneyChange } from '../helpers/moneyHelper'
import { imageSubmitApi, submitApi } from '../../investmentProfileApi'
import { IoClose } from 'react-icons/io5'
import MoneyRangeSelector from './MoneyRangeSelector'

import { 
    ModalBg, 
    Modal,
    Header,
    HeaderText,
    Body,
    ModalTitleInfo, 
    Input,
    ProfileWrapper,
    MoneyInputWrapper,
    MoneyInput,
    TextArea,
    MaxLengthDiv,
    GenreWrapper,
    GenreButton,
    ButtonWrapper,
    CancelButton,
    SaveButton
} from './ModalElements'

import { ProfilePicture } from '../AccountElements'

const StatusMessage = ({ message, type }) => (
    <div 
        style={{
            padding: '12px',
            marginBottom: '16px',
            borderRadius: '4px',
            backgroundColor: type === 'error' ? '#FEE2E2' : 
                           type === 'success' ? '#DCFCE7' : 
                           '#DBEAFE',
            color: type === 'error' ? '#991B1B' : 
                   type === 'success' ? '#166534' : 
                   '#1E40AF',
        }}
    >
        {message}
    </div>
)

const LoadingSpinner = () => (
    <div 
        style={{
            display: 'inline-block',
            width: '16px',
            height: '16px',
            marginRight: '8px',
            border: '2px solid #ffffff',
            borderTopColor: 'transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
        }}
    />
)

const AccountModal = ({ 
    setShowModal, 
    modalType, 
    userPage, 
    setUserPage, 
    imageData, 
    setImageData,
    imagePreview,
    setImagePreview,
    artist
}) => {
    const [textAreaLength, setTextAreaLength] = useState(0)
    const [selectedGenres, setSelectedGenres] = useState([])
    const [data, setData] = useState(null)
    const [minData, setMinData] = useState(100)
    const [maxData, setMaxData] = useState(250)
    const [isLoading, setIsLoading] = useState(false)
    const [updateStatus, setUpdateStatus] = useState({ message: '', type: '' })
    const modalRef = useRef()

    useEffect(() => {
        // Add keyframe animation for spinner
        const styleSheet = document.createElement("style")
        styleSheet.textContent = `
            @keyframes spin {
                to { transform: rotate(360deg); }
            }
        `
        document.head.appendChild(styleSheet)

        setData(userPage[modalType])
        if (modalType === 'favoriteGenres' && userPage[modalType]) {
            setSelectedGenres(userPage[modalType])
        } else if (currentContent.inputType === 'textarea' && userPage[modalType]) {
            setTextAreaLength(userPage[modalType].length)
        } else if (modalType === 'investmentSize') {
            userPage.investmentSizeMin != null && setMinData(userPage.investmentSizeMin)
            userPage.investmentSizeMax != null && setMaxData(userPage.investmentSizeMax)
        }

        return () => {
            document.head.removeChild(styleSheet)
        }
        // eslint-disable-next-line
    }, [modalType, userPage])

    const handleTextAreaChange = (e) => {
        setTextAreaLength(e.target.value.length)
        setData(e.target.value)
    }

    const handleClose = (e) => {
        if (modalRef.current === e.target && !isLoading) {
            setShowModal(false)
        }
    }

    const handleGenreClick = (genre) => {
        if (isLoading) return

        setSelectedGenres(prevSelected => {
            if (prevSelected.includes(genre)) {
                return prevSelected.filter(g => g !== genre)
            } else {
                if (prevSelected.length >= 5) {
                    setUpdateStatus({ message: 'You can only select up to 5 genres', type: 'error' })
                    return prevSelected
                }
                return [...prevSelected, genre]
            }
        })

        setData(selectedGenres)
    }

    const modalContent = {
        artist: {
            header: 'Artist Display Name',
            description: 'Artists cannot change their display name here. Please go to your artist profile to change your display name.',
            inputType: 'none',
            route: '*'
        },
        displayName: {
            header: 'Display Name',
            description: 'What do you want your public display name to be?',
            inputType: 'input',
            route: 'display-name'
        },
        username: {
            header: 'Username',
            description: 'Set your unique username.',
            inputType: 'input',
            route: 'username'
        },
        miniBio: {
            header: 'Mini Bio',
            description: 'Tell us a little about yourself.',
            inputType: 'textarea',
            route: 'mini-bio'
        },
        favoriteGenres: {
            header: 'Favorite Genres',
            description: 'What are your favorite genres? (Pick up to 5)',
            inputType: 'button',
            route: 'favorite-genres'
        },
        investmentThesis: {
            header: 'Investment Thesis',
            description: 'What is your investment thesis?',
            inputType: 'textarea',
            route: 'project-investment-thesis'
        },
        investmentSize: {
            header: 'Typical Investment Size',
            description: 'What is your typical investment size?',
            inputType: 'moneyInput2',
            route: 'typical-investment-size'
        },
        profilePicture: {
            header: 'Profile Picture',
            description: 'Review and Confirm Your Profile Photo',
            inputType: 'profilePicture',
            route: 'profile-picture'
        }
    }

    const currentContent = modalContent[modalType]

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setUpdateStatus({ message: 'Saving changes...', type: 'info' })

        let payload

        if (modalType === 'favoriteGenres') {
            payload = {
                data: selectedGenres
            }
        } else if (modalType === 'investmentSize') {
            payload = {
                data: {
                    min: minData,
                    max: maxData
                }
            }
        } else {
            payload = {
                data
            }
        }

        try {
            const res = await submitApi(modalContent[modalType].route, payload)

            if (modalType === 'investmentSize') {
                setUserPage(prevUserPage => ({
                    ...prevUserPage,
                    investmentSizeMin: res.data.investmentSizeMin,
                    investmentSizeMax: res.data.investmentSizeMax
                }))
            } else {
                setUserPage(prevUserPage => ({
                    ...prevUserPage,
                    [modalType]: res.data
                }))
            }
            setUpdateStatus({ message: 'Changes saved successfully!', type: 'success' })
            setTimeout(() => {
                setShowModal(false)
            }, 1500)
        } catch (error) {
            setUpdateStatus({ 
                message: error.response?.data?.message || 'Failed to save changes', 
                type: 'error' 
            })
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleImageSubmit = async (e) => {
        setIsLoading(true)
        setUpdateStatus({ message: 'Uploading image...', type: 'info' })

        const formData = new FormData()
        formData.append('profilePic', imageData)

        try {
            const res = await imageSubmitApi(formData)
            setUserPage(prevUserPage => ({
                ...prevUserPage,
                profilePicture: res.data.profilePicture
            }))
            setUpdateStatus({ message: 'Image uploaded successfully!', type: 'success' })
            setTimeout(() => {
                setImagePreview(null)
                setImageData(null)
                setShowModal(false)
            }, 1500)
        } catch (error) {
            setUpdateStatus({ 
                message: error.response?.data?.message || 'Failed to upload image', 
                type: 'error' 
            })
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <ModalBg ref={modalRef} onClick={handleClose}>
            <Modal>
                <Header>
                    <HeaderText>{currentContent.header}</HeaderText>
                    <IoClose 
                        size={30} 
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }} 
                        onClick={() => !isLoading && setShowModal(false)} 
                    />
                </Header>
                <Body>
                    {updateStatus.message && (
                        <StatusMessage 
                            message={updateStatus.message} 
                            type={updateStatus.type} 
                        />
                    )}
                    <ModalTitleInfo>{currentContent.description}</ModalTitleInfo>
                    {
                        currentContent.inputType === 'none'
                        ?
                            <div></div>
                        :
                        currentContent.inputType === 'input' 
                        ?
                            <Input 
                                type="text" 
                                placeholder={currentContent.header} 
                                onChange={e => setData(e.target.value)} 
                                defaultValue={data}
                                disabled={isLoading}
                            /> 
                        :
                        currentContent.inputType === 'profilePicture'
                        ?
                            <ProfileWrapper>
                                <ProfilePicture src={imagePreview} style={{ height: 150, width: 150 }}/>
                            </ProfileWrapper>
                        : 
                        currentContent.inputType === 'moneyInput'
                        ?
                            <MoneyInputWrapper>
                                <MoneyInput 
                                    placeholder={0} 
                                    onChange={e => handleMoneyChange(e.target.value, setData)} 
                                    value={data}
                                    disabled={isLoading}
                                />
                            </MoneyInputWrapper>
                        :
                        currentContent.inputType === 'moneyInput2'
                        ?
                        <MoneyRangeSelector
                            minData={minData}
                            maxData={maxData}
                            setMinData={setMinData}
                            setMaxData={setMaxData}
                            isLoading={isLoading}
                        />
                        :
                        currentContent.inputType === 'textarea'
                        ?   
                            <TextArea 
                                maxLength={400} 
                                placeholder={currentContent.header} 
                                onChange={handleTextAreaChange} 
                                defaultValue={data}
                                disabled={isLoading}
                            />
                        :
                            <GenreWrapper>
                                <GenreButton
                                    selected={selectedGenres.includes("Action")} 
                                    onClick={() => handleGenreClick("Action")}
                                >
                                    Action
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Animation")} 
                                    onClick={() => handleGenreClick("Animation")}
                                >
                                    Animation
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Adventure")}
                                    onClick={() => handleGenreClick("Adventure")}
                                >
                                    Adventure
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Biography")}
                                    onClick={() => handleGenreClick("Biography")}
                                >
                                    Biography
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Comedy")}
                                    onClick={() => handleGenreClick("Comedy")}
                                >
                                    Comedy
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Crime")}
                                    onClick={() => handleGenreClick("Crime")}
                                >
                                    Crime
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Dark Comedy")}
                                    onClick={() => handleGenreClick("Dark Comedy")}
                                >
                                    Dark Comedy
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Drama")}
                                    onClick={() => handleGenreClick("Drama")}
                                >
                                    Drama
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Fantasy")}
                                    onClick={() => handleGenreClick("Fantasy")}
                                >
                                    Fantasy
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Historical")}
                                    onClick={() => handleGenreClick("Historical")}
                                >
                                    Historical
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Horror")}
                                    onClick={() => handleGenreClick("Horror")}
                                >
                                    Horror
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Romance")}
                                    onClick={() => handleGenreClick("Romance")}
                                >
                                    Romance
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Science Fiction")}
                                    onClick={() => handleGenreClick("Science Fiction")}
                                >
                                    Science Fiction
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Thriller")}
                                    onClick={() => handleGenreClick("Thriller")}
                                >
                                    Thriller
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("War")}
                                    onClick={() => handleGenreClick("War")}
                                >
                                    War
                                </GenreButton>
                                <GenreButton
                                    selected={selectedGenres.includes("Western")}
                                    onClick={() => handleGenreClick("Western")}
                                >
                                    Western
                                </GenreButton>
                            </GenreWrapper>
                    }
                    {currentContent.inputType === 'textarea' && (
                        <MaxLengthDiv>{textAreaLength}/400</MaxLengthDiv>
                    )}
                    <ButtonWrapper>
                        <CancelButton 
                            onClick={() => !isLoading && setShowModal(false)}
                            disabled={isLoading}
                            style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                        >
                            Cancel
                        </CancelButton>
                        {currentContent.inputType !== 'none' && (
                            <SaveButton 
                                onClick={currentContent.inputType === 'profilePicture' ? handleImageSubmit : handleSubmit}
                                disabled={isLoading}
                                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                            >
                                {isLoading ? (
                                    <>
                                        <LoadingSpinner />
                                        Saving...
                                    </>
                                ) : 'Save'}
                            </SaveButton>
                        )}
                    </ButtonWrapper>
                </Body>
            </Modal>
        </ModalBg>
    )
}

export default AccountModal