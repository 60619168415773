import React from 'react'
import CreateProjectMain from '../components/CreateProject'

function CreateProject() {
    return (
        <div style={{ height: '100vh' }}>
            <CreateProjectMain />
        </div>
    )
}

export default CreateProject