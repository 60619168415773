import styled from 'styled-components'

export const Wrapper = styled.section`
    height: 100%;
    width: 100%;
`

export const MediaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`

export const ScriptMediaContainer = styled.div`
    display: flex;
    display: relative;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 18px;
    height: 150px;
    width: 100%;
`

export const IFrameContainer = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;

    /* @media screen and (max-width: 1000px) {
        height: 400px;
        width: 700px;
    }

    @media screen and (max-width: 750px) {
        height: 300px;
        width: 500px;
    } */
`

export const ScriptImage = styled.img`
    object-fit: cover;
    border: 1px solid black;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
`

export const DeleteLayer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: red;
    }
`

export const ScriptContainer = styled.div`
    border-radius: 5px;
    border: 1px solid black;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Courier Prime', monospace;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
`