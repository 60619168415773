import React from 'react'

import { 
    StepContainer,
    TitleContainer,
    DescriptionContainer,
    StepDescription,
    StepTitle,
    Step,
} from './CreateProjectElements'

function LeftHalfInfo({ step, title, description, descriptionTwo }) {
    return (
        <>
            <StepContainer>
                <Step>
                    {step} of 6
                </Step>
            </StepContainer>
                <TitleContainer>
                    <StepTitle>
                        {title}
                    </StepTitle>
                </TitleContainer>
            <DescriptionContainer>
                <StepDescription>
                    {description}
                </StepDescription>
                <StepDescription style={{ marginTop: 25 }}>
                    {descriptionTwo}
                </StepDescription>
            </DescriptionContainer>
        </>
    )
}

export default LeftHalfInfo
