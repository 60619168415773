import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { API_URL } from '../../config'

import { 
    ExitWrapper,
    Menu,
    MenuItem,
    MenuTitle,
    Overlay
} from './NavbarElements'

import { IoClose } from 'react-icons/io5'
import { AiFillVideoCamera } from 'react-icons/ai'
import { PiUserSquare } from 'react-icons/pi'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'
import { IoHammer } from 'react-icons/io5'
import { CiLogin, CiLogout } from 'react-icons/ci'
import { TbUserEdit } from 'react-icons/tb'
import { FaBookBookmark } from 'react-icons/fa6'
import { PiPenNibBold } from 'react-icons/pi'

const MobileMenu = ({ showMobileMenu, setShowMobileMenu, isMobile, loggedInUser }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const menuRef = useRef(null)
    // const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    useEffect(() => {
        const handleResize = () => {
            if (!isMobile) {
                setShowMobileMenu(false)
                document.body.style.overflow = 'auto'
            }
        }

        window.addEventListener('resize', handleResize)

        if (showMobileMenu) {
            document.body.style.overflow = 'hidden' // Prevent scrolling when menu is open
        } else {
            document.body.style.overflow = 'auto' // Allow scrolling when menu is closed
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [showMobileMenu, isMobile, setShowMobileMenu])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleMobileMenuClose()
            }
        }

        if (showMobileMenu) {
            document.addEventListener('mousedown', handleClickOutside)
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.body.style.overflow = 'auto'
        }

        //eslint-disable-next-line
    }, [showMobileMenu])

    const handleMobileMenuClose = () => {
        setShowMobileMenu(false)
        document.body.style.overflow = 'auto'
    }

    const handleMenuItemClick = (route) => {
        setShowMobileMenu(false)
        document.body.style.overflow = 'auto'
        navigate(route)
    }

    const handleLogout = async () => {
        try {
            await axios.post(`${API_URL}/login/logout`, {}, { withCredentials: true })
            queryClient.setQueryData(['user'], { loggedIn: false })
            setShowMobileMenu(false)
            document.body.style.overflow = 'auto'
            navigate('/')
        } catch (error) {
            console.error('Logout failed:', error)
        }
    }

    const handleCreateProjectClick = () => {
        if (loggedInUser?.loggedIn && loggedInUser?.artist) {
            navigate('/create-project')
        } else if (loggedInUser?.loggedIn && !loggedInUser?.artist) {
            navigate('/artist-registry')
        } else if (!loggedInUser?.loggedIn) {
            navigate('/login')
        }
        handleMobileMenuClose()
    }

    return (
        <>
            <Overlay show={showMobileMenu} />
            <Menu show={showMobileMenu} ref={menuRef}>
                <ExitWrapper onClick={handleMobileMenuClose}>
                    <IoClose size={25}/>
                </ExitWrapper>
                <MenuItem onClick={handleCreateProjectClick} >
                    {loggedInUser?.loggedIn && loggedInUser?.artist ?
                        <IoHammer size={20} />
                    :
                        <PiPenNibBold size={20} />
                    }
                    <MenuTitle>{loggedInUser?.loggedIn && loggedInUser?.artist ? 'Create a Project' : 'Artist Register'}</MenuTitle>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/discover')}>
                    <AiFillVideoCamera size={20} />
                    <MenuTitle>Discover Projects</MenuTitle>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/discover-artists')}>
                    <PiUserSquare size={20} />
                    <MenuTitle>Discover Artists</MenuTitle>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/discover-investors')}>
                    <RiMoneyDollarCircleFill size={20} />
                    <MenuTitle>Discover Top Investors</MenuTitle>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/posts')}>
                    <FaBookBookmark size={18} />
                    <MenuTitle>Blog</MenuTitle>
                </MenuItem>
                {loggedInUser?.loggedIn ? (
                    <MenuItem onClick={handleLogout}>
                        <CiLogout size={20} />
                        <MenuTitle>Logout</MenuTitle>
                    </MenuItem>
                ) : (
                    <>
                        <MenuItem onClick={() => handleMenuItemClick('/login')} >
                            <CiLogin size={20} />
                            <MenuTitle>Login</MenuTitle>
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('/sign-up')}>
                            <TbUserEdit size={20} />
                            <MenuTitle>Signup</MenuTitle>
                        </MenuItem>
                    </>
                )}
            </Menu>
        </>
    )
}

export default MobileMenu