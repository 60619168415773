import React from 'react'
import CardCarousel from '../CardCarousel'

import { 
    SectionContainer, 
    CardContainer, 
} from './ProjectContainerElements'

import './CarouselStyles.css'

function ProjectContainer({ projects, top, title }) {
    return (
        <SectionContainer>
            <CardContainer>
                <CardCarousel top={top} title={title} >
                    {projects}
                </CardCarousel>
            </CardContainer>
            {/* <LinkContainer>
                <ProjectsLink href='/projects'>See All Projects <Arrow /></ProjectsLink>
            </LinkContainer> */}
        </SectionContainer>
    )
}

export default ProjectContainer
