export const genres = [
    'Any Genre',
    'Action',
    'Adventure',
    'Animation',
    'Biography',
    'Comedy',
    'Crime',
    'Dark Comedy',
    'Documentary',
    'Drama',
    'Family',
    'Fantasy',
    'Film Noir',
    'History',
    'Horror',
    'Music',
    'Musical',
    'Mystery',
    'Romance',
    'Sci-Fi',
    'Sport',
    'Superhero',
    'Thriller',
    'War',
    'Western',
]

export const budget = [
    'Any budget',
    'Less than 500k budget',
    'Less than 1 million budget',
    'Less than 2 million budget',
    'Less than 5 million budget',
    'More than 5 million budget'
]

export const licensingCost = [
    'Any licensing cost',
    'Less than $100,000',
    'Less than $250,000',
    'Less than $500,000',
    'Less than $1,000,000',
    'More than $1,000,000'
]