import React from 'react'

import { 
    Card, 
    PictureContainer,
    PictureWrapper,
    PerkSubHeader, 
    PerkHeader, 
    Text,
    ShippingHeaderContainer,
    ShippingHeader,
    Unlocks 
} from '../PerkElements'

function PerkCard({ showEditMode, header, details, amount, estimatedDelivery, shipsTo, unlocks, imgSrc }) {
    return (
        <Card>
            {imgSrc ? 
            <PictureContainer>
                <PictureWrapper src={imgSrc}/>
            </PictureContainer>
            : null
            }
            <PerkHeader>{header}</PerkHeader>
            <PerkSubHeader>Invest ${amount} Dollars or More</PerkSubHeader>
            <Text>{details}</Text>
            <ShippingHeaderContainer>
                <ShippingHeader>
                    <div>Estimated Delivery</div>
                    <div style={{ fontSize: 12, color: 'black' }}>{estimatedDelivery}</div>
                </ShippingHeader>
                <ShippingHeader style={{ marginLeft: 10 }}>
                <div>Ships To</div>
                <div style={{ fontSize: 12, color: 'black' }}>{shipsTo}</div>
                </ShippingHeader>
            </ShippingHeaderContainer>
            <Unlocks>{unlocks} Unlocks</Unlocks>
        </Card>
    )
}

export default PerkCard