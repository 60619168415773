import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const LoginContainer = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #000;
    margin-top: 20px;
`

export const LoginInput = styled.input`
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #000;
    outline: none;
`

export const PasswordInput = styled.input`
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #000;
    outline: none;
`

export const LoginButton = styled.button`
    width: 100%;
    padding: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #333;
    }
`   

//QR CODE
export const QRCodeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

