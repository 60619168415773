import styled from 'styled-components'
import { PrimaryColor } from '../../colors'

export const Wrapper = styled.section`
    position: relative;
    padding: 40px 40px 0px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E5E5E5;
    //make it wrap around if the screen is too small
    flex-wrap: wrap;
`

export const FilterWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 800px;
    flex-wrap: wrap;

    @media screen and (max-width: 960px) {
        visibility: visible;
    }

    @media screen and (max-width: 765px) {
        
    }
`

export const BodyTopWrapper = styled.div`
    padding-left: 40px; 
    padding-right: 40px; 
    width: 100%;

    @media screen and (max-width: 815px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

export const DiscoverTypeWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-gap: 10px;
    width: 100%;
    margin-bottom: 20px;
    max-width: 1220px;

    @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr;
    }
`

export const DiscoverType = styled.div`
    padding: 10px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid ${props => props.isSelected ? PrimaryColor : '#E5E5E5'};
    border-radius: 10px;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 765px) {
        width: 100%;
    }
`

export const DiscoverTypeText = styled.h1`
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.isSelected ? PrimaryColor : 'initial'};
`

export const DiscoverTypeSubText = styled.h1`
    font-size: 13px;
    font-weight: 400;
    color: #9E9E9E;
`

export const SearchWrapper = styled.div`
    position: relative; // Added relative position
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 1220px;
    margin-top: 30px;
    margin-bottom: 10px;
`

export const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

export const SearchBar = styled.input`
    padding: 10px 10px 10px 35px; // Adjusted left padding
    width: 300px;
    border: none;
    border-bottom: 1px solid #E5E5E5;
    outline: none;
    font-size: 16px;

    @media screen and (max-width: 765px) {
        width: 100%;
    }
`

//dropdown menu
export const DropdownHeader = styled.h1`
    line-height: 2;
    &.third {
        margin-left: 10px;
    }

    @media screen and (max-width: 879px) {
        //target third dropdown header with classname
        &.third {
            margin-left: 0px;
            margin-right: 10px;
            margin-top: 15px;
        }
    }

    @media screen and (max-width: 823px) {
        &.third {
            margin-top: 15px;
        }
    }

    @media screen and (max-width: 813px) {
        &.second {
            margin-right: 10px;
        }

        &.third {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 15px;
        }
    }

    @media screen and (max-width: 765px) {
        display: none;
    }
`

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  z-index: 3;

  &.firstContainer {
    z-index: 6;
  }

  &.secondContainer {
    z-index: 5;
  }

  &.thirdContainer {
    z-index: 4;
  }

  &.fourthContainer {

  }

  &.fifthContainer {

  }

    @media screen and (max-width: 765px) {
        margin-left: 0px;
        margin-right: 0px;

        &.firstContainer {
            margin-left: 0px;
        }

        &.thirdContainer {
            margin-top: 0px;
        }

        &.fifthContainer {
            margin-left: 0px;
        }
    }
`

export const GenreDropdownContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  position: absolute;
  top: 25px;
  z-index: 4;
  max-height: 400px; // Adjust as needed
  overflow-y: auto;
  cursor: pointer;
`

export const DropdownButton = styled.button`
  background: white;
  display: flex;
  align-items: center;
  color: black;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 1;

  @media screen and (max-width: 765px) {
    &.fourthButtonLicense {
        
    }
  }
`

export const DropdownContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 25px;
  background-color: #fff;
  min-width: 160px;
  line-height: 1.5;

  &.contentHold {
    border: 1px solid #ccc;
  }

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
`

export const DropdownItem = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #f1f1f1
  }
`

//Discover Body
export const DiscoverBodyWrapper = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const DiscoverTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    max-width: 1200px;
    display: flex;
    //add gap between the number and the word
    & > div {
        margin-left: 5px;
        margin-right: 5px;
    }
`

export const DiscoverCardsWrapper = styled.div`
    display: flex;
    justify-content: center; 
    width: 100%;
`

export const DiscoverProjectsWrapper = styled.div`
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap; // Allow items to wrap to the next line
    width: 1200px;

    @media (min-width: 1024px) { // Large screens
        & > div {
            width: calc(33.333% - 20px); // 3 items per row, adjust the value for spacing
            margin-bottom: 20px;
            margin-right: 20px;
        }
    }

    @media (max-width: 1023px) and (min-width: 768px) { // Medium screens
        & > div {
            width: calc(50% - 20px); // 2 items per row
            margin-bottom: 20px;
            margin-right: 20px;
            max-width: 100%;
        }
    }

    @media (max-width: 767px) { // Small screens
        & > div {
            width: calc(100% - 20px); // 1 item per row
            margin-bottom: 20px;
            max-width: 100%;
        }
    }
`

//Discover Film Card
export const CardContainer = styled.div`
    position: relative;
    width: 100%;
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: translateY(-3px);
        background: white;
        /* box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px; */
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
`

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 5px 5px 0 0;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;
    z-index: 1;
    color: white;
`

export const OverlayName = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    margin-right: 5px;
    height: 100%;
    font-size: 16px;
    font-weight: 700;
    color: white;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
`

export const OverlayRoles = styled.div`
    position: absolute;
    top: 30px;
    left: 10px;
    margin-right: 5px;
    height: 100%;
    font-size: 13px;
    font-weight: 500;
    color: white;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
`

export const OverlayText = styled.div`
    position: absolute;
    top: 48px;
    left: 10px;
    margin-right: 5px;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: white;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
`

export const ProjectCreatorContainer = styled.div`
    position: absolute;
    bottom: 270px;
    right: 30px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;
    border-radius: 50%;  
    background: white; 
    z-index: 2;
    transition: all 0.4s ease;

    &:hover {
        background: ${PrimaryColor};
        border: 4px solid ${PrimaryColor};
        right: 20px;
        bottom: 256px;
        height: 80px;
        width: 80px;
    }
`

export const ProjectCreatorImage = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    transition: all 0.4s ease;

    ${ProjectCreatorContainer}:hover & {
        height: 75px;
        width: 75px;
    }
`

export const ProjectImageContainer = styled.div`
    width: 100%;
    /* border-radius: 5px; */
    padding-top: 56.25%; // 16:9 Aspect Ratio (9 / 16 = 0.5625)
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
`

export const ProjectImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 10px 10px 0 0; */
`

export const CreatorName = styled.h1`
    font-size: 14px;
    font-weight: 400;
    color: #9E9E9E;
`

export const DividerProgress = styled.div`
    height: 2px;
    background-color: ${PrimaryColor};
`

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

`

export const RaisedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    //float to bottom
    margin-top: auto;
`

export const RaisedTitle = styled.h1`
    font-size: 13px;
    font-weight: 700;
`

export const RaisedSubtitle = styled.h1`
    font-size: 13px;
    font-weight: 300;
    margin-left: 5px;
    color: #9E9E9E;
`

export const NoProjects = styled.div`
    background: #F7F7F8;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    text-align: center;
    padding: 20px;
`

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`

export const PageButton = styled.button`
  /* background-color: ${props => props.active ? `${PrimaryColor}` : '#f8f9fa'}; */
  background-color: transparent;
  color: ${props => props.active ? `${PrimaryColor}` : 'black'};
  /* border: 1px solid black; */
  border: none;
  width: 30px;
  height: 30px;
  font-size: 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #F5F7F8;
  }
`

export const ArrowButton = styled.button`
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;

    &:hover {
        background: #F5F7F8;
    }
`
