export const handleDateChange = (e, dispatch, label) => {
    let value = e.target.value

    value = value.replace(/[^0-9/]/g, '')

    const numericValue = value.replace(/[^0-9]/g, '')

    // Initialize formattedValue with the raw value
    let formattedValue = value

    // Add the first slash after MM if not already present
    if (numericValue.length === 2 && !formattedValue.includes('/')) {
        formattedValue += '/'
    }

    // Adjust for scenarios where MM/ is present and user types another number
    if (numericValue.length === 3 && formattedValue.length === 3) {
        formattedValue = numericValue.substring(0, 2) + '/' + numericValue.substring(2);
    }

    // Add the second slash after DD if not already present
    if (numericValue.length === 4 && formattedValue.charAt(5) !== '/') {
        formattedValue += '/'
    }

    // Truncate if length is over 10
    if (formattedValue.length > 10) {
        formattedValue = formattedValue.substring(0, 10)
    }

    // If user tries to enter an invalid month or day, we correct it
    if (formattedValue.length >= 2) {
        const month = parseInt(formattedValue.substring(0, 2))
        if (month < 1) {
            formattedValue = "01" + formattedValue.substring(2)
        } else if (month > 12) {
            formattedValue = "12" + formattedValue.substring(2);
        }
    }
    if (formattedValue.length >= 5) {
        const day = parseInt(formattedValue.substring(3, 5));
        if (day < 1) {
            formattedValue = formattedValue.substring(0, 3) + "01" + formattedValue.substring(5);
        } else if (day > 31) {
            formattedValue = formattedValue.substring(0, 3) + "31" + formattedValue.substring(5);
        }
    }

    dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label } });
}






















