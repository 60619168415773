import React, { useEffect } from 'react'
import moment from 'moment'

import {
    Title,
    SubTitle,
    InputLabel,
    InputWrapper,
    InputContainer,
    Input,
    ErrorContainer,
    ErrorMessage,
    Button,
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    Select, 
    TimeSelect
} from '../../CreateProjectElements'

import { AiOutlineExclamationCircle } from 'react-icons/ai'

function InputFields({ 
    isFixed, 
    setIsFixed, 
    fundDuration, 
    setFundDuration, 
    setDay,
    setMonth,
    setYear,
    setHour,
    setMinute,
    hour,
    minute,
    day,
    month,
    year,
    timeDesignation,
    setTimeDesignation, 
    isEmpty,
    setIsEmpty,
    isPastDate,
    setIsPastDate,
    isDateInvalid,
    setIsDateInvalid,
    isFixedInvalid,
    setIsFixedInvalid,
    isFixedError,
    setIsFixedError,
}) {

    const validateDate = () => {
        let inputDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')
        if (!inputDate.isValid()) {
            // invalid date
            setIsPastDate(false)
            setIsDateInvalid(true)
        } else if (!inputDate.isAfter(moment())) {
            // date is in the past
            setIsDateInvalid(true)
            setIsPastDate(true)
        } else {
            // valid date
            setIsDateInvalid(false)
            setIsPastDate(false)
        }
    }

    useEffect(() => {
        if (year.length === 4 && month.length === 2 && day.length === 2) {
            validateDate()
        } else {
            if(year.length < 4) {
                setIsDateInvalid(false)
            }
            if(month.length < 2) {
                setIsDateInvalid(false)
            }
            if(day.length < 2) {
                setIsDateInvalid(false)
            }
        }
        
        //eslint-disable-next-line
    }, [year, month, day])

    const hours = []
    for(let i = 1; i <= 12; i++){
        let val = i.toString().padStart(2, '0')
        hours.push(<option key={val} value={val}>{val}</option>)
    }

    // Generate the options for minutes
    const minutes = []
    for(let i = 0; i < 60; i++){
        let val = i.toString().padStart(2, '0')
        minutes.push(<option key={val} value={val}>{val}</option>)
    }

    const handleDayChange = (event) => {
        let val = event.target.value;
        if (/^\d*$/.test(val) && val.length <= 2) {
            setDay(val)
            setIsEmpty(false)
        }
    }

    const handleMonthChange = (event) => {
        let val = event.target.value;
        if (/^\d*$/.test(val) && val.length <= 2) {
            setMonth(val)
            setIsEmpty(false)
        }
    }

    const handleYearChange = (event) => {
        let val = event.target.value;
        if (/^\d*$/.test(val) && val.length <= 4) {
            setYear(val)
            setIsEmpty(false)
        }
    }
    
    const handleMinuteChange = (event) => {
        let val = event.target.value
        if (val === '' || (Number(val) < 60 && Number(val) >= 0)) {
          setMinute(val.padStart(2, '0'))
        }
    }

    const handleHourChange = (event) => {
        let val = event.target.value
        if (val === '' || (Number(val) <= 12 && Number(val) >= 1)) {
          setHour(val.padStart(2, '0'))
        }
    }

    const handleFixedChange = (event) => {
        let val = event.target.value
        setFundDuration(val)
        if (val === '' || (Number(val) <= 60 && Number(val) >= 1)) {
            setIsFixedInvalid(false)
            setIsFixedError(null)
        } else if (Number(val) < 1) {
            setIsFixedInvalid(true)
            setIsFixedError('LOW')
        } else if (Number(val) > 60) {
            setIsFixedInvalid(true)
            setIsFixedError('HIGH')
        }
    }

    return (
        <>
            <InputContainer>
                    <InputWrapper>
                        <Title>Campaign Duration</Title>
                        <div style={{ display: 'flex', marginBottom: 10, width: '100%' }}>
                            <Button isActive={isFixed === true} onClick={() => setIsFixed(true)} style={{ width: '50%' }} >Fixed number of days (1-60)</Button>
                            <Button isActive={isFixed === false} onClick={() => setIsFixed(false)} style={{ marginLeft: 10, width: '50%' }} >End on a specific date and time</Button>
                        </div>
                        <InputLabel>* Once your project is launched, the campaign duration is set and cannot be altered. For fixed-day campaigns, the countdown begins only after the film enters the funding stage, following a successful interest-gauging phase.</InputLabel>
                    </InputWrapper>
                </InputContainer>
                {isFixed ?
                <InputContainer>
                    <InputWrapper>
                    <SubTitle isInvalid={isFixedInvalid} >Enter the number of days</SubTitle>
                        <Input
                            value={fundDuration}
                            isInvalid={isFixedInvalid}
                            onChange={(e) => handleFixedChange(e)}
                            style={{ width: '60%' }}
                        />
                        {isFixedInvalid && isFixedError !== 'LOW' && isFixedError !== 'HIGH' ? <ErrorContainer><ErrorMessage>Please enter a number</ErrorMessage><AiOutlineExclamationCircle color='red'/></ErrorContainer> : null}
                        {isFixedInvalid && isFixedError === 'LOW' ? <ErrorContainer><ErrorMessage>Number of days must be greater than 0 days</ErrorMessage><AiOutlineExclamationCircle color='red'/></ErrorContainer> : null}
                        {isFixedInvalid && isFixedError === 'HIGH' ? <ErrorContainer><ErrorMessage>Number of days must be less than 60 days</ErrorMessage><AiOutlineExclamationCircle color='red'/></ErrorContainer> : null}
                    </InputWrapper>
                </InputContainer>
                :
                <>
                <InputContainer>
                    <InputWrapper>
                        <InputLabel>Month</InputLabel>
                        <Input
                            isInvalid={isDateInvalid}
                            value={month}
                            placeholder='MM'
                            onChange={(e) => handleMonthChange(e)}
                        />
                        {isDateInvalid && !isEmpty && !isPastDate ? <ErrorContainer><ErrorMessage>Invalid Date</ErrorMessage><AiOutlineExclamationCircle color='red'/></ErrorContainer> : null}
                        {isEmpty ? <ErrorContainer><ErrorMessage>Please enter a date</ErrorMessage><AiOutlineExclamationCircle color='red'/></ErrorContainer> : null}
                        {isPastDate && !isEmpty ? <ErrorContainer><ErrorMessage>Date must be in the future</ErrorMessage><AiOutlineExclamationCircle color='red'/></ErrorContainer> : null}
                    </InputWrapper>
                    <InputWrapper style={{ marginLeft: '5%' }}>
                        <InputLabel>Day</InputLabel>
                        <Input
                            isInvalid={isDateInvalid}
                            value={day}
                            placeholder='DD'
                            onChange={(e) => handleDayChange(e)}
                        />
                    </InputWrapper>
                    <InputWrapper style={{ marginLeft: '5%' }}>
                        <InputLabel>Year</InputLabel>
                        <Input
                            isInvalid={isDateInvalid}
                            value={year}
                            placeholder='YYYY'
                            onChange={(e) => handleYearChange(e)}
                        />
                    </InputWrapper>
                </InputContainer>
                <InputContainer>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel>Time</InputLabel>
                        <div style={{ display: 'flex' }}>
                        <InputWrapper>
                            <TimeSelect value={hour} onChange={(e) => handleHourChange(e)} >
                                {hours}
                            </TimeSelect>
                        </InputWrapper>
                        <InputWrapper>
                            <TimeSelect value={minute} style={{ marginLeft: 10 }} onChange={(e) => handleMinuteChange(e)}>
                                {minutes}
                            </TimeSelect>
                        </InputWrapper>
                        <InputWrapper>
                            <Select value={timeDesignation} onChange={(e) => setTimeDesignation(e.target.value)} style={{ marginLeft: 10 }}>
                                <option value='AM'>AM</option>
                                <option value='PM'>PM</option>
                            </Select>
                        </InputWrapper>
                        </div>
                    </div>
                </InputContainer>
            </>
            }
        </>
    )
}

export default InputFields