import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { API_URL } from '../../config'
import NonMainNav from '../NonMainNav'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'

import {
    CheckboxText,
    CheckboxLink,
    InputWrapper,
    SignupError,
} from '../Signup/SignupElements.js'

import {
    Wrapper,
    PasswordWrapper,
    PasswordInput,
    EyeIcon,
    LoginWrapper, 
    LoginHeader,
    LoginInput,
    LoginButton 
} from './LoginElements.js'

function LoginComponent() {
    const queryClient = useQueryClient()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loginError, setLoginError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const { loggedInUser } = useContext(AuthContext)

    useEffect(() => {
        if (loggedInUser && loggedInUser.loggedIn) {
            navigate('/')
        }
    }, [loggedInUser, navigate])

    const handleLogin = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setLoginError('')
        setEmailError('')
        setPasswordError('')

        try {
            if (!email) {
                setEmailError('Please enter your email.')
                return
            }
    
            if (!password) {
                setPasswordError('Please enter your password.')
                return
            }
    
            const response = await axios.post(`${API_URL}/login`, { email, password }, { withCredentials: true })
    
            if (response.data.error) {
                setLoginError(response.data.error)
            } else {
                await queryClient.invalidateQueries(['user'])
                // navigate('/')
            }
        } catch (error) {
            console.error('There was an error during login:', error)
            setLoginError('An error occurred during login. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <NonMainNav loggedInUser={loggedInUser} />
            <Wrapper>
                <LoginWrapper>
                    <LoginHeader>Muugen Invest</LoginHeader>
                        <InputWrapper>
                            <LoginInput 
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                style={emailError ? { borderColor: 'red' } : {}} 
                                placeholder='Email' 
                            />
                            <SignupError>{emailError}</SignupError>
                        </InputWrapper>
                        <PasswordWrapper>
                            <PasswordInput 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={passwordError ? { borderColor: 'red' } : {}}
                                type={showPassword ? 'text' : 'password'}
                                id='password-input' 
                                placeholder='Password' 
                            />
                            <EyeIcon style={{ marginTop: -3 }} onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                            </EyeIcon>
                            <SignupError>{passwordError}</SignupError>
                        </PasswordWrapper>
                        <LoginButton onClick={handleLogin} type="submit" disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Log in'}
                        </LoginButton>
                        <SignupError>{loginError}</SignupError>
                </LoginWrapper>
                <LoginWrapper>
                    <CheckboxText>Don&apos;t have an account? <CheckboxLink onClick={() => navigate('/sign-up')} >Sign up</CheckboxLink> instead.</CheckboxText>
                    <CheckboxText style={{ marginTop: 10 }}><CheckboxLink onClick={() => navigate('/password/reset')} >Forgot password?</CheckboxLink></CheckboxText>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default LoginComponent