import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    background: white;
    padding: 20px;
    margin-bottom: 50px;
    font-family: 'Montserrat', sans-serif;
`

export const Header = styled.div`
    font-weight: 500;
    font-size: 25px;
    color: #000000;
    margin-top: 40px; 
    margin-bottom: 20px;
`

export const FaqContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`

export const FaqElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    width: 100%;
    height: 60px;
    margin-top: 20px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
`

export const ArrowIcon = styled.div`
    transition: transform 0.3s ease;
    transform: ${({ expanded }) => (expanded ? 'rotate(90deg)' : 'rotate(0deg)')};
`

export const Answer = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin-top: 10px;
    margin-left: 10px;
    border-left: 2px solid #eee;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: calc(100% - 20px);
    white-space: pre-wrap;

    a {
        color: #007bff;
        text-decoration: none;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`

export const RiskParagraph = styled.p`
    font-size: 14px;
    font-weight: 300;
    /* color: #ff0000; */
    margin-top: 40px;
    padding: 10px;
    border-left: 5px solid #ff0000;
    background: #ffe6e6;
    width: 100%;
`

export const LinkTag = styled.a`
    color: red;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`