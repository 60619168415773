import React, { useState, useRef, useEffect } from 'react'
import AuthCode from 'react-auth-code-input'
import axios from 'axios'
import { API_URL } from '../../../../config'

import { 
    ModalBg, 
    Modal,
    Header,
    HeaderText,
    Body,
    ModalTitleInfo, 
    ShowCodesButton,
    BackupInfo,
    BackupCodeText,
    BackupCodeWrapper,
    BackupButton,
    Input,
    TwoFAWrapper,
    GlobalStyles,
    InstructionText,
    ButtonWrapper,
    CancelButton,
    SaveButton
} from '../Modal/ModalElements'

import {
    PasswordWrapper,
    PasswordInput,
    EyeIcon,
} from '../../../Login/LoginElements'

import { IoClose } from 'react-icons/io5'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const LoginModal = ({ modalType, setActiveModal, setShowModal, authEnabled, userPage, setUserPage }) => {
    const [qrCode, setQrCode] = useState(null)
    const [token, setToken] = useState(null)
    const [tempSecret, setTempSecret] = useState(null)
    const [newEmail, setNewEmail] = useState('')
    const [newPhone, setNewPhone] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [backupCode, setBackupCode] = useState(null)
    const [showCodes, setShowCodes] = useState(false)
    const modalRef = useRef()

    useEffect(() => {
        const retrieveQrCode = async () => {
            try {
                const response = await axios.get(`${API_URL}/user-profile/enable-2fa/me`, { withCredentials: true })
                setQrCode(response.data.qrCode)
                setTempSecret(response.data.tempSecret)
            } catch (error) {
                alert('Error retrieving qr code. Try again later.')
                console.error("Error retrieving qr code:", error)
            }
        }

        if (modalType === '2FA')
            retrieveQrCode()
    }, [modalType])

    const handleOnChange = (res) => {
        setToken(res)
    }

    const handleClose = async (e) => {
        if (modalRef.current === e.target) {
            if (modalType === '2FA' || modalType === 'Disable 2FA' || modalType === 'Delete Account' || modalType === 'Change Email' || modalType === 'Change Phone') {
                await axios.get(`${API_URL}/user-profile/cancel-2fa-setup/me`, { withCredentials: true })
            }
            setShowModal(false)
        }
    }

    const handleCloseButton = async () => {
        try {
            if (modalType === '2FA' || modalType === 'Disable 2FA' || modalType === 'Delete Account' || modalType === 'Change Email' || modalType === 'Change Phone') {
                await axios.get(`${API_URL}/user-profile/cancel-2fa-setup/me`, { withCredentials: true })
            }
        setShowModal(false)
        } catch (error) {
            console.error("Error canceling 2FA setup:", error)
        }
    }

    const verify2FA = async () => {
        try {
            const response = await axios.post(`${API_URL}/user-profile/verify-2fa/me`, { token, tempSecret }, { withCredentials: true })
            setBackupCode(response.data.backupCode)
            setUserPage(prevUserPage => ({
                ...prevUserPage,
                hasTwoFactorAuthEnabled: true,
            }))
            setActiveModal('Backup Code')
        } catch (error) {
            alert('Incorrect 2FA code.')
            console.error("Error verifying 2FA:", error)
        }
    }

    const verifyBackupCode = async () => {
        try {
            const response = await axios.post(`${API_URL}/user-profile/verify-backup-code/me`, { token }, { withCredentials: true })
            if (response.data.success) {
                setActiveModal('Disable 2FA')
            }
        } catch (error) {
            alert('Incorrect backup code.')
            console.error("Error verifying backup code:", error)
        }
    }

    const check2FA = async () => {
        try {
        const response = await axios.post(`${API_URL}/user-profile/check-2fa/me`, { token }, { withCredentials: true })

        if (response.data.verified) {
            if (modalType === 'Check 2FA Email')
                setActiveModal('Change Email')
            else if (modalType === 'Check 2FA Phone')
                setActiveModal('Change Phone')
            else if (modalType === 'Check 2FA Disable')
                setActiveModal('Disable 2FA')
            else if (modalType === 'Check 2FA Delete')
                setActiveModal('Delete Account')
        }
        } catch (error) {
            alert('Incorrect 2FA code.')
            console.error("Error verifying 2FA:", error)
        }
    }

    const passwordSubmit = async () => {
        try {
            const response = await axios.post(`${API_URL}/user-profile/password-auth/me`, { token }, { withCredentials: true })
            if (response.data.verified) {
                if (userPage.hasTwoFactorAuthEnabled) {
                    if (modalType === 'Phone Password')
                        setActiveModal('Check 2FA Phone')
                    else if (modalType === 'Disable 2FA Password')
                        setActiveModal('Check 2FA Disable')
                    else if (modalType === 'Email Password')
                        setActiveModal('Check 2FA Email')
                    else if (modalType === 'Delete Account Password')
                        setActiveModal('Check 2FA Delete')
                } else {
                    if (modalType === 'Phone Password')
                        setActiveModal('Change Phone')
                    else if (modalType === 'Email Password')
                        setActiveModal('Change Email')
                    else if (modalType === 'Delete Account Password')
                        setActiveModal('Delete Account')
                }
            }
        } catch (error) {
            alert('Incorrect password.')
            console.error("Error verifying password:", error)
        }
    }

    const updateEmail = async () => {
        // Regular expression pattern to validate an email address
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    
        // Check if email format is correct
        if (!emailRegex.test(newEmail)) {
            alert("Please provide a valid email address. (example@example.com)")
            return // Exit function
        }
    
        try {
            const response = await axios.post(`${API_URL}/user-profile/update-email/me`, { newEmail }, { withCredentials: true })
            setUserPage(prevUserPage => ({
                ...prevUserPage,
                email: response.data.email,
            }))
            setShowModal(false)
        } catch (error) {
            console.error("Error updating email:", error)
        }
    }

    const handlePhoneChange = (event) => {
        let { value } = event.target
        
        // Remove all non-digit characters
        value = value.replace(/\D/g, '')
    
        // Adding the dashes after the area code and next 3 digits
        if (value.length > 3 && value.length <= 6) 
            value = value.slice(0,3) + '-' + value.slice(3)
        else if (value.length > 6) 
            value = value.slice(0,3) + '-' + value.slice(3,6) + '-' + value.slice(6,10)
    
        setNewPhone(value)
    }

    const updatePhone = async () => {
        // Regular expression pattern to validate a phone number
        const phoneRegex = /^\d{3}-\d{3}-\d{4}$/
    
        // Check if phone number format is correct
        if (!phoneRegex.test(newPhone)) {
            alert("Please provide a valid phone number. (555-555-5555)")
            return // Exit function
        }
    
        try {
            await axios.post(`${API_URL}/user-profile/update-phone/me`, { newPhone }, { withCredentials: true })
            setShowModal(false)
        } catch (error) {
            console.error("Error updating phone number:", error)
        }
    }

    const disable2FA = async () => {
        try {
            await axios.post(`${API_URL}/user-profile/disable-2fa/me`, {}, { withCredentials: true })
            setUserPage(prevUserPage => ({
                ...prevUserPage,
                hasTwoFactorAuthEnabled: false,
            }))
            setShowModal(false)
        } catch (error) {
            console.error("Error disabling 2FA:", error)
        }
    }

    let buttonMessage 

    if (['Email Password', 'Phone Password', 'Disable 2FA Password', 'Delete Account Password'].includes(modalType)) {
        buttonMessage = 'Continue'
    } else if (['Change Email', 'Change Phone'].includes(modalType)) {
        buttonMessage = 'Save'
    } else if (['Check 2FA Phone', 'Check 2FA Email', 'Check 2FA Disable', 'Check 2FA Password', 'Check 2FA', '2FA', 'Verify Backup Code'].includes(modalType)) {
        buttonMessage = 'Verify'
    } else if (modalType === 'Password' || modalType === 'Backup Code') {
        buttonMessage = 'Close'
    } else if (modalType === 'Logout') {
        buttonMessage = 'Logout'
    } else if (modalType === 'Delete Account') {
        buttonMessage = 'Delete'
    } else if (modalType === 'Disable 2FA') {
        buttonMessage = 'Disable'
    }

    let onClickFunction

    if (modalType === '2FA') {
        onClickFunction = verify2FA
    } else if (modalType === 'Verify Backup Code') {
        onClickFunction = verifyBackupCode
    } else if (modalType === 'Change Email') {
        onClickFunction = updateEmail
    } else if (modalType === 'Phone Password' || modalType === 'Disable 2FA Password' || modalType === 'Email Password' || modalType === 'Delete Account Password') {
        onClickFunction = passwordSubmit
    } else if (['Check 2FA Phone', 'Check 2FA Email', 'Check 2FA Disable', 'Check 2FA Delete', '2FA'].includes(modalType)) {
        onClickFunction = check2FA
    } else if (modalType === 'Change Phone') {
        onClickFunction = updatePhone
    } 
    // else if (modalType === 'Delete Account') {
        
    // } 
    else if (modalType === 'Disable 2FA') {
        onClickFunction = disable2FA
    } else if (modalType === 'Backup Code') {
        onClickFunction = handleCloseButton
    }

    let header 

    if (['Email Password', 'Change Email', 'Check 2FA Email'].includes(modalType)) {
        header = 'Update Email'
    } else if (['Phone Password', 'Check 2FA Phone', 'Change Phone'].includes(modalType)) {
        header = 'Update Phone'
    } else if (['Check 2FA Disable', 'Disable 2FA Password', 'Disable 2FA', 'Verify Backup Code'].includes(modalType)) {
        header = 'Disable 2FA'
    } else if (['Delete Account', 'Check 2FA Delete', 'Delete Account Password'].includes(modalType)) {
        header = 'Delete Account Permanently'
    } else if (modalType === '2FA' || modalType === 'Backup Code') {
        header = 'Enable 2FA'
    } else if (modalType === 'Password') {
        header = 'Reset Password'
    }

    return (
        <ModalBg ref={modalRef} onClick={handleClose}>
            <Modal>
                <Header>
                    <HeaderText>{header}</HeaderText>
                    <IoClose size={30} style={{ cursor: 'pointer' }} onClick={handleCloseButton} />
                </Header>
                <Body>
                    {modalType === 'Change Email' ?
                    <>
                        <ModalTitleInfo>Please enter your new email address.</ModalTitleInfo>
                        <Input placeholder='New Email' onChange={e => setNewEmail(e.target.value)} />
                    </>
                    :
                    modalType === 'Password' ?
                    <>
                        <InstructionText>We have sent password update instructions to your provided email address.</InstructionText>
                    </>
                    :
                    modalType === '2FA' ?
                    <>
                        <ModalTitleInfo>{modalType}</ModalTitleInfo>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={qrCode} alt="Scan this QR code with your authentication app" />
                            <ModalTitleInfo>Verify the two factor authentication code from your authentication app.</ModalTitleInfo>
                            <TwoFAWrapper>
                                <GlobalStyles />
                                <AuthCode 
                                    inputClassName='custom-auth-input'
                                    allowedCharacters='numeric' 
                                    onChange={handleOnChange}
                                />
                            </TwoFAWrapper>
                        </div>
                    </>
                    :
                    modalType === 'Backup Code' ?
                    <>
                        <ModalTitleInfo>Backup Code</ModalTitleInfo>
                        <BackupInfo>Your backup code is used to to disable and reset your 2FA if you ever lose access to your authentication app. This code can only be used once and is unique to your account. Remember that if you lose your backup code, it cannot be recovered, so keep it in a safe place.</BackupInfo>
                        {!showCodes && <ShowCodesButton onClick={() => setShowCodes(true)} >Show Code</ShowCodesButton>}
                        {showCodes &&
                        <BackupCodeWrapper>
                            <BackupCodeText>{backupCode}</BackupCodeText>
                        </BackupCodeWrapper>
                        }
                    </>
                    :
                    modalType === 'Disable 2FA Password' || modalType === 'Delete' ?
                    <>
                        <ModalTitleInfo>Please enter your password to disable 2FA.</ModalTitleInfo>
                        <PasswordWrapper>
                            <PasswordInput
                                showPassword={showPassword}
                                onChange={e => setToken(e.target.value)}
                                placeholder='Password'
                                type={!showPassword ? 'password' : 'text'}
                                id='password-input'
                            />
                            <EyeIcon style={{ top: 37 }} onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                            </EyeIcon>
                        </PasswordWrapper>
                    </>
                    :
                    modalType === 'Phone Password' || modalType === 'Delete Account Password' ?
                    <>
                        <ModalTitleInfo>Please enter your password to continue.</ModalTitleInfo>
                        <PasswordWrapper>
                            <PasswordInput 
                                showPassword={showPassword} 
                                onChange={e => setToken(e.target.value)}
                                placeholder='Password' 
                                type={!showPassword ? 'password' : 'text'}
                                id='password-input' 
                            />
                            <EyeIcon style={{ top: 37 }} onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                            </EyeIcon>
                        </PasswordWrapper>
                    </>
                    :
                    modalType === 'Check 2FA Phone' || modalType === 'Check 2FA Email' || modalType === 'Check 2FA Delete' ?
                    <>
                        <ModalTitleInfo>Please enter your two factor authentication code to continue. If you have lost your 2FA credentials, disable 2FA using your backup code.</ModalTitleInfo>
                        <TwoFAWrapper>
                            <GlobalStyles />
                            <AuthCode
                                inputClassName='custom-auth-input'
                                allowedCharacters='numeric'
                                onChange={handleOnChange}
                            />
                        </TwoFAWrapper>
                    </>
                    :
                    modalType === 'Check 2FA Disable' ?
                    <>
                        <ModalTitleInfo>Please enter your two factor authentication code to continue.</ModalTitleInfo>
                        <TwoFAWrapper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <GlobalStyles />
                            <AuthCode
                                inputClassName='custom-auth-input'
                                allowedCharacters='numeric'
                                onChange={handleOnChange}
                            />
                            <ModalTitleInfo style={{ marginTop: 20 }}>Lost your 2FA authencation credentials? <BackupButton onClick={() => setActiveModal('Verify Backup Code')}>Use backup code instead.</BackupButton></ModalTitleInfo>
                        </TwoFAWrapper>
                    </>
                    :
                    modalType === 'Verify Backup Code' ?
                    <>
                        <ModalTitleInfo>Please enter your backup code to continue.</ModalTitleInfo>
                        <PasswordWrapper>
                            <PasswordInput
                                showPassword={showPassword}
                                onChange={e => setToken(e.target.value)}
                                placeholder='Backup Code'
                                type='password'
                                id='password-input'
                            />
                            <EyeIcon style={{ top: 37 }} onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                            </EyeIcon>
                        </PasswordWrapper>
                    </>
                    :
                    modalType === 'Change Phone' ?
                    <>
                        <ModalTitleInfo>Enter your new phone number.</ModalTitleInfo>
                        <Input value={newPhone} placeholder='555-555-5555' onChange={handlePhoneChange} />
                    </>
                    :
                    modalType === 'Disable 2FA' ?
                    <>
                        <ModalTitleInfo>Please click the button below to disable 2FA.</ModalTitleInfo>
                    </>
                    :
                    modalType === 'Delete Account' ?
                    <>
                        <ModalTitleInfo>Please enter &quot;delete account permanently&quot; to confirm account deletion.</ModalTitleInfo>
                        <Input placeholder='delete account permanently' />
                    </>
                    :
                    null
                    }
                    <ButtonWrapper>
                        {modalType !== 'Backup Code' && <CancelButton onClick={handleCloseButton}>{modalType === 'Password' ? 'Close' : 'Cancel'}</CancelButton>}
                        {modalType !== 'Password' && <SaveButton onClick={onClickFunction} style={modalType === 'Delete Account' || modalType === 'Disable 2FA' ? { background: 'red' } : {}}>{buttonMessage}</SaveButton>}
                    </ButtonWrapper>
                </Body>
            </Modal>
        </ModalBg>
    )
}

export default LoginModal