import styled from 'styled-components'

export const FilmPicture = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`

export const Currency = styled.div`
    margin-left: 3px;
    font-size: 11px;
    color: #898b8a;
`

export const CurrencyWrapper = styled.div`
    width: 100%;
    display: flex;
`

//CARD REDESIGN

export const CardContainer = styled.div`
    position: relative;
    height: 500px;
    width: 320px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: translateY(-3px);
        background: white;
        box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
    }
`

export const ProjectImageContainer = styled.div`
    height: 200px;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
`

export const ProjectCreatorContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 175px;
    right: 30px;
    height: 55px;
    width: 55px;
    border-radius: 50%;  
    background: white; 
`

export const ProjectCreatorImage = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
`

export const Body = styled.div`
    height: 300px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: white;
`

export const Status = styled.div`
    font-size: 12px;
    color: #7AA874;
    font-weight: bold;
`

export const Title = styled.div`
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #20262E;
    font-family: 'DM Sans', sans-serif;
`

export const Logline = styled.div`
    margin-top: 20px;
    font-size: 14px;
    /* color: #B2B2B2; */
`

export const ProgressWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const ProgressHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const Raised = styled.div`
    font-size: 13px;
    display: flex;

    &:hover {
        color: #7AA874;
    }
`

export const Percent = styled.div`
    font-size: 13px;
    color: #B2B2B2;
    
    &:hover {
        color: #7AA874;
    }
`

export const RaisedBar = styled.div`
    height: 8px;
    width: 100%;
    background: #E8E8E8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    &:hover {
        background: #D8D8D8;
    }
`

export const ProgressBar = styled.div`
    height: 8px;
    width: ${props => props.percent}%;
    background: #7AA874;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #6AA864;
    }

`