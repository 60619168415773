import React, { useState, useRef, useEffect } from 'react'
import AuthCode from 'react-auth-code-input'
import axios from 'axios'
import { API_URL } from '../../../../config'

import { 
    ModalBg, 
    Modal,
    Header,
    HeaderText,
    Body,
    ModalTitleInfo,
    Input,
    ButtonWrapper,
    CancelButton,
    SaveButton,
    TwoFAWrapper,
    GlobalStyles
} from '../Modal/ModalElements'

import { IoClose } from 'react-icons/io5'

const EmailVerificationModal = ({ modalType, setActiveModal, setShowModal, userPage, setUserPage }) => {
    const [newEmail, setNewEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [isCodeVerified, setIsCodeVerified] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const modalRef = useRef()
    const isInitialMount = useRef(true)

    useEffect(() => {
        const sendInitialVerificationEmail = async () => {
            if (isInitialMount.current) {
                isInitialMount.current = false
                try {
                    await axios.post(`${API_URL}/api/email/send-email-verification`, 
                        { 
                            currentEmail: userPage.email
                        }, 
                        { withCredentials: true }
                    )
                } catch (error) {
                    setErrorMessage('Error sending verification email. Please try again later.')
                    console.error("Error sending verification email:", error)
                    setShowModal(false)
                }
            }
        }
    
        sendInitialVerificationEmail()
    
        return () => {
            console.log('Email verification modal unmounted')
        }
    
        //eslint-disable-next-line
    }, [])

    const handleClose = (e) => {
        if (modalRef.current === e.target) {
            setShowModal(false)
        }
    }

    const handleCloseButton = () => {
        setShowModal(false)
    }

    const handleVerificationCodeChange = (code) => {
        setVerificationCode(code)
        setErrorMessage('') // Clear any previous error messages
    }

    const handleEmailChange = async (e) => {
        const email = e.target.value
        setNewEmail(email)
        setErrorMessage('') // Clear any previous error messages
    }

    const verifyCode = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/email/verify-code`, 
                { verificationCode }, 
                { withCredentials: true }
            )
            
            if (response.data.success) {
                setIsCodeVerified(true)
                setErrorMessage('')
            }
        } catch (error) {
            setErrorMessage('Invalid verification code. Please try again.')
            console.error("Error verifying code:", error)
        }
    }

    const updateEmail = async () => {
        // Regular expression pattern to validate an email address
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    
        if (!emailRegex.test(newEmail)) {
            setErrorMessage("Please provide a valid email address. (example@example.com)")
            return
        }

        try {
            const response = await axios.post(`${API_URL}/api/email/update-email`, 
                { 
                    newEmail,
                    verificationCode 
                }, 
                { withCredentials: true }
            )
            
            setUserPage(prevUserPage => ({
                ...prevUserPage,
                email: response.data.email,
            }))
            setShowModal(false)
        } catch (error) {
            setErrorMessage('Error updating email. Please try again.')
            console.error("Error updating email:", error)
        }
    }

    return (
        <ModalBg ref={modalRef} onClick={handleClose} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <Modal>
                <Header>
                    <HeaderText>Update Email</HeaderText>
                    <IoClose size={30} style={{ cursor: 'pointer' }} onClick={handleCloseButton} />
                </Header>
                <Body>
                    {!isCodeVerified ? (
                        <>
                            <ModalTitleInfo>
                                A verification code has been sent to your current email ({userPage.email}).
                                Enter the code below to continue.
                            </ModalTitleInfo>
                            <TwoFAWrapper>
                                <GlobalStyles />
                                <AuthCode 
                                    inputClassName='custom-auth-input'
                                    allowedCharacters='numeric'
                                    onChange={handleVerificationCodeChange}
                                />
                            </TwoFAWrapper>
                            {errorMessage && (
                                <ModalTitleInfo style={{ color: 'red', marginTop: '10px' }}>
                                    {errorMessage}
                                </ModalTitleInfo>
                            )}
                            <ButtonWrapper>
                                <CancelButton onClick={handleCloseButton}>Cancel</CancelButton>
                                <SaveButton onClick={verifyCode}>Verify Code</SaveButton>
                            </ButtonWrapper>
                        </>
                    ) : (
                        <>
                            <ModalTitleInfo>
                                Code verified! Enter your new email address below.
                            </ModalTitleInfo>
                            <Input 
                                placeholder='New Email' 
                                onChange={handleEmailChange}
                                value={newEmail}
                                style={{ marginTop: '20px' }}
                            />
                            {errorMessage && (
                                <ModalTitleInfo style={{ color: 'red', marginTop: '10px' }}>
                                    {errorMessage}
                                </ModalTitleInfo>
                            )}
                            <ButtonWrapper>
                                <CancelButton onClick={handleCloseButton}>Cancel</CancelButton>
                                <SaveButton onClick={updateEmail}>Update Email</SaveButton>
                            </ButtonWrapper>
                        </>
                    )}
                </Body>
            </Modal>
        </ModalBg>
    )
}

export default EmailVerificationModal