import React, { useState, useRef } from 'react'
import { getCurrentInfoValue, isFieldFilled } from '../helpers/reducerHelper'

import { 
    Wrapper,
    ModalBg,
    Header,
    HeaderText,
} from '../../ProjectProfile/Modal/EditModalElements'

import { 
    Modal, 
    Body, 
    CreditCardSection,
    CreditCardTitle,
    InvestmentAmountWrapper,
    InvestmentInfo,
    PaymentInfo,
    CreditCardWrapper,
    CreditCardInfo,
    CardIcon,
    AdditionalInfo,
    BorderBottom,
    SubmitButton,
} from '../ProjectInvestElements'

import { IoClose } from 'react-icons/io5'
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { FaCcVisa, FaCcMastercard, FaCcDiscover } from "react-icons/fa";
import AmericanExpress from '../../../assets/American Express.png'

//CAN REMOVE SETPAYMENTMETHOD SINCE WE FIXED ANCESTOR.
function CreditCardModal({ state, dispatch, setShowCreditModal }) {
    const [cardType, setCardType] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''  
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Payment Type' } }) 
            setShowCreditModal(false)
        }
    }

    const closeModalExit = e => {
        document.body.style.overflow = ''
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Payment Type' } })
        setShowCreditModal(false)
    }

    function getCardType(cardNumber) {
        // Remove spaces or hyphens
        const digits = cardNumber.replace(/[\s-]/g, '')
    
        if (/^4/.test(digits)) {
            return 'Visa'
        }
    
        if (/^5[1-5]/.test(digits) || /^2[2-7]/.test(digits)) {
            return 'MasterCard'
        }
    
        if (/^6011|^622(1(2[6-9]|[3-9]\d)|[2-8]\d\d|9([01]\d|2[0-5]))|^64[4-9]|^65/.test(digits)) {
            return 'Discover'
        }
    
        if (/^3[47]/.test(digits)) {
            return 'American Express'
        }

        if (cardNumber.length > 0) {
            return 'Unknown'
        }
    
        return ''
    }  

    const handleCardInputChange = (e) => {
        const value = e.target.value
    
        // Removing all non-numeric characters
        const numericValue = value.replace(/[^0-9]/g, '')
    
        let formattedValue = ''
        for (let i = 0; i < numericValue.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedValue += ' '
            }
            formattedValue += numericValue[i]
        }
    
        // setCardInput(formattedValue)
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'Card Number' } })
        const detectedCardType = getCardType(numericValue)
        setCardType(detectedCardType)
    }
    

    const handleCvcChange = (e) => {
        const value = e.target.value
    
        // Removing all non-numeric characters
        const numericValue = value.replace(/[^0-9]/g, '')
    
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: numericValue, label: 'CVC' } })
        // setCvc(numericValue)
    }

    const handleDateChange = (e) => {
        let value = e.target.value
    
        // Handle '1 ' scenario
        if (value === '1 ') {
            value = '01/'
        }
    
        // Removing all non-numeric characters for further processing
        const numericValue = value.replace(/[^0-9]/g, '')
    
        // If user types "00", return early and ignore input
        if (numericValue === '00') {
            return
        }
    
        // Initialize formattedValue as numericValue by default
        let formattedValue = numericValue
    
        // If only one character and it's greater than '1', prepend '0'
        if (numericValue.length === 1 && parseInt(numericValue) > 1) {
            formattedValue = '0' + numericValue
        }
    
        // Making sure month doesn't exceed 12 and automatically adding '/'
        if (formattedValue.length === 2 && !value.includes('/')) {
            if (parseInt(formattedValue) > 12) {
                formattedValue = formattedValue[0]  // Keep only the first digit
            } else {
                formattedValue += '/' // Add the slash
            }
        }
    
        // If trying to delete the last number before the slash and previous value was longer
        if (value.endsWith('/') && e.target.value.length < e.target.defaultValue.length) {
            formattedValue = formattedValue.slice(0, 2)
        }
    
        // If 3 or 4 characters, format it appropriately. This condition ensures the slash doesn't get added twice.
        if (formattedValue.length >= 3 && !formattedValue.includes('/')) {
            formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2)
        }
    
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'Expiration Date' } })
    }  
    
    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            const value = e.target.value
            let formattedValue = value
            
            if (value.endsWith('/') && e.target.value.length === 3) {
                formattedValue = formattedValue.slice(0, 2)
            } 

            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'Expiration Date' } })
        }
    }

    const areAllRequiredFieldsFilled = (state) => {
        const requiredFields = [
          'Name On Card',
          'Card Number',
          'Expiration Date',
          'CVC'
        ]
      
        const invalidFields = requiredFields.filter(field => {
          const item = state.store.find(item => item.label === field)
          return item ? item.unFilled : false
        })
      
        return invalidFields
    }

    const handleSubmitClick = () => {
        const invalidFields = areAllRequiredFieldsFilled(state)
        setHasSubmitted(true)
        if (invalidFields.length === 0) {
            document.body.style.overflow = ''
            setShowCreditModal(false)
        } else {
            return
        }
    }
    
    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText style={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 700 }}>Credit Card Payment</HeaderText>
                        <IoClose size={25} style={{ cursor: 'pointer' }} onClick={closeModalExit} />
                    </Header>
                    <Body>
                        <CreditCardSection>
                            <CreditCardTitle>Credit Card Information</CreditCardTitle>
                            <PaymentInfo 
                                placeholder='Name On Card' 
                                value={getCurrentInfoValue('Name On Card', state)}
                                isInvalid={hasSubmitted && isFieldFilled('Name On Card', state)} 
                                onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Name On Card' } })}
                            />
                            <CreditCardWrapper>
                                {/* <CardIcon size={20} color={'lightgrey'} /> */}
                                <CardIcon>
                                    {cardType === 'Visa' && <FaCcVisa />}
                                    {cardType === 'MasterCard' && <FaCcMastercard />}
                                    {cardType === 'Discover' && <FaCcDiscover />}
                                    {cardType === 'American Express' && <img src={AmericanExpress} alt='American Express' style={{ width: 23, height: 23 }} />}
                                    {cardType === 'Unknown' && <BsCreditCard2FrontFill />}
                                    {cardType === '' && <BsCreditCard2FrontFill color={'lightgrey'} />}
                                </CardIcon>
                                <CreditCardInfo 
                                    placeholder='Card Number' 
                                    value={getCurrentInfoValue('Card Number', state)}
                                    isInvalid={hasSubmitted && isFieldFilled('Card Number', state)} 
                                    onChange={handleCardInputChange}
                                    maxLength={19}
                                />
                            </CreditCardWrapper>
                            <div style={{ display: 'flex' }}>
                                <AdditionalInfo 
                                    placeholder='CVC' 
                                    maxLength={4} 
                                    value={getCurrentInfoValue('CVC', state)}
                                    isInvalid={hasSubmitted && isFieldFilled('CVC', state)} 
                                    onChange={handleCvcChange}
                                />
                                <AdditionalInfo 
                                    style={{ marginLeft: 10 }} 
                                    value={getCurrentInfoValue('Expiration Date', state)}
                                    isInvalid={hasSubmitted && isFieldFilled('Expiration Date', state)} 
                                    placeholder='MM/YY' 
                                    onChange={handleDateChange}
                                    onKeyDown={handleKeyDown}
                                    maxLength={5}
                                />
                            </div>
                        </CreditCardSection>
                        <InvestmentAmountWrapper>
                            <InvestmentInfo>Your Investment</InvestmentInfo>
                            <InvestmentInfo style={{ fontWeight: 700 }}>$100</InvestmentInfo>
                        </InvestmentAmountWrapper>
                        <InvestmentAmountWrapper>
                            <InvestmentInfo>Escrow Agent Fee</InvestmentInfo>
                            <InvestmentInfo style={{ fontWeight: 700 }}>$3.25</InvestmentInfo>
                        </InvestmentAmountWrapper>
                        <BorderBottom />
                        <InvestmentAmountWrapper>
                            <InvestmentInfo>Total</InvestmentInfo>
                            <InvestmentInfo style={{ fontWeight: 700 }}>$103.25</InvestmentInfo>
                        </InvestmentAmountWrapper>
                        <SubmitButton onClick={handleSubmitClick}>Submit</SubmitButton>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default CreditCardModal