import React, { useState, useEffect, lazy, Suspense } from 'react'
import { API_URL } from '../../../config'
import axios from 'axios'
import LazyLoaderModal from '../../LazyLoaderModal'

import { 
    Wrapper, 
    MainVideoWrapper,
    GridVideoWrapper, 
    SmallVideoWrapper,
    VideoTitle,
    TrashContainer
} from './VideoElements'

import { 
    VideoWrapper,
    StyledIframe, 
} from '../About/AboutElements'

import { 
    NoInfo
} from '../ArtistProjects/ArtistProjectsElements.js'

import { Button } from '../ArtistProjects/ArtistProjectsElements'
import { BiVideoPlus } from 'react-icons/bi'
import { AiOutlineDelete } from 'react-icons/ai'

const VideoModal = lazy(() => import('./AddVideoModal'))
const VideoPlayModal = lazy(() => import('./VideoPlayModal'))

function extractSrc(iframeString) {
    const match = iframeString.match(/src="([^"]+)"/)
    return match ? match[1] : null
}

function Videos({ mainVideoTitle, mainVideoEmbed, setArtistAssets, videoEmbeds, loggedInUser, artistId }) {
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [mainSrc, setMainSrc] = useState('')
    const [showPlayModal, setShowPlayModal] = useState(false)
    const [embedSrc, setEmbedSrc] = useState('')
    const [videos, setVideos] = useState(videoEmbeds)
    const [mainTitle, setMainTitle] = useState(mainVideoTitle)

    useEffect(() => {
        if (mainVideoEmbed) {
            setMainSrc(extractSrc(mainVideoEmbed))
        }
    }, [mainVideoEmbed])

    const handleOpenModal = () => {
        document.body.style.overflow = 'hidden'
        setShowVideoModal(true)
    }

    const handleMainVideoDelete = async () => {
        const userConfirmation = window.confirm("Are you sure you want to delete this video?")

        if (userConfirmation) {
            try {
                await axios.delete(`${API_URL}/profile/delete-main-embed/me`, { withCredentials: true })

                setArtistAssets(prevAssets => ({
                    ...prevAssets,
                    mainVideoTitle: null,
                    mainVideoEmbed: null
                }))

                setMainSrc('')
            } catch (error) {
                console.error("Failed to delete video:", error)
                alert('There was an error deleting the video. Please try again later.', error)
            }
        }
    }

    const handleVideoDelete = async (videoId) => {
        const userConfirmation = window.confirm("Are you sure you want to delete this video?")
    
        if (userConfirmation) {
            try {
                const payload = {
                    videoId
                }
    
                const response = await axios.post(`${API_URL}/profile/delete-embed/me`, payload, { withCredentials: true })

                setArtistAssets(prevAssets => ({
                    ...prevAssets,
                    videos: response.data
                }))

                setVideos(response.data)
    
            } catch (error) {
                console.error("Failed to delete video:", error)
                alert('There was an error deleting the video. Please try again later.')
            }
        }
    }
    
    const handleThumbnailClick = (embedString) => {
        document.body.style.overflow = 'hidden'
        setEmbedSrc(embedString)
        setShowPlayModal(true)
    }    

    let videoMapping

    if (videoEmbeds) {
        videoMapping = videos.map((video) => {
            const src = extractSrc(video.videoEmbed)

            return (
                <SmallVideoWrapper key={video._id} >
                        <VideoWrapper style={{ marginBottom: 0, position: 'relative' }}>
                            <div onClick={() => handleThumbnailClick(src)} style={{ background: 'transparent', position: 'absolute', bottom: 0, left: 0, height: '100%', width: '100%', zIndex: 10, cursor: 'pointer'}}></div>
                            <StyledIframe src={src} />
                            {loggedInUser?.artist === artistId &&
                                <TrashContainer onClick={() => handleVideoDelete(video._id)} >
                                    <AiOutlineDelete size={20} />
                                </TrashContainer>
                            }
                        </VideoWrapper>
                        <VideoTitle>{video.title}</VideoTitle>
                </SmallVideoWrapper>
            )
        })
    }

    return (
        <Wrapper>
            <Suspense fallback={<LazyLoaderModal />} >
                {showVideoModal && 
                    <VideoModal 
                        showVideoModal={showVideoModal} 
                        setShowVideoModal={setShowVideoModal} 
                        setArtistAssets={setArtistAssets}
                        mainSrc={mainSrc} 
                        setMainSrc={setMainSrc}
                        setMainTitle={setMainTitle}
                        videoEmbeds={videoEmbeds}  
                        setVideos={setVideos}
                        videos={videos}
                    />
                }
            </Suspense>
            <Suspense fallback={<LazyLoaderModal />} > 
                {showPlayModal &&  <VideoPlayModal setShowPlayModal={setShowPlayModal} embedSrc={embedSrc} /> }
            </Suspense>
            {loggedInUser?.artist === artistId &&
                <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-start', marginBottom: 20 }}>
                    <Button onClick={handleOpenModal} >
                        <BiVideoPlus size={20} />
                        <div style={{ marginLeft: 4 }}>Add Video</div>
                    </Button>
                </div>
            }
            {mainVideoEmbed &&
            <MainVideoWrapper>
                <VideoWrapper style={{ marginBottom: 0 }}>
                    <div onClick={() => handleThumbnailClick(mainSrc)} style={{marginBottom: '22px', background: 'transparent', position: 'absolute', bottom: 0, left: 0, height: '100%', width: '100%', zIndex: 10, cursor: 'pointer'}}></div>
                    <StyledIframe src={mainSrc}/>
                    {loggedInUser?.artist === artistId &&
                        <TrashContainer onClick={handleMainVideoDelete} >
                            <AiOutlineDelete size={20} />
                        </TrashContainer>
                    }
                </VideoWrapper>
                <VideoTitle>{mainTitle}</VideoTitle>
            </MainVideoWrapper>
            }
            <GridVideoWrapper>
                {videoMapping}
            </GridVideoWrapper>
            {!mainVideoEmbed && videoEmbeds?.length === 0 &&
                <NoInfo>This creator doesn&apos;t currently have any videos.</NoInfo>
            }
        </Wrapper>
    )
}   

export default Videos