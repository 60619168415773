import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CLOUDFRONT_URL } from '../../../config'
import { PrimaryColor } from '../../../colors'

import { 
    ProjectContainer, 
    ProjectImage, 
    PercentageText, 
    ContainerTint,
    PercentageContainer,
    CardInfoWrapper,
    ProjectLink,
    ProgressBar,
    Progress,
    Title,
} from './ProjectCardElements'

function ProjectCard({ projectId, image, name, projectStatus, totalExpressedInterest, currentFunds, targetFundAmount }) {
    const navigate = useNavigate()

    const handleProjectClick = () => {
        navigate(`/project-profile/${projectId}`)
    }

    let projectState = projectStatus

    if (projectStatus === 'GAUGING_INTEREST')
        projectState = 'Gauging Interest'
    else if (projectStatus === 'FUNDING')
        projectState = 'Funding'
    else if (projectStatus === 'COMPLETED')
        projectState = 'Completed'
    else if (projectState === 'CANCELLED')
        projectState = 'CANCELLED'
    else if (projectState === 'FUNDED')
        projectState = 'Funded'

    const percentage = () => {
        if (projectStatus === 'GAUGING_INTEREST') {
            return Math.ceil((totalExpressedInterest / targetFundAmount) * 100)
        } else {
            return Math.ceil((currentFunds / targetFundAmount) * 100)
        }
    }

    return (
        <ProjectContainer>
            <CardInfoWrapper>
                <Title>{name}</Title>
                <Title style={{ color: `${PrimaryColor}` }} >{projectState}</Title>
            </CardInfoWrapper>
            <ProjectLink onClick={handleProjectClick} >
                <ContainerTint className='tint' />
                <PercentageContainer className='percentage'>
                    <PercentageText>{percentage()}%</PercentageText>
                    <PercentageText>Funded</PercentageText>
                </PercentageContainer>
                <ProjectImage src={`${CLOUDFRONT_URL}/${image}`} />
            </ProjectLink>
            <ProgressBar>
                <Progress percentage={percentage()} />
            </ProgressBar>
        </ProjectContainer>
    )
}

export default ProjectCard
