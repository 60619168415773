import axios from 'axios'
import { API_URL } from '../../config'

export const followUser = async (userId) => {
    try {
        await axios.post(`${API_URL}/discover-users/follow`, { followedUserId: userId }, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const unFollowUser = async (userId) => {
    try {
        await axios.post(`${API_URL}/discover-users/unfollow`, { followedUserId: userId }, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}