import styled from 'styled-components'
import { PrimaryColor } from '../../../../../colors'

export const ProjectName = styled.div`
    font-size: 15px;
    font-weight: 700;
    color: ${PrimaryColor};
`

export const Title = styled.div`
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
`

export const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`

export const CommentCountContainer = styled.div`
    display: flex;
    align-items: center;
`

export const CommentCount = styled.div`
    font-size: 15px;
    color: ${PrimaryColor};
    margin-bottom: 20px;
`

export const Underline = styled.div`
    width: 100%;
    height: 1px;
    background-color: #E8E8E8;
    margin-top: 20px;
`

export const ShareButton = styled.button`
    padding: 2px;
    margin-right: 10px;
    font-family: 'DM Sans', sans-serif;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: grey;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: black;
    }
`