import React, { useState, useRef } from 'react'

import { 
    Wrapper, 
    ModalBg, 
    Modal, 
    Header, 
    HeaderText, 
    Body,
    BodySection,
} from '../../../Modal/EditModalElements'

import AddPerk from './AddPerk'
import EditPerk from './EditPerk'
import DeletePerk from './DeletePerk'

import { IoIosAddCircleOutline } from 'react-icons/io'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { IoCloseSharp } from 'react-icons/io5' 

function PerkModal({ setShowModal, projectInfo, setShowEditMode, perkArray, setPerkArray }) {
    const [showAddPerk, setShowAddPerk] = useState(false)
    const [showEditPerk, setShowEditPerk] = useState(false)
    const [showDeletePerk, setShowDeletePerk] = useState(false)

    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = 'visible'
            setShowModal(false)
        }
    }

    const handleClose = () => {
        document.body.style.overflow = 'visible'
        setShowModal(false)
    }

    let renderOption

    if (showAddPerk) {
        renderOption = <AddPerk setShowModal={setShowModal} perkArray={perkArray} setPerkArray={setPerkArray} showAddPerk={showAddPerk} setShowAddPerk={setShowAddPerk} projectInfo={projectInfo} />
    } else if (showEditPerk) {
        renderOption = <EditPerk setShowModal={setShowModal} perkArray={perkArray} setPerkArray={setPerkArray} setShowEditPerk={setShowEditPerk} projectInfo={projectInfo} />
    } else if (showDeletePerk) {
        renderOption = <DeletePerk setShowModal={setShowModal} setPerkArray={setPerkArray} setShowDeletePerk={setShowDeletePerk} perkArray={perkArray} projectInfo={projectInfo} />
    } else {
        renderOption = 
        <Modal>
            <Header>
                <HeaderText style={{ display: 'flex', alignItems: 'center'}}>Edit Perks</HeaderText>
                <IoCloseSharp onClick={handleClose} size={25} style={{ cursor: 'pointer' }}/>
            </Header>
            <Body>
                <BodySection onClick={() => setShowAddPerk(true)}><IoIosAddCircleOutline size={20} style={{ marginRight: 5 }}/>Add Perk</BodySection>
                <BodySection onClick={() => setShowEditPerk(true)}><AiOutlineEdit size={20} style={{ marginRight: 5 }}/>Edit Perk</BodySection>
                <BodySection onClick={() => setShowDeletePerk(true)}><MdOutlineDeleteOutline size={20} style={{ marginRight: 5 }}/>Delete Perk</BodySection>
            </Body>
        </Modal>
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                {renderOption}
            </ModalBg>
        </Wrapper>
    )
}

export default PerkModal