import styled from 'styled-components'
import { PrimaryColor, PrimaryHoverColor } from '../../../colors'

export const Wrapper = styled.div`
    height: auto;
    width: auto;

    .no-show{
        visibility: hidden;
        opacity: 0;
    }
`

export const ModalBg = styled.div`
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
`

export const Modal = styled.div`
    background-color: white;
    width: 350px;
    height: auto;
    position: absolute;
    top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 3px;
`

export const Header = styled.div`
    padding: 10px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
`

export const HeaderText = styled.div`
    font-size: 18px;
    cursor: pointer;
`

export const Body = styled.div`
    height: auto;
    width: 100%;
`

export const BodySection = styled.div`
    padding-left: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    cursor: pointer;

    &:hover {
        background: #EEEEEE;
    }
`

export const AddInfo = styled.input`
    margin-top: 5px;
    width: 150px;
    height: 35px;
    padding: 5px;
`

export const BudgetSection = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

export const SubmitButton = styled.button`
    margin-top: 20px;
    height: 40px;
    width: 320px;
    background: black;
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
`

export const AddButton = styled.button`
    height: 40px;
    width: 100%;
    background: #36AE7C;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 3px;
`

export const DeleteButton = styled.button`
    height: 40px;
    width: 75px;
    left: 170px;
    bottom: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EB5353;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    position: absolute;
`

export const AddWrapper = styled.div`
    display: flex;
    width: auto;
    margin-top: 10px;
`

export const CurrentStatus = styled.select`
    margin-top: 10px;
    width: 175px;
    height: 35px;
`

export const ItemTextBoxContainer = styled.div`
    display: flex;
    margin-top: 10px;
`

export const ItemTextBox = styled.textarea`
    resize: none;
    padding: 5px;
    width: 175px;
    height: 75px;
`

export const AddItem = styled.div`
    color: #36AE7C;
    margin-top: -8.5px;
    font-size: 10px;
    height: 25px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    float: right;
    cursor: pointer;
    margin-left: auto;

    &:hover {
        background: #F1EEE9;
    }
`

export const SearchWrapper = styled.div`
    width: 100%;
    display: flex;
`

export const SearchUsers = styled.input`
    width: 80%;
    height: 40px;
    padding: 5px;
    border: 1px solid #eee;
`

export const Search = styled.button`
    height: 40px;
    width: 50px;
    margin-left: 5px;
    background: black;
    border: none;
    color: white;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`

export const UserWrapper = styled.div`
    margin-top: 50px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
`

export const UserProfileImage = styled.img`
    height: 40px;
    width: 40px;
    object-fit: cover;
`

export const ImageErrorDiv = styled.div`
    height: 40px;
    width: 40px;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const User = styled.div`
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
`

export const UserAddButton = styled.button`
    height: 40px;
    padding: 5px;
    margin-left: 5px;
    background: ${PrimaryColor};
    border: none;
    color: white;
    cursor: pointer;

    &:hover {
        background: ${PrimaryHoverColor};
    }
`

export const UserRemoveButton = styled.button`
    height: 40px;
    padding: 5px;
    margin-left: 5px;
    background: #E72929;
    border: none;
    color: white;
    cursor: pointer;

    &:hover {
        background: rgba(231, 41, 41, 0.8);
    }
`