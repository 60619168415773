import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { AWS_URL } from '../../../config'
import Typewriter from 'typewriter-effect'
import { words } from './words'

import { 
    Wrapper, 
    VideoBackground,
    HeaderTextWrapper, 
    HeaderText,
    SubHeaderText,
    ButtonWrapper,
    Button,
    ButtonBlack
} from './HeaderElements'

import { PiArrowDownThin } from 'react-icons/pi'

function Header() {
    const videoRef = useRef()
    const [selectedWord, setSelectedWord] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        const randomWord = words[Math.floor(Math.random() * words.length)]
        setSelectedWord(randomWord)

        const checkScroll = async () => {
            if (!videoRef.current) return

            if (window.pageYOffset > 800) {
                await videoRef.current.play()
                videoRef.current.pause()
                videoRef.current.currentTime = 0
            } else {
                try {
                    await videoRef.current.play()
                } catch (err) {
                    console.log("Error playing video: ", err)
                }
            }
        }

        window.addEventListener('scroll', checkScroll)

        return () => {
            window.removeEventListener('scroll', checkScroll)
        }
    }, [])

    useEffect(() => {
        const changeWord = () => {
            const randomWord = words[Math.floor(Math.random() * words.length)]
            setSelectedWord(randomWord)
        }

        const intervalId = setInterval(changeWord, 25000) // Match this with Typewriter's pauseFor duration

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const handleProjectsClick = () => {
        navigate('/discover')
    }

    return (
        <Wrapper>
            <VideoBackground>
                <video 
                    ref={videoRef} 
                    autoPlay 
                    loop 
                    muted 
                    playsInline 
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                >
                    <source src={`${AWS_URL}home/CallMeByYourNameHeader.mp4`} type="video/mp4" />
                </video>
                <div className="content">
                    {/* The rest of your content goes here */}
                </div>
            </VideoBackground>
            <HeaderTextWrapper>
                <HeaderText>
                    <Typewriter
                        options={{
                            strings: [`${selectedWord} independent funding.`],
                            autoStart: true,
                            loop: true,
                            delay: 50,
                            deleteSpeed: 50, // Adjust delete speed as needed
                            pauseFor: 25000,
                        }}
                    />
                    <SubHeaderText>
                        Join our community and invest in independent films and series with zero platform fees for both investors and filmmakers.
                    </SubHeaderText>
                </HeaderText>
                <ButtonWrapper>
                    <Button onClick={handleProjectsClick}>Start Investing</Button>
                    <ButtonBlack onClick={() => navigate('/posts')} >Learn More</ButtonBlack>
                </ButtonWrapper>
            </HeaderTextWrapper>
            <div style={{ position: 'absolute', bottom: 30, right: 30, color: 'white', zIndex: 1000 }}>
                <PiArrowDownThin size={50} color={'white'} aria-label="Scroll Down Arrow" />
            </div>
        </Wrapper>
    )
}

export default Header