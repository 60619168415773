import styled from 'styled-components'

export const DiscoverArtistsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 50px 0;
`

export const ArtistsWrapper = styled.div`
    margin-top: 50px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ArtistsContainer = styled.div`
    width: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

//CARDS
export const ArtistCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 400px;
    width: 300px;
    padding: 20px;

    &:hover {
        //lift and shadow
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
`

export const ArtistCardNoImageContainer = styled.div`
    width: 100px;
    height: 100px;
    min-width: 100px;
    flex-shrink: 0; 
    border-radius: 50%;
    background: #eee;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
`

export const ArtistCardImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
`

export const ArtistCardName = styled.h1`
    font-size: 1.25rem;
    margin-top: 12px;
`

export const ArtistMiniBio = styled.p`
    font-size: 1rem;
    margin-top: 12px;
    text-align: center;
    width: 200px;
`

export const ArtistRoles = styled.p`
    font-size: 1rem;
    margin-top: 6px;
`

export const FollowButton = styled.button`
    width: 100%;
    height: 40px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 12px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
`

//FILTER SECTION
export const FilterSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* max-width: 1200px; */
    margin-top: 20px;
`

export const BorderBottom = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin-top: 20px;
`

