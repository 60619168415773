import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'

import { formatReadableDate } from '../../helpers/readableDate'

import {
  AdminArtistsContainer,
  Table,
  Th,
  Td,
  AcceptReject,
} from '../AdminArtists/AdminArtistsElements'

import {
  SearchBar,
} from '../AdminProjects/AdminProjectElements'

import { MdOutlineArrowRightAlt } from 'react-icons/md'

const AdminCurrentPosts = () => {
  const [allPosts, setAllPosts] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/posts`, {
          params: { search: searchQuery },
          withCredentials: true
        })

        setAllPosts(response.data)
      } catch (e) {
        console.log(e)
      }
    }

    fetchPosts()
  }, [searchQuery])

  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleDelete = async (postId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this post?")
        if (!confirmDelete) {
            return
        }

        try {
            await axios.delete(`${API_URL}/admin/posts/delete/${postId}`, { withCredentials: true })
            setAllPosts(allPosts.filter(post => post._id !== postId))
        } catch (e) {
            console.log(e)
        }
  };


  const postMapping = allPosts.map((post, index) => (
    <tr key={index}>
      <Td>{post?.title}</Td>
      <Td>{post?._id}</Td>
      <Td>{post?.author?.firstName} {post?.author?.lastName}</Td>
      <Td>{post?.postType}</Td>
      <Td>{post?.tags}</Td>
      <Td>{formatReadableDate(post?.createdAt)}</Td>
      <Td><AcceptReject className="reject" onClick={() => handleDelete(post._id)}>Delete</AcceptReject></Td>
    </tr>
  ))

  return (
    <AdminArtistsContainer>
      <SearchBar
        style={{ width: '450px' }}
        onChange={handleSearch}
        placeholder="Search for posts by title, post type or tag"
      />
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {allPosts.length === 0 ? <h3>0 posts</h3> : <h3>{allPosts.length} posts</h3>}
        <MdOutlineArrowRightAlt style={{ fontSize: 50, color: 'black', cursor: 'pointer', float: 'right' }} />
      </div>
      <Table>
        <thead>
          <tr>
            <Th>Post Title</Th>
            <Th>Post ID</Th>
            <Th>Author Name</Th>
            <Th>Post Type</Th>
            <Th>Tag</Th>
            <Th>Post Creation Date</Th>
            <Th>Delete</Th>
          </tr>
        </thead>
        <tbody>
          {postMapping.length > 0 ? postMapping : <tr><Td colSpan="10" style={{ textAlign: 'center' }}>No posts found</Td></tr>}
        </tbody>
      </Table>
    </AdminArtistsContainer>
  )
}

export default AdminCurrentPosts