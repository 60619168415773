import React, { useState, useEffect } from 'react'
import { getExpressedInterest } from '../investmentProfileApi'
import { useQuery } from '@tanstack/react-query'

import {
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../LoadingElement.js'

import { 
    GridWrapper,
    PortfolioHeader 
} from '../Portfolio/PortfolioElements'

import {
    ShowMoreButton,
    ShowMoreWrapper
} from '../Followers/FollowerElements'

import {
    NoInfo
} from '../InvestmentProfileElements'

import Project from './Project'

const ExpressedInterest = ({ selectedOption, setSelectedOption }) => {
    const [expressedInterest, setExpressedInterest] = useState([])
    const [page, setPage] = useState(1)

    const { data, error, isLoading, isError } = useQuery({
        queryKey: ['expressed-interest', page], // Include page in the queryKey
        queryFn: () => getExpressedInterest(page), // Use an inline function that calls getWatchlist with the current page
        keepPreviousData: true, // Optional: This can be useful for pagination to keep previous data until new data is loaded
    })

    useEffect(() => {
        if (data && 'expressedInterest' in data) {
            if (page === 1) {
                setExpressedInterest(data.expressedInterest)
            } else if (page > 1) {
                setExpressedInterest(prevProjects => [...prevProjects, ...data.expressedInterest])
            }
        }
    }, [data, page])

    if (isError) {
        return <div>An error occurred: {error.message}</div>
    }

    const handleShowMore = () => {
        setPage((prevPage) => prevPage + 1) // Increment page to trigger refetch
    }

    const expressedInterestMapping = expressedInterest?.map((project, index) => {
        return (
            <Project
                key={project._id}
                project={project}
            />
        )
    })

    return (
        <div style={{ padding: '20px 20px 50px 20px' }}>
            <PortfolioHeader>Expressed Interest</PortfolioHeader>
            {isLoading ? (
                <LoadContainer style={{ height: 500 }}>
                    <div style={{ position: 'relative' }}>
                        <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                            <OuterPath cx="25" cy="25" r="20" fill="none" />
                        </OuterSpinner>
                        <Spinner width="50" height="50" viewBox="0 0 50 50">
                            <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                        </Spinner>
                    </div>
                </LoadContainer>
            ) : expressedInterest.length > 0 ? (
                <>
                    <GridWrapper>
                        {expressedInterestMapping}
                    </GridWrapper>
                    {data?.pagination?.hasMore &&
                        <ShowMoreWrapper>
                            <ShowMoreButton onClick={handleShowMore}>Show More</ShowMoreButton>
                        </ShowMoreWrapper>
                    }
                </>
            ) : (
                <NoInfo>No projects to display</NoInfo>
            )}
        </div>
    )
}

export default ExpressedInterest