import React, { useRef, useEffect } from 'react'

import { EditModalWrapper, EditSection } from '../DiscussionElements'
import { FiEdit2 } from 'react-icons/fi'
import { BsTrash2 } from 'react-icons/bs'

import { deleteChildComment } from '../discussionApi'
import { deleteUpdateChildComment } from '../../Updates/updatesApi'
 
//TRY PAGE COUNT.
function ChildEditModal({ 
  replyId,
  setShowReplyEdit,
  comments, 
  setComments, 
  editRef, 
  commentRefs, 
  setCommentsLength, 
  childCommentAdds,
  index,
  replyIndex,
  route
  }) {
    const modalRef = useRef(null)

    const handleClickOutside = (event) => {
      // Close user profile modal if click is outside of it
      if (modalRef.current && !modalRef.current.contains(event.target) && editRef.current && !editRef.current.contains(event.target)) {
          setShowReplyEdit(prevState => ({
            ...prevState,
            [replyId]: false
          }))
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside)
      
      // Cleanup the listener when the component unmounts
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      }

      //eslint-disable-next-line
    }, [])

    //@dev changes current comment and closes edit modal. 
    const handleEditComment = () => {
        let tempArray = [...comments]
        tempArray[index].replies[replyIndex].editReply = true
        setShowReplyEdit(prevState => ({
          ...prevState,
          [replyId]: !prevState[replyId]
        }))
        setComments(tempArray)
    }

    //IF DELETE ALL AND THEN ADD REPLIES AFTERWARDS, DOESN'T OPEN/WORK.

    //@dev deletes comment if confirmed and removes comment from current array and
    //subtracts from the commentAdds counter to update pagination "view" of start index
    const handleDeleteComment = async () => {
      if (window.confirm('Are you sure you want to delete this comment? This cannot be undone.')) {

        if (route === 'project-comments')
          await deleteChildComment(comments[index]._id, comments[index].replies[replyIndex]._id)
        else
         await deleteUpdateChildComment(comments[index]._id, comments[index].replies[replyIndex]._id)

        setComments(currentComments => {
          const newComments = [...currentComments]
          const targetComment = newComments[index]
  
          if (targetComment.replies.length === 1) {
              targetComment.showReplies = false
              targetComment.repliesCalled = false
              targetComment.childCommentAdds = 0
          } else {
              targetComment.childCommentAdds -= 1
          }
  
          // Remove the reply from the replies array
          targetComment.replies.splice(replyIndex, 1)
          // Remove the corresponding child comment ID from the childComments array
          targetComment.childComments.splice(replyIndex, 1)
  
          return newComments
        })

        setCommentsLength(prevLength => prevLength - 1)
      }
    }

    return (
        <EditModalWrapper ref={modalRef}>
            <EditSection onClick={handleEditComment} ><FiEdit2 size={18} style={{ marginRight: 10 }}/> Edit</EditSection>
            <EditSection onClick={handleDeleteComment}><BsTrash2 size={18} style={{ marginRight: 10 }}/> Delete</EditSection>
        </EditModalWrapper>
    )
}

export default ChildEditModal