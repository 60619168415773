import styled from 'styled-components'

export const Wrapper = styled.section`
    padding-top: 20px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
`

export const AboutInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const General = styled.div`
    padding: 20px;
    margin-top: 15px;
    background: white;
    width: 70%;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`

export const VideoWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 3px;
    margin-bottom: 20px;
`

export const StyledIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`

export const InfoContainer = styled.div`
    background: white;
    margin-top: 20px;
    padding: 20px;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const InfoHeader = styled.div`
    font-size: 13px;
    color: grey;
`

export const InfoText = styled.div`
    text-align: center;
    font-size: 13px;
`

export const Header = styled.div`
    margin-left: 10px;
    font-size: 17px;
    font-weight: bold;
`

export const AwardsWrapper = styled.div`
    background: white;
    margin-top: 20px;
    width: 70%;
    padding: 20px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`

export const Awards = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const AwardsContainer = styled.div`
    margin-top: 8px;
    width: 95%;
    height: 100%;
    display: table;
    justify-content: flex-start;
    border: 1px solid #eeeeee;
`

export const AwardDateContainer= styled.div`
    width: 15%;
    height: 100%;
    border-right: 1px solid #eeeeee;
    display: table-cell;
    align-items: center;
    justify-content: center;
`

export const AwardDate = styled.div`
    font-size: 20px;
`

export const AwardCeremonyContainer = styled.div`
    width: 30%;
    height: inherit;
    border-right: 1px solid #eeeeee;
    display: table-cell;
    align-items: center;
    justify-content: center;
`

export const AwardCeremonyWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Result = styled.div`
    font-size: 14px;
`

export const Ceremony = styled.div`
    color: #a6a6a4;
    font-size: 12px;
`

export const AwardTypeBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const AwardTypeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #eeeeee;
`

export const AwardTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const AwardType = styled.div`
    font-size: 15px;
`

export const Film = styled.div`
    font-size: 13px;
`

export const Button = styled.button`
    width: 75px;
    height: 40px;
    margin-top: 15px;
    padding: 5px;
    background-color: #2940d3;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`

