import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { 
    ArtistCardContainer,
    ArtistCardImage,
    ArtistCardName,
    ArtistMiniBio,
    ArtistRoles,
    FollowButton,
    ArtistCardNoImageContainer
} from './DiscoverArtistElements'

import {
    UserCardMainContent,
    UserCardFooter,
    Divider,
    InfoWrapper,
    Followers,
    Invested
} from '../DiscoverUsers/DiscoverUsersElements'

import { FaUser } from 'react-icons/fa'

const ArtistCard = ({ artist }) => {
    const [imageError, setImageError] = useState('')
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(`/artist-profile/${artist?.slug}`)
    }

    const handleImageError = () => {
        setImageError(true)
    }

    const artistImage = `${artist?.artistProfileImage}`

    return (
        <ArtistCardContainer onClick={handleNavigate}>
            <UserCardMainContent>
                {!imageError ?
                    <ArtistCardImage src={artistImage} alt={artist?.artistName} onError={handleImageError} />
                    :
                    <ArtistCardNoImageContainer>
                        <FaUser color='white' size={50} />
                    </ArtistCardNoImageContainer>
                }
                <ArtistCardName>{artist?.artistName}</ArtistCardName>
                <ArtistRoles>{Array.isArray(artist?.artistRoles) ? artist?.artistRoles.join(' | ') : ''}</ArtistRoles>
                <ArtistMiniBio>
                {artist?.artistMiniBio?.length > 100 ? artist?.artistMiniBio?.substring(0, 100) + "..." : artist?.artistMiniBio}
                </ArtistMiniBio>
            </UserCardMainContent>
            <UserCardFooter>
                <Divider />
                <FollowButton>Follow</FollowButton>
                <InfoWrapper>
                    <Followers>{artist?.followersCount} followers</Followers>
                    {artist?.projectCount > 0 && <Invested><div style={{ color: 'black', marginRight: 4 }}>{artist?.projectCount}</div> {artist?.projectCount > 1 ? 'projects' : 'project'}</Invested>}
                </InfoWrapper>
            </UserCardFooter>
        </ArtistCardContainer>
    )
}

export default ArtistCard