import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../authContext'
import { API_URL } from '../config'
import axios from 'axios'

import NonMainNavbar from '../components/NonMainNav'
import DiscoverArtistsComponent from '../components/DiscoverArtists'

const DiscoverArtists = () => {
    const [artists, setArtists] = useState(null)

    const { loggedInUser } = useContext(AuthContext)

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${API_URL}/discover-artist/artists`)
                setArtists(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchUser()
    }, [])

    return (
        <div>
            <NonMainNavbar loggedInUser={loggedInUser} />
            <DiscoverArtistsComponent artists={artists} />
        </div>
    )
}

export default DiscoverArtists