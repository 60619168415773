import styled from 'styled-components'

export const EmbedLink = styled.textarea`
    height: 80px;
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #EEEEEE;
    margin-top: 30px;
    resize: none;
`

export const InputNoBorder = styled.input`
    height: 40px;
    width: 100%;
    border: none;

    &:focus {
        outline: none;
    }
`

export const AddButton = styled.button`
    height: 40px;
    width: 100%;
    background: black;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`

export const CancelButton = styled.button`
    height: 40px;
    width: 100%;
    background: transparent;
    color: black;
    border: none;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`