import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../authContext'
import NonMainNavbar from '../components/NonMainNav'
import DiscoverFilterSection from '../components/Discover/DiscoverFilterSection'
import DiscoverBody from '../components/Discover/DiscoverBody'
import { searchFunded, searchGaugingInterest, searchLicensing, searchRevenueShare } from '../components/Discover/discoverApi'
import SearchContext from '../components/Discover/SearchContext'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const Discover = () => {
    const location = useLocation()
    const urlParams = queryString.parse(location.search)

    const [selected, setSelected] = useState(urlParams.selected || 'funding')
    const [searchedProjects, setSearchedProjects] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPages, setTotalPages] = useState(1)
    const [totalProjects, setTotalProjects] = useState(0)
    const [genreOneSelect, setGenreOneSelect] = useState(urlParams.genreOneSelect || 'Any Genre')
    const [genreTwoSelect, setGenreTwoSelect] = useState(urlParams.genreTwoSelect || 'Any Genre')
    const [budgetSelect, setBudgetSelect] = useState(urlParams.budgetSelect || 'Any budget')
    const [licensingCostSelect, setLicensingCostSelect] = useState(urlParams.licensingCostSelect || 'Any licensing cost')
    const [fundingSelect, setFundingSelect] = useState(urlParams.fundingSelect || 'Currently Funding')
    const [durationSelect, setDurationSelect] = useState(urlParams.durationSelect || 'Any duration')
    const [sortSelect, setSortSelect] = useState(urlParams.sortSelect || 'Popularity')
    const [searchTerm, setSearchTerm] = useState(urlParams.searchTerm || '')

    useEffect(() => {
        const fetchDiscoverFilter = async () => {
            if (selected === 'funding') {
                try {
                    const payload = {
                        genreOneSelect,
                        genreTwoSelect,
                        budgetSelect,
                        fundingSelect,
                        sortSelect,
                        searchTerm,
                        limit,
                        page
                    }

                    const response = await searchFunded(payload, page, limit)

                    setTotalProjects(response.data.totalProjects)
                    setTotalPages(response.data.totalPages)
                    setSearchedProjects(response.data.projects)
                } catch (error) {
                    console.log(error)
                }
            } else if (selected === 'gauging-interest') {
                try {
                    const payload = {
                        genreOneSelect,
                        genreTwoSelect,
                        budgetSelect,
                        sortSelect,
                        searchTerm,
                        limit,
                        page
                    }

                    const response = await searchGaugingInterest(payload)

                    setTotalProjects(response.data.totalProjects)
                    setTotalPages(response.data.totalPages)
                    setSearchedProjects(response.data.projects)
                } catch (error) {
                    console.log(error)
                }
            } else if (selected === 'licensing') {
                try {
                    const payload = {
                        genreOneSelect,
                        genreTwoSelect,
                        budgetSelect,
                        sortSelect,
                        searchTerm,
                        limit,
                        page
                    }

                    const response = await searchLicensing(payload)

                    setTotalProjects(response.data.totalProjects)
                    setTotalPages(response.data.totalPages)
                    setSearchedProjects(response.data.projects)
                } catch (error) {
                    console.log(error)
                }
            } else if (selected === 'revenue-share') {
                try {
                    const payload = {
                        genreOneSelect,
                        genreTwoSelect,
                        sortSelect,
                        searchTerm,
                        limit,
                        page
                    }

                    const response = await searchRevenueShare(payload)

                    setTotalProjects(response.data.totalProjects)
                    setTotalPages(response.data.totalPages)
                    setSearchedProjects(response.data.projects)
                } catch (error) {
                    console.log(error)
                }
            }
        }
        fetchDiscoverFilter()

        //eslint-disable-next-line
    }, [selected])

    const { loggedInUser } = useContext(AuthContext)

    return (
        <div>
            <SearchContext.Provider 
                value={{ 
                    isLoading, 
                    isError, 
                    setIsLoading, 
                    setIsError,
                    page,
                    setPage,
                    limit,
                    setLimit,
                    totalPages,
                    setTotalPages,
                    genreOneSelect,
                    setGenreOneSelect,
                    genreTwoSelect,
                    setGenreTwoSelect,
                    budgetSelect,
                    licensingCostSelect,
                    setLicensingCostSelect,
                    setBudgetSelect,
                    fundingSelect,
                    setFundingSelect,
                    durationSelect,
                    setDurationSelect,
                    sortSelect,
                    setSortSelect,
                    searchTerm,
                    setSearchTerm
                }}>
                <NonMainNavbar loggedInUser={loggedInUser} />
                <DiscoverFilterSection 
                    searchedProjects={searchedProjects}
                    setSearchedProjects={setSearchedProjects}
                    selected={selected}
                    setSelected={setSelected}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isError={isError}
                    setIsError={setIsError}
                />
                <DiscoverBody 
                    totalProjects={totalProjects}
                    searchedProjects={searchedProjects}
                />
            </SearchContext.Provider>
        </div>
    )
}

export default Discover