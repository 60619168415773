import styled from 'styled-components'

export const Wrapper = styled.div`
    height: auto;
    width: auto;
`

export const OfferingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const OfferingItem = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
`

export const Offering = styled.div`
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #68B984;
    border-radius: 4px;
    border: none;
    cursor: pointer;
`

export const PDFContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`

export const FileName = styled.span`
  display: inline-block;
  max-width: 20ch; // 10ch for the file name and 4ch for the extension
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

export const Cancel = styled.button`
    width: 150px;
    height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: black;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;

    &:hover {
        background: #f0f0f0;
        color: black;
    }
`

export const SubmitPDF = styled.button`
    margin-top: 10px;
    width: 150px;
    height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    color: white;
    border-radius: 4px;
    border: none;
    cursor: pointer;

    &:hover {
        background: #333333;
        color: white;
    }
`

export const Input = styled.input`
    width: 100%;
    height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border: none;
    margin-bottom: 5px;

    &:focus {
        outline: none;
    }
`

export const DeleteOffering = styled.div`
    width: 100px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #EA5455;
    border-radius: 4px;
    border: none;
    cursor: pointer;

    &:hover {
        background: #EA5455;
        color: white;
    }
`