import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
} from '../ProjectProfile/Modal/EditModalElements'

import {
    ButtonWrapper,
    ConfirmButton,
    CancelButton,
    RoleSelect,
    AdminInput, 
    DeleteButton,
    ImagePreview,
    DropzoneWrapper
} from './AdminElements'

import { editAdmin, deleteAdmin } from './AdminApi'

import { IoClose } from 'react-icons/io5'

const EditModal = ({ setShowEditModal, user }) => {
    const [adminType, setAdminType] = useState(user.adminType)
    const [email, setEmail] = useState(user.email)
    const [image, setImage] = useState(null)

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]
        setImage(Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*'
    })

    const handleConfirm = async () => {
        const formData = new FormData()
        formData.append('adminType', adminType)
        formData.append('email', email)
        if (image) {
            formData.append('image', image)
        }
        
        await editAdmin(user._id, formData)
        setShowEditModal(false)
    }

    const handleDelete = () => {
        window.confirm('Are you sure you want to delete this admin? This cannot be undone.') && 
        deleteAdmin(user._id)
    }

    return (
        <Wrapper>
            <ModalBg>
            <Modal>
                <Header>
                    <HeaderText>Edit Admin</HeaderText>
                    <IoClose style={{ cursor: 'pointer' }} size={25} onClick={() => setShowEditModal(false)} />
                </Header>
                <Body style={{ padding: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }} >
                        <p>{user.firstName} {user.lastName}</p>
                        <p style={{ fontWeight: 'bold' }}>{user.adminType}</p>
                    </div>
                    <p>Admin Type</p>
                    <RoleSelect defaultValue={user.adminType} onChange={e => setAdminType(e.target.value)}>
                        <option value='Super Admin'>Super Admin</option>
                        <option value='Admin'>Admin</option>
                        <option value='Artist Manager'>Artist Manager</option>
                    </RoleSelect>
                    <p>Email</p>
                    <AdminInput 
                        type='email'
                        value={email}
                        placeholder='Enter a new email...'
                        onChange={e => setEmail(e.target.value)}
                    />
                    <DropzoneWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        {image ? (
                            <ImagePreview src={image.preview} alt="Admin Image" />
                        ) : (
                            <p>Drag &apos;n&apos; drop an image here, or click to select one</p>
                        )}
                    </DropzoneWrapper>
                    <ButtonWrapper>
                        <CancelButton onClick={() => setShowEditModal(false)}>Cancel</CancelButton>
                        <ConfirmButton onClick={handleConfirm} >Confirm</ConfirmButton>
                    </ButtonWrapper>
                    <DeleteButton onClick={handleDelete}>Delete Admin</DeleteButton>
                </Body>
            </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default EditModal