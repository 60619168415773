import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { searchRevenueShare } from '../discoverApi'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@tanstack/react-query'
import SearchContext from '../SearchContext'

import { FilterWrapper, DropdownHeader } from '../DiscoverElements'
import Dropdown from './Dropdown'
import { genres } from '../options'

const RevenueShare = ({ selected, setSearchedProjects, searchTerm }) => {
  const navigate = useNavigate()

  const {
    setIsLoading,
    setIsError,
    page,
    setPage,
    limit,
    genreOneSelect,
    setGenreOneSelect,
    setTotalPages,
    genreTwoSelect,
    setGenreTwoSelect,
    sortSelect,
    setSortSelect,
  } = useContext(SearchContext)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 765px)' });

    const useSearchRevenueShare = (payload, page, limit) => {
        return useQuery({
        queryKey: ['searchRevenueShare', payload, page, limit],
        queryFn: () => searchRevenueShare(payload, page, limit),
        enabled: Object.values(payload).some((val) => val !== ''),
        })
    }

    const { data, isLoading, isError } = useSearchRevenueShare({
        genreOneSelect,
        genreTwoSelect,
        sortSelect,
        searchTerm,
        page,
        limit,
    })

    useEffect(() => {
        setIsLoading(isLoading)
        setIsError(isError)
    }, [isLoading, isError, setIsLoading, setIsError])

    useEffect(() => {
        if (data) {
            setSearchedProjects(data.data.projects || [])
            // Handle pagination metadata
            const { totalPages: totalPagesFromResponse } = data.data
            setTotalPages(totalPagesFromResponse)
        }
    }, [data, setSearchedProjects, setTotalPages])

    const constructUrl = () => {
        const queryParams = {
            selected,
            genreOneSelect,
            genreTwoSelect,
            sortSelect,
            searchTerm,
            page,
            limit,
        }

        return `/discover?${queryString.stringify(queryParams)}`
    }

    useEffect(() => {
        const url = constructUrl()
        navigate(url)

        //eslint-disable-next-line
    }, [selected, genreOneSelect, genreTwoSelect, sortSelect, searchTerm, page, limit])

    const handleSearch = () => {
        setPage(1)
    }
    
    return (
        <>
        {!isTabletOrMobile ?
        <FilterWrapper>
            <DropdownHeader>
                Discover&nbsp;
                {
                    <>
                        <Dropdown
                            options={genres} 
                            defaultOption={genreOneSelect}
                            setSelect={(value) => {
                                setGenreOneSelect(value)
                                handleSearch()
                            }}
                            isGenre={true}
                            className='firstContainer'
                            select={genreOneSelect}
                        />
                    &nbsp;
                        <Dropdown 
                            options={genres} 
                            defaultOption={genreTwoSelect}
                            setSelect={(value) => {
                                setGenreTwoSelect(value)
                                handleSearch()
                            }}
                            isGenre={true}
                            className='secondContainer'
                            select={genreTwoSelect}
                        />
                    </>
                }
                &nbsp; films that are sorted by &nbsp;
                {
                    <Dropdown 
                        options={["Most Votes", "Least Votes", "Popularity"]} 
                        defaultOption={sortSelect}
                        setSelect={(value) => {
                            setSortSelect(value)
                            handleSearch()
                        }}
                        className='thirdContainer'
                        select={sortSelect}
                    />
                }
            </DropdownHeader> 
        </FilterWrapper>
        :
        <div>
            <Dropdown
                options={genres} 
                defaultOption={genreOneSelect}
                setSelect={(value) => {
                    setGenreOneSelect(value)
                    handleSearch()
                }}
                isGenre={true}
                className='firstContainer'
            />
            &nbsp;&nbsp;
            <Dropdown 
                options={genres} 
                defaultOption={genreTwoSelect}
                setSelect={(value) => {
                    setGenreTwoSelect(value)
                    handleSearch()
                }}
                isGenre={true}
                className='secondContainer'
            />
            &nbsp;&nbsp;
            <Dropdown 
                options={["Most Votes", "Least Votes", "Popularity"]} 
                defaultOption={sortSelect}
                setSelect={(value) => {
                    setSortSelect(value)
                    handleSearch()
                }}
                className='thirdContainer'
            />
        </div>
        }
        </>
    )
}

export default RevenueShare