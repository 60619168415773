import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
    LeftHalfWrapper,
    OptionButton,
    RightHalfWrapper,
    PortfolioHeader,
} from '../Portfolio/PortfolioElements'

import { 
    OptionSection,
    OptionInfo, 
} from './AccountElements'

import {
   Back
} from '../InvestmentProfileElements'

import Profile from './Profile'
import Login from './Login'
import InvestorInfo from './InvestorInfo'  
import InvestorLimits from './InvestorLimits' 
import Notifications from './Notifications'

import { MdChevronRight, MdChevronLeft } from "react-icons/md"

import { getInvestmentInfo } from '../investmentProfileApi'

const Account = ({ userPage, setUserPage }) => {
    const [selectedOption, setSelectedOption] = useState('profile')
    const [investmentInfo, setInvestmentInfo] = useState(null)
    const [authEnabled, setAuthEnabled] = useState(false)

    useEffect(() => {
        const retrieveInvestmentInfo = async () => {
            const data = await getInvestmentInfo()
            setInvestmentInfo(data)
        }
        retrieveInvestmentInfo()
        setAuthEnabled(userPage.hasTwoFactorAuthEnabled)

        // retrieveInvestmentInfo()
    },  [userPage, setUserPage])

    const optionHeaders = {
        'login': 'Login & Security',
        'investorInfo': 'Investor Information',
        'investorLimit': 'Investor Limit',
        'profile': 'Profile',
        'notifications': 'Notifications',
    }

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 760px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 760px)'
    })

    return (
        <div>
            {isDesktopOrLaptop &&
            <div style={{ maxWidth: '1200px', overflowX: 'hidden', display: 'flex', flexDirection: 'row', paddingBottom: '50px' }}>
                <LeftHalfWrapper>
                <OptionButton
                        selected={selectedOption === 'profile'}
                        onClick={() => setSelectedOption('profile')}
                >Profile</OptionButton>
                <OptionButton
                        selected={selectedOption === 'login'}
                        onClick={() => setSelectedOption('login')}
                    >Login & Security</OptionButton>
                    <OptionButton
                        selected={selectedOption === 'investorInfo'}
                        onClick={() => setSelectedOption('investorInfo')}
                    >Investor Information</OptionButton>
                    <OptionButton
                        selected={selectedOption === 'investorLimit'}
                        onClick={() => setSelectedOption('investorLimit')}
                    >Investor Limits</OptionButton>
                    <OptionButton
                        selected={selectedOption === 'notifications'}
                        onClick={() => setSelectedOption('notifications')}
                    >Notifications</OptionButton>
                </LeftHalfWrapper>
                <RightHalfWrapper>
                    <PortfolioHeader>{optionHeaders[selectedOption]}</PortfolioHeader>
                    {selectedOption === 'profile' && <Profile userPage={userPage} setUserPage={setUserPage} />}
                    {selectedOption === 'login' && <Login authEnabled={authEnabled} userPage={userPage} setUserPage={setUserPage} />}
                    {selectedOption === 'investorInfo' && <InvestorInfo userPage={userPage} setUserPage={setUserPage} investmentInfo={investmentInfo} setInvestmentInfo={setInvestmentInfo} />}
                    {selectedOption === 'investorLimit' && 
                        <InvestorLimits 
                            investmentInfo={investmentInfo} 
                            setInvestmentInfo={setInvestmentInfo} 
                        />
                    }
                    {selectedOption === 'notifications' && <Notifications />}
                </RightHalfWrapper>
            </div>
            }
            {
                isTabletOrMobile &&
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: '50px' }}>
                    {selectedOption === 'Menu' &&
                    <>
                        <OptionSection onClick={() => setSelectedOption('profile')}>
                            <OptionInfo>Profile</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('login')}>
                            <OptionInfo>Login & Security</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('investorInfo')}>
                            <OptionInfo>Investor Information</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('investorLimit')}>
                            <OptionInfo>Investor Limits</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('notifications')}>
                            <OptionInfo>Notifications</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                    </>
                    }
                    {selectedOption !== 'Menu' && <Back onClick={() => setSelectedOption('Menu')}><MdChevronLeft size={25} /> Back</Back>}
                    <PortfolioHeader>{optionHeaders[selectedOption]}</PortfolioHeader>
                    {selectedOption === 'profile' && <Profile userPage={userPage} setUserPage={setUserPage} />}
                    {selectedOption === 'login' && <Login authEnabled={authEnabled} userPage={userPage} setUserPage={setUserPage} />}
                    {selectedOption === 'investorInfo' && <InvestorInfo userPage={userPage} setUserPage={setUserPage} investmentInfo={investmentInfo} setInvestmentInfo={setInvestmentInfo} />}
                    {selectedOption === 'investorLimit' &&
                        <InvestorLimits
                            investmentInfo={investmentInfo}
                            setInvestmentInfo={setInvestmentInfo}
                        />
                    }
                    {selectedOption === 'notifications' && <Notifications />}
                </div>
            }
        </div>
    )
}

export default Account