import React, { useContext } from 'react'
import { AuthContext } from '../authContext'

import ArtistForms from '../components/ArtistRegistry/ArtistForms'
import NonMainNav from '../components/NonMainNav'

function ArtistRegistry() {

    const { loggedInUser } = useContext(AuthContext)

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <NonMainNav loggedInUser={loggedInUser} />
            <ArtistForms loggedInUser={loggedInUser}  />
        </div>
    )
}

export default ArtistRegistry
