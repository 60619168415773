import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import './global.css'
import { Wrapper } from './LearnElements'

const Font = Quill.import('formats/font')
Font.whitelist = ['Merriweather', 'sans-serif']

const modules = {
  toolbar: [
    [{ font: ['Merriweather'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
}

const LearnTextEditor = forwardRef(({ addEditor, setShowModal, onFocus, index, setContent, removeEditor }, ref) => {
  const [value, setValue] = useState('')
  const quillRef = useRef(null)

  useImperativeHandle(ref, () => ({
    focus: () => {
      quillRef.current.focus()
    },
  }))

  useEffect(() => {
    const quill = quillRef.current.getEditor()
    if (quill.keyboard) {
      quill.keyboard.bindings[13].unshift({
        key: 13,
        handler: () => {
          setShowModal(true)
          return false
        },
      })
    }
  }, [addEditor, index, removeEditor, setShowModal])

  const handleChange = (value) => {
    setValue(value)
    setContent(prevContent => {
      const updatedContent = [...prevContent]
      updatedContent[index].content = value
      return updatedContent
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && index !== 0 && quillRef.current.getEditor().getText().trim() === '') {
      removeEditor(index)
    }
  }

  return (
    <Wrapper style={{ marginBottom: 0, marginLeft: -10 }}>
      <ReactQuill
        ref={quillRef}
        modules={modules}
        style={{ width: '600px' }}
        theme="bubble"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter your information here. Hit enter to create a new element."
        onFocus={onFocus}
      />
    </Wrapper>
  )
})

LearnTextEditor.displayName = 'LearnTextEditor'

export default LearnTextEditor