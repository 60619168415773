import React, { useState, useRef } from 'react'
import { uploadGraphic, deletePerkImage, updatePerk } from '../PerksApi'
import { useDropzone } from 'react-dropzone'
import { 
    AWS_URL,
} from '../../../../../config'

import { 
    Modal, 
    Header, 
    HeaderText, 
    Body,
    BodySection,
} from '../../../Modal/EditModalElements'

import { 
    AddPerkSection,
    EditGraphicSection, 
    EditGraphicWrapper,
    EditGraphicText,
    AddPerkInput, 
    AddPerkTextBox,
    PhotoSection,
    UploadContainer,
    PerkImage,
    AddPerkButton,
    CancelPerkButton 
} from '../PerkElements'

import { IoIosArrowBack } from 'react-icons/io'
import { BsCardHeading } from 'react-icons/bs'
import { MdUpload } from 'react-icons/md'

function EditPerk({ setShowModal, perkArray, setPerkArray, setShowEditPerk, projectInfo }) {
    const [perkIndex, setPerkIndex] = useState(-1)
    const [imageFile, setImageFile] = useState([])
    const [showEditGraphic, setShowEditGraphic] = useState(false)
    const [header, setHeader] = useState('')
    const [amount, setAmount] = useState('')
    const [details, setDetails] = useState('')
    const [estimatedDelivery, setEstimatedDelivery] = useState('')
    const [shipsTo, setShipsTo] = useState('')

    const { _id, slug } = projectInfo.project
    
    const imgRef = useRef(null)
    const overlayRef = useRef(null)

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles, fileRejections) => {
            // Reset image error state
    
            // Process accepted files
            if (acceptedFiles.length > 0) {
                setImageFile(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })))
            }
    
            // Handle any file rejections
            if (fileRejections.length > 0) {
                const errors = fileRejections.map(rejection => rejection.errors.map(error => error.message))
                alert(errors.flat().join('\n'))
            }
        }
    })

    const handleAmountChange = (e) => {
        const value = e.target.value
        // Regular expression to match only numeric characters
        const re = /^[0-9\b]+$/
    
        // If value is empty or it matches the regex, update the state
        if (value === '' || re.test(value)) {
            setAmount(value)
        }
    }

    const handleAmountKeyPress = (e) => {
        // Regular expression to allow only numeric characters
        if (!/^[0-9]+$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
            e.preventDefault()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (imageFile.length > 0) {
            try {
                let data = new FormData()

                const file = imageFile[0]

                data.append('uploadGraphic', file, imageFile.name)

                uploadGraphic(slug, data)

                if (perkArray[perkIndex].imageFile) {
                    const payload = {
                        perkIndex,
                        projectId: _id
                    }

                    await deletePerkImage(payload, slug, perkArray, perkIndex)
                }
            
                const perk = {
                    imageFile: imageFile[0].path,
                    imagePath: `${AWS_URL}projects/${slug}/perks/${imageFile[0].path}`,
                    header,
                    amount,
                    details,
                    estimatedDelivery,
                    shipsTo,
                    unlocks: 0
                }

                const payload = {
                    perk,
                    slug: projectInfo.project.slug
                }

                const responseData = await updatePerk(payload, projectInfo?.project?._id, perkIndex)

                setPerkArray(responseData.data)
            } catch (e) {
                console.error(e)
            }
        } else {
            let perk = {
                imagePath: perkArray[perkIndex].imagePath,
                imageFile: perkArray[perkIndex].imageFile,
                header,
                amount,
                details,
                estimatedDelivery,
                shipsTo,
                unlocks: 0
            }

            const payload = {
                perk,
                projectName: projectInfo.project.projectName
            }

            const responseData = await updatePerk(payload, projectInfo?.project?._id, perkIndex)

            setPerkArray(responseData.data)

            if (showEditGraphic) {
                const payload = {
                    perkIndex,
                    projectId: _id
                }

                await deletePerkImage(payload, slug, perkArray, perkIndex)

                setPerkArray(prevPerkArray => 
                    prevPerkArray.map((perk, index) => 
                      index === perkIndex ? { ...perk, imageFile: '', imagePath: '' } : perk
                    )
                )
            }
        }
        setShowModal(false)
        document.body.style.overflow = 'visible'
    }

    const handleSectionClick = (index) => {
        setPerkIndex(index)
        setHeader(perkArray[index].header)
        setAmount(perkArray[index].amount)
        setDetails(perkArray[index].details)
        setEstimatedDelivery(perkArray[index].estimatedDelivery)
        setShipsTo(perkArray[index].shipsTo)
    }

    let perks

    perks = perkArray.map((perk, index) => {
        return (
            <BodySection onClick={() => handleSectionClick(index)} key={perk._id}>
                <BsCardHeading size={19} style={{ marginRight: 5 }}/>
                {perk.header}
            </BodySection>
        )
    })

    //@dev resets uploaded images and all modal steps. 
    const handleBackClick = () => {
        if (perkIndex !== -1) {
            setPerkIndex(-1)
        } else {
            setShowEditPerk(false)
        }
        setShowEditGraphic(false)
        setImageFile([])
    }

    //@dev sets the div to the image height. 
    const handleMouseOver = () => {
        const imageHeight = imgRef.current.clientHeight
        overlayRef.current.style.height = `${imageHeight}px`
    }

    let renderEditGraphic

    if (!showEditGraphic) {
       renderEditGraphic = 
       <div style={{ position: 'relative' }}>
            <PerkImage ref={imgRef} style={{ objectFit: 'cover' }} src={perkArray[perkIndex] ? perkArray[perkIndex].imagePath : ''} alt='preview'/>
            <EditGraphicWrapper ref={overlayRef} onMouseOver={handleMouseOver} onClick={() => setShowEditGraphic(true)}><EditGraphicText>Remove Image</EditGraphicText></EditGraphicWrapper>
        </div>
    } else if (imageFile.length > 0) {
        renderEditGraphic =
        <div>
            <PerkImage style={{ objectFit: 'cover' }} src={imageFile[0].preview} alt='preview'/>
        </div>
    } else {
       renderEditGraphic = 
       <PhotoSection {...getRootProps()} style={{ width: '100%', marginTop: 10 }}>
            <input {...getInputProps()}/>
            <UploadContainer>
                <MdUpload size={30}/>
            </UploadContainer>
            <div style={{ fontSize: 13 }}>Drag and drop image to upload</div>
        </PhotoSection>
    }

    return (
        <Modal>
            <Header>
                <HeaderText onClick={handleBackClick} style={{ display: 'flex', alignItems: 'center' }} ><IoIosArrowBack size={25} /> Edit Perk</HeaderText>
            </Header>
            <Body>
                {perkIndex !== -1 ?
                <form onSubmit={handleSubmit}>
                {perkArray[perkIndex].imagePath ?
                <EditGraphicSection>
                    <div>Perk Image</div>
                    {renderEditGraphic}
                </EditGraphicSection>
                : 
                <EditGraphicSection>
                    <div>Perk Image</div>
                    {imageFile.length === 0 ? 
                    <PhotoSection {...getRootProps()} style={{ width: '100%', marginTop: 10 }}>
                        <input {...getInputProps()}/>
                        <UploadContainer>
                            <MdUpload size={30}/>
                        </UploadContainer>
                        <div style={{ fontSize: 13 }}>Drag and drop image to upload</div>
                    </PhotoSection>
                    : 
                    <div>
                        <PerkImage style={{ objectFit: 'cover' }} src={imageFile[0].preview} alt='preview'/>
                    </div>
                    }
                </ EditGraphicSection>
                }
                <AddPerkSection>
                    <div>Perk Header</div>
                    <AddPerkInput placeholder='Enter a header...' defaultValue={perkArray[perkIndex].header} onChange={e => setHeader(e.target.value)}/>
                </AddPerkSection>
                <AddPerkSection>
                    <div>Perk Amount</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        &#36;
                        <AddPerkInput placeholder='0' style={{ marginLeft: 5 }} defaultValue={perkArray[perkIndex].amount} onChange={handleAmountChange} onKeyPress={handleAmountKeyPress}/>
                    </div>
                </AddPerkSection>
                <AddPerkSection>
                    <div>Perk Details</div>
                    <AddPerkTextBox placeholder='Enter perk details...' defaultValue={perkArray[perkIndex].details} onChange={e => setDetails(e.target.value)} />
                </AddPerkSection>
                <AddPerkSection>
                    <div>Estimated Delivery</div>
                    <AddPerkInput placeholder='January 2024...' defaultValue={perkArray[perkIndex].estimatedDelivery} onChange={e => setEstimatedDelivery(e.target.value)}/>
                </AddPerkSection>
                <AddPerkSection>
                    <div>Perk Ships To</div>
                    <AddPerkInput placeholder='Anywhere in the World...' defaultValue={perkArray[perkIndex].shipsTo} onChange={e => setShipsTo(e.target.value)} />
                </AddPerkSection>
                <AddPerkSection>
                    <AddPerkButton>Save Update</AddPerkButton>
                    <CancelPerkButton type='button' onClick={handleBackClick}>Cancel</CancelPerkButton>
                </AddPerkSection>
            </form>
                :
                <React.Fragment>
                    {perks}
                </React.Fragment>
                }
            </Body>
        </Modal>
    )
}

export default EditPerk