import React, { useState, useEffect } from 'react'
import { getCurrentInfoValue } from '../helpers/reducerHelper'

import { 
    DragBarContainer,
    Stop,
    DragCircle,
    TipEnds,
    InfoDiv
} from '../ProjectInvestElements'

function DragBar({ state, dispatch, currentStop, setCurrentStop }) {
    const totalStops = 6  // Including both ends, so we have 4 "in-between" stops.
    const tipPercentages = [0, 5, 10, 15, 20, 25]
    const [isDragging, setIsDragging] = useState(false)
    const [tipAmount, setTipAmount] = useState(0)

    const investmentAmount = state.store.find(item => item.label === "Investment Amount").info

    useEffect(() => {
        const investmentValueString = getCurrentInfoValue('Investment Amount', state)
        let investmentValue = parseFloat(investmentValueString.replace(/,/g, '')) // Convert string with commas to number
    
        // Check for NaN and reset to 0 if necessary
        if (isNaN(investmentValue)) {
            investmentValue = 0
        }
    
        const percentTip = (tipPercentages[currentStop] / 100) * investmentValue  // Now it's safe to perform arithmetic
        setTipAmount(percentTip.toFixed(2))
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: percentTip.toFixed(2), label: 'Tip Amount' } })

        //eslint-disable-next-line
    }, [currentStop, investmentAmount])
    

    const handleMouseDown = (event) => {
        setIsDragging(true)

        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)

        const { left, width } = event.currentTarget.getBoundingClientRect()
        const movePosition = event.clientX - left
        const stopWidth = width / (totalStops - 1)

        const closestStop = Math.round(movePosition / stopWidth)
        setCurrentStop(Math.min(Math.max(closestStop, 0), totalStops - 1))
    }
    
    const handleMouseUp = () => {
        setIsDragging(false)

        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = (event) => {
        if (!isDragging) return

        const { left, width } = event.currentTarget.getBoundingClientRect()
        const movePosition = event.clientX - left
        const stopWidth = width / (totalStops - 1)

        const closestStop = Math.round(movePosition / stopWidth)
        setCurrentStop(Math.min(Math.max(closestStop, 0), totalStops - 1)) // Ensures stop is within bounds
    }

    let circlePositionPercentage = (100 / (totalStops - 1)) * currentStop

    const offset = 2
    if (currentStop === 0) {
        circlePositionPercentage += offset
    } else if (currentStop === totalStops - 1) {
        circlePositionPercentage -= offset
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
            <TipEnds style={{ marginRight: 7 }}>0%</TipEnds>
            <DragBarContainer 
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                style={{
                    background: `linear-gradient(to right, #68B984 0%, #68B984 ${circlePositionPercentage}%, #E5E5E5 ${circlePositionPercentage}%, #E5E5E5 100%)`
                }}
            >
                {[...Array(totalStops - 2)].map((_, index) => (
                    <Stop style={{ left: `${(100 / (totalStops - 1)) * (index + 1)}%` }} key={index} />
                ))}
                <InfoDiv style={{ left: `${circlePositionPercentage}%` }}>
                    <div style={{ fontWeight: '700', marginRight: 5 }}>${tipAmount}</div>{`(${tipPercentages[currentStop]}%)`}
                </InfoDiv>
                <DragCircle 
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    style={{ left: `${circlePositionPercentage}%` }} 
                />
            </DragBarContainer>
            <TipEnds style={{ marginLeft: 7 }}>25%</TipEnds>
        </div>
    )
}

export default DragBar
