import styled from 'styled-components'

export const Wrapper = styled.div`
    height: auto;
    width: auto; 
`

export const RiskInput = styled.textarea`
    margin-top: 5px;
    width: 100%;
    height: auto;
    padding: 5px;
    resize: none;
    overflow: hidden;
    border: none;

    &:focus {
        outline: none;
    }

    &.header {
        font-size: 17px;
        font-weight: bold;
        color: #333;
        height: 25px;
    }
`

export const RiskHeader = styled.div`
    font-size: 17px;
    font-weight: bold;
    color: #333;
`

export const RiskText = styled.div`
    font-size: 14px;
`