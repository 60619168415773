import React from 'react'
import axios from 'axios'

import {
    AwardsContainer,
    AwardTable,
    TableBody,
    TableRow,
    YearTableCell,
    OutcomeTableCell,
    DescriptionTableCell,
    OutcomeText,
    EventText,
    Film,
    AwardType
} from './AwardsElements'

import { PlusButton } from '../AboutModal/AboutModalElements'
import { HiMinus } from "react-icons/hi";

function Awards({ awards, artistId, loggedInUser, setArtistAssets }) {
    const handleDeleteClick = async (e, index) => {
        e.preventDefault()
    
        const userConfirmed = window.confirm('Are you sure you want to delete this award?')
        if (!userConfirmed) {
            return
        }
    
        const payload = {
            index
        }
    
        try {
            const response = await axios.post('http://localhost:5001/profile/about/delete-awards/me', payload, { withCredentials: true })
            setArtistAssets(prevAssets => ({
                ...prevAssets,
                awards: response.data
            }))
        } catch (error) {
            console.error('Error deleting award:', error)
            // Optionally, handle the error further, e.g., by showing a user-friendly message
        }
    }

    const renderAwards = awards ? awards.map((award, index) => {
        const wins = award.wins.map((win, index) => {
            return (
                <TableRow key={index + 1}>
                    <DescriptionTableCell>
                        <AwardType>{win}</AwardType>
                        <Film>{award.project}</Film>
                    </DescriptionTableCell>
                </TableRow>
            )
        })

        const nominations = award.nominations.map((nom, index) => {
            return (
                <TableRow key={index + 2}>
                    <DescriptionTableCell>
                        <AwardType>{nom}</AwardType>
                        <Film>{award.project}</Film>
                    </DescriptionTableCell>
                </TableRow>
            )
        })

        const renderWins = award.wins.length > 0 ?
                <AwardsContainer key={index + 3}>
                    <AwardTable>
                        <TableBody>
                            <TableRow>
                                <YearTableCell rowSpan='6'>{award.year}</YearTableCell>
                                <OutcomeTableCell rowSpan='6'>
                                    <OutcomeText>Winner</OutcomeText>
                                    <EventText>{award.ceremony}</EventText>
                                </OutcomeTableCell>
                            </TableRow>
                            {wins}
                        </TableBody>
                    </AwardTable>
                </AwardsContainer>
        : ''

        const renderNominations = award.nominations.length > 0 ?
            <AwardsContainer key={index + 4}>
                    <AwardTable>
                        <TableBody>
                            <TableRow>
                                <YearTableCell rowSpan='6'>{award.year}</YearTableCell>
                                <OutcomeTableCell rowSpan='6'>
                                    <OutcomeText>Nominated</OutcomeText>
                                    <EventText>{award.ceremony}</EventText>
                                </OutcomeTableCell>
                            </TableRow>
                            {nominations}
                        </TableBody>
                    </AwardTable>
                </AwardsContainer>
        : ''

        return (
            <React.Fragment key={index}>
                {
                loggedInUser?.artist === artistId ?
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <PlusButton onClick={e => handleDeleteClick(e, index)} style={{ borderColor: 'red', background: 'red', marginRight: 5 }} ><HiMinus color='white' /></PlusButton>
                    <div style={{ marginTop: 20 }}>{award.ceremony} - {award.year}</div>
                </div>
                :
                <div style={{ marginTop: 20 }}>{award.ceremony} - {award.year}</div>
                }
                {renderWins}
                {renderNominations}
            </React.Fragment>
        )
    }) : ''

    return (
        <React.Fragment>
            {renderAwards}
        </React.Fragment>
    )
}

export default Awards

