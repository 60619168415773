import React, { useState, useEffect } from 'react'
import { PrimaryColor } from '../../../../colors'

import { 
    Wrapper, 
    Header,
    GridWrapper, 
    EditButton
} from './PerkElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'
import { NoInfo } from '../Details/ProjectDetailElements'

import PerkCard from './PerkCard'
import PerkModal from './PerkModal'
import { AiFillEdit } from 'react-icons/ai'

function Activity({ perkArray, setPerkArray, projectInfo, loggedInUser }) {
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (projectInfo?.project && perkArray.length === 0) {
            setPerkArray(projectInfo?.project?.perks)
        }
        setIsLoading(false)
        
        //eslint-disable-next-line
    }, [])

    const perks = perkArray?.map(perk => {
        return (
            <PerkCard 
                key={perk?._id} 
                imgSrc={perk?.imagePath} 
                header={perk?.header} 
                amount={perk?.amount} 
                details={perk?.details} 
                estimatedDelivery={perk?.estimatedDelivery} 
                shipsTo={perk?.shipsTo} 
                unlocks={perk?.unlocks} 
            />
        )
    })

    const handleShowModal = () => {
        setShowModal(true)
        document.body.style.overflow = 'hidden'
    }

    return (
        <Wrapper>
            {isLoading
            ?
            <LoadContainer>
                <div style={{position: "relative"}}>
                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                    </OuterSpinner>
                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </Spinner>
                </div>
            </LoadContainer>
            :
            <>
                {showModal ? <PerkModal perkArray={perkArray} setPerkArray={setPerkArray} setShowModal={setShowModal} projectInfo={projectInfo} /> : null}
                {(loggedInUser?.artist === projectInfo?.project?.artist._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && (
                    <EditButton onClick={handleShowModal}>
                        <AiFillEdit size={20} style={{ marginRight: 3 }} />
                        Edit Perks
                    </EditButton>
                )}
                <Header><div style={{ color: `${PrimaryColor}` }}>{perkArray.length}</div>&nbsp; Perks Available</Header>
                <GridWrapper>
                    {perks}
                </GridWrapper>
                {perkArray.length === 0 ? <NoInfo>No perks available yet.</NoInfo> : null}
            </>
            }
        </Wrapper>
    )
}

export default Activity