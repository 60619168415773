import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import LikeComponent from './LikeComponent'
import LoadingComponet from '../../../../LoadingAsset'
import { API_URL } from '../../../../../config'

import { 
    ModalBg, 
    Modal, 
    Header, 
    HeaderText,
} from '../../../Modal/EditModalElements.js'

import { 
    Body,
} from './LikesModalElements.js'

import { IoClose } from 'react-icons/io5'

function LikesModal({ 
    updateId, 
    showLikeModal, 
    setShowLikeModal, 
    setShowInModalLike,
    loggedInUser,
}) {
    const [userProfiles, setUserProfiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [limit] = useState(12)
    const modalRef = useRef()
    const bodyRef = useRef()

    const navigate = useNavigate()

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = '' 
            setPage(1)
            if (showLikeModal)
                setShowLikeModal(false)
            else 
                setShowInModalLike(false)
        }
    }

    const closeModalExit = () => {
        document.body.style.overflow = ''
        setPage(1)
        if (showLikeModal)
            setShowLikeModal(false)
        else 
            setShowInModalLike(false)
    }

    useEffect(() => {
        const fetchLikedUsers = async () => {
          try {
            if (page === 1) {
                setIsLoading(true)
                let response = await axios.get(`${API_URL}/updates/like-users/${updateId}?page=${page}&limit=${limit}`, {
                    withCredentials: true,
                })

                setUserProfiles(response.data)
                setIsLoading(false)
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              // Unauthorized, navigate to the login page
              document.body.style.overflow = ''  
              navigate('/login')
            } else {
              console.error(error)
            }
            setIsLoading(false)
          }
        }
        fetchLikedUsers()

        //eslint-disable-next-line
    }, [updateId, limit])

    const handleScroll = () => {
        if (
          bodyRef.current.scrollHeight - bodyRef.current.scrollTop === bodyRef.current.clientHeight
        ) {
          fetchMoreUsers()
        }
    }

    const fetchMoreUsers = async () => {
        try {
          setIsLoading(true)
          let response = await axios.get(`${API_URL}/updates/like-users/${updateId}?page=${page + 1}&limit=${limit}`, {
            withCredentials: true,
          })
          setUserProfiles((prevProfiles) => [...prevProfiles, ...response.data])
          setPage((prevPage) => prevPage + 1)
          setIsLoading(false)
        } catch (e) {
          console.error(e)
          setIsLoading(false)
        }
    }

    // Then use these refs in your map
    const userMap = userProfiles?.map((user, index) => {
        return (
            <LikeComponent 
                key={user.username} 
                updateId={updateId}
                page={page}
                limit={limit}
                setUserProfiles={setUserProfiles} 
                user={user} 
                loggedInUser={loggedInUser} 
            />
        )
    })

    return (
        <ModalBg ref={modalRef} onClick={closeModal} >
            <Modal style={{ marginTop: 100, width: 400 }}>
                <Header>
                    <HeaderText style={{ fontWeight: 'bold' }}>Likes</HeaderText>
                    <div onClick={closeModalExit} style={{ cursor: 'pointer' }} >
                        <IoClose size={25} />
                    </div>
                </Header>
                <Body ref={bodyRef} onScroll={handleScroll} >
                    {userMap}
                    {isLoading && <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><LoadingComponet /></div>}
                </Body>   
            </Modal>
        </ModalBg>    
    )
} 

export default LikesModal