import React, { useState, useRef } from 'react'
import DOMPurify from 'dompurify'
import { addMediaApi, addScriptApi } from '../MediaApis'

import {
  Wrapper,
  ModalBg,
  Modal,
  Header,
  HeaderText,
  Body,
  BodySection,
} from '../../../Modal/EditModalElements'

import { 
    EmbedLink, 
    InputNoBorder, 
    AddButton, 
    CancelButton 
} from './MediaModalElements'

import ScriptDropzone from './ScriptDropzone'

import { IoClose } from 'react-icons/io5'
import { RiFileList3Fill, RiVideoFill } from 'react-icons/ri'
import { IoIosArrowBack } from 'react-icons/io'

function MediaModal({ setShowEditModal, project, setProjectInfo, media, setMedia, setScript }) {
  const [title, setTitle] = useState('')
  const [embedLink, setEmbedLink] = useState('')
  const [showVideo, setShowVideo] = useState(false)
  const [showScript, setShowScript] = useState(false)
  const [scriptDrop, setScriptDrop] = useState([])
  const [scriptError, setScriptError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [convertedEmbedCode, setConvertedEmbedCode] = useState('')

  const modalRef = useRef(null)

  const closeModalClick = (e) => {
    if (modalRef.current === e.target) {
      setShowEditModal(false)
    }
  }

  const handleBack = () => {
    if (showVideo) setShowVideo(false)
    else {
        setScriptDrop([])
        setShowScript(false)
    } 

    setTitle('')
  }

  const handleEmbedChange = (e) => {
    const embedCode = e.target.value
    setEmbedLink(embedCode)

    const regex = /src="(https:\/\/[^"]*)"/
    const match = regex.exec(embedCode)

    if (match !== null) {
      const videoSrc = match[1]
      const iframeCode = `<iframe src="${videoSrc}" frameborder="0" allowfullscreen></iframe>`
      setConvertedEmbedCode(iframeCode)
    } else {
      setConvertedEmbedCode('')
    }
  }

  const handleEmbedSubmit = async (e) => {
    e.preventDefault()

    if (!title) {
        alert('Please enter a title.')
        return
    }

    const regex = /^<iframe.*src="(https:\/\/[^"]*)".*<\/iframe>$/
    const match = regex.exec(embedLink)

    if (match === null) {
      alert('Invalid embed code format. Please provide a valid iframe embed code.')
      return
    }

    const videoSrc = match[1]
    const sanitizedVideoSrc = DOMPurify.sanitize(videoSrc, {
        ALLOWED_TAGS: ['iframe'],
        ALLOWED_ATTR: ['src', 'width', 'height', 'autoplay', 'frameborder', 'allowfullscreen'],
    })

    const sanitizedEmbedCode = `<iframe src="${sanitizedVideoSrc}" width="100%" height="400" frameborder="0" allowfullscreen></iframe>`

    if (sanitizedEmbedCode) {
      try {
        const mediaElement = {
            isScript: false,
            title,
            fileName: embedLink,
        }

        const response = await addMediaApi(mediaElement, project._id)

        setMedia(prev => [...prev, response.data])

        setProjectInfo(prevProjectInfo => {
          return {
            ...prevProjectInfo,
            project: {
              ...prevProjectInfo.project,
              mediaElement: [...prevProjectInfo.project.mediaElement, response.data]
            }
          }
        })
        
        setShowEditModal(false)
      } catch (e) {
        console.error(e)
      }
    } else {
      console.error('Invalid embed code provided')
    }
  }

  const handleScriptSubmit = async (e) => {
    e.preventDefault()

    if (!title) {
        alert('Please enter a title.')
        return
    }

    setIsSubmitting(true)

    const mediaElement = {
      isScript: true,
      title,
      fileName: scriptDrop[0].name,
    }

    let data = new FormData()
    const scriptFile = scriptDrop[0]
    data.append('uploadScript', scriptFile, scriptFile.name)
    data.append('mediaElement', JSON.stringify(mediaElement))

    const response = await addScriptApi(
      data,
      scriptFile,
      project.slug,
      setProgress
    )

    setScript((prevScript) => [...prevScript, response.data])
    setShowEditModal(false)
    setIsSubmitting(false)
  }

  const ProgressSection = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h3>Uploading Script...</h3>
      <progress value={progress} max="100" style={{ width: '100%', background: 'black' }} />
      <p>{progress}%</p>
    </div>
  )

  let renderSection

  if (isSubmitting) {
    renderSection = <ProgressSection />
  } else if (showVideo) {
    renderSection = (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 30,
          }}
        >
            <InputNoBorder
              placeholder="Video Title"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <EmbedLink
              placeholder="Paste full embed link. Make sure it is an embed link (usually indicated by <>) and not a share link."
              onChange={handleEmbedChange}
              required
            />
            {convertedEmbedCode && (
                <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: convertedEmbedCode }}></div>
            )}
            <>
                <AddButton onClick={handleEmbedSubmit} style={{ height: 40, marginTop: 20, marginBottom: 20 }}>
                Embed Video
                </AddButton>
                <CancelButton onClick={handleBack}>
                    Cancel
                </CancelButton>
            </>
        </div>
      </React.Fragment>
    )
  } else if (showScript) {
    renderSection = (
      <React.Fragment>
          {scriptDrop.length > 0 ? (
            <InputNoBorder
              placeholder="Enter Title (Ex: My Script: First Ten Pages)"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          ) : null}
          <ScriptDropzone
            scriptFiles={scriptDrop}
            setScriptFiles={setScriptDrop}
            scriptError={scriptError}
            setScriptError={setScriptError}
          />
          {scriptDrop.length > 0 ? (
            <>
            <AddButton onClick={handleScriptSubmit} style={{ height: 40, marginBottom: 20 }}>
              Upload PDF
            </AddButton>
            <CancelButton onClick={handleBack}>
                Cancel
            </CancelButton>
            </>
          ) : null}
      </React.Fragment>
    )
  } else {
    renderSection = (
      <React.Fragment>
        <BodySection onClick={() => setShowVideo(true)}>
          <RiVideoFill size={20} />
          &nbsp;&nbsp;Embed Video
        </BodySection>
        <BodySection onClick={() => setShowScript(true)}>
          <RiFileList3Fill size={20} />
          &nbsp;&nbsp;Upload PDF
        </BodySection>
      </React.Fragment>
    )
  }

  return (
    <Wrapper>
      <ModalBg ref={modalRef} onClick={closeModalClick}>
        <Modal style={showScript || showVideo ? { width: 500 } : {}}>
          <Header onClick={handleBack}>
            <HeaderText style={{ display: 'flex', alignItems: 'center' }}>
              {showVideo || showScript ? <IoIosArrowBack size={23} /> : null}
              Add Media
            </HeaderText>
            <IoClose
              size={25}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowEditModal(false)}
            />
          </Header>
          <Body
            style={
              showScript
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 40
                  }
                : {}
            }
          >
            {renderSection}
          </Body>
        </Modal>
      </ModalBg>
    </Wrapper>
  )
}

export default MediaModal