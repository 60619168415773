import styled from 'styled-components'

export const AdminArtistsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure it doesn't exceed the viewport */
    max-width: 1200px; /* Adjust based on your design */
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px; /* Add some padding */
`

export const Table = styled.table`
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    overflow-x: auto; /* Allow horizontal scrolling */
    display: block; /* Needed to apply overflow styles */
`

export const Th = styled.th`
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    width: 20%; /* Example width */
`

export const Td = styled.td`
    padding: 10px 40px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    text-overflow: wrap;

    @media (max-width: 768px) {
        padding: 5px 10px; /* Reduce padding on small screens */
    }
`

export const AcceptReject = styled.button`
    width: 100px;
    height: 40px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;

    &.accept {
        background-color: #4CAF50; /* Green */

        &:hover {
            background-color: #46a049; /* Darker green */
        }
    }

    &.reject {
        background-color: #f44336; /* Red */

        &:hover {
            background-color: #d32f2f; /* Darker red */
        }
    }

    &.edit {
        background-color: #008CBA; /* Blue */

        &:hover {
            background-color: #0073e6; /* Darker blue */
        }
    }
`

export const Button = styled.button`
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #e0e0e0;
    }
`