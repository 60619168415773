export const handleMoneyChange = (inputValue, setInputFunction) => {
    // Check if the inputValue consists of only digits, commas, or potentially one dot.
    if (/^[\d,]*$/.test(inputValue)) {
        const strippedValue = inputValue.replace(/,/g, '') // remove all commas
        setInputFunction(formatWithCommas(strippedValue))
    }
}

const formatWithCommas = (value) => {
    // Convert to number and back to string to remove leading zeros
    const numberValue = parseInt(value, 10)
    return isNaN(numberValue) ? '' : numberValue.toLocaleString()
}