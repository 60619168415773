import React from 'react'
import NewLearnPost from './NewLearnPost'

import { Wrapper } from './LearnElements'

const PostComponent = () => {
    return (
        <Wrapper>
            <NewLearnPost />
        </Wrapper>
    )
}

export default PostComponent
