import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import { toast } from 'react-toastify'

import {
    AdminArtistsContainer,
    Table,
    Th,
    Td,
    AcceptReject,
} from './AdminArtistsElements'

import {
    SearchBar
} from '../../components/AdminProjects/AdminProjectElements'

import { MdOutlineArrowRightAlt } from "react-icons/md"

const AdminArtists = () => {
    const [artists, setArtists] = useState([])
    const [displayArtists, setDisplayArtists] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const fetchArtists = useCallback(async () => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${API_URL}/admin/artist-waitlist`, { withCredentials: true })
            setArtists(response.data)
            setDisplayArtists(response.data)
        } catch (e) {
            console.error('Error fetching artists:', e)
            toast.error('Failed to fetch artists. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchArtists()
    }, [fetchArtists])

    const handleAccept = async (artistId) => {
        try {
            await axios.post(`${API_URL}/admin/accept-artist`, { artistId }, { withCredentials: true })
            setArtists(prevArtists => prevArtists.filter(artist => artist._id !== artistId))
            setDisplayArtists(prevDisplayArtists => prevDisplayArtists.filter(artist => artist._id !== artistId))
            toast.success('Artist accepted successfully!')
        } catch (e) {
            console.error('Error accepting artist:', e)
            toast.error('Failed to accept artist. Please try again.')
        }
    }

    const handleReject = async (artistId) => {
        try {
            await axios.post(`${API_URL}/admin/reject-artist`, { artistId }, { withCredentials: true })
            setArtists(prevArtists => prevArtists.filter(artist => artist._id !== artistId))
            setDisplayArtists(prevDisplayArtists => prevDisplayArtists.filter(artist => artist._id !== artistId))
            toast.success('Artist rejected successfully!')
        } catch (e) {
            console.error('Error rejecting artist:', e)
            toast.error('Failed to reject artist. Please try again.')
        }
    }

    const handleArtistSearch = (e) => {
        const searchValue = e.target.value.toLowerCase()
        const filteredArtists = artists.filter(artist => 
            `${artist.artistFirstName} ${artist.artistLastName}`.toLowerCase().includes(searchValue)
        )
        setDisplayArtists(filteredArtists)
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <AdminArtistsContainer>
            <SearchBar onChange={handleArtistSearch} placeholder="Search for artists by name" />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <MdOutlineArrowRightAlt style={{ fontSize: 50, color: 'black', cursor: 'pointer', float: 'right' }} />
            </div>
            <Table>
                <thead>
                    <tr>
                        <Th>Artist Name</Th>
                        <Th>User ID</Th>
                        <Th>Email</Th>
                        <Th>Artist Type</Th>
                        <Th>Link to Work</Th>
                        <Th>Portfolio/Website</Th>
                        <Th>Accept/Reject</Th>
                    </tr>
                </thead>
                <tbody>
                    {displayArtists.length > 0 ? (
                        displayArtists.map((artist, index) => (
                            <tr key={artist._id}>
                                <Td>{`${artist.artistFirstName} ${artist.artistLastName}`}</Td>
                                <Td>{artist.user}</Td>
                                <Td>{artist.artistEmail}</Td>
                                <Td>{artist.artistType}</Td>
                                <Td>{artist.linkToWork}</Td>
                                <Td>{artist.portfolioLink}</Td>
                                <Td>
                                    <AcceptReject className='accept' onClick={() => handleAccept(artist._id)}>Accept</AcceptReject>
                                    <AcceptReject className='reject' onClick={() => handleReject(artist._id)}>Reject</AcceptReject>
                                </Td>
                            </tr>
                        ))
                    ) : (
                        <tr><Td colSpan="7" style={{ textAlign: 'center' }}>No artists found</Td></tr>
                    )}
                </tbody>
            </Table>
        </AdminArtistsContainer>
    )
}

export default AdminArtists