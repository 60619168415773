import React, {  } from 'react'

import {
    SearchWrapper,
    SearchContainer,
    SearchBar,
} from '../../Discover/DiscoverElements'

import {
    FilterSectionWrapper,
    BorderBottom
} from '../DiscoverArtistElements'

import { GoSearch } from 'react-icons/go'

const DiscoverArtistsComponent = ({ handleSearchChange, artists }) => {
    
    return (
        <FilterSectionWrapper>
            <SearchWrapper style={{ marginRight: 50 }}>
                    <SearchContainer>
                        <GoSearch size={20} color='#9E9E9E' style={{ position: 'absolute', left: '10px' }} />
                        <SearchBar 
                            placeholder='Search' 
                            onChange={handleSearchChange} 
                            // onKeyDown={handleKeyDown} 
                        />
                    </SearchContainer>
            </SearchWrapper>
            <BorderBottom />
        </FilterSectionWrapper>
    )
}

export default DiscoverArtistsComponent