import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`

export const AdminContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
`

export const ModalButton = styled.button`
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #e0e0e0;
    }
`

export const AdminInput = styled.input`
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
`

export const PasswordInput = styled.input`
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
`

export const RoleSelect = styled.select`
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const ConfirmButton = styled.button`
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #e0e0e0;
    }
`

export const CancelButton = styled.button`
    padding: 10px;
    margin-right: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    
    &:hover {
        background-color: #e0e0e0;
    }
`

export const DeleteButton = styled.button`
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    
    &:hover {
        background-color: rgba(255, 0, 0, 0.8);
    }
`

//CARD
export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
`

export const Title = styled.h1`
    margin-bottom: 10px;
`

export const Name = styled.h3`
    margin-bottom: 10px;
`

export const Email = styled.p`
    margin-bottom: 10px;
`

export const Roles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    transition: 0.3s;
    cursor: pointer;
`

//NAVBAR
export const Navbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
    background-color: #f0f0f0;
`

export const NavButton = styled.button`
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #e0e0e0;
    }
`

export const Image = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
`

export const ImagePreview = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 10px;
`

export const DropzoneWrapper = styled.div`
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
`