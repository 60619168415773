import React, { useState, useEffect }  from 'react'

import { getTimelineData, updateStrikethroughApi } from './TimelineApi'

import TimelineModal from './TimelineModal'
import EditTimelineModal from './EditTimelineModal'

import { 
    Wrapper,
    SectionWrapper,
    TimelineSection,
    Header,
    Body,
    BodySection,
    Stage,
    HeaderText,
    CircleContainer,
    VerticalLine,
    TooltipText,
    TooltipTarget
} from './ProjectTimelineElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'
import { NoInfo } from '../Details/ProjectDetailElements'

import { Add } from '../Details/ProjectDetailElements'
import { EditButton } from '../Perks/PerkElements'
import { IoHourglassOutline } from 'react-icons/io5'
import { IoMdConstruct } from 'react-icons/io'
import { IoMdAdd } from 'react-icons/io'
import { FaPauseCircle, FaCheck } from 'react-icons/fa'

function ProjectTimeline({ projectInfo, setProjectInfo, loggedInUser }) {
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [timelineData, setTimelineData] = useState([])
    const [showEditTimelineModal, setShowEditTimelineModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const strikethrough = {
        textDecoration: 'line-through'
    }

    useEffect(() => {
        const fetchTimelineData = async () => {
          try {
            if (projectInfo?.project?.slug) {
                const response = await getTimelineData(projectInfo?.project?.slug)

                setTimelineData(response.timeline.sections)
            }
            setIsLoading(false)
          } catch (error) {
            console.error('Error fetching timeline data:', error)
          }
        }
    
        fetchTimelineData()
    }, [projectInfo?.project?.slug])

    const renderIcon = (index) => {
        let icon
        if (timelineData[index].status === 'Awaiting') {
            icon = <IoHourglassOutline size={20} color='white' />
        } else if (timelineData[index].status === 'In Progress') {
            icon = <IoMdConstruct size={20} color='white' />
        } else if (timelineData[index].status === 'Completed') {
            icon = <FaCheck size={20} color='white' />
        } else if (timelineData[index].status === 'Paused') {
            icon = <FaPauseCircle size={20} color='white' />
        }

        return icon
    }

    const renderColor = (index) => {
        let color

        if (timelineData[index].status === 'Awaiting') {
            color = { background: '#DDDDDD' }
        } else if (timelineData[index].status === 'In Progress') {
            color = { background: '#F9D923' }
        } else if (timelineData[index].status === 'Completed') {
            // color 
        } else if (timelineData[index].status === 'Paused') {
            color = { background: '#EB5353' }
        }

        return color
    }

    const handleShowModal = (index) => {
        setShowModal(true)
        setCurrentIndex(index)
    }

    const handleShowEditModal = (index) => {
        setShowEditTimelineModal(true)
        setCurrentIndex(index)
    }

    const updateStrikethrough = async (itemId, crossedOut) => {
        try {
          const response = await updateStrikethroughApi(itemId, crossedOut, projectInfo.project.slug)

          if (!response.status === 200) {
            console.error('Error updating strikethrough status')
          }
        } catch (error) {
          console.error('Error updating strikethrough status', error)
        }
    }

    const toggleStrikeThrough = (sectionId, taskId, index) => {
        setTimelineData((prevTimelineData) =>
          prevTimelineData.map((section) => {
            if (section._id === sectionId) {
              const updatedTasks = section.tasks.map((task) => {
                if (task._id === taskId) {
                  return { ...task, striked: !task.striked }
                }
                return task
              })
              return { ...section, tasks: updatedTasks }
            }
            return section
          })
        )
      
        // Call the updateStrikethrough function after updating the state
        updateStrikethrough(index, taskId, projectInfo?.project?.slug)
    }
      
    const renderTimeline = timelineData && timelineData?.map((section, index) => {
        return (
            <React.Fragment key={section._id}>
                {(loggedInUser?.artist === projectInfo?.project?.artist?._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                        <Add size={25} style={{ cursor: 'pointer' }} onClick={() => handleShowModal(index)}>
                        <IoMdAdd />
                        </Add>
                        {/* <VscDiffAdded size={25} style={{ cursor: 'pointer' }} onClick={() => handleShowModal(index)} /> */}
                    </div>
                )}
                <SectionWrapper style={(loggedInUser?.artist === projectInfo?.project?.artist?._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && index > 0  ? {} : { marginTop: 20 }}>
                    <CircleContainer>
                        {renderIcon(index)}
                    </CircleContainer>
                    {index + 1 !== timelineData.length ? <VerticalLine></VerticalLine> : null}
                    <TimelineSection>
                        <Header>
                            <HeaderText>{section.title}</HeaderText>
                            {(loggedInUser?.artist === projectInfo?.project?.artist?._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && <EditButton style={{ marginTop: 5 }} onClick={() => handleShowEditModal(index)} onMouseEnter={() => setCurrentIndex(index)} >Edit</EditButton>}
                            <Stage style={renderColor(index)} >{section.status}</Stage>
                        </Header>
                        <Body>
                            {section.tasks &&
                                section.tasks.map((task) => (
                                <React.Fragment key={task._id}>
                                    <BodySection onClick={() => toggleStrikeThrough(section._id, task._id, index)}>
                                    <TooltipTarget style={task.striked ? strikethrough : {}}>
                                        {task.item}
                                    </TooltipTarget>
                                    <TooltipText>
                                        {!task.striked ? 'Click to strike through' : 'Click to unstrike'}
                                    </TooltipText>
                                    </BodySection>
                                </React.Fragment>
                            ))}
                        </Body>
                    </TimelineSection>
                </SectionWrapper>
            </React.Fragment>
        )
    })

    return (
        <Wrapper>
            {isLoading 
            ?
            <LoadContainer>
                <div style={{position: "relative"}}>
                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                    </OuterSpinner>
                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </Spinner>
                </div>
            </LoadContainer>
            :
            <>
                {timelineData && timelineData?.length === 0 && (loggedInUser?.artist === projectInfo?.project?.artist?._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) ? (
                    <EditButton style={{ width: 120, display: 'flex', justifyContent: 'center' }} onClick={() => setShowModal(true)}>
                        Add Element
                    </EditButton>
                ) : null}
                {showModal ? 
                    <TimelineModal 
                        setShowModal={setShowModal} 
                        setProjectInfo={setProjectInfo} 
                        projectInfo={projectInfo} 
                        timelineData={timelineData} 
                        setTimelineData={setTimelineData} 
                        currentIndex={currentIndex} 
                    /> 
                : null}
                {showEditTimelineModal ? 
                    <EditTimelineModal 
                        setShowEditTimelineModal={setShowEditTimelineModal} 
                        setProjectInfo={setProjectInfo} 
                        projectInfo={projectInfo} 
                        timelineData={timelineData[currentIndex]} 
                        setTimelineData={setTimelineData} 
                        currentIndex={currentIndex} 
                    /> 
                    : null}
                {renderTimeline}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {timelineData && timelineData?.length > 0 && (loggedInUser?.artist === projectInfo?.project?.artist?._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) ? (
                    <Add style={{ cursor: 'pointer', marginTop: 10 }} onClick={() => handleShowModal(projectInfo?.project?.timeline?.length)}>
                        <IoMdAdd />
                    </Add>
                ) : null}
                </div>
                {timelineData?.length === 0 && <NoInfo>{loggedInUser?.artist === projectInfo?.project?.artist?._id ? 'Add a timline section to begin.' : 'No timeline information available yet.'}</NoInfo>}
            </>
            }
        </Wrapper>
    )
}

export default ProjectTimeline