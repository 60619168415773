import axios from 'axios'
import { API_URL } from '../../config'

export const invest = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project-invest/submit-investment/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}