import React, { useState, useEffect } from 'react'

import { 
    InfoCardWrapper, 
    InfoCardTitle,
    InvestInfo
} from '../ProjectInvestElements'

function InfoCard ({ project }) {
    const [equityGiven, setEquityGiven] = useState('')
    const [bonusReturn, setBonusReturn] = useState(0)
    const [projectName, setProjectName] = useState('')

    useEffect(() => {
        if (project) {
            setProjectName(project.projectName)
            setEquityGiven(project.equityGiven)
            setBonusReturn(project.bonusReturn + 100)
        }

        return () => {
            setBonusReturn(0)
        }
    }, [project])

    return (
        <InfoCardWrapper>
            <InfoCardTitle>Deal Terms</InfoCardTitle>
            <InvestInfo>
                {project?.artist?.artistName} is offering <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>{equityGiven}%</strong> equity on {projectName}
            </InvestInfo>
            <InvestInfo>
                Investors receive <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>100%</strong> of the film&apos;s profits until <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>{bonusReturn}%</strong> of their investment is returned after which profits are split according to the equity given.
            </InvestInfo>
        </InfoCardWrapper>
    )
}

export default InfoCard