import React, { useState } from 'react'

import {
    AccountContainer,
    OptionSection,
    OptionInfo
} from '../AccountElements'

import InfoModal from './InfoModal'

const InvestorInfo = ({ userPage, setUserPage, investmentInfo, setInvestmentInfo }) => {
    const [showModal, setShowModal] = useState(false)
    const [activeModal, setActiveModal] = useState(null)

    const handleModalOpen = (modalType) => {
        setActiveModal(modalType)
        setShowModal(true)
    }

    let formattedDate = null

    if (investmentInfo?.birthdate) {
        const dateObject = new Date(investmentInfo?.birthdate)

        const year = dateObject.getFullYear() // 1993
        const month = dateObject.getMonth() + 1 // December is 11 in Date object (0-indexed), so add 1
        const day = dateObject.getDate() // 31

        formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`
    }

    return (
        <AccountContainer>
            {showModal && <InfoModal setShowModal={setShowModal} modalType={activeModal} investmentInfo={investmentInfo} setInvestmentInfo={setInvestmentInfo}/>}
            <OptionSection onClick={() => handleModalOpen('Full Name')}>
                <OptionInfo>Legal Full Name</OptionInfo>
                <OptionInfo>{investmentInfo?.legalFirstName} {investmentInfo?.legalLastName}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Birthdate')}>
                <OptionInfo>Birthdate</OptionInfo>
                <OptionInfo>{formattedDate}</OptionInfo>
            </OptionSection>
            <OptionSection>
                <OptionInfo>Nationality</OptionInfo>
                <OptionInfo>USA</OptionInfo>
            </OptionSection>
        </AccountContainer>
    )
}

export default InvestorInfo