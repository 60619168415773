import styled from 'styled-components'
import { MdKeyboardArrowDown } from 'react-icons/md'

export const Wrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 50px;
    }
`

export const BlogTitle = styled.h1`
    font-weight: 500;
    margin-bottom: 10px;
    align-self: flex-start;
    font-size: 24px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`

export const BlogSubtitle = styled.h4`
    max-width: 400px;
    font-weight: 300;
    margin-bottom: 30px;
    align-self: flex-start;
    color: grey;
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`

export const PostsWrapper = styled.div`
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const HeaderBar = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #eee;
    gap: 15px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        justify-content: center;
        gap: 0;
    }
`

export const HeaderButton = styled.button`
    background: none;
    border: none;
    font-size: 12px;
    height: 30px;
    padding: 5px 0 5px 0px;
    font-weight: 500;
    cursor: pointer;

    border-bottom: ${props => props.isSelected ? '3px solid black' : 'none'};
    color: ${props => props.isSelected ? 'black' : 'grey'};
    cursor: ${({ isSelected }) => (isSelected ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'auto')};

    &:hover {
        color: black;
    }

    @media (max-width: 768px) {
        font-size: 10px;
        padding: 5px;
    }
`

export const PostContainer = styled.div`
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 769px) {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 769px) {
        width: 35%;
    }
`

export const Header = styled.h3`
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
`

export const SubHeader = styled.h4`
    font-size: 16px;
    cursor: pointer;
`

export const AuthorContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: auto; // Pushes this element to the bottom of the parent container
    cursor: pointer;
`

export const AuthorPhoto = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`

export const AuthorName = styled.h4`
    font-size: 13px;
    color: #373A40;
    font-weight: 500;
    margin-left: 5px;
`

export const PostCreationDate = styled.h4`
    font-size: 13px;
    color: grey;
    font-weight: 300;
`

export const CoverPhoto = styled.img`
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 20px;

    @media (min-width: 769px) {
        margin-bottom: 0;
    }
`

export const ContentBlock = styled.div`
    font-size: 14px;
    line-height: 1.5;
    color: gray;
    cursor: pointer;

    // Add more styles as needed to format your HTML content
    p {
        margin: 0 0 10px;
    }
`

export const Tag = styled.div`
    display: inline-block;
    padding: 5px;
    background: #FF8989;
    color: #FFEADD;
    font-size: 12px;
    width: fit-content;
    max-width: 100%;
    margin-top: 10px; // Adds spacing between AuthorContainer and Tag, and ensures Tag is at the bottom
`

export const PostWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Underline = styled.div`
    width: 100%;
    border-bottom: 1px solid #eee;
    margin-top: 40px;
`

//Post
export const PostCoverPhoto = styled.img`
    width: 100%;
    max-width: 850px;
    aspect-ratio: 16 / 9;
    object-fit: cover;
`

export const ContentContainer = styled.div`
  margin-top: 35px;
  padding-bottom: 50px;
  width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 30px;
  }
`

export const PostContentBlock = styled.div`
    font-size: 15px;
    font-weight: 300;
    font-family: inherit;
    line-height: 1.5;

    p, span, h1, h2, h3, h4, h5, h6, blockquote, pre, code, ul, ol, li, a, img, video, iframe, strong, em, u, s, sub, sup, table, thead, tbody, tfoot, tr, th, td, div {
        font-family: inherit;
    }

    // Add more styles as needed to format your HTML content
`

export const Photo = styled.img`
    width: 100%;
    object-fit: cover;
    margin-top: 20px;
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const LoadMoreButton = styled.button`
  background-color: #3572EF;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  margin: 100px auto;
  display: block;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`

export const Modal = styled.div`
    position: absolute;
    top: 35px;
    right: 0;
    width: 90%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    z-index: 1000;
    padding: 20px;

    @media (max-width: 768px) {
        right: 5%;
        left: 5%;
        width: 90%;
    }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const ModalTitle = styled.h2`
  font-size: 18px;
  margin: 0;
`

export const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`

export const ResetTagItem = styled.button`
    padding: 10px;
    border: none;
    background: ${({ isSelected }) => (isSelected ? '#003f7f' : '#3572EF')};
    color: white;
    font-weight: 500;
    cursor: ${({ isSelected }) => (isSelected ? 'not-allowed' : 'pointer')};
    opacity: ${({ isSelected }) => (isSelected ? 0.6 : 1)};
    pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'auto')};

    &:hover {
        background-color: ${({ isSelected }) => (isSelected ? '#003f7f' : '#0056b3')};
    }
`

export const TagItem = styled.button`
  padding: 10px;
  border: none;
  background: ${({ isSelected }) => (isSelected ? 'lightgrey' : '#eee')};
  font-weight: 500;
  cursor: ${({ isSelected }) => (isSelected ? 'not-allowed' : 'pointer')};
  opacity: ${({ isSelected }) => (isSelected ? 0.6 : 1)};
  pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? 'lightgrey' : 'lightgrey')};
  }
`

export const RotateIcon = styled(MdKeyboardArrowDown)`
  transition: transform 0.3s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`