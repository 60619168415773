import React, { useState } from 'react'

import {
    InputWrapper,
    InputContainer,
    FormContainer,
    SubTitle,
    ErrorContainer,
    ErrorMessage,
    Input,
    StepHeader,
    Button,
    StepsContainer,
    Next,
    Back
} from './ArtistForms/ArtistFormsElements'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

function StepTwo({ 
    setCurrentStep, 
    artistType, 
    setArtistType, 
    worksLink, 
    setWorksLink,
    portfolioLink,
    setPortfolioLink 
}) {
    const [isArtistTypeInvalid, setIsArtistTypeInvalid] = useState(false)
    const [isWorksLinkInvalid, setIsWorksLinkInvalid] = useState(false)

    const handleNextStep = () => {
        if (!worksLink) {
            setIsWorksLinkInvalid(true)
        }

        if (artistType === null) {
            setIsArtistTypeInvalid(true)
        }

        if (worksLink && artistType) {
            setIsWorksLinkInvalid(false)
            setIsArtistTypeInvalid(false)
            setCurrentStep(prev => prev + 1)
        }
    }

    const handleChange = (e, setInput, setIsValid) => {
        if (e.target.value !== '') {
            setIsValid(false)
        }
        setInput(e.target.value)
    }

    const handleArtistTypeClick = (artistType) => {
        setIsArtistTypeInvalid(false)
        setArtistType(artistType)
    }

    return (
        <>
            <StepHeader>Works</StepHeader>
                <FormContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle isInvalid={isArtistTypeInvalid}>I am applying as a...</SubTitle>
                            {isArtistTypeInvalid && <ErrorContainer><ErrorMessage>Creator type is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                            <div style={{ display: 'flex' }}>
                                <Button isActive={artistType === 'CREATOR'} onClick={() => handleArtistTypeClick('CREATOR')} >Creator</Button>
                                <Button isActive={artistType === 'WRITER'} style={{ marginLeft: 10 }} onClick={() => handleArtistTypeClick('WRITER')} >Writer</Button>
                                <Button isActive={artistType === 'OTHER'} style={{ marginLeft: 10 }} onClick={() => handleArtistTypeClick('OTHER')}>Other</Button>
                            </div>
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle isInvalid={isWorksLinkInvalid}>Link to work</SubTitle>
                            <Input value={worksLink} isInvalid={isWorksLinkInvalid} placeholder='https://vimeo.com/channels/videos/00000000' onChange={(e) => handleChange(e, setWorksLink, setIsWorksLinkInvalid)} />
                            {isWorksLinkInvalid && <ErrorContainer><ErrorMessage>Link to portfolio/works is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle>Link to portfolio/website (not required)</SubTitle>
                            <Input value={portfolioLink} placeholder='AkiraKurosawa.com' onChange={(e) => setPortfolioLink(e.target.value)} />
                        </InputWrapper>
                    </InputContainer>
                </FormContainer>
                <StepsContainer>
                    <Back onClick={() => setCurrentStep(prev => prev - 1)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} /> Basic Info</Back>
                    <Next style={{ width: 200 }} onClick={handleNextStep}>Next: Consent & Confirm</Next>
                </StepsContainer>
        </>
    )
}

export default StepTwo