export const formatReadableDate = (dateStr) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    
    // Create a Date object from the ISO string
    const date = new Date(dateStr)
  
    // Extract the day, month, and year
    const day = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
  
    // Return the formatted string
    return `${month} ${day}, ${year}`
}