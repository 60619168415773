import React from 'react'

import AdminProjectComponent from '../../components/AdminProjects'
import AdminNavbar from '../../components/Admin/AdminNavbar'

const AdminProjects = () => {
    return (
        <div>
            <AdminNavbar />
            <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Projects</h1>
            </div>
            <AdminProjectComponent />
        </div>
    )
}

export default AdminProjects