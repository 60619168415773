import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
`

export const EditButton = styled.button`
    padding: 10px;
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid #EAEAEA;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;
    width: auto;

    &:hover {
        border-color: black;
    }
`

export const Header = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
`

export const GridWrapper = styled.div`
    display: grid;
    /* margin-top: 60px; */
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    /* transform: translateY(-60px);

    transition: 0.25s ease-in-out; */
`

export const PictureContainer = styled.div`

`

export const PictureWrapper = styled.img`
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    display: block;
`

export const Card = styled.div`
    min-width: 250px;
    height: 100%;
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #EAEAEA;
`

export const PerkHeader = styled.div`
    font-size: 18px;
    margin-top: 5px;
`

export const PerkSubHeader = styled.div`
    font-size: 16px;
    margin-top: 10px;
    color: #696969;
`

export const Text = styled.div`
    margin-top: 10px;
    font-size: 13px;
`

export const ShippingHeaderContainer = styled.div`
    margin-top: 10px;
    display: flex;
`

export const ShippingHeader = styled.div`
    font-size: 10px;
    color: #696969;
    display: flex;
    flex-direction: column;

`

export const Unlocks = styled.div`
    margin-top: 10px;
    width: fit-content;
    padding: 5px;
    background: #CBEDD5;
    border-radius: 4px;
    font-size: 12px;
`

export const AddPerkSection = styled.div`
    padding: 10px;
`

export const AddPerkTitle = styled.div`
    color: ${props => props.isInvalid ? 'red' : 'black'};
`

export const EditGraphicSection = styled.div`
    position: relative;
    padding: 10px;
    cursor: pointer;
`

export const EditGraphicWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    transition: 0.25s ease-in-out;
    opacity: 0;

    &:hover {
        opacity: 1;
        background: rgba(130,195,236, 0.7); 
    }
`

export const EditGraphicText = styled.div`
    color: white;
`

export const AddPerkInput = styled.input`
    margin-top: 5px;
    width: 100%;
    border: none;

    &:focus {
        outline: none;
    }
`

export const AddPerkTextBox = styled.textarea`
    margin-top: 5px;
    height: 200px;
    width: 300px;
    border: none;
    resize: none;

    &:focus {
        outline: none;
    }
`

export const PhotoSection = styled.div`
    height: 200px;
    width: 300px;
    border: 1px dashed #C0C0C0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const UploadContainer = styled.div`
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #EAEAEA;
    margin-bottom: 5px;
`

export const PerkImage = styled.img`
    margin-top: 10px;
    border-radius: 3px;
    height: 100%;
    width: 100%;
    object-fit: cover;
`

export const AddPerkButton = styled.button`
    margin-top: 5px;
    height: 50px;
    width: 100%;
    background: black;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background: #222222;
    }
`

export const CancelPerkButton = styled.button`
    margin-top: 5px;
    height: 50px;
    width: 100%;
    background: transparent;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`

export const DeletePerkButton = styled.button`
    margin-top: 5px;
    height: 50px;
    width: 100%;
    background: #EB1D36;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background: #FA9494;
    }
`

//EDIT PERK MODAL ELEMENTS

// export const EditPerk