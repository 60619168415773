import styled from 'styled-components'

export const EditTitle = styled.h1`
    font-size: 20px;
    margin-bottom: 20px;
`

export const SearchBar = styled.input`
    padding: 10px;
    width: 30%;
    margin-bottom: 20px;
`

export const SelectInput = styled.select`
    padding: 10px;
    width: 200px;
    margin-bottom: 20px;
`

export const EditDescription = styled.p`
    font-size: 16px;
    margin-bottom: 20px;
`

export const SelectionBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`

export const ModalInput = styled.input`
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    //wrap
    flex-wrap: wrap;
`

export const Button = styled.button`
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #e0e0e0;
    }
`

export const SaveButton = styled.button`
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 20px;

    &.cancel {
        background-color: transparent;
    }

    

    &:hover {
        background-color: #e0e0e0;
    }
`