import React, { useState, lazy, Suspense, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../../config'
import StepOne from '../StepOne'
import StepTwo from '../StepTwo'
import StepThree from '../StepThree'
import StepFour from '../StepFour'

import {
    Wrapper,
    Container,
    Header,
} from './ArtistFormsElements'

import LazyLoaderModal from '../../LazyLoaderModal'

const FinalizeModal = lazy(() => import('../FinalizeModal'))

function ArtistForms({ loggedInUser }) {
    const [currentStep, setCurrentStep] = useState(1)
    const [artistFirstName, setArtistFirstName] = useState('')
    const [artistLastName, setArtistLastName] = useState('')
    const [artistEmail, setArtistEmail] = useState('')
    const [isUSCitizen, setIsUSCitizen] = useState(null)
    const [artistType, setArtistType] = useState(null)
    const [worksLink, setWorksLink] = useState('')
    const [portfolioLink, setPortfolioLink] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const checkWaitlistStatus = async () => {
            try {
                setIsLoading(true)
                const response = await axios.get(`${API_URL}/artist-registry/check-waitlist?userId=${loggedInUser.id}`)
                
                if (response.data.isOnWaitlist) {
                    // If user is on waitlist, set their existing data
                    const waitlistData = response.data.waitlistDetails
                    setArtistFirstName(waitlistData.artistFirstName)
                    setArtistLastName(waitlistData.artistLastName)
                    setArtistEmail(waitlistData.artistEmail)
                    setIsUSCitizen(waitlistData.isUSCitizen)
                    setArtistType(waitlistData.artistType)
                    setWorksLink(waitlistData.linkToWork)
                    setPortfolioLink(waitlistData.portfolioLink)
                    // Set to step 4 (waitlist confirmation)
                    setCurrentStep(4)
                }
            } catch (error) {
                console.error('Error checking waitlist status:', error)
                // If there's an error, we'll let them continue with registration
            } finally {
                setIsLoading(false)
            }
        }

        if (loggedInUser?.id) {
            checkWaitlistStatus()
        }
    }, [loggedInUser])

    if (isLoading) {
        return <LazyLoaderModal />
    }

    return (
        <Wrapper>
            <Suspense fallback={<LazyLoaderModal />} >
                {modalOpen && 
                    <FinalizeModal
                        setModalOpen={setModalOpen}
                        artistFirstName={artistFirstName}
                        artistLastName={artistLastName}
                        artistEmail={artistEmail}
                        isUSCitizen={isUSCitizen}
                        artistType={artistType}
                        worksLink={worksLink}
                        portfolioLink={portfolioLink}
                        loggedInUser={loggedInUser}
                        setCurrentStep={setCurrentStep}
                    />
                }
            </Suspense>
            <Container>
                <Header>Artist Registry</Header>
                {
                    currentStep === 1 ?
                        <StepOne 
                            setCurrentStep={setCurrentStep}
                            artistFirstName={artistFirstName}
                            setArtistFirstName={setArtistFirstName}
                            artistLastName={artistLastName}
                            setArtistLastName={setArtistLastName}
                            artistEmail={artistEmail}
                            setArtistEmail={setArtistEmail}
                            isUSCitizen={isUSCitizen}
                            setIsUSCitizen={setIsUSCitizen}
                        /> :
                    currentStep === 2 ?
                        <StepTwo 
                            setCurrentStep={setCurrentStep}
                            artistType={artistType}
                            setArtistType={setArtistType}
                            worksLink={worksLink}
                            setWorksLink={setWorksLink}
                            portfolioLink={portfolioLink}
                            setPortfolioLink={setPortfolioLink}
                        /> :
                    currentStep === 3 ?
                        <StepThree setCurrentStep={setCurrentStep} setModalOpen={setModalOpen} />
                    :
                    currentStep === 4 ?
                        <StepFour />
                    :
                    null
                }
            </Container>
        </Wrapper>
    )
}

export default ArtistForms