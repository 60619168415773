import axios from 'axios'
import { API_URL } from '../../../../config'

export const getScriptApi = async (project, index, fileName) => {
    return axios.get(`${API_URL}/project/script-pdf/${project.slug}/${fileName}`, { responseType: 'blob' })
}

export const addMediaApi = async (mediaElement, projectId) => {
    const payload = {
        mediaElement,
        projectId,
    }

    try {
        return axios.post(`${API_URL}/project/add-media-element/me`, payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const addScriptApi = async (data, scriptFile, slug, setProgress) => {
    try {
        return axios.post(`${API_URL}/project/upload-pdf/me?slug=${slug}`, data, {
        headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
        },
        withCredentials: true,
        onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total))
            console.log(Math.round((100 * data.loaded) / data.total))
        },
        })
    } catch (e) {
        console.log(e)
    } 
}

export const deleteMediaApi = async (projectId, mediaId) => {
    const payload = {
        projectId,
        mediaId
    }

    console.log(mediaId)

    return axios.post(`${API_URL}/project/delete-media-element/me`, payload, { withCredentials: true })
}

export const deleteScriptApi = async (slug, fileName) => {

    return axios.delete(`${API_URL}/project/delete-script/${slug}/${fileName}`, { withCredentials: true })
}
