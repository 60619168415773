import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentInfoValue } from '../helpers/reducerHelper'

import {
    StepWrapper,
    Step,
    StepMobile,
    FinalInvestmentWrapper,
    InvestmentAmountWrapper,
    InvestmentInfo,
    BorderBottom,
    InvestmentButton,
    WarningMessage,
    CheckboxInfoContainer,
    CheckboxContainer,
    RadioDescription,
    CheckboxInput,
    CheckboxLabel
} from '../ProjectInvestElements'

import ReviewModal from '../Modals/ReviewModal'

import { invest } from '../investApi'

function StepFive ({ state, dispatch, invalidDOB, invalidPhoneNumber, project }) {
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)
    const [allFieldsFilled, setAllFieldsFilled] = useState(false)
    const [showReviewModal, setShowReviewModal] = useState(false)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    const requiredFields = [
        'First Name', 
        'Last Name', 
        'Email', 
        'Phone Number',
        'DOB',
        'Address',
        'City',
        'Country',
        'State',
        'Zip Code',
        'Accreditor Type',
        'Investment Amount',
        'Yearly Income',
        'Net Worth',
        'Signature',
        // 'Payment Type'
    ]

    const checkFieldsFilled = () => {
        let fieldsToCheck = [...requiredFields]
        
        const isAccredited = getCurrentInfoValue('isAccredited', state)
        
        if (isAccredited === false) {
            fieldsToCheck = fieldsToCheck.filter(field => field !== 'Accreditor Type')
        } else {
            fieldsToCheck = fieldsToCheck.filter(field => field !== 'Yearly Income' && field !== 'Net Worth')
        }
        
        const allFieldsNonEmpty = fieldsToCheck.every(field => {
            const item = state.store.find(entry => entry.label === field)
            return item && item.info && item.info.trim() !== ''
        })
    
        const investmentAmount = state.store.find(entry => entry.label === 'Investment Amount')
        const isInvestmentValid = investmentAmount && parseFloat(investmentAmount.info) >= 100

        // Check if Postal Code is valid
        const postalCode = state.store.find(entry => entry.label === 'Zip Code')
        const isPostalCodeValid = postalCode && postalCode.info && postalCode.info.length === 5 && !isNaN(postalCode.info)
    
        return allFieldsNonEmpty && isInvestmentValid && invalidPhoneNumber === '' && invalidDOB === '' && isPostalCodeValid
    }
    
    useEffect(() => {
        const areFieldsFilled = checkFieldsFilled()
        setAllFieldsFilled(areFieldsFilled)
    
        if (hasAttemptedSubmit) {
            const currentRelevantFieldsFilledValue = getCurrentInfoValue('All Relevant Fields Filled', state)
            const newRelevantFieldsFilledValue = areFieldsFilled ? 1 : 0
    
            // Dispatch only if there's an actual change
            if (currentRelevantFieldsFilledValue !== newRelevantFieldsFilledValue) {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: newRelevantFieldsFilledValue, label: 'All Relevant Fields Filled' } })
            }
        }

        //eslint-disable-next-line
    }, [state, invalidDOB, invalidPhoneNumber, hasAttemptedSubmit, isDesktopOrLaptop, isTabletOrMobile, checkFieldsFilled])

    if (!state || !state.store) {
        return null // or return some fallback UI
    }  
    
    const handleSubmitInvestment = () => {
        setHasAttemptedSubmit(true)
        if (allFieldsFilled) {
            const payload = {
                projectId: project?._id,
                investmentAmount: parseToNumber(getCurrentInfoValue('Investment Amount', state)),
                tipAmount: getCurrentInfoValue('Tip Amount', state),
                isAnonymous: getCurrentInfoValue('isAnonymous', state),
                isAccredited: getCurrentInfoValue('isAccredited', state),
                accreditationType: getCurrentInfoValue('Accreditor Type', state),
                paymentType: getCurrentInfoValue('Payment Type', state),
                isOrg: getCurrentInfoValue('isOrg', state)
            }

            document.body.style.overflow = 'hidden'
            setShowReviewModal(true)
            invest(payload)
        }
    }    
    
    const parseToNumber = (value) => {
        if (!value) return 0
        // Remove commas and convert to a number
        return parseFloat(value.replace(/,/g, ''))
    }

    const calculateTotalAmount = () => {
        const investmentAmount = parseToNumber(getCurrentInfoValue('Investment Amount', state))
        const tipAmount = parseToNumber(getCurrentInfoValue('Tip Amount', state))

        // Sum up the amounts
        const total = investmentAmount + tipAmount

        // Return the total amount in string format
        return total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    return (
        <StepWrapper>
            {isDesktopOrLaptop &&
                <Step><div style={{ marginRight: 20 }}>3.</div>Complete Investment</Step>
            }
            {isTabletOrMobile &&
                <StepMobile>5. Complete Investment</StepMobile>
            }
            {showReviewModal && 
                <ReviewModal 
                    projectName={project?.projectName}
                    projectId={project?._id}
                    state={state} 
                    getCurrentInfoValue={getCurrentInfoValue} 
                    setShowReviewModal={setShowReviewModal}
                />
            }
            <FinalInvestmentWrapper style={isTabletOrMobile ? { marginLeft: 0 } : {}}>
                <InvestmentAmountWrapper>
                    <InvestmentInfo>Your Investment</InvestmentInfo>
                    <InvestmentInfo style={{ fontWeight: 700 }}>${getCurrentInfoValue('Investment Amount', state) !== '' ? getCurrentInfoValue('Investment Amount', state) : '0.00'}</InvestmentInfo>
                </InvestmentAmountWrapper>
                <InvestmentAmountWrapper>
                    <InvestmentInfo>Escrow Agent Fee</InvestmentInfo>
                    <InvestmentInfo style={{ fontWeight: 700 }}>$0.00</InvestmentInfo>
                </InvestmentAmountWrapper>
                <InvestmentAmountWrapper>
                    <InvestmentInfo>Tip</InvestmentInfo>
                    <InvestmentInfo style={{ fontWeight: 700 }}>${getCurrentInfoValue('Tip Amount', state)}</InvestmentInfo>
                </InvestmentAmountWrapper>
                <BorderBottom />
                <InvestmentAmountWrapper>
                    <InvestmentInfo>Total</InvestmentInfo>
                    <InvestmentInfo style={{ fontWeight: 700 }}>${calculateTotalAmount()}</InvestmentInfo>
                </InvestmentAmountWrapper>
            </FinalInvestmentWrapper>
            <CheckboxInfoContainer style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 35, marginTop: 10, width: '100%' }}>
                <RadioDescription>Do you want your investment to remain anonymous?</RadioDescription>
                <CheckboxContainer onClick={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: !getCurrentInfoValue('isAnonymous', state), label: 'isAnonymous' } })}>
                    <CheckboxInput 
                        type="checkbox" 
                        checked={getCurrentInfoValue('isAnonymous', state)} 
                        readOnly={true}
                    />
                    <CheckboxLabel  
                        checked={getCurrentInfoValue('isAnonymous', state)}
                        readOnly={true}
                    />
                </CheckboxContainer>
            </CheckboxInfoContainer>
            {isDesktopOrLaptop &&
            <InvestmentButton 
                style={!allFieldsFilled ? { background: '#D8D9DA' } : {}} 
                onClick={handleSubmitInvestment} >
                    Finish Investment
            </InvestmentButton>
            }
            {hasAttemptedSubmit && !allFieldsFilled &&<WarningMessage>*All required fields have not been filled out. Please go back and fill out the required fields indicated by a red outline before continuing.</WarningMessage>}
        </StepWrapper>
    )
}

export default StepFive