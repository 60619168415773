import { 
  deleteOverviewApi, 
  addOverviewApi, 
  editOverviewApi, 
  addImageApi 
} from './OverviewApis'

const useHandleUpdates = (
  projectId, 
  projectName, 
  artistId, 
  slug,
  storyElements, 
  deletedElementIds, 
  imageFiles, 
  dispatch, 
  setUpdateMade, 
  deletedIndexes, 
  setProjectInfo) => {

  const handleSaveChanges = async () => {
    if (!projectId) {
      return
    }

    if (deletedElementIds.length > 0) {
      // Make an API call to delete elements
      const response = await deleteOverviewApi(deletedElementIds, projectId)
      dispatch({ type: 'POPULATE_STORY', payload: response.data })
      setProjectInfo(prev => ({ ...prev, project: { ...prev.project, storyElements: response.data }}))
    }

    //remove elements from storyElements that have been deleted
    const updatedStoryElements = storyElements.filter(
      (element) =>
        !(element.operation === 'new' && element.content.length === 0) &&
        !(element.operation === 'delete')
    )

    const updatedStoryWithIndex = updatedStoryElements.map((element, index) => ({
      ...element,
      index: index,
    }))

    let updatedMappedStoryElements = updatedStoryWithIndex.filter(
      (element) => !(element.operation === 'add' && element.isFromDatabase)
    )

    const elementsToAdd = updatedMappedStoryElements.filter((element) => element.operation === 'add')

    if (elementsToAdd.length > 0) {
      const response = await addOverviewApi(projectId, elementsToAdd)
      // Make an API call to add new elements
      dispatch({ type: 'POPULATE_STORY', payload: response.data })
      setProjectInfo(prev => ({ ...prev, project: { ...prev.project, storyElements: response.data }}))
    }

    const elementsToEdit = updatedMappedStoryElements.filter((element) => element.operation === 'edit')

    if (elementsToEdit.length > 0) {
      const response = await editOverviewApi(projectId, elementsToEdit)
      // Make an API call to edit existing elements
      dispatch({ type: 'POPULATE_STORY', payload: response.data })
      setProjectInfo(prev => ({ ...prev, project: { ...prev.project, storyElements: response.data }}))
    }

    if (imageFiles.length > 0) {
      const formData = new FormData()
      imageFiles.forEach((file, index) => {
        formData.append('images', file)
      })

      try {
        await addImageApi(slug, formData)
      } catch (error) {
        console.error(error)
      }
    }
    
    dispatch({ type: 'TOGGLE_EDITOR_MODE_STRICT' })
    setUpdateMade(true)
  }
  return { handleSaveChanges }
}

export default useHandleUpdates