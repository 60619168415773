import React from 'react'

import {
    SubTitle,
    InputWrapper,
    InputContainer,
    Input,
    ErrorContainer,
    ErrorMessage,
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    InfoBubble,
    Info,
    InputWithDollarSign,
    InputWithPercentSign,
    DropdownMenuWrapper,
    DropdownFundingMenu
} from '../../CreateProjectElements'

import { AiOutlineExclamationCircle } from 'react-icons/ai'

function InputFields({ 
    fundingAmount, 
    setFundingAmount, 
    equityAmount, 
    setEquityAmount, 
    isInvalid, 
    setIsInvalid, 
    isEquityInvalid, 
    setIsEquityInvalid,
    equityTooHigh,
    setEquityTooHigh,
    projectCategory,
    setProjectCategory,
    projectFundingType,
    setProjectFundingType
}) {
    const handleChange = event => {
        const value = event.target.value.replace(/\D/g, "")  // Only allow digits
        const formattedValue = Number(value).toLocaleString()  // Add commas
        setFundingAmount(formattedValue)
        setIsInvalid(false)
    }

    const handleEquityChange = event => {
        let input = event.target.value.replace(/\D/g, "")  // Only allow digits
    
        // Limit the value to only 3 digits
        if (input.length > 3) {
            input = input.slice(0, 3)
        }
    
        // Now, we call the state updater function with the new value
        setEquityAmount(input)
        setIsEquityInvalid(false)
        setEquityTooHigh(false)
    }
    
    const handleBlur = () => {
        const numericAmount = Number(fundingAmount.replace(/,/g, "")) // convert the string back to number

        if (numericAmount > 5000000) {
            alert('The maximum allowed amount is $5,000,000. For larger amounts, please contact us via email.')
            setFundingAmount('5,000,000')  // Reset to maximum allowed
        }
    }

    return (
        <>
            <InputContainer>
                <InputWrapper>
                    <SubTitle>Funding Category</SubTitle>
                    <DropdownMenuWrapper>
                        <DropdownFundingMenu value={projectCategory} onChange={(e) => setProjectCategory(e.target.value)}>
                            <option value="funding">Funding New Project</option>
                            <option disabled value="licensing">Licensing</option>
                            <option disabled value="revenue-share">Revenue Share</option>
                        </DropdownFundingMenu>
                    </DropdownMenuWrapper>
                </InputWrapper>
            </InputContainer>
            <InputContainer>
                <InputWrapper>
                    <SubTitle>Project Funding Type</SubTitle>
                    <DropdownMenuWrapper>
                        <DropdownFundingMenu value={projectFundingType} onChange={(e) => setProjectFundingType(e.target.value)}>
                            <option value="REG CF">REG CF</option>
                            <option value="REG CF Fixed">REG CF Fixed</option>
                        </DropdownFundingMenu>
                    </DropdownMenuWrapper>
                </InputWrapper>
            </InputContainer>
            <InputContainer>
                <InputWrapper>
                    <SubTitle isInvalid={isInvalid} >Goal Amount</SubTitle>
                    <InputWithDollarSign>
                        <Input
                            isInvalid={isInvalid}
                            type='text'
                            value={fundingAmount}
                            placeholder='5,000,000'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ paddingLeft: 30 }}
                        />
                    </InputWithDollarSign>
                    {isInvalid && <ErrorContainer><ErrorMessage>Please enter an amount</ErrorMessage><AiOutlineExclamationCircle  color='red' /></ErrorContainer>}
                    <InfoBubble>
                        <Info>To receive funds, the person withdrawing must have a US social security number and a bank account and mailing address within the US.</Info>
                    </InfoBubble>
                </InputWrapper>
            </InputContainer>
            {projectFundingType === 'REG CF' ? (
                <>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle isInvalid={isEquityInvalid}>Investor Equity</SubTitle>
                                <InputWithPercentSign>
                                    <Input 
                                        isInvalid={isEquityInvalid}
                                        value={equityAmount}
                                        onChange={handleEquityChange}
                                        placeholder='Enter an amount between 0 and 100'
                                        maxLength={3}
                                    />
                                </InputWithPercentSign>
                                {isEquityInvalid && !equityTooHigh && <ErrorContainer><ErrorMessage>Please enter an amount</ErrorMessage><AiOutlineExclamationCircle  color='red' /></ErrorContainer>}
                                {equityTooHigh && <ErrorContainer><ErrorMessage>Equity amount given cannot be greater than 100%</ErrorMessage><AiOutlineExclamationCircle  color='red' /></ErrorContainer>}
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle>Investor Bonus Return Percentage</SubTitle>
                                <InputWithPercentSign>
                                    <Input 
                                        defaultValue={0}
                                    />
                                </InputWithPercentSign>
                            <InfoBubble>
                                <Info>This percentage refers to the bonus that investors receive on top of their initial investment before any profits are divided according to equity stakes. </Info>
                            </InfoBubble>
                        </InputWrapper>
                    </InputContainer>
                </>
            ) : (
                <InputContainer>
                    <InputWrapper>
                        <SubTitle>Investor Return Cap</SubTitle>
                            <InputWithPercentSign>
                                <Input 
                                    value={equityAmount}
                                    onChange={handleEquityChange}
                                    placeholder='Enter a return cap percentage'
                                    maxLength={3}
                                />
                            </InputWithPercentSign>
                            {isEquityInvalid && !equityTooHigh && <ErrorContainer><ErrorMessage>Please enter an amount</ErrorMessage><AiOutlineExclamationCircle  color='red' /></ErrorContainer>}
                            {equityTooHigh && <ErrorContainer><ErrorMessage>Return cap cannot be greater than 100%</ErrorMessage><AiOutlineExclamationCircle  color='red' /></ErrorContainer>}
                            <InfoBubble>
                                <Info>Users investing in a REG CF fixed project will ONLY receive profits on their investment up to the designated return cap.</Info>
                            </InfoBubble>
                    </InputWrapper>
                </InputContainer>
            )}
        </>
    )
}

export default InputFields