import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Button = styled.button`
    display: flex;
    background: transparent;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    margin-left: 10px;
    border: none;
    cursor: pointer;

    &:hover {
        background: #EEE;
    }

    &:active {
        transform: scale(0.9);
    }
`

export const CarouselContainer = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 10px 4px 20px 4px;
    gap: 10px;
`

export const FilmCardContainer = styled.div`
    margin-right: 15px;
`