import styled from 'styled-components'
import { PrimaryColor } from '../../../colors'

export const ProjectContainer = styled.div`
    margin-top: 20px;
    padding: 20px;
    /* border-radius: 5px; */
    position: relative;
    width: 70%;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;

    .tint {
        transition: 0.1s ease-in-out;
        opacity: 0;
    }

    .percentage {
        transition: 0.25s ease-in-out;
        visibility: hidden;
        z-index: 3;
    }

    &:hover {
        .tint {
            opacity: 0.6;
            z-index: 1;
        }

        .percentage {
            visibility: visible;
            transform: translateY(-20px);
        }
    }

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`

export const Title = styled.div`
    margin-left: 2px;
    font-weight: bold;
`

export const ProjectImage = styled.img`
    width: 100vw;
    height: 100%;
    object-fit: cover;
    overflow-y: hidden;
    position: relative;
`

export const ProjectLink = styled.a`
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const ContainerTint = styled.div`
    width: 100%;
    height: 100%; 
    background: black;
    position: absolute; // Set to absolute
    top: 0; 
    left: 0;
    /* border-radius: 10px; */
    transition: 0.1s ease-in-out;
    opacity: 0;
    z-index: 2; 
`

export const PercentageContainer = styled.div`
    margin-top: 22px;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
`

export const PercentageText = styled.div`
    font-size: 30px;
    font-weight: bold;
    z-index: 3;
    color: white;
`

export const CardInfoWrapper = styled.div`
    margin-bottom: -3px;
    width: 100%;
    height: 25px;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
`

export const ProgressBar = styled.div`
    width: 100%;
    margin-top: 20px;
    height: 10px;
    background: lightgrey;
    z-index: 3;
`

export const Progress = styled.div`
    width: ${props => props.percentage}%;
    height: 10px;
    background: ${PrimaryColor};
    z-index: 3;
`

