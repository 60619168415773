import React from 'react'

import AdminArtists from '../../components/AdminArtists'
import AdminNavbar from '../../components/Admin/AdminNavbar'

const AdminArtistWaitlist = () => {
    return (
        <div>
            <AdminNavbar />
            <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Artist Waitlist</h1>
            </div>
            <AdminArtists />
        </div>
    )
}

export default AdminArtistWaitlist