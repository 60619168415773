import React from 'react'

import NonMainNavbar from '../components/NonMainNav'
import PostsComponent from '../components/Posts'

function Posts({ loggedInUser }) {

    return (
        <>
            <NonMainNavbar loggedInUser={loggedInUser} />
            <PostsComponent />
        </>
    )
}

export default Posts