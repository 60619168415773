import React, { useState, useRef, useEffect } from 'react'

import { 
    editTimelineSectionTasks,
    editStatus,
    addTimelineTasks,
    editTimelineSectionTitle,
    removeTimelineSection,
    removeTimelineTasks
} from '../TimelineApi'

import {
  DeleteButton
} from '../ProjectTimelineElements.js'

import { 
    Wrapper, 
    ModalBg, 
    Modal, 
    Header, 
    HeaderText, 
    Body,
    AddInfo,
    BudgetSection,
    CurrentStatus,
    ItemTextBoxContainer,
    ItemTextBox,
    AddItem
} from '../../../Modal/EditModalElements' 

import { 
  AddButton, 
  CancelButton 
} from '../../Media/MediaModal/MediaModalElements.js'

import { IoCloseSharp } from 'react-icons/io5'

function EditTimelineModal({ 
    setShowEditTimelineModal, 
    setProjectInfo,
    projectInfo, 
    currentIndex, 
    timelineData,
    setTimelineData, 
    setTimelineItemsData, 
}) {
    const [editTimelineData, setEditTimelineData] = useState({ status: 'Awaiting', tasks: [{ striked: false, item: '', tempId: Date.now().toString() }], title: timelineData.title, _id: timelineData._id })
    const [title, setTitle] = useState(timelineData.title)
    const [status, setStatus] = useState('')

    useEffect(() => {
      if (timelineData.tasks.length > 0) {
        setEditTimelineData(JSON.parse(JSON.stringify(timelineData)))
      }
    }, [timelineData])

    const modalRef = useRef(null)

    const handleDelete = async (e) => {
      const payload = {
        projectId: projectInfo.project._id,
        sectionId: timelineData._id,
      }
    
      let confirmDelete = window.confirm('Are you sure you want to delete this timeline section? This cannot be undone.')
    
      if (confirmDelete) {
        const response = await removeTimelineSection(payload)
        setTimelineData(response.sections)
        setShowEditTimelineModal(false)
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      const titleChange = title !== editTimelineData.title
      const updateObj = {}
      const removeArray = []
    
      // Filter out empty tasks from tasksArray
      const filteredTasksArray = editTimelineData.tasks.filter((task) => task.item !== '')
    
      // Compare tasks for edits
      filteredTasksArray.forEach((task) => {
        if (task._id) {
          const originalTask = timelineData.tasks.find((t) => t._id === task._id)
          if (originalTask && task.item !== originalTask.item) {
            updateObj[`tasks._id.${task._id}.item`] = task.item
          }
        }
      })

      // Check for deleted tasks
      for (let i = 0; i < timelineData.tasks.length; i++) {
        const taskExists = filteredTasksArray.some((task) => task._id === timelineData.tasks[i]._id)
        if (!taskExists) {
          removeArray.push(timelineData.tasks[i]._id)
        }
      }
    
      // Prepare the payload
      const payload = {
        title,
        originalTitle: timelineData.title,
        projectId: projectInfo.project._id,
        sectionId: timelineData._id,
        updateObj,
        tasksToAdd: filteredTasksArray.filter((task) => task.tempId),
        removeArray,
        status,
      }

      // Edit timeline section tasks
      if (Object.keys(updateObj).length > 0) {
        const response = await editTimelineSectionTasks(payload)
        setTimelineData((prevTimelineData) =>
          prevTimelineData.map((section) => {
            if (section._id === timelineData._id) {
              return { ...section, tasks: response }
            }
            return section;
          })
        )
      }

      // Remove tasks
      if (removeArray.length > 0) {
        await removeTimelineTasks(payload)
        setTimelineData((prevTimelineData) =>
          prevTimelineData.map((section) => {
            if (section._id === timelineData._id) {
              return {
                ...section,
                tasks: section.tasks.filter((task) => !removeArray.includes(task._id)),
              }
            }
            return section
          })
        )
      }

      // Calculate the index to add the new tasks
      const addIndex = timelineData.tasks.length - removeArray.length
    
      // Add new tasks
      if (payload.tasksToAdd.length > 0) {
        const response = await addTimelineTasks({ ...payload, addIndex })
        setTimelineData((prevTimelineData) =>
          prevTimelineData.map((section) => {
            if (section._id === timelineData._id) {
              return {
                ...section,
                tasks: [
                  ...section.tasks.slice(0, addIndex),
                  ...response,
                  ...section.tasks.slice(addIndex),
                ],
              }
            }
            return section
          })
        )
      }
    
      // Update status
      if (status) {
        const response = await editStatus(payload)
        setTimelineData((prevTimelineData) =>
          prevTimelineData.map((section) => {
            if (section._id === timelineData._id) {
              return { ...section, status: response }
            }
            return section
          })
        )
      }
    
      // Update timeline section title
      if (titleChange) {
        const response = await editTimelineSectionTitle(payload)
        setTimelineData((prevTimelineData) =>
          prevTimelineData.map((section) => {
            if (section._id === timelineData._id) {
              return { ...section, title: response.title }
            }
            return section;
          })
        )
      }
    
      setShowEditTimelineModal(false)
    }

    const handleRemoveTask = (taskIndex) => {
      setEditTimelineData((prevTimelineData) => {
        if (prevTimelineData.tasks.length === 1) {
          // If there is only one task, clear the input instead of deleting it
          return {
            ...prevTimelineData,
            tasks: [{ ...prevTimelineData.tasks[0], item: '' }],
          }
        } else {
          // If there are multiple tasks, remove the task at the specified index
          const updatedTasks = prevTimelineData.tasks.filter((_, index) => index !== taskIndex)
          return {
            ...prevTimelineData,
            tasks: updatedTasks,
          }
        }
      })
    }

    const handleItemChange = (e, index) => {
      const { name, value } = e.target
    
      setEditTimelineData((prevTimelineData) => {
        const updatedTasks = [...prevTimelineData.tasks]
        updatedTasks[index] = {
          ...updatedTasks[index],
          [name]: value,
        }
    
        return {
          ...prevTimelineData,
          tasks: updatedTasks,
        }
      })
    }

    const handleAddItem = () => {
      const tempId = Date.now().toString()
  
      setEditTimelineData((prevTimelineData) => ({
        ...prevTimelineData,
        tasks: [
          ...prevTimelineData.tasks,
          {
            item: '',
            striked: false,
            tempId,
          },
        ],
      }))
    }

    const handleTitleChange = e => {
        setTitle(e.target.value)
    }

    const handleStatusChange = e => {
        setStatus(e.target.value)
    }

    const closeModalClick = async e => {
        if (modalRef.current === e.target) {
            setShowEditTimelineModal(false)
        }
    }

    let renderAddInputs

    if (editTimelineData?.tasks?.length > 0) {
      renderAddInputs = editTimelineData?.tasks?.map((task, index) => {
        return (
          <React.Fragment key={task._id || task.tempId}>
            <ItemTextBoxContainer>
              <ItemTextBox
                name='item'
                value={task.item || ''}
                type='text'
                placeholder='Item'
                onChange={(e) => handleItemChange(e, index)}
              />
              <AddItem type='button' style={{ color: 'red' }} onClick={() => handleRemoveTask(index)}>
                Delete
              </AddItem>
              {index + 1 === editTimelineData.tasks.length ? (
                <AddItem type='button' onClick={() => handleAddItem()}>
                  Add
                </AddItem>
              ) : null}
            </ItemTextBoxContainer>
          </React.Fragment>
        )
      })
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModalClick}>
                <Modal>
                    <Header>
                        <HeaderText>
                            Edit Timeline
                        </HeaderText>
                        <IoCloseSharp size={25} style={{ cursor: 'pointer' }} onClick={() => setShowEditTimelineModal(false)} />
                    </Header>
                    <Body style={{ padding: 10 }}>
                        <BudgetSection >
                            <div>Item</div>
                            <AddInfo style={{ width: 175 }} defaultValue={timelineData?.title} name='title' type='text' onChange={handleTitleChange} required />
                            <CurrentStatus name='status' defaultValue={timelineData?.status} onChange={handleStatusChange}>
                                <option value='Awaiting'>Awaiting</option>
                                <option value='In Progress'>In Progress</option>
                                <option value='Completed'>Completed</option>
                                <option value='Paused'>Paused</option>
                            </CurrentStatus>
                        </BudgetSection>
                        {renderAddInputs}
                        <AddButton style={{ marginTop: 10 }} onClick={handleSubmit} >Update Timeline</AddButton>
                        <DeleteButton onClick={handleDelete} >Delete Section</DeleteButton>
                        <CancelButton style={{ marginTop: 5 }} onClick={() => setShowEditTimelineModal(false)} >Cancel</CancelButton>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default EditTimelineModal