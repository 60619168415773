import styled from 'styled-components'

export const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
`

export const LoginHeader = styled.h1`
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
`

export const LoginInput = styled.input`
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 4px;
    font-size: 16px;
`

export const LoginButton = styled.button`
    width: 100%;
    margin-top: 20px;
    padding: 15px;
    background-color: #68B984;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-size: 16px;

    &:hover {
        background: rgba(104, 185, 132, 0.8);
    }
`

export const PasswordRecoveryText = styled.a`
    color: #28df99;
    cursor: pointer;
    font-size: 12px;
`

export const LoginWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin-bottom: 20px;
`

export const PasswordWrapper = styled.div`
    position: relative;
    width: 100%;
`

export const EyeIcon = styled.button`
    position: absolute;
    top: 62%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    background: none;
`

export const PasswordInput = styled.input`
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    font-size: 16px;
`