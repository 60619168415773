import styled from 'styled-components'
import { PrimaryColor, SecondaryColor } from '../../../../../colors'

export const LikesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 75px;
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 350px;
    width: 100%;
    overflow-y: scroll;
`

export const BodySection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    cursor: pointer;
`

export const ProfileImage = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
`

export const BodySectionTitle = styled.p`
    font-size: 12px;
    font-weight: 500;
    width: 100%;
`

export const BodySectionSubtitle = styled.p`
    font-size: 10px;
    color: gray;
    font-weight: 400;
    width: 100%;
`

export const FollowButton = styled.button`
    display: flex;
    justify-content: center;
    white-space: nowrap;
    width: 80px;
    padding: 8px 22px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;

    &.unfollow {
        background: #eee;
        color: black;
        font-weight: 700;

        &:hover {
            background: lightgray;
        }
    }

    &.follow {
        background: ${SecondaryColor};

        &:hover {
            background: rgba(0, 0, 0, 0.8);
        }
    }
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;
`

export const ProfilePic = styled.img`
    width: 40px;
    height: 40px;
    /* border-radius: 50%; */
    object-fit: cover;
`

export const HoverDiv = styled.div`
    background: red;
`

export const UserModalDiv = styled.div`
    position: fixed;
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    height: 350px;
    width: 350px;
    z-index: 500;
    cursor: default;
    opacity: ${props => props.active ? '1' : '0'};
    pointer-events: ${props => props.active ? 'auto' : 'none'};
    transition: opacity 0.2s ease-in-out;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.4);
`

export const UserModalHeader = styled.div`
    padding: 20px;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const UserModalInfoContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
    /* width: 100%; */
`

export const UserModalDescription = styled.div`
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    /* width: 100%; */
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to display */
    -webkit-box-orient: vertical; 
    overflow: hidden;
`

export const UserModalBody = styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 100%;
    padding: 20px;
`

export const InvestText = styled.p`
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    color: black;
    margin-bottom: 10px;
`

export const InvestBar = styled.div`
    border-bottom: 1px solid ${SecondaryColor};
    width: 100%;
`

export const ProjectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 50px;
    margin-top: 15px;
`

export const ProjectInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;


`

export const ProjectImageContainer = styled.div`
    width: 50px;
    height: 50px;
    cursor: pointer;
`

export const ProjectImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
`

export const ProjectTitle = styled.div`
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    color: black;
`

export const SeeAllButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: ${SecondaryColor};
    margin-top: 22%;
    margin-bottom: 10px;
    padding: 5px 10px;

    &:hover {
        background: #F8F6F4;
    }
`

export const UserModalFollowButton = styled.button`
    margin-top: 20px;
    background: ${PrimaryColor};
    white-space: nowrap;
    padding: 8px 22px;
    color: #fff;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
        background: rgba(104, 185, 132, 0.8);
    }
`

export const ImageError = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
`