import styled from 'styled-components'

export const Wrapper = styled.section`
    position: relative;
    width: 100vw;
    height: 56.25vw;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
`

export const Logo = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 25px;
    color: white;
    z-index: 2;
`

export const Login = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: white;
    z-index: 2;
`

export const Title = styled.div`
    font-size: 40px;
    color: white;
    z-index: 2;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 950px) {
        display: none;
    }
`

export const CreatedBy = styled.div`
    font-size: 15px;
    color: white;
    z-index: 2;
    font-weight: 500;

    @media screen and (max-width: 950px) {
        display: none;
    }
`

export const SubtitleBar = styled.div`
    margin-top: 5px;
    font-size: 13px;
    color: #D8D9DA;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 950px) {
        display: none;
    }
`

export const Logline = styled.div`
    text-align: center;
    font-family: 'DM Sans', serif;
    color: white;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 300;
    width: 450px;
    z-index: 3;

    @media screen and (max-width: 950px) {
        display: none;
    }
`

export const VideoFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

export const VideoWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 10px;
`

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &:after {
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: rgba(0,0,0, .30);
        top: 0;
        left: 0;
        display: block;
        content: "";
    }
`

export const ImageWrapper = styled.img`
    width: 100vw;
    height: 100%;
    object-fit: cover;
    overflow-y: hidden;
`

export const ButtonWrapper = styled.div`
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: transparent;
    border: 1px solid white;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }
`

export const FlexCenterWrapper = styled.div`
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    color: white;
    font-size: 21px;

    @media screen and (max-width: 950px) {
        top: 40%;
    }

    @media screen and (max-width: 768px) {
        top: 40%;
    }

    @media screen and (max-width: 500px) {
        top: 25%;
    }
`

export const EditButton = styled.button`
    margin-top: 20px;
    height: 40px;
    width: 100px;
    border: none;
    background: transparent;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    background: rgba(255,255,255,0.2);

    &:hover {
        background: rgba(255,255,255,0.3);
    }
`

