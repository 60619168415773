import React, { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { 
    Modal,
    SectionContainer,
    Section 
} from '../UserProfileModal/UserProfileElements'

import { AiFillVideoCamera } from 'react-icons/ai'
import { PiUserSquare } from 'react-icons/pi'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'

const DiscoverModal = forwardRef((props, ref) => {
    const navigate = useNavigate()

    const handleArtistsClick = () => {
        navigate(`/discover-artists`)
    }

    const handleProjectsClick = async () => {
        navigate(`/discover`)
    }

    const handleInvestorsClick = () => {
        navigate(`/discover-investors`)
    }

    return (
        <Modal ref={ref} style={{ top: 30, left: 10 }}>
            <SectionContainer>
                <Section 
                    style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}
                    onClick={handleProjectsClick}
                >
                    <AiFillVideoCamera style={{ marginRight: '10px' }} size={20} />
                    Discover Projects
                </Section>
                <Section 
                    onClick={handleArtistsClick}
                >
                    <PiUserSquare style={{ marginRight: '10px' }} size={20} />
                    Discover Artists
                </Section>
                <Section
                    style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}
                    onClick={handleInvestorsClick}
                >
                   <RiMoneyDollarCircleFill style={{ marginRight: '10px' }} size={20} />
                    Discover Top Investors
                </Section>
            </SectionContainer>
        </Modal>
    )
})

DiscoverModal.displayName = 'DiscoverModal'
export default DiscoverModal