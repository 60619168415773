import React, { useState, useContext } from 'react'
import SearchContext from '../SearchContext'

import {
    Wrapper,
    DiscoverTypeWrapper,
    DiscoverType,
    DiscoverTypeText,
    DiscoverTypeSubText,
    SearchWrapper,
    SearchContainer,
    SearchBar,
} from '../DiscoverElements'

import Funding from './Funding'
import GaugingInterest from './GaugingInterest'
import Licensing from './Licensing'
import RevenueShare from './RevenueShare'

import { GoSearch } from 'react-icons/go'

const DiscoverFilterSection = ({ 
    projects, 
    filteredProjects, 
    setFilteredProjects, 
    searchedProjects, 
    setSearchedProjects,
    selected,
    setSelected,
}) => {
    const [searchTerm, setSearchTerm] = useState('')

    const { 
        setPage,
        setGenreOneSelect,
        setDurationSelect,
        setLicensingCostSelect,
        setGenreTwoSelect,
        setBudgetSelect,
        setSortSelect 
    } = useContext(SearchContext)

    const handleSelectionChange = (selection) => {
        setGenreOneSelect('Any Genre')
        setGenreTwoSelect('Any Genre')
        setBudgetSelect('Any budget')
        setDurationSelect('Any duration')
        setLicensingCostSelect('Any licensing cost')
        setSearchTerm('')
        setPage(1)

        if (selection === 'revenue-share') 
            setSortSelect('Most Votes')
        else 
            setSortSelect('Popularity')

        setSelected(selection)
    }

    return (
        <Wrapper>
            <DiscoverTypeWrapper>
                <DiscoverType 
                    onClick={() => handleSelectionChange('funding')} 
                    isSelected={selected === 'funding'}
                >
                    <DiscoverTypeText isSelected={selected === 'funding'}>Funding</DiscoverTypeText>
                    <DiscoverTypeSubText>New films looking for funding</DiscoverTypeSubText>
                </DiscoverType>
                <DiscoverType
                    onClick={() => handleSelectionChange('gauging-interest')}
                    isSelected={selected === 'gauging-interest'}
                >
                    <DiscoverTypeText isSelected={selected === 'gauging-interest'}>Gauging Interest</DiscoverTypeText>
                    <DiscoverTypeSubText>Testing demand for new film projects</DiscoverTypeSubText>
                </DiscoverType>
                <DiscoverType 
                    onClick={() => handleSelectionChange('licensing')}
                    isSelected={selected === 'licensing'}
                >
                    <DiscoverTypeText isSelected={selected === 'licensing'}>Licensing</DiscoverTypeText>
                    <DiscoverTypeSubText>films available for streaming rights acquisition</DiscoverTypeSubText>
                </DiscoverType>
                <DiscoverType 
                    onClick={() => handleSelectionChange('revenue-share')}
                    isSelected={selected === 'revenue-share'}
                >
                    <DiscoverTypeText isSelected={selected === 'revenue-share'}>Revenue Share</DiscoverTypeText>
                    <DiscoverTypeSubText>films offering profit-sharing</DiscoverTypeSubText>
                </DiscoverType>
            </DiscoverTypeWrapper>
            {selected === 'funding' ?
                <Funding 
                    selected={selected} 
                    setSelected={setSelected} 
                    projects={projects} 
                    searchedProjects={searchedProjects}
                    setSearchedProjects={setSearchedProjects}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            :
            selected === 'gauging-interest' ?
                <GaugingInterest 
                    selected={selected} 
                    setSelected={setSelected} 
                    projects={projects} 
                    searchedProjects={searchedProjects}
                    setSearchedProjects={setSearchedProjects}
                    searchTerm={searchTerm}
                />
            : 
            selected === 'licensing' ?
                <Licensing 
                    selected={selected} 
                    setSelected={setSelected} 
                    setSearchedProjects={setSearchedProjects}
                    searchTerm={searchTerm}
                />
            :
                <RevenueShare 
                    selected={selected} 
                    setSelected={setSelected} 
                    setSearchedProjects={setSearchedProjects} 
                    searchTerm={searchTerm}
                />
            }
            <SearchWrapper>
                <SearchContainer>
                    <GoSearch size={20} color='#9E9E9E' style={{ position: 'absolute', left: '10px' }} />
                    <SearchBar 
                        placeholder='Search' 
                        onChange={e => setSearchTerm(e.target.value)} 
                    />
                </SearchContainer>
            </SearchWrapper>
        </Wrapper>
    )
}

export default DiscoverFilterSection