import styled from 'styled-components'

export const Card = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
`

export const HeaderContainer = styled.div`
    display: flex;
`

export const PhotoContainer = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
    border-radius: 50%;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const Photo = styled.img`
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;
`

export const Name = styled.div`

`

export const Role = styled.div`
    margin-left: 10px;
    color: #B2B2B2;
`

export const Details = styled.div`
    margin-top: 20px;
    font-size: 13px;
    color: #181823;
`