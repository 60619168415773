import React from 'react'
import DOMPurify from 'dompurify'
import { 
    InputLabel,
    InputWrapper,
    InputContainer,
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'
import {
    LoglineInput,
    IframeWrapper 
} from '../../CreateProjectElements'

import { FaRegWindowClose } from 'react-icons/fa'

// Allowed embed domains for security
const ALLOWED_DOMAINS = [
  'youtube.com',
  'youtube-nocookie.com',
  'player.vimeo.com',
  'vimeo.com',
  'dailymotion.com',
  // Add other trusted domains as needed
]

function InputFields({ 
    isInvalid, 
    setIsInvalid, 
    videoEmbed, 
    setVideoEmbed,
    validationError,
    setValidationError 
}) {

    const resetEmbed = () => {
        setVideoEmbed('')
    }

    // Validate embed code
    const validateEmbed = (embedCode) => {
        try {
            // Create a DOMParser to parse the embed code
            const parser = new DOMParser()
            const doc = parser.parseFromString(embedCode, 'text/html')
            const iframe = doc.querySelector('iframe')

            if (!iframe) {
                throw new Error('Invalid embed code: Must contain an iframe')
            }

            // Get the src URL
            const src = iframe.getAttribute('src')
            if (!src) {
                throw new Error('Invalid embed code: Missing src attribute')
            }

            // Check if URL is from allowed domains
            const url = new URL(src);
            const isDomainAllowed = ALLOWED_DOMAINS.some(domain => 
                url.hostname.endsWith(domain)
            )

            if (!isDomainAllowed) {
                throw new Error('Invalid embed code: Domain not allowed')
            }

            // Validate required iframe attributes
            if (!iframe.getAttribute('width') || !iframe.getAttribute('height')) {
                throw new Error('Invalid embed code: Missing dimensions')
            }

            return true
        } catch (error) {
            setValidationError(error.message)
            return false
        }
    }

    // Enhanced sanitization configuration
    const sanitizeConfig = {
        ALLOWED_TAGS: ['iframe'],
        ALLOWED_ATTR: ['src', 'width', 'height', 'frameborder', 'allowfullscreen'],
        ALLOW_DATA_ATTR: false,
        ADD_TAGS: ['iframe'],
        SANITIZE_DOM: true,
        WHOLE_DOCUMENT: false,
        // Custom hook to validate URLs
        CUSTOM_ELEMENT_HANDLING: {
            tagNameCheck: (tagName) => tagName === 'iframe',
            attributeNameCheck: (attr) => ['src', 'width', 'height', 'frameborder', 'allowfullscreen'].includes(attr),
            allowCustomizedBuiltInElements: false,
        }
    }

    const handleInputChange = (e) => {
        const newValue = e.target.value
        
        if (newValue) {
            if (validateEmbed(newValue)) {
                const sanitized = DOMPurify.sanitize(newValue, sanitizeConfig)
                setVideoEmbed(sanitized)
                setIsInvalid(false)
                setValidationError('')
            } else {
                setIsInvalid(true)
                setVideoEmbed(newValue)
            }
        } else {
            setVideoEmbed('')
            setIsInvalid(false)
            setValidationError('')
        }
    }

    return (
        <InputContainer>
            <InputWrapper>
                {(!videoEmbed || validationError) && (
                    <>
                        <InputLabel isInvalid={isInvalid}>Video Embed Link</InputLabel>
                        <LoglineInput
                            isInvalid={isInvalid}
                            placeholder="Enter embed video link here"
                            value={videoEmbed}
                            onChange={handleInputChange}
                        />
                        {validationError && (
                            <div style={{ color: 'red' }} >
                                {validationError}
                            </div>
                        )}
                    </>
                )}
                {videoEmbed && !validationError && (
                    <>
                        <FaRegWindowClose onClick={resetEmbed} color='red' size={30} style={{ cursor: 'pointer' }} />
                        <IframeWrapper 
                            dangerouslySetInnerHTML={{ 
                                __html: DOMPurify.sanitize(videoEmbed, sanitizeConfig) 
                            }} 
                        />
                    </>
                )}
            </InputWrapper>
        </InputContainer>
    )
}

export default InputFields