import React, { useState } from 'react'
import { AWS_URL } from '../../../../config'

import { formatReadableDate } from '../../../../helpers/readableDate'

import { 
    CreatorContainer,
    Icon,
    IconImage,
    Name,
    DateUploaded,
    CreatorType
} from './UpdatesElements'

import { ImageError } from './LikesModal/LikesModalElements'

import { FaUser } from 'react-icons/fa'

const CreatorComponent = ({ update, handleShowUpdateModal, index, projectInfo }) => {
    const [imageError, setImageError] = useState(false)

    return (
        <CreatorContainer onClick={() => handleShowUpdateModal(index)} >
            {update.user.profilePicture && !imageError ?
            <Icon>
                <IconImage src={`${AWS_URL}${update.user.profilePicture}`} onError={() => setImageError(true)} />
            </Icon>
            :
            <ImageError>
                <FaUser />
            </ImageError>
            }
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
                <Name>{update.user.displayName}</Name>
                <DateUploaded>published on {formatReadableDate(update?.timeCreated)}</DateUploaded>
            </div>
            <div style={{ display: 'flex' }}>
                <CreatorType>{update.user.artist === projectInfo.project.artist._id ? 'Creator' : 'Collaborator'}</CreatorType>
            </div>
            </div>
        </CreatorContainer>
    )
}

export default CreatorComponent