export const optionInitialState = {
    store: [
        { show: true, label: 'STORY' },
        { show: false, label: 'MEDIA' },
        { show: false, label: 'DETAILS' },
        { show: false, label: 'STAGE' },
        { show: false, label: 'INFO' },
        { show: false, label: 'PERKS' },
        { show: false, label: 'FAQ' },
        { show: false, label: 'DISCUSSION' },
        { show: false, label: 'UPDATES' }
    ]
}

export const optionReducer = (state, action) => {
    if (action.type === 'select') {
        state.store.forEach(selected => {
            if (selected.label === action.payload) {
                selected.show = true
            } else {
                selected.show = false
            }
        })
        return {
            store: [...state.store]
        }
    } 
}