import styled from 'styled-components'

export const DesktopContainer = styled.div`
    @media (max-width: 760px) {
        display: none;
    }
`

//MOBILE
export const MobileContainer = styled.div`
    padding-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: 760px) {
        display: none;
    }
`

export const Back = styled.div`
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    font-family: 'DM sans', sans-serif;
    color: grey;

    &:hover {
        text-decoration: underline;
    }
`

export const NoInfo = styled.div`
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
`