import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import {
    DiscoverArtistsContainer,
    ArtistsContainer,
    ArtistsWrapper
} from './DiscoverArtistElements'
import ArtistCard from './ArtistCard'
import FilterSection from './FilterSection'
import Pagination from '../Discover/Pagination'
import LoadingComponent from '../LoadingAsset'
import { NoProjects } from '../Discover/DiscoverElements'

const DiscoverArtistsComponent = ({ initialArtists }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [searchedArtists, setSearchedArtists] = useState(initialArtists)
    const [searchTerm, setSearchTerm] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        totalPages: 0,
        totalArtists: 0
    })

    useEffect(() => {
        fetchArtists('', 1, pagination.limit)
    }, [pagination.limit])

    const handleSearchChange = async (e) => {
        const newSearchTerm = e.target.value.toLowerCase()
        setSearchTerm(newSearchTerm)
        fetchArtists(newSearchTerm, 1, pagination.limit) // Reset to page 1 on new search
    }

    const fetchArtists = async (searchTerm, page, limit) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/discover-artist/artists`, {
                params: { search: searchTerm, page, limit }
            })
            const data = response.data
            setSearchedArtists(data.artists)
            setPagination(data.pagination)
        } catch (error) {
            console.error('Error fetching artists:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handlePageChange = (newPage) => {
        fetchArtists(searchTerm, newPage, pagination.limit)
    }

    const artistMapping = searchedArtists?.map((artist) => {
        return (
            <div key={artist._id}>
                <ArtistCard artist={artist} />
            </div>
        )
    })

    return (
        <DiscoverArtistsContainer>
            <h1>Discover Artists</h1>
            <FilterSection handleSearchChange={handleSearchChange} />
            {isLoading ? (
                <div style={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LoadingComponent />
                </div>
            )
            : artistMapping?.length > 0 ?
            (
                <>
                    <ArtistsWrapper>
                        <ArtistsContainer>
                            {artistMapping}
                        </ArtistsContainer>
                    </ArtistsWrapper>
                    <Pagination 
                        currentPage={pagination.page}
                        totalPages={pagination.totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            )
            : 
            (
                <div style={{ width: '100%', marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                    <NoProjects style={{ }} >
                        There are currently no artists that fit this description.
                    </NoProjects>
                </div>
            )
            }
        </DiscoverArtistsContainer>
    )
}

export default DiscoverArtistsComponent