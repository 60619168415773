import React, { useCallback } from 'react'
import { DATABASE, CHANGE } from '../../../../../constants'
import TeamCard from '../TeamCard'
import TeamMemberCard from './TeamMemberCard'

import { 
    HeaderWrapper, 
    NoInfo,
} from '../ProjectDetailElements'

import { EditButton } from '../../Perks/PerkElements'
import { AiFillEdit } from 'react-icons/ai'

const Team = ({ 
    responseTeamArray, 
    handleSubmit, 
    setResponseTeamArray, 
    project, 
    focusedIndex, 
    setFocusedIndex, 
    teamMembers, 
    imageFiles, 
    setImageFiles, 
    setTeamMembers, 
    setCurrentPage, 
    setItemsToShow, 
    showImageModal, 
    setShowImageModal, 
    showEditModal, 
    setShowEditModal, 
    editTeam, 
    setEditTeam, 
    deleteArray, 
    setDeleteArray, 
    imageDeleteArray, 
    setImageDeleteArray,
    loggedInUser
}) => {

    const handleEditClick = () => {
        if (!editTeam) {
            setEditTeam(true)
    
            if (project.team.length > 0) {
                setTeamMembers(
                    project.team.map((obj, index) => ({
                        ...obj,
                        imageFile: [],
                        status: DATABASE,
                        index: index,
                    })),
                )
            }
        } else {
            setEditTeam(false)
        }
    }

    const handleImageSelected = useCallback((file, index) => {
        setFocusedIndex(index)
        setShowImageModal(true)
        setTeamMembers(prevTeamMembers => prevTeamMembers.map((member, i) =>
          i === index ? {
            ...member,
            imageFile: [file].map(f => Object.assign(f, { preview: URL.createObjectURL(f) })),
            previewFileName: file.path,
          } : member
        ))
      
        if (teamMembers[index].status === CHANGE) {
          setImageFiles(prevImageFiles => {
            const updatedImageFiles = [...prevImageFiles]
            const existingIndex = updatedImageFiles.findIndex(imageFile => imageFile.path === teamMembers[index].imageFileName)
            if (existingIndex !== -1) {
              updatedImageFiles[existingIndex] = file
            } else {
              updatedImageFiles.push(file)
            }
            return updatedImageFiles
          })
        } else {
          setImageFiles(prevImageFiles => [...prevImageFiles, file])
        }
      }, [setFocusedIndex, setImageFiles, setShowImageModal, setTeamMembers, teamMembers])

    let teamMemberAddMapping

    if (project) {
        teamMemberAddMapping = teamMembers.map((member, index) => {
            return (
                <div key={index}>
                    <TeamMemberCard 
                        project={project}
                        editTeam={editTeam}
                        teamMembers={teamMembers}
                        setTeamMembers={setTeamMembers}
                        member={member}
                        index={index}
                        deleteArray={deleteArray}
                        setDeleteArray={setDeleteArray}
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles}
                        handleImageSelected={handleImageSelected}
                    />
                </div>
            )
        })
    }

    const teamMemberMapping = teamMembers?.map((member, index) => {
        return (
            //MUST CHANGE KEY
            <div key={index}>
                <TeamCard 
                    project={project} 
                    imageFileName={member.imageFileName} 
                    name={member.name} 
                    role={member.role} 
                    description={member.description} 
                />
            </div>
        )
    })

    const renderTeam = editTeam
        ? teamMemberAddMapping
        : project && project.team.length > 0
        ? teamMemberMapping
        : <NoInfo>No team members have been added yet.</NoInfo>

    return (
        <div>
            <HeaderWrapper style={{ display: 'flex' }}>
                {(loggedInUser?.artist === project?.artist._id || project?.authorizedCollaborators?.includes(loggedInUser?.id)) ? (
                    <EditButton onClick={handleEditClick}>
                        <AiFillEdit size={20} style={{ marginRight: 3 }} />
                        {!editTeam ? 'Edit Team' : 'Cancel'}
                    </EditButton>
                ) : ''}
                {editTeam ? <EditButton style={{ marginLeft: 5 }} onClick={handleSubmit}>Save and Publish</EditButton> : null}
            </HeaderWrapper>
            {renderTeam}
        </div>
    )
}

export default React.memo(Team)