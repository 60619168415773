import React from 'react'
import { useNavigate } from 'react-router-dom'
import truncate from 'html-truncate'
import { formatReadableDate } from '../../helpers/readableDate'

import { 
    PostWrapper,
    PostContainer,
    TextContainer,
    Header,
    AuthorContainer,
    AuthorPhoto,
    AuthorName,
    PostCreationDate,
    CoverPhoto,
    ContentBlock,
    Tag,
    Underline
} from './PostElements' 

const PostThumbnail = ({ isMobile, post, index, totalPosts }) => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(`${post?.slug}`)
    }

    return (
        <PostWrapper>
            <PostContainer>
                <TextContainer onClick={handleNavigate}>
                    <Header>{post?.title}</Header>
                    <ContentBlock dangerouslySetInnerHTML={{ __html: truncate(post?.content[0]?.value, 175) }} />
                    <AuthorContainer>
                        <AuthorPhoto src={post?.author?.image} alt='Author Profile Photo'/>
                        <AuthorName>{post?.author?.firstName} {post?.author?.lastName}</AuthorName>
                        <PostCreationDate>{formatReadableDate(post?.createdAt)}</PostCreationDate>
                    </AuthorContainer>
                    <Tag 
                        style={{
                            ...(post?.tags === 'Risks' ? { background: '#ffe6e6', color: 'red' } : { background: '#eee', color: 'grey' }),
                            ...(isMobile ? { marginBottom: 10 } : {})
                        }}
                        >
                        {post?.tags}
                    </Tag>
                </TextContainer>
                <div style={{ width: '60%' }} onClick={handleNavigate}>
                    <CoverPhoto src={post?.coverPhoto} alt="Cover" />
                </div>
            </PostContainer>
            {index + 1 !== totalPosts && <Underline />}
        </PostWrapper>
    )
}

export default PostThumbnail