import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getPosts } from './PostsApi'
import { useMediaQuery } from 'react-responsive'
import LoadingComponent from '../LoadingAsset'
import TagModal from './TagModal'

import { 
  Wrapper, 
  PostsWrapper, 
  BlogTitle,
  BlogSubtitle,
  HeaderBar, 
  HeaderButton, 
  LoadMoreButton,
  RotateIcon
} from './PostElements'

import PostThumbnail from './PostThumbnail'

const PostsComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const initialTab = queryParams.get('tab') || 'ALL'

  const [selectedTab, setSelectedTab] = useState(initialTab)
  const [selectedTag, setSelectedTag] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [posts, setPosts] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const listRef = useRef(null)
  const scrollPositionRef = useRef(0)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    navigate(`?tab=${selectedTab}&tag=${selectedTag || ''}&page=${currentPage}`)
  }, [selectedTab, selectedTag, currentPage, navigate])

  const { data, error, isLoading } = useQuery({
    queryKey: ['posts', selectedTab, selectedTag, currentPage],
    queryFn: () => getPosts({ tab: selectedTab, tag: selectedTag, page: currentPage, limit: 2 }),
    keepPreviousData: true,
  })

  useEffect(() => {
    if (data && data.posts) {
      setIsFetchingMore(false)
      if (currentPage === 1) {
        setPosts(data.posts)
      } else {
        setPosts((prevPosts) => [...prevPosts, ...data.posts])
      }
      setHasMore(data.posts.length > 0 && data.currentPage !== data.totalPages)
      window.scrollTo(0, scrollPositionRef.current)
    }
  }, [data, currentPage])

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
    setSelectedTag(null) // Reset selected tag when tab changes
    setCurrentPage(1)
    setPosts([])
    setHasMore(true)
  }

  const loadMorePosts = () => {
    scrollPositionRef.current = window.pageYOffset
    setIsFetchingMore(true)
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSelectTag = (tag) => {
    setSelectedTag(tag)
    setCurrentPage(1)
    setPosts([])
    setHasMore(true)
    setIsModalOpen(false)
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <Wrapper>
      <PostsWrapper ref={listRef}>
        <BlogTitle>muugen blog</BlogTitle>
        <BlogSubtitle>Discover insights, tips, and updates on film funding, investor education, and creator resources on the muugen blog.</BlogSubtitle>
        <HeaderBar>
          {['ALL', 'BLOG', 'INVESTOR EDUCATION', 'CREATOR EDUCATION'].map(tab => (
              <HeaderButton
                  key={tab}
                  onClick={() => handleTabChange(tab)}
                  isSelected={selectedTab === tab}
              >
                  {tab}
              </HeaderButton>
          ))}
          <HeaderButton
              onClick={handleOpenModal}
              style={!isModalOpen ? { marginLeft: isMobile ? '0' : 'auto', display: 'flex', alignItems: 'center' } : { marginLeft: isMobile ? '0' : 'auto', display: 'flex', alignItems: 'center', color: 'black' }}
          >
              <RotateIcon 
                size={18} 
                style={{
                  transform: isModalOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out'
                }}
              />
              {isMobile ? 'TAG' : 'SORT BY TAG'}
          </HeaderButton>
          <TagModal 
              tab={selectedTab}
              isOpen={isModalOpen} 
              onClose={handleCloseModal} 
              onSelectTag={handleSelectTag}
              selectedTag={selectedTag}
          />
        </HeaderBar>
        {isLoading && currentPage === 1 && 
          <div>
            <LoadingComponent />
          </div>}
        {!isLoading && posts.map((post, index) => (
          <PostThumbnail 
            isMobile={isMobile}
            key={post._id} 
            post={post} 
            index={index} 
            totalPosts={posts?.length} 
          />
        ))}
        {hasMore && !isLoading && !isFetchingMore && (
          <LoadMoreButton onClick={loadMorePosts}>Load more posts</LoadMoreButton>
        )}
        {isFetchingMore && (
          <div>
            <LoadingComponent />
          </div>
        )}
      </PostsWrapper>
    </Wrapper>
  )
}

export default PostsComponent