import React, { useState } from 'react'

import {
    Wrapper,
    AdminContainer,
    ModalButton,
    Card,
    Title,
    Name,
    Email,
    Roles,
    Image
} from './AdminElements'

import AdminModal from './Modal'
import RoleModal from './RoleModal'
import EditModal from './EditModal'

const AdminComponent = ({ users, setUsers }) => {
    const [showModal, setShowModal] = useState(false)
    const [showRoleModal, setShowRoleModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [clickedUser, setClickedUser] = useState({})

    const handleEditModalClick = (user) => {
        setClickedUser(user)
        setShowEditModal(true)
    }

    const adminMapping = users.map(user => {
        return (
            <Card key={user?._id} onClick={() => handleEditModalClick(user)}>
                {user?.image && <Image src={user?.image}/>}
                <Title>{user?.adminType}</Title>
                <Name>{user?.firstName} {user?.lastName}</Name>
                <Email>{user?.email}</Email>
                <Name>Permissions</Name>
                <Roles>
                    {user?.role?.permissions.map(permission => {
                        return <p key={permission} >{permission}</p>
                    })}
                </Roles>
            </Card>
        )
    })

    return (
        <Wrapper>
            {showModal && 
                <AdminModal 
                    users={users} 
                    setUsers={setUsers} 
                    showModal={showModal} 
                    setShowModal={setShowModal}
                />
            }
            {showRoleModal &&
                <RoleModal 
                    showRoleModal={showRoleModal} 
                    setShowRoleModal={setShowRoleModal}
                />
            }
            {showEditModal && 
                <EditModal 
                    showEditModal={showEditModal} 
                    setShowEditModal={setShowEditModal}
                    user={clickedUser}
                />
            }
            <h1>Admin Dashboard</h1>
            <div style={{ display: 'flex', gap: '5px' }}>
                <ModalButton onClick={() => setShowModal(true)}>Add Admin</ModalButton>
                <ModalButton onClick={() => setShowRoleModal(true)} >Add Role</ModalButton>
            </div>
            <AdminContainer>
                {adminMapping}
            </AdminContainer>
        </Wrapper>
    )
}

export default AdminComponent