import React, { useState } from 'react'
import { AWS_URL } from '../../../config'
import { useNavigate } from 'react-router-dom'

import {
    UnfollowButton,
    FollowContainer,
    ProfilePicture,
    NoImageWrapper,
    Name,
    Description,
} from './FollowerElements'

import { FaUser } from 'react-icons/fa'

const ArtistFollowCard = ({ follow, index }) => {
    const [imageErrorFollowing, setImageErrorFollowing] = useState(false)
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(`/artist-profile/${follow.slug}`)
    }

    const handleImageError = (setImageError) => {
        setImageError(true)
    }

    const truncatedMiniBio = follow?.artist?.artistMiniBio && 
        follow?.artist?.artistMiniBio?.length > 150
        ? `${follow?.artist?.artistMiniBio?.substring(0, 100)}...`
        : follow?.artist?.artistMiniBio

    const imageUrl = `${AWS_URL}artists/${follow?.slug}/profile-picture/${follow?.artist?.artistProfileImage}`

    return (
        <FollowContainer style={index > 0 ? { marginLeft: 10 } : {}} onClick={handleNavigate}>
            {!imageErrorFollowing ?
                <ProfilePicture src={imageUrl} alt={follow.artist.artistName} onError={() => handleImageError(setImageErrorFollowing)}/>
            :
                <NoImageWrapper>
                    <FaUser size={50} color='white' />
                </NoImageWrapper>
            }
            <Name>{follow.artist.artistName}</Name>
            <Description>{truncatedMiniBio}</Description>
            <UnfollowButton>Unfollow</UnfollowButton>
        </FollowContainer>
    )
}

export default ArtistFollowCard