import React from 'react'
import { getCurrentInfoValue } from '../helpers/reducerHelper'
import { isFieldInvalid } from '../helpers/isFieldInvalid'

import { RadioButton, RadioWrapper, RadioLabel, AccTitle } from '../ProjectInvestElements'

function FinancialnfoAcc ({ state, dispatch }) {
    return (
        <>
            <AccTitle isInvalid={isFieldInvalid('Accreditor Type', state)} >What qualifies you as an accredited investor? (Select One)</AccTitle>
            <RadioWrapper onClick={() => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'Investments', label: 'Accreditor Type' } })} >
                <RadioButton
                    type='radio' 
                    name='AccreditorType'
                    value='Investments'
                    checked={getCurrentInfoValue('Accreditor Type', state) === '5_MIL_INVESTMENTS'}
                    onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '5_MIL_INVESTMENTS', label: 'Accreditor Type' } })}
                />
                <RadioLabel isInvalid={isFieldInvalid('Accreditor Type', state)}>I have at least $5 million in investments</RadioLabel>
            </RadioWrapper>
            <RadioWrapper onClick={() => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'NetWorth', label: 'Accreditor Type' } })}>
                <RadioButton
                    type='radio' 
                    name='AccreditorType' 
                    value='NetWorth'
                    checked={getCurrentInfoValue('Accreditor Type', state) === '1_MIL_NET_WORTH'}
                    onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '1_MIL_NET_WORTH', label: 'Accreditor Type' } })}
                />
                <RadioLabel isInvalid={isFieldInvalid('Accreditor Type', state)}>I have at least $1 million in net worth</RadioLabel>
            </RadioWrapper>
            <RadioWrapper onClick={() => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'Income', label: 'Accreditor Type' } })}>
                <RadioButton
                    type='radio' 
                    name='AccreditorType' 
                    value='Income' 
                    checked={getCurrentInfoValue('Accreditor Type', state) === '200K_INCOME'}
                    onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '200K_INCOME', label: 'Accreditor Type' } })}
                />
                <RadioLabel isInvalid={isFieldInvalid('Accreditor Type', state)}>I have $200k+ income for the past 2 years (or $300k joint with spouse) and expect the same this year</RadioLabel>
            </RadioWrapper>
            <RadioWrapper style={{ marginBottom: 20 }} onClick={() => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'ActiveCredential', label: 'Accreditor Type' } })}>
                <RadioButton
                    type='radio' 
                    name='AccreditorType'
                    value='ActiveCredential' 
                    checked={getCurrentInfoValue('Accreditor Type', state) === 'ACTIVE_SERIES'}
                    onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'ACTIVE_SERIES', label: 'Accreditor Type' } })}
                />
                <RadioLabel isInvalid={isFieldInvalid('Accreditor Type', state)}>I have an active Series 7, 65, or 82 that’s in good standing</RadioLabel>
            </RadioWrapper>
        </>
    )
}

export default FinancialnfoAcc