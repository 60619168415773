import styled from 'styled-components'

export const ChartContainer = styled.div`
    margin-top: 10px;
    height: 500px;
    width: 100%;
    background: #EEEEEE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const LegendContainer = styled.div`
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    position: absolute;
`

export const LegendItem = styled.div`
    display: flex;
    width: 100px;
`

export const ColorCode = styled.div`
    height: 10px;
    width: 10px;
    margin-right: 5px;
`

export const BudgetInfo = styled.div`
    position: absolute;
    top: 45%;
    left: 40%;
`

export const Title = styled.div`
    font-size: 16px;

`

export const Value = styled.div`
    font-size: 14px;
`

export const ColorContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Color = styled.div`
    height: 10px;
    width: 10px;
    margin-left: 5px;
    background: ${props => props.color};
`
