import React from 'react'
import { getCurrentInfoValue } from '../helpers/reducerHelper'
import { isFieldInvalid } from '../helpers/isFieldInvalid'

import { 
    InputWrapper, 
    MoneyInputWrapper,
    MoneyInput,
    MoneySymbol,
    InputLabel
} from '../ProjectInvestElements'

function FinancialnfoAcc ({ state, handleYearlyIncomeChange, handleNetWorthChange }) {
    return (
        <>
            <InputWrapper style={{ width: '100%', marginLeft: 0 }}>
                <InputLabel isInvalid={isFieldInvalid('Yearly Income', state)} >Yearly Income</InputLabel>
                <MoneyInputWrapper style={{ minWidth: 440 }}>
                    <MoneySymbol>$</MoneySymbol>
                    <MoneyInput 
                        isInvalid={isFieldInvalid('Yearly Income', state)}
                        style={{ background: '#F5F5F5' }}
                        type='text'
                        value={getCurrentInfoValue('Yearly Income', state)}
                        onChange={handleYearlyIncomeChange}
                        onKeyPress={(e) => !/\d/.test(e.key) && e.preventDefault()}
                    />
                </MoneyInputWrapper>
            </InputWrapper>
            <InputWrapper style={{ width: '100%', marginLeft: 0 }}>
                <InputLabel isInvalid={isFieldInvalid('Net Worth', state)}>Net Worth (exluding home)</InputLabel>
                <MoneyInputWrapper style={{ marginBottom: 5, minWidth: 440 }}>
                    <MoneySymbol>$</MoneySymbol>
                    <MoneyInput 
                        isInvalid={isFieldInvalid('Net Worth', state)}
                        style={{ background: '#F5F5F5' }}
                        type='text'
                        value={getCurrentInfoValue('Net Worth', state)}
                        onChange={handleNetWorthChange}
                        onKeyPress={(e) => !/\d/.test(e.key) && e.preventDefault()}
                    />
                </MoneyInputWrapper>
                <InputLabel style={{ marginBottom: 10 }}>You may enter 0 if you don&apos;t know your net worth.</InputLabel>
            </InputWrapper>
        </>
    )
}

export default FinancialnfoAcc