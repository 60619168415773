import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 400px;
    margin-top: 20px;
    margin-bottom: 20px;
`

export const ModalBg = styled.div`
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;

    @media screen and (max-width: 700px) {
        align-items: flex-start;
        padding-top: 150px;
    }
`

export const MainVideoWrapper = styled.div`
    width: 70%;
    height: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

export const VideoTitle = styled.div`
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
`

//Grid wrapper for videos that allows for 2 videos per row
export const GridVideoWrapper = styled.div`
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

export const SmallVideoWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const TrashContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    z-index: 10;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: red;
        color: white;
    }
`

//MODAL

export const InputVideoTitle = styled.input`
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
`

export const IndexLabel = styled.label`
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    color: grey;
`

export const IndexSelect = styled.select`
    width: 40%;
    height: 40px;
    border: 1px solid lightgrey;
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
`

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  /* padding-top: 56.25%; // 16:9 aspect ratio */
  max-width: 1100px; // Set your desired max width
  margin: 0 auto; // Center the video wrapper horizontally
  aspect-ratio: 16 / 9; // Set the aspect ratio to 16:9
`;

// IFrameContainer with absolute positioning
export const IFrameContainer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`