import styled from 'styled-components'
import { PrimaryColor, PrimaryHoverColor } from '../../../../colors'

export const Wrapper = styled.div`
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
`

export const CommentWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

export const AddCommentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 25px;
`

export const AddComment = styled.textarea`
    outline: 0;
    border-width: 0 0 1px;
    border-color: gray;
    height: 25px;
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 15px;
    overflow: none;
    resize: none;
`

export const AddButton = styled.button`
    height: 40px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${PrimaryColor};
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;

    &:hover {
        background: ${PrimaryHoverColor};
    }
`

export const CancelButton = styled.button`
    height: 40px;
    width: 90px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    border-radius: 3px;
    color: grey;
    cursor: pointer;

    &:hover {
        background: #FAF7F0;
    }
`

export const Reply = styled.button`
    font-size: 13px;
    padding: 10px;
    border: none;
    background: transparent;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
        background: #FAF7F0;
    }
`

export const CommentSectionWrapper = styled.div`
    padding: 20px;
    background: #F9F9F9;
    border: 1px solid #EEEEEE;
    width: 100%;
`

export const CommentSection = styled.div`
    margin-top: 15px;
    width: 100%;
    background: white;
    border: 1px solid #EDEDED;
    padding: 10px;
    display: flex;
`

export const CreatorTab = styled.div`
    min-width: 10px;
    margin-right: 10px;
    background: #C6EBC5;
`

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const ProfilePicContainer = styled.img`
    height: 45px;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
`

export const HeaderWrapper = styled.div`
    display: flex;
    cursor: pointer;
`

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const Header = styled.div`
    margin-top: 5px;
    margin-left: 5px;
    font-size: 13px;
`

export const ProfileErrorContainer = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center; 
    width: 45px; 
    height: 45px;
    border-radius: 50%;
    background: #eee;
`

export const SubHeader = styled.div`
    color: ${PrimaryColor};
    padding: 2px;
    margin-left: 3px;
    font-size: 12px;
    font-weight: 500;
`

export const Time = styled.div`
    margin-top: 8px;
    font-size: 10px;
    margin-left: 5px;
    color: gray;
`

export const CommentsMetadata = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B2B2B2;
`

export const LoadMoreComments = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid #D8D9CF;
    margin-top: 20px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        border-color: black;
    }
`

export const ShowMoreWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 30px;
    border-radius: 5px;
    border: none;
    margin-top: 3px;
    color: #2192FF;
    font-size: 13px;
    cursor: pointer;
    background: transparent;

    &:hover {
        background: #DFF6FF;
    }
`

export const ShowMoreRepliesWrapper = styled.button`
    display: flex;
    justify-content: flex-start; 
    margin-top: 4px;
    align-items: center;
    padding: 8px;
    font-size: 12px; 
    color: #2192FF; 
    border-radius: 5px;
    border: none;
    background: transparent;
    margin-top: 5;
    cursor: pointer;

    &:hover {
        background: #DFF6FF;
    }
`
//EDIT MODAL ELEMENTS
export const EditModalWrapper = styled.div`
    background: white;
    border: 1px solid #EAEAEA;
    border-radius: 3px;
    top: 50px;
    left: 10px;
    position: absolute;
    width: 200px;
    height: 100px;
`

export const EditSection = styled.div`
    height: 50%;
    width: 100%;
    padding-left: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #FAF7F0;
    }
`