import React from 'react'
import DOMPurify from 'dompurify'
import {
  CaptionTextDisplay,
  StoryText,
  StoryHeader,
  NoOutlineImage,
  VideoFrame,
  VideoWrapper,
} from './StoryElements'

const RenderedStoryElement = ({ element }) => {
  const sanitizeOptions = {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a'],
    ALLOWED_ATTR: ['href', 'target'],
  }

  const sanitizedContent = DOMPurify.sanitize(element.content, sanitizeOptions)

  switch (element.type) {
    case 'title':
      return <StoryHeader>{element.content}</StoryHeader>

    case 'text':
      return (
        <StoryText
          dangerouslySetInnerHTML={{
            __html: sanitizedContent,
          }}
        />
      )

    case 'embed': {
      const extractSrc = (iframeString) => {
        const match = iframeString.match(/src="([^"]+)"/)
        return match ? match[1] : null
      }
      
      const iframeString = element.content
      const src = extractSrc(iframeString)
      
      return (
        <VideoWrapper>
          {src ? (
            <VideoFrame src={src} title="Embedded Video" />
          ) : (
            <StoryText></StoryText>
          )}
        </VideoWrapper>
      )
    }

    case 'image':
      return (
        <div style={{ marginBottom: 10 }}>
          <NoOutlineImage
            src={element.content}
            alt={element.caption || 'Story image'}
          />
          {element.caption && (
            <CaptionTextDisplay>{element.caption}</CaptionTextDisplay>
          )}
        </div>
      )

    default:
      return <StoryHeader></StoryHeader>
  }
}

export default RenderedStoryElement