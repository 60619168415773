import React, { useState, useEffect } from 'react'

import { 
    Wrapper,
    ModalBg,
} from '../../ProjectProfile/Modal/EditModalElements'

import { 
    InfoCardTitle,
    InvestInfo,
    ProgressBarWrapper,
    ProgressBarAmount,
    ProgressBar, 
    Progress,
    MobileRiseModal, 
    ModalDropWrapper,
    ModalDropText
} from '../ProjectInvestElements'

import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

function MobileRiseMenu({ project, showDealTermModal, setShowDealTermModal, calculateProgressWidth, addCommasToNumber }) {
    const [modalActive, setModalActive] = useState(false)
    const [equityGiven, setEquityGiven] = useState('')
    const [bonusReturn, setBonusReturn] = useState(0)
    const [projectName, setProjectName] = useState('')

    useEffect(() => {
        if (project) {
            setProjectName(project.projectName)
            setEquityGiven(project.equityGiven)
            setBonusReturn(project.bonusReturn + 100)
        }

        return () => {
            setBonusReturn(0)
        }
    }, [project])
   
    useEffect(() => {
        if (showDealTermModal) {
            setModalActive(true)
        } else {
            setTimeout(() => {
                setModalActive(false)
            }, 500)
        }
    }, [showDealTermModal])

    const handleClose = () => {
        setModalActive(false)
        setTimeout(() => {
            setShowDealTermModal(false)
        }, 300)
    }

    return (
        <Wrapper>
            <ModalBg>
                <MobileRiseModal className={modalActive ? 'active' : ''}>
                    <InfoCardTitle>Deal Terms</InfoCardTitle>
                    <ProgressBarWrapper>
                        <ProgressBarAmount>${addCommasToNumber(project?.currentFunds)}</ProgressBarAmount>
                        <ProgressBar >
                            <Progress style={{ width: calculateProgressWidth() }} />
                        </ProgressBar>
                        <ProgressBarAmount style={{ marginTop: 4, color: 'black' }}>of ${addCommasToNumber(project?.targetFundAmount)}</ProgressBarAmount>
                    </ProgressBarWrapper>
                    <InvestInfo style={{ fontSize: 25, textAlign: 'center' }}>
                        {project?.artist?.artistName} is offering <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>{equityGiven}%</strong> equity on {projectName}
                    </InvestInfo>
                    <InvestInfo style={{ marginTop: 40, fontSize: 25, textAlign: 'center' }}>
                        Investors receive <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>100%</strong> of the film&apos;s revenue until <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>{bonusReturn}%</strong> of their investment is returned after which profits are split according to the equity given.
                    </InvestInfo>
                    <ModalDropWrapper onClick={handleClose}>
                        <ModalDropText>Close</ModalDropText>
                        <MdOutlineKeyboardArrowDown size={30} />
                    </ModalDropWrapper>
                </MobileRiseModal>
            </ModalBg>
        </Wrapper>
    )
}

export default MobileRiseMenu