import React, { useRef, lazy, Suspense, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Wrapper,
    ModalBg,
    Header,
    HeaderText,
} from '../../ProjectProfile/Modal/EditModalElements'
import {
    Modal,
    Body,
    SubmitButton,
    SuccessMessage
} from '../ProjectInvestElements'
import { IoClose } from 'react-icons/io5'

const Lottie = lazy(() => import('lottie-react'))

const ReviewModal = ({ state, getCurrentInfoValue, setShowReviewModal, projectName, projectId }) => {
    const modalRef = useRef(null)
    const navigate = useNavigate(null)
    const [animationData, setAnimationData] = useState(null)

    useEffect(() => {
        import('../../ProjectProfile/Body/ExpressedInterestModal/animation.json')
            .then(module => setAnimationData(module.default))
    }, [])

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''
            setShowReviewModal(false)
        }
    }

    const closeModalExit = e => {
        document.body.style.overflow = ''
        setShowReviewModal(false)
    }

    const handleProjectProfileClick = () => {
        document.body.style.overflow = ''
        navigate(`/project-profile/${projectId}`)
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText style={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 700 }}>Review Investment</HeaderText>
                        <IoClose size={25} style={{ cursor: 'pointer' }} onClick={closeModalExit} />
                    </Header>
                    <Body>
                        <SuccessMessage>You have successfully invested <strong>${getCurrentInfoValue('Investment Amount', state)}</strong> into <strong>{projectName}</strong>! You can view your investment on your investment page.</SuccessMessage>
                        <Suspense fallback={<div>Loading...</div>}>
                            {animationData && (
                                <Lottie
                                    loop={false}
                                    animationData={animationData}
                                    style={{ width: 200, height: 200 }}
                                />
                            )}
                        </Suspense>
                        <SubmitButton onClick={handleProjectProfileClick}>Return To Project Page</SubmitButton>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default ReviewModal