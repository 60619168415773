import styled from 'styled-components'

const breakpoint = '1205px'

export const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 100%;
    /* padding-left: 40px;
    padding-right: 40px; */
    /* background: #F7F7F7; */
    /* background: rgb(252, 252, 252); */
    /* padding-bottom: 50px; */
    /*background: #fcf8e8; */

    /* @media (max-width: ${breakpoint}) {
    display: none;
  } */
`

export const CardContainer = styled.div`
    width: 100%;
    max-width: 1200px;

    @media (max-width: ${breakpoint}) {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const HeaderContainer = styled.div`
    width: 90%;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const Header = styled.div`
    font-size: 13px;
    font-weight: 700;
    color: gray;
`

export const LinkContainer = styled.div`
    width: 100%; /* ADJUST ACCORDING TO SIZE */
    display: flex;
    margin-right: 10%;
    margin-bottom: 100px;
    justify-content: flex-end;
    align-items: flex-end;
`

export const ProjectsLink = styled.a`
    font-size: 18px;
    cursor: pointer;
    color: black;

    &:hover {
        color: #ec4646;
        transform: scale(1.02);
    }
`

export const Arrow = styled.span`
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;

    transform: rotate(-45deg);
`

export const ExploreButton = styled.div`
    width: 250px;
    height: 50px;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
        background: rgba(0, 0, 0, 0.85);
    }
`