import axios from 'axios'
import { API_URL } from '../../config'

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${API_URL}/admin`)
        return response.data
    } catch (e) {
        console.log(e)
    }
}

export const addAdmin = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/admin/add-admin`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response.data
    } catch (e) {
        console.log(e)
        throw e
    }
}

//delete admin
export const deleteAdmin = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/admin/delete-admin/${id}`, { withCredentials: true })
        return response.data
    } catch (e) {
        console.log(e)
    }
}

//edit admin
export const editAdmin = async (id, formData) => {
    try {
        const response = await axios.post(`${API_URL}/admin/edit-admin/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })
        return response.data
    } catch (e) {
        alert('You do not have permission to edit this user')
        console.log(e)
    }
}

//add role
export const addRole = async (role, permissions, hierarchyLevel) => {
    try {
        const response = await axios.post(`${API_URL}/admin/add-role`, { role, permissions, hierarchyLevel }, { withCredentials: true })
        return response.data
    } catch (e) {
        console.log(e)
    }
}

//login and logout
export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/admin/login`, { email, password }, { withCredentials: true })
        return response.data
    } catch (e) {
        console.log(e)
    }
}

export const logout = async () => {
    try {
        const response = await axios.get(`${API_URL}/admin/logout`, { withCredentials: true })
        return response.data
    } catch (e) {
        console.log(e)
    }
}