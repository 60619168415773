import React, { useState, useEffect, useContext } from 'react'
import { API_URL } from '../config'
import axios from 'axios'
import { AuthContext } from '../authContext'
import NonMainNavbar from '../components/NonMainNav'

// import UserProfileBar from '../components/InvestmentProfile/UserProfileBar'
import MyInvestmentProfile from '../components/MyInvestmentProfile'

const InvestmentProfile = () => {
    const [userPage, setUserPage] = useState([])
    const [unauthorized, setUnauthorized] = useState(false)
    const { loggedInUser } = useContext(AuthContext)

    useEffect(() => {
        let isMounted = true  // Variable to track the mounted status
    
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${API_URL}/user-profile/me`, { withCredentials: true })
                if (isMounted) {  // Only update the state if the component is still mounted
                    setUserPage(response.data)
                }
            } catch (error) {
                console.error("There was an error fetching user data", error)
                if (error.response && error.response.status === 401) {
                    setUnauthorized(true)
                }
            }
        }
    
        fetchUser()
    
        return () => {
            isMounted = false  // Set to false when the component unmounts
        }
    }, [])

    return (
        <div>
            <NonMainNavbar loggedInUser={loggedInUser} />
            {unauthorized ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
                    <h2>Unauthorized</h2>
                    <p>You are not authorized to access this page.</p>
                </div>
            ) : (
                <MyInvestmentProfile userPage={userPage} setUserPage={setUserPage} />
            )}
        </div>
    )
}

export default InvestmentProfile