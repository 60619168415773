import styled from 'styled-components'
import { PrimaryColor } from '../../../../colors'

export const Body = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;

`

export const LegalInfo = styled.p`
    font-size: 11px;
    color: #333;
    margin-top: 10px;
`

export const InterestAmountWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

export const InterestAmountHeader = styled.p`
    font-size: 12px;
    color: #333;
`

export const InterestAmountButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
`

export const InterestAmountButton = styled.button`
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: #4CAF50;
        color: #fff;
        border: 1px solid #4CAF50;
    }
`

export const InterestAmountSymbol = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
`

export const InterestAmount = styled.input`
    width: 100%;
    padding: 10px 0 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    outline: none;

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const SubmitWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    gap: 10px;
`

export const CancelButton = styled.button`
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: #EEE;
    }
`

export const SubmitButton = styled.button`
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: #555;
    }
`

export const ToggleContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const ToggleText = styled.p`
    font-size: 13px;
    font-weight: 500;
    color: #333;
`

export const ToggleWrapper = styled.div`
  width: 55px;
  min-width: 55px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  cursor: pointer;
  border: ${props => props.isActive ? `1px solid ${PrimaryColor}` : '1px solid #eee'};
  background: ${props => props.isActive ? PrimaryColor : 'white'};
  position: relative;
`

export const Notch = styled.div`
  height: 21px;
  width: 21px;
  border: 1px solid #eee;
  margin-top: 1px;
  border-radius: 50%;
  transition: transform 0.1s linear;
  transform: translate(${props => props.isActive ? '31px' : '1.5px'});
  background: ${props => props.isActive ? 'white' : '#eee'};
`

export const ActiveText = styled.div`
  color: white;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
`

export const InactiveText = styled.div`
  color: black;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
`

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: #f9f9f9; */
    padding: 15px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
`

export const SuccessMsg = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin-top: 20px;
    text-align: center;
`

export const InterestRemoveAmount = styled.div`
    font-size: 22px;
    font-weight: 700;
    color: ${PrimaryColor};
    margin-bottom: 10px;
    text-align: center;
`