import axios from 'axios'
import { API_URL } from '../../../../config'

export const addCommentRoute = async (payload) => {
    try {
        return axios.post(`${API_URL}/project-comments/add-comment`, payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const updateComment = async (commentId, updatedComment) => {
    const payload = {
        updatedComment
    }

    try {
        return await axios.post(`http://localhost:5001/project-comments/update-comment/${commentId}`, payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const deleteComment = async (route, commentId) => {
    try {
        return axios.delete(`http://localhost:5001/${route}/delete-comment/${commentId}`, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const loadMore = async (projectId, pageCount, limit, isChildComments, commentAdds) => {
    try {
        return axios.get(`${API_URL}/project-comments/comments/${projectId}`, 
            { params: { page: pageCount, limit, commentAdds }, withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const showOrLoadMoreReplies = async (parentId, pageCount, limit, isChildComments, childCommentAdds) => {
    try {
        return axios.get(`${API_URL}/project-comments/childComments/${parentId}`, 
            { params: { page: pageCount, limit, isChildComments, childCommentAdds }, withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const addChildComment = async (payload) => {

    try {
        return await axios.post('http://localhost:5001/project-comments/add-childComment', payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const updateChildComment = async (userId, comment, updateComment) => {
    const payload = {
        userId,
        updatedChildComment: updateComment
    }

    try {
        return axios.post(`http://localhost:5001/project-comments/update-child-comment/${comment}`, payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const deleteChildComment = async (commentId, replyId) => {
    try {
        return axios.delete(`http://localhost:5001/project-comments/delete-child-comment/${commentId}/${replyId}`, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}