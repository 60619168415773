import React, { useState, useRef } from 'react'
import useOutsideClick from '../useOutsideClick'

import {
    DropdownContainer,
    GenreDropdownContainer,
    DropdownButton,
    DropdownContent,
    DropdownItem,
} from '../DiscoverElements'

import { IoMdArrowDropdown } from "react-icons/io"

const Dropdown = ({ options, defaultOption, onSelectionChange, left, right, isGenre, className, select, setSelect, type }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(defaultOption || options[0])
    const dropdownRef = useRef()

    const handleItemClick = (item) => {
        setSelectedItem(item)
        setSelect(item)
        setIsOpen(false)
        if (onSelectionChange) {
            onSelectionChange(item, type)
        }
    }

    useOutsideClick(dropdownRef, () => {
        if (isOpen) setIsOpen(false)
    })

    return (
        <DropdownContainer className={className} ref={dropdownRef}>
            <DropdownButton onClick={() => setIsOpen(!isOpen)}>
                {selectedItem} <IoMdArrowDropdown style={{ marginLeft: 10 }} />
            </DropdownButton>
            <DropdownContent isOpen={isOpen}>
                {isGenre ? (
                    <GenreDropdownContainer isOpen={isOpen}>
                        {options.map(option => (
                            <DropdownItem key={option} onClick={() => handleItemClick(option)}>
                                {option}
                            </DropdownItem>
                        ))}
                    </GenreDropdownContainer>
                ) : (
                    <DropdownContent className='contentHold' isOpen={isOpen}>
                        {options.map(option => (
                            <DropdownItem key={option} onClick={() => handleItemClick(option)} href="#">
                                {option}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                )}
            </DropdownContent>
        </DropdownContainer>
    )
}

export default Dropdown