import React from 'react'

import LoginComponent from '../components/Login'

function Login({ setIsLoggedIn }) {
    return (
        <div>
            <LoginComponent setIsLoggedIn={setIsLoggedIn} />
        </div>
    )
}

export default Login
