import React, { useState, useEffect, useReducer, useContext } from 'react'
import { AuthContext } from '../authContext'
import { investReducer, initialState } from '../components/ProjectInvest/Reducer'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import { addCommasToNumber } from '../helpers/numberConvert'

import NonMainNavbar from '../components/NonMainNav'

import Title from '../components/ProjectInvest/Title'
import StepOne from '../components/ProjectInvest/Steps/StepOne'
import StepTwo from '../components/ProjectInvest/Steps/StepTwo'
import StepThree from '../components/ProjectInvest/Steps/StepThree'
import StepFour from '../components/ProjectInvest/Steps/StepFour'
import StepFive from '../components/ProjectInvest/Steps/StepFive'

import InfoCard from '../components/ProjectInvest/InfoCard'
import TermsCard from '../components/ProjectInvest/InfoCard/TermsCard'
import ContractsCard from '../components/ProjectInvest/InfoCard/ContractsCard'
import FaqsCard from '../components/ProjectInvest/InfoCard/FaqsCard'

import FaqModal from '../components/ProjectInvest/Modals/FaqModal'
import DealTermModal from '../components/ProjectInvest/Modals/DealTermModal'
import ContractsModal from '../components/ProjectInvest/Modals/ContractsModal'

import { 
    MobileContinueButton,
    MobileBackButton,
    MobileButtonContainer,
    MobileWrapperDiv,
    MobileContainerDiv,
    MobileInfoCard 
} from '../components/ProjectInvest/ProjectInvestElements'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'

function InvestProject() {
    const { project, projectId } = useParams()
    const [projectInfo, setProjectInfo] = useState({})
    const [mobileStep, setMobileStep] = useState(1)
    const [currentStop, setCurrentStop] = useState(1)
    const [showMobileRiseMenu, setShowMobileRiseMenu] = useState(false)
    const [showDealTermModal, setShowDealTermModal] = useState(false)
    const [showContractsModal, setShowContractsModal] = useState(false)
    const [allFieldsFilled, setAllFieldsFilled] = useState(false)
    const [invalidDOB, setInvalidDOB] = useState(false)
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState('')
    const [funds, setFunds] = useState(0)
    const [targetFund, setTargetFund] = useState(0)
    const [state, dispatch] = useReducer(investReducer, initialState)

    const { loggedInUser } = useContext(AuthContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let didCancel = false
    
        const getProjectInfo = async () => {
            const response = await axios.get(`http://localhost:5001/project-invest/${projectId}`, { withCredentials: true })
            if (!didCancel) {
                setProjectInfo(response.data)
            }
        }
        getProjectInfo()
    
        return () => {
            didCancel = true
        }
    }, [project, projectId])

    useEffect(() => {
        if (project?.currentFunds)
            setFunds(project.currentFunds)

        if (project?.targetFundAmount)
            setTargetFund(project.targetFundAmount)
    }, [project?.currentFunds, project?.targetFundAmount])

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    const handleNextStep = () => {
        if (mobileStep === 1) {
            const isAllFieldsFilled = state.store.slice(0, 10).every(item => item.info !== '')
            if (!isAllFieldsFilled) {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 0, label: 'All Relevant Fields Filled' } })
                return
            } else {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 1, label: 'All Relevant Fields Filled' } })
                setMobileStep(2)
            }
        } else if (mobileStep === 2) {
            const isInvestmentAndTipFilled = state.store.slice(12, 14).every(item => item.info !== '') 

            const isAccredited = state.store.find(item => item.label === 'isAccredited').info
            
            let isValid = isInvestmentAndTipFilled

            if (!isAccredited) {
                isValid = isValid && state.store.slice(15, 17).every(item => item.info !== '') 
            } else {
                isValid = isValid && state.store[14].info !== ''
            }

            if (!isValid || Number(state.store[12].info) < 100) {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 0, label: 'All Relevant Fields Filled' } })
                return
            } else {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 1, label: 'All Relevant Fields Filled' } })
                setMobileStep(3)
            }
        } else if (mobileStep === 3) {
            const signatureField = state.store.find(item => item.label === 'Signature')
        
            if (!signatureField.signed || !signatureField.info) {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 0, label: 'All Relevant Fields Filled' } })
                return
            } else {
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 1, label: 'All Relevant Fields Filled' } })
                setMobileStep(4)
            }
        } else if (mobileStep === 4) {
            //COME BACK TO PAYMENT TYPE WE CAN'T DO THIS YET BECAUSE WE DON'T HAVE THE MODALS
            setMobileStep(5)
        } else if (mobileStep === 5) {
            setMobileStep(6)
        }
        window.scrollTo(0, 0)
    }

    const handleBackStep = () => {
        if (mobileStep === 2) {
            setMobileStep(1)
        } else if (mobileStep === 3) {
            setMobileStep(2)
        } else if (mobileStep === 4) {
            setMobileStep(3)
        } else if (mobileStep === 5) {
            setMobileStep(4)
        }
        window.scrollTo(0, 0)
    }

    const calculateProgressWidth = () => {
        if (funds && targetFund) {
          const percentage = (funds / targetFund) * 100

          return `${percentage}%`
        }
        return '0%'
    }

    return (
        <>
            <NonMainNavbar loggedInUser={loggedInUser} />
            {isDesktopOrLaptop && (
                <div style={{ display: 'flex' }}>
                    <div style={{ height: '100vh', width: '50%', paddingTop: '80px', paddingLeft: '80px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Title 
                            creatorName={projectInfo?.project?.artist?.artistName} 
                            projectType={projectInfo?.project?.projectType} 
                            projectName={projectInfo?.project?.projectName || ''}
                        />
                        <StepOne 
                            state={state} 
                            dispatch={dispatch} 
                            invalidDOB={invalidDOB} 
                            setInvalidDOB={setInvalidDOB} 
                            invalidPhoneNumber={invalidPhoneNumber} 
                            setInvalidPhoneNumber={setInvalidPhoneNumber} 
                        />
                        <StepTwo 
                            state={state} 
                            dispatch={dispatch} 
                            currentStop={currentStop} 
                            setCurrentStop={setCurrentStop} 
                            perks={projectInfo.project ? projectInfo.project.perks : []} 
                        />
                        <StepThree 
                            state={state} 
                            dispatch={dispatch} 
                        />
                        <StepFour 
                            state={state} 
                            dispatch={dispatch} 
                        />
                        <StepFive 
                            project={projectInfo?.project}
                            state={state} 
                            dispatch={dispatch} 
                            allFieldsFilled={allFieldsFilled} 
                            setAllFieldsFilled={setAllFieldsFilled} 
                            invalidDOB={invalidDOB} 
                            invalidPhoneNumber={invalidPhoneNumber}
                        />
                    </div>
                    <div style={{ 
                        height: '100vh',
                        width: '50%',
                        paddingTop: '240px', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'flex-end', 
                        paddingRight: '80px'
                    }}>
                        <InfoCard project={projectInfo.project} state={state} />
                        <TermsCard project={projectInfo.project}/>
                        <ContractsCard project={projectInfo.project}/>
                        <FaqsCard project={projectInfo.project}/>
                    </div>
                </div>
            )}
            {isTabletOrMobile && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {showMobileRiseMenu && 
                        <FaqModal 
                            project={projectInfo.project}
                            showMobileRiseMenu={showMobileRiseMenu} 
                            setShowMobileRiseMenu={setShowMobileRiseMenu} 
                            calculateProgressWidth={calculateProgressWidth}
                            addCommasToNumber={addCommasToNumber}
                        />
                    }
                    {showDealTermModal && 
                        <DealTermModal 
                            project={projectInfo.project} 
                            showDealTermModal={showDealTermModal} 
                            setShowDealTermModal={setShowDealTermModal} 
                            calculateProgressWidth={calculateProgressWidth}
                            addCommasToNumber={addCommasToNumber}
                        />
                    }
                    {showContractsModal && 
                        <ContractsModal 
                            project={projectInfo.project} 
                            showContractsModal={showContractsModal} 
                            setShowContractsModal={setShowContractsModal} 
                            calculateProgressWidth={calculateProgressWidth}
                            addCommasToNumber={addCommasToNumber}
                        />
                    }
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Title 
                            state={state} 
                            creatorName={projectInfo?.project?.artist?.artistName} 
                            projectType={projectInfo?.project?.projectType} 
                            projectName={projectInfo?.project?.projectName || ''}
                        />
                        {state && state.store && (
                        <>
                            {mobileStep === 1 &&
                                <MobileContainerDiv>
                                    <StepOne 
                                        state={state} 
                                        dispatch={dispatch} 
                                        invalidDOB={invalidDOB} 
                                        setInvalidDOB={setInvalidDOB} 
                                        invalidPhoneNumber={invalidPhoneNumber} 
                                        setInvalidPhoneNumber={setInvalidPhoneNumber} 
                                    />
                                </MobileContainerDiv>
                            }
                            {mobileStep === 2 &&
                                <MobileContainerDiv>
                                    <StepTwo 
                                        state={state} 
                                        dispatch={dispatch} 
                                        currentStop={currentStop} 
                                        setCurrentStop={setCurrentStop} 
                                        perks={projectInfo.project ? projectInfo.project.perks : []} 
                                    />  
                                </MobileContainerDiv>
                            }
                            {mobileStep === 3 &&
                                <MobileContainerDiv>
                                    <StepThree 
                                        state={state} 
                                        dispatch={dispatch} 
                                    />
                                </MobileContainerDiv>
                            }
                            {mobileStep === 4 &&
                                <MobileContainerDiv>
                                    <StepFour 
                                        state={state} 
                                        dispatch={dispatch} 
                                    />
                                </MobileContainerDiv>
                            }
                            {mobileStep === 5 &&
                                <MobileContainerDiv>
                                    <StepFive 
                                        state={state} 
                                        dispatch={dispatch} 
                                        allFieldsFilled={allFieldsFilled} 
                                        setAllFieldsFilled={setAllFieldsFilled} 
                                        invalidDOB={invalidDOB} 
                                        invalidPhoneNumber={invalidPhoneNumber}
                                    />
                                </MobileContainerDiv>
                            }
                        </>
                        )}
                    </div>
                    <MobileWrapperDiv>
                        <MobileButtonContainer>
                        {
                            mobileStep !== 1 && 
                            <MobileBackButton onClick={handleBackStep}>
                                <LiaLongArrowAltLeftSolid style={{ marginRight: 2 }} size={20} /> Back
                            </MobileBackButton>
                        }
                        {
                            mobileStep === 5 
                            ? 
                            <MobileContinueButton onClick={handleNextStep}>Finish Investment</MobileContinueButton>
                            : 
                            <MobileContinueButton style={mobileStep === 1 ? { width: '100%' } : {}} onClick={handleNextStep}>Continue</MobileContinueButton>
                        }
                        </MobileButtonContainer>
                        {mobileStep === 1 &&
                            <MobileInfoCard onClick={() => setShowMobileRiseMenu(true)}>
                                Frequently Asked Questions
                            </MobileInfoCard>
                        }   
                        {mobileStep === 2 &&
                            <MobileInfoCard onClick={() => setShowDealTermModal(true)}>
                                Deal Terms
                            </MobileInfoCard>
                        }
                        {mobileStep === 3 &&
                            <MobileInfoCard onClick={() => setShowContractsModal(true)}>
                                Contracts
                            </MobileInfoCard>
                        }
                        {mobileStep === 4 &&
                            <MobileInfoCard onClick={() => setShowMobileRiseMenu(true)}>
                                Frequently Asked Questions
                            </MobileInfoCard>
                        } 
                        {mobileStep === 5 &&
                            <MobileInfoCard onClick={() => setShowMobileRiseMenu(true)}>
                                Frequently Asked Questions
                            </MobileInfoCard>
                        } 
                    </MobileWrapperDiv>
                </div>
            )}
        </>
    )
}

export default InvestProject