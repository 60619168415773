import React, { useState, useRef } from 'react'

import { addTimelineSection } from '../TimelineApi'

import { 
    Wrapper, 
    ModalBg, 
    Modal, 
    Header, 
    HeaderText, 
    Body,
    AddInfo,
    BudgetSection,
    CurrentStatus,
    ItemTextBoxContainer,
    ItemTextBox,
    AddItem
} from '../../../Modal/EditModalElements' 

import { 
    AddButton, 
    CancelButton 
} from '../../Media/MediaModal/MediaModalElements'

import { IoCloseSharp } from 'react-icons/io5'

function TimelineModal({ 
    setShowModal, 
    setProjectInfo,
    projectInfo, 
    setTimelineData, 
    currentIndex 
}) {
    const [timelineArray, setTimelineArray] = useState([{ title: '', status: 'Awaiting', item: [''] }])

    const modalRef = useRef(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
      
        const payload = {
          sectionsArray: timelineArray.map((section) => ({
            title: section.title,
            status: section.status,
            tasks: section.item.filter((task) => task).map((task) => ({ item: task })),
          })),
          projectId: projectInfo.project._id,
          currentIndex,
        }
      
        const response = await addTimelineSection(payload)
      
        setTimelineData(response.timeline.sections)
      
        setShowModal(false)
    }

    // const handleAddClick = () => {
    //     setTimelineArray([...timelineArray, { title: '', status: 'Awaiting', item: [''] }])
    // }

    const handleItemRemoveClick = (index, itemIndex) => {
        let tempArray = [...timelineArray]
        tempArray[index].item.splice(itemIndex, 1)
        setTimelineArray(tempArray)
    }

    const handleChange = (e, index) => {
        let tempArray = [...timelineArray]
        tempArray[index][e.target.name] = e.target.value
        setTimelineArray(tempArray)
    }

    const handleItemChange = (e, index, itemIndex) => {
        e.preventDefault()
        let tempArray = [...timelineArray]
        tempArray[index][e.target.name][itemIndex] = e.target.value
        setTimelineArray(tempArray)
    }

    const handleAddItem = (e, index) => {
        let tempArray = [...timelineArray]
        tempArray[index].item = [...tempArray[index].item, '']
        setTimelineArray(tempArray)
    }

    const closeModalClick = async e => {
        if (modalRef.current === e.target) {
            setShowModal(false)
        }
    }

    const renderAddInputs = timelineArray.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <BudgetSection style={index > 0 ? { marginTop: 10 } : {}} >
                    <div>Section {index + 1}</div>
                    <AddInfo style={{ width: 175 }} name='title' value={item.title || ''} type='text' placeholder='Title' onChange={e => handleChange(e, index)} required />
                    <CurrentStatus name='status' onChange={e => handleChange(e, index)}>
                        <option value='Awaiting'>Awaiting</option>
                        <option value='In Progress'>In Progress</option>
                        <option value='Completed'>Completed</option>
                        <option value='Paused'>Paused</option>
                    </CurrentStatus>
                    {item.item.map((i, itemIndex) => (
                    <ItemTextBoxContainer key={itemIndex}>
                        <ItemTextBox name='item' value={i || ''} type='text' placeholder='Task' onChange={e => handleItemChange(e, index, itemIndex)} />
                        {itemIndex > 0 ? <AddItem type='button' style={{ color: 'red' }} onClick={() => handleItemRemoveClick(index, itemIndex)} >Delete</AddItem> : null}
                        {itemIndex + 1 === item.item.length ? <AddItem type='button' onClick={e => handleAddItem(e, index)}>Add</AddItem> : null}
                    </ItemTextBoxContainer>
                    ))}
                </BudgetSection>
                {/* {index + 1 === timelineArray.length ? <AddWrapper><AddElementButton size={20} style={{ cursor: 'pointer', width: '50%' }} onClick={handleAddClick}>Add Section</AddElementButton></AddWrapper> : null} */}
            </React.Fragment>
        )
    })

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModalClick}>
                <Modal>
                    <Header>
                        <HeaderText>
                            Add Timeline Sections
                        </HeaderText>
                        <IoCloseSharp size={25} style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)}/>
                    </Header>
                    <Body style={{ padding: 10 }}>
                            {renderAddInputs}
                            <AddButton style={{ marginTop: 10 }} onClick={handleSubmit} >Update</AddButton>
                            <CancelButton style={{ marginTop: 5 }} onClick={() => setShowModal(false)} >Cancel</CancelButton>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default TimelineModal