import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentInfoValue } from './helpers/reducerHelper'

import { 
    TitleWrapper, 
    TitleName, 
    SubTitle, 
    ProjectName,
    ProjectCategory,
    MobileTitleWrapper,
    MobileTitleName,
    MobileProjectName,
    MobileProjectCategory,
    MobileSubTitle
} from './ProjectInvestElements'

function Title({ creatorName, projectType, projectName, state }) {
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    let type

    if (projectType === 'REG_CF')
        type = 'Reg CF'
    else if (projectType === 'FIXED_REG_CF')
        type = 'Fixed Reg CF'
    else if (projectType === 'REG_A_TIER_ONE')
        type = 'Reg A Tier One'
    else if (projectType === 'REG_A_TIER_TWO')
        type = 'Reg A Tier Two'


    return (
        <>
        {isDesktopOrLaptop &&
            <TitleWrapper>
                <TitleName>INVEST IN</TitleName>
                <ProjectName>{projectName}</ProjectName>
                <SubTitle>By {creatorName}</SubTitle>
                <ProjectCategory>Offering: {type}</ProjectCategory>
            </TitleWrapper>
        }
        {isTabletOrMobile &&
            <MobileTitleWrapper>
                <MobileTitleName>INVEST IN</MobileTitleName>
                <MobileProjectName>{projectName}</MobileProjectName>
                <MobileSubTitle>By {creatorName}</MobileSubTitle>
                <MobileProjectName>You are investing ${getCurrentInfoValue('Investment Amount', state) > 0 ? getCurrentInfoValue('Investment Amount', state) : 0}</MobileProjectName>
                <MobileProjectCategory>Offering: {type}</MobileProjectCategory>
            </MobileTitleWrapper>
        }
        </>
    )
}

export default Title