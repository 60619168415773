import React from 'react'
import { useNavigate } from 'react-router-dom'
import LaunchProjectModal from '../LaunchProjectModal'

import { addCommasToNumber } from '../../../../helpers/numberConvert'

import { 
    Wrapper, 
    Header, 
    CreatorContainer,
    CreatorHeader,
    Creator,
    ProgressContainer, 
    ProgressBar, 
    Progress, 
    ProgressHeader, 
    ProgressSubHeader,
    Backers,
    InvestButton,
    SocialWrapper
} from './ProjectInfoCardElements'

import {
    StyledFaFacebook,
    StyledFaSquareXTwitter,
    StyledMdEmail,
    StyledImEmbed2,
} from '../BodyElements.js'

function ProjectInfoCard({ 
    projectInfo, 
    wrapperStyle, 
    subHeader, 
    header,
    interestPercentage,
    setShowGaugeInterestModal,
    setShowRemoveInterestModal,
    isExpressedInterest,
    projectId,
    showLaunchModal,
    setShowLaunchModal
}) {
    const { targetFundAmount } = projectInfo.project || 0

    const navigate = useNavigate()

    //@dev takes totalInvested and target amount and finds percentage
    const percentageCalc = () => {
        let decimal = projectInfo?.project?.currentFunds / targetFundAmount

        const percentage = (decimal * 100).toFixed(2)

        return percentage
    }

    const handleCreatorClick = () => {
        navigate(`/artist-profile/${projectInfo.project.artist.slug}`)
    }

    const handleInvestClick = () => {
        navigate(`/project-profile/${projectInfo.project._id}/invest-in-project`)
    }

    const handleDraftClick = () => {
        document.body.style.overflow = 'hidden'
        setShowLaunchModal(true)
    }

    const handleExpressInterestClick = () => {
        if (!isExpressedInterest) {
            document.body.style.overflow = 'hidden'
            setShowGaugeInterestModal(true)
        } else {
            document.body.style.overflow = 'hidden'
            setShowRemoveInterestModal(true)
        }
    }

    const handleShowInterestOrFund = () => {
        if (projectInfo?.project?.projectStatus === 'GAUGING_INTEREST') {
            return (
                <ProgressContainer>
                    <ProgressHeader>${addCommasToNumber(projectInfo?.project?.totalExpressedInterest)}</ProgressHeader>
                    <ProgressBar onClick={percentageCalc}>
                        <Progress style={{ width: `${interestPercentage}%`, maxWidth: '100%' }}></Progress>
                    </ProgressBar>
                    <React.Fragment> 
                        <ProgressSubHeader style={{ color: subHeader }} >of ${addCommasToNumber(targetFundAmount)} goal</ProgressSubHeader> 
                    </React.Fragment>
                    <Backers >{projectInfo.project.equityGiven}%</Backers>
                    <ProgressSubHeader style={{ color: subHeader }}>Equity</ProgressSubHeader>
                    <Backers >{projectInfo?.expressedInterestCount}</Backers>
                    <ProgressSubHeader style={{ color: subHeader }} >Backers</ProgressSubHeader>
                </ProgressContainer>
            )
        } else if (projectInfo?.project?.projectStatus === 'FUNDING') {
            return (
                <ProgressContainer>
                    <ProgressHeader>${addCommasToNumber(projectInfo?.project?.currentFunds)}</ProgressHeader>
                    <ProgressBar onClick={percentageCalc}>
                        <Progress style={{ width: `${percentageCalc()}%` }}></Progress>
                    </ProgressBar>
                    <React.Fragment> 
                        <ProgressSubHeader style={{ color: subHeader }} >of ${addCommasToNumber(targetFundAmount)} goal</ProgressSubHeader> 
                    </React.Fragment>
                    <Backers >{projectInfo?.project?.equityGiven}%</Backers>
                    <ProgressSubHeader style={{ color: subHeader }}>Equity</ProgressSubHeader>
                    <Backers >{addCommasToNumber(projectInfo?.project?.investors)}</Backers>
                    <ProgressSubHeader style={{ color: subHeader }} >Backers</ProgressSubHeader>
                </ProgressContainer>
            )
        } else if (projectInfo?.project?.projectStatus === 'DRAFT') {
            return (
                <ProgressContainer>
                    <ProgressHeader>${addCommasToNumber(projectInfo?.project?.currentFunds)}</ProgressHeader>
                    <ProgressBar onClick={percentageCalc}>
                        <Progress style={{ width: `${percentageCalc()}%` }}></Progress>
                    </ProgressBar>
                    <React.Fragment> 
                        <ProgressSubHeader style={{ color: subHeader }} >of ${addCommasToNumber(targetFundAmount)} goal</ProgressSubHeader> 
                    </React.Fragment>
                    <Backers >{projectInfo?.project?.equityGiven}%</Backers>
                    <ProgressSubHeader style={{ color: subHeader }}>Equity</ProgressSubHeader>
                    <Backers >{addCommasToNumber(projectInfo?.project?.investors)}</Backers>
                    <ProgressSubHeader style={{ color: subHeader }} >Backers</ProgressSubHeader>
                </ProgressContainer>
            )
        } else if (projectInfo?.project?.projectStatus === 'COMPLETED') {
            return (
                <ProgressContainer>
                        <ProgressHeader>${addCommasToNumber(projectInfo?.project?.currentFunds)}</ProgressHeader>
                        <ProgressBar onClick={percentageCalc}>
                            <Progress style={{ width: `${percentageCalc()}%` }}></Progress>
                        </ProgressBar>
                        <React.Fragment> 
                            <ProgressSubHeader style={{ color: subHeader }} >of ${addCommasToNumber(targetFundAmount)} goal</ProgressSubHeader> 
                        </React.Fragment>
                        <Backers >{projectInfo?.project?.equityGiven}%</Backers>
                        <ProgressSubHeader style={{ color: subHeader }}>Equity</ProgressSubHeader>
                        <Backers >{addCommasToNumber(projectInfo?.project?.investors)}</Backers>
                        <ProgressSubHeader style={{ color: subHeader }} >Backers</ProgressSubHeader>
                </ProgressContainer>
            )
        }
    }

    return (
        <Wrapper style={wrapperStyle}>
            {showLaunchModal && <LaunchProjectModal projectId={projectId} setShowLaunchModal={setShowLaunchModal} />}
            {!header ? <Header>{projectInfo?.project?.projectName}</Header> : null}
            {!header ? 
            <CreatorContainer onClick={handleCreatorClick} >
                <CreatorHeader>Created by</CreatorHeader>
                &nbsp;
                <Creator>{projectInfo?.project?.artist?.artistName}</Creator>
            </CreatorContainer> 
            : null}
            {handleShowInterestOrFund()}
            {projectInfo?.project?.projectStatus === 'GAUGING_INTEREST' ? (
                <InvestButton onClick={handleExpressInterestClick}>
                    {!isExpressedInterest ? 'Express Interest' : 'Remove Interest'}
                </InvestButton>
            ) : projectInfo?.project?.projectStatus === 'FUNDING' ? (
                <InvestButton onClick={handleInvestClick}>
                    Back This Project
                </InvestButton>
            ) : projectInfo?.project?.projectStatus === 'DRAFT' ? (
                <InvestButton onClick={handleDraftClick}>
                    Launch Project
                </InvestButton>
            ) : null}
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', color: 'gray' }}>
                <SocialWrapper>
                    <a style={{ color: 'black' }} href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/project-profile/65f8c59de89ebc343e5f70c8" target="_blank" rel="noopener noreferrer">
                        <StyledFaFacebook />
                    </a>
                </SocialWrapper>
                <SocialWrapper style={{ marginLeft: 5 }}>
                    <a style={{ color: 'black' }} href="https://twitter.com/intent/tweet?url=[URL_TO_SHARE]&text=[TEXT_TO_SHARE]&hashtags=[HASHTAGS]" target="_blank" rel="noopener noreferrer">
                        <StyledFaSquareXTwitter />
                    </a>
                </SocialWrapper>
                <SocialWrapper style={{ marginLeft: 5 }}>
                    <a style={{ color: 'black' }} href="mailto:?subject=[SUBJECT]&body=[BODY_TEXT]%20[URL_TO_SHARE]" target="_blank" rel="noopener noreferrer">
                        <StyledMdEmail />
                    </a>
                </SocialWrapper>
                <SocialWrapper style={{ marginLeft: 5 }}>
                    <StyledImEmbed2 style={{ color: 'black' }} />
                </SocialWrapper>
            </div>
        </Wrapper>
    )
}

export default ProjectInfoCard