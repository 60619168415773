import React, { useState, useRef, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentInfoValue } from '../helpers/reducerHelper'
import { isFieldInvalid } from '../helpers/isFieldInvalid'
import { handleDateChange } from '../helpers/dateChangeHelper'

import { 
    StepWrapper, 
    Step, 
    StepMobile,
    InputWrapper, 
    TwoInputWrapper, 
    TwoInputContainer,
    InputContainer,
    InputLabel,
    Input, 
    SelectContainer,
    StyledSelect,
    ArrowIcon,
    CheckboxInfoContainer,
    QuestionBubble,
    RadioDescription,
    CheckboxContainer,
    CheckboxLabel,
    CheckboxInput,
    QuestionInfoBox,
    QuestionInfo
} from '../ProjectInvestElements'

import { AiFillCaretDown } from 'react-icons/ai'
import { BiErrorAlt } from 'react-icons/bi'

function StepOne ({ state, dispatch, invalidDOB, setInvalidDOB, invalidPhoneNumber, setInvalidPhoneNumber }) {  
    const [questionBubbleHover, setQuestionBubbleHover] = useState(false)
    const [questionBubbleHoverTwo, setQuestionBubbleHoverTwo] = useState(false)
    const [questionBubblePosition, setQuestionBubblePosition] = useState('top')
    const [questionBubblePositionTwo, setQuestionBubblePositionTwo] = useState('top')
    const [isValidPostalCode, setIsValidPostalCode] = useState(false)

    const questionBubbleRef = useRef(null)
    const questionBubbleRefTwo = useRef(null)

    // FIELD VALIDATION
    useEffect(() => {
        // Check if the DOB is valid
        const DOB = getCurrentInfoValue('DOB', state)

        const currentYear = new Date().getFullYear()
        const yearFromDOB = parseInt(DOB.substring(6, 10), 10)

        const dobDate = new Date(DOB) // Convert the DOB string to a Date object
        const eighteenYearsAgo = new Date()
        eighteenYearsAgo.setFullYear(currentYear - 18)

        if (DOB.length !== 10 && DOB.length !== 0) {
            setInvalidDOB('Invalid Date of Birth')
        } else if (DOB.length === 10 && (isNaN(yearFromDOB) || yearFromDOB >= currentYear || yearFromDOB <= 1900)) {
            setInvalidDOB('Invalid Year')
        } else if (dobDate > eighteenYearsAgo) {
            setInvalidDOB('Must be at least 18 years old')
        } else {
            setInvalidDOB('')
        }

        // Check if the phone number is valid
        const phoneNumber = getCurrentInfoValue('Phone Number', state)
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '')

        if (numericPhoneNumber.length === 0) {
            setInvalidPhoneNumber('')
        } else if (numericPhoneNumber.length < 10) {
            setInvalidPhoneNumber('Invalid Phone Number')
        } else if (numericPhoneNumber.startsWith('1') && numericPhoneNumber.length < 11) {
            setInvalidPhoneNumber('Invalid Phone Number')
        } else {
            setInvalidPhoneNumber('')
        }

        //eslint-disable-next-line
    }, [state])

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    if (!state || !state.store) {
        return null // or return some fallback UI
    }  

    const handleAccreditedChange = (e) => {
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: !getCurrentInfoValue('isAccredited', state), label: 'isAccredited' } })
        if (getCurrentInfoValue('isAccredited', state)) {
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Yearly Income' } })
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Net Worth' } })
        } else {
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Accreditor Type' } })
        }
    }

    let stylesOne

    if (isTabletOrMobile) {
        stylesOne = { top: '-325px', left: '40px' }
    } else {
        stylesOne = questionBubblePosition === 'bottom' ? { top: '-300px' } : { top: 0 }
    }

    let stylesTwo

    if (isTabletOrMobile) {
        stylesTwo = { top: '-130px', left: '100px' }
    } else {
        stylesTwo = questionBubblePositionTwo === 'bottom' ? { top: '-105px', left: 425 } : { top: 0, left: 425 }
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            const value = e.target.value
            let formattedValue = value
            
            if (value.endsWith('/') && e.target.value.length === 3) {
                formattedValue = formattedValue.slice(0, 2)
            } else if (value.endsWith('/') && e.target.value.length === 6) {
                formattedValue = formattedValue.slice(0, 5)
            }
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'DOB' } });
        }
    }

    const statesList = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
        "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
        "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
        "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
        "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma",
        "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee",
        "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ]

    const formatPhoneNumber = (value) => {
        const numericValue = value.replace(/\D/g, ''); // Remove all non-digit characters
        
        let startIndex = 0
        let countryCode = ""
        
        // Check for a leading "1" and adjust accordingly
        if (numericValue.startsWith('1')) {
            countryCode = "1 "
            startIndex = 1
        }

        if (numericValue.length - startIndex <= 3) {
            return `${countryCode}${numericValue.substring(startIndex)}`
        } else if (numericValue.length - startIndex <= 6) {
            return `${countryCode}(${numericValue.substring(startIndex, startIndex + 3)}) ${numericValue.substring(startIndex + 3)}`
        } else {
            return `${countryCode}(${numericValue.substring(startIndex, startIndex + 3)}) ${numericValue.substring(startIndex + 3, startIndex + 6)}-${numericValue.substring(startIndex + 6, startIndex + 10)}`
        }
    }

    const handlePhoneKeyDown = (e) => {
        if (e.key === 'Backspace' && e.target.value.startsWith('1 ')) {
            let formattedValue = e.target.value
    
            // If value starts with '1 ' and the cursor is at position 2 (right after the space)
            if (e.target.selectionStart === 2) {
                formattedValue = formattedValue.slice(2)  // Remove '1 '
                e.target.value = formattedValue  // Update the value immediately
                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'Phone Number' } })
                e.preventDefault()  // Prevent default backspace behavior
            }
        }
    }    

    return (
        <StepWrapper>
            {isDesktopOrLaptop &&
                <Step><div style={{ marginRight: 20 }}>1.</div> Investor Info</Step>
            }
            {isTabletOrMobile &&
                <StepMobile>1. Investor Info</StepMobile>
            }
            <InputWrapper style={isTabletOrMobile ? { marginLeft: 0 } : {}}>
                <TwoInputWrapper>
                    <TwoInputContainer>
                        <InputLabel isInvalid={isFieldInvalid('First Name', state)} >First Name</InputLabel>
                        <Input 
                            isInvalid={isFieldInvalid('First Name', state)} 
                            onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'First Name' } })} 
                            value={getCurrentInfoValue('First Name', state)}
                        />
                    </TwoInputContainer>
                    <TwoInputContainer style={{ marginLeft: 15 }}>
                        <InputLabel isInvalid={isFieldInvalid('Last Name', state)}>Last Name</InputLabel>
                        <Input 
                            isInvalid={isFieldInvalid('Last Name', state)} 
                            onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Last Name' } })} 
                            value={getCurrentInfoValue('Last Name', state)}
                        />
                    </TwoInputContainer>
                </TwoInputWrapper>
                <InputContainer>
                    <InputLabel isInvalid={isFieldInvalid('Email', state)}>Email</InputLabel>
                    <Input 
                        isInvalid={isFieldInvalid('Email', state)} 
                        onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Email' } })} 
                        value={getCurrentInfoValue('Email', state)}
                    />
                </InputContainer>
                <TwoInputWrapper>
                    <TwoInputContainer style={{ marginBottom: 10 }}>
                        <InputLabel isInvalid={isFieldInvalid('Phone Number', state) || invalidPhoneNumber}>Phone Number</InputLabel>
                        <Input 
                            isInvalid={isFieldInvalid('Phone Number', state) || invalidPhoneNumber}
                            maxLength={16}
                            onChange={e => {
                                const formattedValue = formatPhoneNumber(e.target.value)
                                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: formattedValue, label: 'Phone Number' } })
                            }}    
                            onKeyDown={handlePhoneKeyDown}                        
                            value={getCurrentInfoValue('Phone Number', state)}
                            style={{ marginBottom: 2 }}
                        />
                        {invalidPhoneNumber && <InputLabel style={{ color: 'red', display: 'flex', alignItems: 'center', marginBottom: 10 }}><BiErrorAlt color='red' size={16} style={{ marginRight: 5 }}/>Invalid Phone Number</InputLabel>}
                    </TwoInputContainer>
                    <TwoInputContainer style={{ marginLeft: 15, marginBottom: 10 }}>
                        <InputLabel isInvalid={isFieldInvalid('DOB', state) || invalidDOB}>Date of Birth</InputLabel>
                        <Input 
                            isInvalid={isFieldInvalid('DOB', state) || invalidDOB} placeholder='MM/DD/YYYY' 
                            onChange={e => handleDateChange(e, dispatch, 'DOB')} 
                            value={getCurrentInfoValue('DOB', state)}
                            onKeyDown={handleKeyDown}
                            style={{ marginBottom: 2 }}
                        />
                        {invalidDOB !== '' && <InputLabel style={{ color: 'red', display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 0 }}><BiErrorAlt color='red' size={16} style={{ marginRight: 5 }}/>{invalidDOB}</InputLabel>}
                    </TwoInputContainer>
                </TwoInputWrapper>
                <InputContainer>
                    <InputLabel isInvalid={isFieldInvalid('Address', state)}>Address</InputLabel>
                    <Input 
                        isInvalid={isFieldInvalid('Address', state)} 
                        onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Address' } })} 
                        value={getCurrentInfoValue('Address', state)}
                    />
                </InputContainer>
                <TwoInputWrapper>
                    <TwoInputContainer>
                        <InputLabel isInvalid={isFieldInvalid('City', state)}>City</InputLabel>
                        <Input 
                            isInvalid={isFieldInvalid('City', state)} 
                            onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'City' } })} 
                            value={getCurrentInfoValue('City', state)}
                        />
                    </TwoInputContainer>
                    <TwoInputContainer style={{ marginLeft: 15 }}>
                        <InputLabel isInvalid={isFieldInvalid('Country', state)}>Country</InputLabel>
                        <SelectContainer>
                            <StyledSelect
                                    isInvalid={isFieldInvalid('Country', state)}
                                    value={getCurrentInfoValue('Country', state)}
                                    readOnly
                                >
                                    <option value="United States" disabled>United States</option>
                            </StyledSelect>
                            <ArrowIcon>
                                <AiFillCaretDown />
                            </ArrowIcon>
                        </SelectContainer>
                    </TwoInputContainer>
                </TwoInputWrapper>
                <TwoInputWrapper>
                    <TwoInputContainer>
                        <InputLabel isInvalid={isFieldInvalid('State', state)}>State</InputLabel>
                        <SelectContainer>
                            <StyledSelect
                                isInvalid={isFieldInvalid('State', state)}
                                onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'State' } })}
                                value={getCurrentInfoValue('State', state)}
                            >
                                <option value="" disabled>Select a state</option>
                                {statesList.map(stateName => (
                                    <option key={stateName} value={stateName}>
                                        {stateName}
                                    </option>
                                ))}
                            </StyledSelect>
                            <ArrowIcon>
                                <AiFillCaretDown />
                            </ArrowIcon>
                        </SelectContainer>
                    </TwoInputContainer>
                    <TwoInputContainer style={{ marginLeft: 15 }}>
                        <InputLabel isInvalid={isFieldInvalid('Zip Code', state) || isValidPostalCode}>Postal Code</InputLabel>
                        <Input 
                            isInvalid={isFieldInvalid('Zip Code', state) || isValidPostalCode} 
                            onChange={e => {
                                const numericValue = e.target.value.replace(/[^0-9]/g, '')
                                if (numericValue.length !== 5 && numericValue.length !== 0) {
                                    setIsValidPostalCode(true)
                                } else {
                                    setIsValidPostalCode(false)
                                }
                                dispatch({ type: 'UPDATE_INFO', payload: { newInfo: numericValue, label: 'Zip Code' } })
                            }}
                            value={getCurrentInfoValue('Zip Code', state)}
                            style={{ marginBottom: 2 }}
                        />
                        {isValidPostalCode && <InputLabel style={{ color: 'red', display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 0 }}><BiErrorAlt color='red' size={16} style={{ marginRight: 5 }}/>Must be exactly 5 digits</InputLabel>}
                    </TwoInputContainer>
                </TwoInputWrapper>
                <TwoInputWrapper style={{ marginTop: 15, justifyContent: 'space-between' }}>
                    <CheckboxInfoContainer>
                        <RadioDescription>Are you an accredited investor?</RadioDescription>
                        <QuestionBubble
                            ref={questionBubbleRef}
                            onMouseEnter={() => {
                                const rect = questionBubbleRef.current.getBoundingClientRect()
                                const screenHeight = window.innerHeight || document.documentElement.clientHeight
                            
                                if (rect.top > screenHeight / 2) {
                                    // Bubble is in the bottom half of the screen
                                    setQuestionBubblePosition('bottom')
                                } else {
                                    // Bubble is in the top half of the screen
                                    setQuestionBubblePosition('top')
                                }
                                setQuestionBubbleHover(true)
                            }}
                            onMouseLeave={() => setQuestionBubbleHover(false)}
                        >?</QuestionBubble>
                        {questionBubbleHover && (
                            <QuestionInfoBox style={stylesOne}>
                                <div style={{ fontSize: 15, fontWeight: 700, marginBottom: 10 }} >What is an accredited investor?</div>
                                <QuestionInfo style={{ fontSize: 14 }}>An accredited investor is defined by US federal law:</QuestionInfo>
                                <QuestionInfo>1. Income: An individual with an income of more than $200,000 each year for the last two years (or $300,000 combined income if married) and the expectation of earning the same or higher income in the current year.</QuestionInfo>
                                <QuestionInfo>2. Net Worth: An individual or a couple with a combined net worth exceeding $1 million, excluding the value of their primary residence.</QuestionInfo>
                                <QuestionInfo>3. Certain Professionals: Individuals holding in good standing one or more professional certifications, designations, or credentials that the SEC has designated as qualifying, which includes Series 7, Series 65, and Series 82 licenses.</QuestionInfo>
                                <QuestionInfo>4. Knowledgeable Employees: of a fund with respect to investments in a private fund.</QuestionInfo>
                            </QuestionInfoBox>
                        )}
                    </CheckboxInfoContainer>
                    <CheckboxContainer onClick={handleAccreditedChange}>
                        <CheckboxInput 
                            type="checkbox" 
                            checked={getCurrentInfoValue('isAccredited', state)} 
                            onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: !getCurrentInfoValue('isAccredited', state), label: 'isAccredited' } })} 
                        />
                        <CheckboxLabel 
                            checked={getCurrentInfoValue('isAccredited', state)} 
                            onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: !getCurrentInfoValue('isOrg', state), label: 'isOrg' } })} 
                        />
                    </CheckboxContainer>
                </TwoInputWrapper>
                <TwoInputWrapper style={{ marginTop: 15, justifyContent: 'space-between' }}>
                    <CheckboxInfoContainer>
                        <RadioDescription>Are you investing as a company, trust, or self-directed IRA?</RadioDescription>
                        <QuestionBubble
                            ref={questionBubbleRefTwo}
                            onMouseEnter={() => {
                                const rect = questionBubbleRefTwo.current.getBoundingClientRect()
                                const screenHeight = window.innerHeight || document.documentElement.clientHeight
                            
                                if (rect.top > screenHeight / 2) {
                                    // Bubble is in the bottom half of the screen
                                    setQuestionBubblePositionTwo('bottom')
                                } else {
                                    // Bubble is in the top half of the screen
                                    setQuestionBubblePositionTwo('top')
                                }
                                setQuestionBubbleHoverTwo(true)
                            }}
                            onMouseLeave={() => setQuestionBubbleHoverTwo(false)}
                        >?</QuestionBubble>
                        {questionBubbleHoverTwo && (
                            <QuestionInfoBox style={stylesTwo}>
                                <div style={{ fontSize: 15, fontWeight: 700, marginBottom: 10 }} >What does it mean to invest as an Entity?</div>
                                <QuestionInfo>Investing as an entity means that a legal structure, such as a corporation, LLC, trust, or partnership, makes the investment instead of an individual. The minimum investment for an entity is $3,500.</QuestionInfo>
                            </QuestionInfoBox>
                        )}
                    </CheckboxInfoContainer>
                    <CheckboxContainer onClick={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: !getCurrentInfoValue('isOrg', state), label: 'isOrg' } })}>
                        <CheckboxInput 
                            type="checkbox" 
                            checked={getCurrentInfoValue('isOrg', state)} 
                            readOnly={true}
                        />
                        <CheckboxLabel  
                            checked={getCurrentInfoValue('isOrg', state)}
                            readOnly={true}
                        />
                    </CheckboxContainer>
                </TwoInputWrapper>
            </InputWrapper>
        </StepWrapper>
    )
}

export default StepOne