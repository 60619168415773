import styled from 'styled-components'

export const Wrapper = styled.div`
    height: auto;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .no-show{
        visibility: hidden;
        opacity: 0;
    }
`

export const ModalBg = styled.div`
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
`

export const Modal = styled.div`
    background-color: white;
    width: 90%;
    max-width: 560px;
    height: auto;
    position: absolute;
    top: 60px;
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    margin: 0 auto;
    overflow-y: auto;
    max-height: 80vh;

    @media (max-width: 768px) {
        width: 95%;
        top: 20px;
    }
`;

export const CloseModalButton = styled.button`
    cursor: pointer;
    margin-bottom: 160px;
    margin-left: 500px;
    position: absolute;
    height: 48px;
    width: 48px;
    padding: 0;
    z-index: 20;
    background: white;
    border: none;
`

export const StyledDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 200px;
  padding: 20px;
  border-radius: 2px;
  border: dashed 1px lightgray;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const ExternalLinkButton = styled.button`
    cursor: pointer;
    height: 50px;
    width: 175px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    border: none;
    
    &:focus {
        display: none;
    }

    &:hover {
        text-decoration: underline;
    }
`

export const AddressWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const ButtonWrapper = styled.div`
    display: flex;
`

export const CopiedWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #16c79a;
`

export const LogoutButton = styled.button`
    cursor: pointer;
    margin-top: 10px;
    height: 50px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid #d8c292;
    color: #d8c292;
    
    &:focus {
        display: none;
    }
`

export const Header = styled.div`
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
`

export const SubHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'DM Sans', sans-serif;
`

export const InfoWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 0 10px;
    }
`;

export const SubHeader = styled.div`
    font-size: 17px;
    font-weight: bold;
    margin-left: 20px;
`

export const Info = styled.div`
    font-size: 16px;
    width: 300px;
    text-align: center;
`

export const EditButton = styled.button`
    font-size: 14px;
    margin-right: 20px;
    color: #005792;
    background: none;
    border: none;
    padding: 10px 5px;

    &:hover {
        cursor: pointer;
        background: #F1EFEF;
        border-radius: 4px;
    }
`

export const NameEditForm = styled.input`
    width: 100%;
    max-width: 300px;
    height: 40px;
    border-radius: 4px;
    padding: 15px;
    border-width: 1px;
    border-color: #bbbbbb;
    border-style: solid;
    box-sizing: border-box;

    &.show {
        display: none;
    }

    &.minus {
        margin-left: 0;
    }
`

export const MiniBioText = styled.textarea`
    height: 120px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border: 1px solid #cdcdcd;
    border-color: rgba(0, 0, 0, .15);
    background-color: white;
    font-size: 12px;
    padding: 10px;
    resize: none;
    box-sizing: border-box;
`

export const MiniBioCharCount = styled.div`
    font-size: 12px;
    color: #cdcdcd;
    margin-top: 5px;
`

export const Button = styled.button`
    height: 40px;
    width: 100px;
    margin-top: 15px;
    background-color: black;
    border: none;
    color: white;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    @media (max-width: 768px) {
        width: 80px;
        font-size: 14px;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`

export const CancelButton = styled(Button)`
    background-color: white;
    color: black;
    margin-right: 5px;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
`

export const FileInput = styled.input`
    display: none;
`

export const FileLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 45px;
    width: 230px;
    background-color: #d1d9d9;
    border-radius: 5px;
    cursor: pointer;
`

export const CoverPhotoPreview = styled.img`
    margin-top: 10px;
    height: auto;
    width: 100%;
    max-width: 500px;
    object-fit: cover;
`

export const SocialText = styled.div`
    margin-left: 3px;
    font-size: 13px;
`

export const IconWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 530px;
    overflow-x: auto;
    padding: 5px 0;
`

export const SubmitSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid lightgray;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
`