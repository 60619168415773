import styled from 'styled-components'

export const SubmitButton = styled.button`
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background: black;
    color: white;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`

export const CancelButton = styled.button`
    width: 100%;
    height: 40px;
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    background: transparent;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`