import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .custom-auth-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 1.5rem;
    margin-right: 10px;
  }
`

export const ModalBg = styled.div`
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
`

export const Modal = styled.div`
    background-color: white;
    width: 560px;
    height: auto;
    position: absolute;
    top: 60px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 5px;
`

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderText = styled.div`
    font-size: 17px;
    font-family: 'DM sans', sans-serif;
    font-weight: 700;
`

export const Body = styled.div`
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`

export const ModalTitleInfo = styled.div`
    font-size: 15px;
    font-weight: 500;
    font-family: 'DM sans', sans-serif;
`

export const Input = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    outline: none;
    &:focus {
        border: 1px solid #2F80ED;
    }
`

//Create a money input

export const MoneyInputWrapper = styled.div`
    width: 100%;
    height: 40px;
    position: relative;

    &::before {
        content: '$';
        position: absolute;
        left: 10px; /* adjust as needed */
        top: 30.5px;
        transform: translateY(-50%);
        font-size: 15px;
        font-weight: 500;
    }
`

export const MoneyInput = styled.input`
    width: 50%;
    height: 100%;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px 10px 10px 25px; /* Adjusted the left padding */
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    outline: none;
    background: transparent; /* Ensure the container's ::before content is visible */

    &:focus {
        border: 1px solid #2F80ED;
    }
`

export const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    outline: none;
    resize: none;
`

export const ProfileWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%; 
    display: flex; 
    justify-content: center;
`

export const MaxLengthDiv = styled.div`
    font-size: 12px;
    font-weight: 500;
    font-family: 'DM sans', sans-serif;
    color: #828282;
    margin-top: 10px;
`

export const GenreWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
` 

export const GenreButton = styled.button`
    width: auto;
    height: 30px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    outline: none;
    padding: 5px 10px;
    background-color: ${props => props.selected ? 'black' : '#E0E0E0'};
    color: ${props => props.selected ? 'white' : 'black'};

    &:hover {
        background-color: #BDBDBD;
        background-color: ${props => props.selected ? 'rgb(0, 0, 0, 0.8)' : '#BDBDBD'};
    }
`

export const ButtonWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const CancelButton = styled.button`
    width: 100px;
    height: 40px;
    border: none;
    background-color: white;
    color: black;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
    margin-right: 10px;

    &:hover {
        background-color: #E5E5E5;
        border-radius: 5px;
    }
`

export const SaveButton = styled.button`
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #68B984;
    color: white;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: rgba(104, 185, 132, 0.8);
    }
`

export const InstructionText = styled.div`
    font-size: 15px;
    font-weight: 500;
    font-family: 'DM sans', sans-serif;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #828282;
`

export const TwoFAWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`

export const TwoFAInput = styled.input`
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 1.5rem;
`

export const ShowCodesButton = styled.button`
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    color: #68B984;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
`

export const BackupInfo = styled.div`
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
    margin-top: 10px;
`

export const BackupCodeWrapper = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;
`

export const BackupCodeText = styled.div`
    font-size: 30px;
    width: 50px;
    font-weight: 500;
    font-family: 'DM sans', sans-serif;
    margin-top: 20px;
    color: #828282;
`

export const BackupButton = styled.a`
    width: 200px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    color: #68B984;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    outline: none;
    text-decoration: underline;
`

export const Container = styled.div`
    font-family: 'DM sans', sans-serif;
    color: #333;
    max-width: 600px;
    margin: auto;
`

export const Heading = styled.div`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #4CAF50;
`

export const SubHeading = styled.div`
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    span {
        font-weight: 700;
    }
`

export const StyledList = styled.ul`
    margin-top: 5px;
    padding-left: 20px;
`

export const ListItem = styled.li`
    font-family: 'DM sans', sans-serif;
    font-size: 15px;
    margin-top: 5px;
`

export const Paragraph = styled.div`
    font-family: 'DM sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    margin-top: 10px;
    span {
        font-weight: 700;
    }
`

export const AccreditedWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
`

export const AccreditedContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const AccreditedText = styled.div`
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    margin-left: 10px;
`

export const AccreditedInfo = styled.div`
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
    margin-top: 10px;
`

export const ToggleWrapper = styled.div`
    width: 40px;
    height: 18px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: ${({ toggled }) => (toggled ? 'black' : '#e6e6e6')}; 
    transition: background 0.2s;
    position: relative;
`

export const ToggleCircle = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: transform 0.2s;
    transform: ${({ toggled }) => (toggled ? 'translateX(28px)' : 'translateX(0px)')};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`

export const ToggleText = styled.span`
    color: ${({ toggled, isYes }) => (toggled === isYes ? '#ffffff' : '#000000')};
    font-size: 10px;
    user-select: none;
    position: absolute;
    left: ${({ toggled }) => (toggled ? '5px' : '20px')};
    color: ${({ toggled }) => (toggled ? '#ffffff' : '#000000')};
`

export const MoneySelect = styled.select`
    width: 100%;
    height: 40px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    font-size: 15px;
    font-family: 'DM sans', sans-serif;
    font-weight: 500;
    outline: none;
    &:focus {
        border: 1px solid #2F80ED;
    }
`

