import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import { DiscoverUsersWrapper } from './DiscoverUsersElements'
import { ArtistsContainer, ArtistsWrapper } from '../DiscoverArtists/DiscoverArtistElements'
import UserCard from './UserCard'
import FilterSection from '../DiscoverArtists/FilterSection'
import Pagination from '../Discover/Pagination'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingAsset'

import { NoProjects } from '../Discover/DiscoverElements'

const DiscoverUsersComponent = ({ initialUsers }) => {
    const [users, setUsers] = useState(initialUsers)
    const [searchTerm, setSearchTerm] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 25,
        totalPages: 0,
        totalUsers: 0
    })
    const [isLoading, setIsLoading] = useState(false)

    const fetchUsers = useCallback(async (search, page, limit) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/discover-users/users`, {
                params: { search, page, limit }
            })
            const { users, pagination } = response.data
            setUsers(users)
            setPagination(pagination)
        } catch (error) {
            console.error('Error fetching users:', error)
            toast.error('Failed to fetch users. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchUsers(searchTerm, pagination.page, pagination.limit)
    }, [fetchUsers, searchTerm, pagination.page, pagination.limit])

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase()
        setSearchTerm(newSearchTerm)
        setPagination(prev => ({ ...prev, page: 1 })) // Reset to page 1 on new search
    }

    const handlePageChange = (newPage) => {
        setPagination(prev => ({ ...prev, page: newPage }))
    }

    return (
        <DiscoverUsersWrapper>
            <h1>Discover Investors</h1>
            <FilterSection handleSearchChange={handleSearchChange} />
            {isLoading ? (
                <div style={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LoadingComponent />
                </div>
            )
            : users?.length > 0 ?
            (
                <>
                    <ArtistsWrapper>
                        <ArtistsContainer>
                            {users?.map((user) => (
                                <UserCard key={user?._id} user={user} />
                            ))}
                        </ArtistsContainer>
                    </ArtistsWrapper>
                    <Pagination
                        currentPage={pagination.page}
                        totalPages={pagination.totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            )
            : 
            (
                <div style={{ width: '100%', marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                    <NoProjects>
                        There are currently no investors that fit this description.
                    </NoProjects>
                </div>
            )
            }
        </DiscoverUsersWrapper>
    )
}

export default DiscoverUsersComponent