import axios from 'axios'
import { API_URL } from '../../../../config'

export const getUpdatesApi = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/updates/get-updates/me`, {
            params: {
                project: projectId
            }
        })

        return response
    } catch (e) {
        console.error(e)
    }
}

//UPDATE APIS ARE NOT HERE ARE IN USEHANDLEUPDATES FUNCTION
export const deleteExistingUpdate = async (deletedElementIds, updateId, projectId) => {
    try {
        const response = await axios.delete(`${API_URL}/updates/existing-delete-updates/me`, { data: { elementIds: deletedElementIds, updateId, projectId }, withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const deleteMedia = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/updates/delete-media/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const addExistingUpdate = async (payload) => {
    try {
        const response = await axios.put(`${API_URL}/updates/existing-add-updates/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const addUpdate = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/updates/add-updates/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const deleteUpdate = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/updates/delete-update/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const updateExistingTitle = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/updates/existing-title-update/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const editExistingUpdate = async (payload) => {
    try {
        const response = await axios.put(`${API_URL}/updates/existing-edit-updates/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const multiGraphicUpdates = async (projectSlug, formData) => {
    try {
        const response = await axios.post(`${API_URL}/updates/body/multi-graphic/${projectSlug}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const setCoverFile = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/updates/set-cover-file/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const unsetCoverFile = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/updates/unset-cover-file/me`, payload, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const addLike = async (updateId, artistId, slug, artistProfileImage, page, limit) => {
    const payload = {
        artistId, 
        slug, 
        artistProfileImage,
        page,
        limit
    }

    const response = await axios.post(`${API_URL}/updates/add-like/${updateId}`, payload, {withCredentials: true})

    return response
}

export const removeLike = async (updateId) => {
    const response = await axios.post(`${API_URL}/updates/remove-like/${updateId}`, {}, {withCredentials: true})

    return response
}

export const addComment = async (
    id,
    projectId,
    updateId,
    userId,
    comment,
    commentBy,
    isCreator,
    date
    ) => {
        const payload = {
            id,
            projectId,
            updateId,
            userId,
            comment,
            commentBy,
            isCreator,
            date
        }

    try {
        axios.post(`${API_URL}/updates/add-comment`, payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const updateUpdateChildComment = async (userId, commentId, updatedChildComment) => {

    const payload = {
        updatedChildComment
    } 

    try {
        return axios.post(`${API_URL}/updates/update-child-comment/${commentId}`, payload, { withCredentials: true })
    } catch (e) {
        console.error(e)
    } 
}

export const deleteUpdateChildComment = async (commentId, replyId) => {
    try {
        return axios.delete(`${API_URL}/updates/delete-child-comment/${commentId}/${replyId}`, { withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

export const showOrLoadMoreReplies = async (parentId, pageCount, limit, isChildComments, childCommentAdds) => {
    try {
        return axios.get(`${API_URL}/updates/childComments/${parentId}`, 
        { params: { page: pageCount, limit, isChildComments, childCommentAdds }, withCredentials: true })
    } catch (e) {
        console.error(e)
    }
}

//FOLLOW
export const followUser = async (username, updateId, page, limit) => {
    try {
        const response = await axios.post(`${API_URL}/updates/follow-user/me`, { username, updateId, page, limit }, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const followArtist = async (slug, updateId, page, limit) => {
    try {
        const response = await axios.post(`${API_URL}/updates/follow-artist/me`, { slug, updateId, page, limit }, { withCredentials: true })

        return response.data
    } catch (error) {
        console.log(error)
    }
}