import React, { useState } from 'react'

import {
    InputWrapper,
    InputContainer,
    FormContainer,
    SubTitle,
    ErrorContainer,
    ErrorMessage,
    StepHeader,
    CheckboxWrapper,
    CheckboxInput,
    CheckboxLabel,
    StepsContainer,
    Next,
    Back
} from './ArtistForms/ArtistFormsElements'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

function StepThree({ setCurrentStep, setModalOpen }) {
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [isConsented, setIsConsented] = useState(false)
    const [isConfirmInvalid, setIsConfirmInvalid] = useState(false)
    const [isConsentInvalid, setIsConsentInvalid] = useState(false)

    const handleCheckboxClick = (e, setCheck, setIsInvalid) => {
        setCheck(e.target.checked)
        if (e.target.checked === true) {
            setIsInvalid(false)
        }
    }

    const handleNextStep = () => {
        if (!isConfirmed) {
            setIsConfirmInvalid(true)
        }

        if (!isConsented) {
            setIsConsentInvalid(true)
        }

        if (isConfirmed && isConsented) {
            setIsConfirmInvalid(false)
            setIsConsentInvalid(false)
            setModalOpen(true)
        }
    }

    return (
        <>
            <StepHeader>Consent and Confirmation of Works</StepHeader>
                <FormContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle isInvalid={isConfirmInvalid} >Check here to confirm that none of your submitted works contain any infringing, unauthorized, or unoriginal intellectual property.</SubTitle>
                            {isConfirmInvalid && <ErrorContainer><ErrorMessage>This is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                            <CheckboxWrapper>
                                <CheckboxInput>
                                    <input type="checkbox" onClick={e => handleCheckboxClick(e, setIsConfirmed, setIsConfirmInvalid)}/>
                                    <span></span>
                                </CheckboxInput>
                                <CheckboxLabel>Confirm</CheckboxLabel>
                            </CheckboxWrapper>
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle isInvalid={isConsentInvalid} >I acknowledge and consent to the invest.muugen.com Terms of Service and Privacy Policy.</SubTitle>
                            {isConsentInvalid && <ErrorContainer><ErrorMessage>This is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                            <CheckboxWrapper>
                                <CheckboxInput>
                                    <input type="checkbox" onClick={e => handleCheckboxClick(e, setIsConsented, setIsConsentInvalid)}/>
                                    <span></span>
                                </CheckboxInput>
                                <CheckboxLabel>Confirm</CheckboxLabel>
                            </CheckboxWrapper>
                        </InputWrapper>
                    </InputContainer>
                </FormContainer>
                <StepsContainer>
                    <Back onClick={() => setCurrentStep(prev => prev - 1)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} /> Works</Back>
                    <Next onClick={handleNextStep}>Next: Review</Next>
                </StepsContainer>
        </>
    )
}

export default StepThree