import React, { useState } from 'react'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@tanstack/react-query'
import { API_URL } from '../../../config'
import LoadingComponent from '../../LoadingAsset'

import {
    LeftHalfWrapper,
    OptionButton,
    RightHalfWrapper,
    PortfolioHeader,
} from './PortfolioElements'

import { 
    OptionSection,
    OptionInfo, 
} from '../Account/AccountElements'

import {
    Back
} from '../InvestmentProfileElements'

import Project from './Project'

import { MdChevronRight, MdChevronLeft } from "react-icons/md"

const fetchInvestments = async () => {
    const response = await axios.get(`${API_URL}/investment-profile/investments/me`, { withCredentials: true })
    return response.data
}

const Portfolio = () => {
    const [selectedOption, setSelectedOption] = useState('active')

    const { data: investments = [], isLoading, isError, error } = useQuery({
        queryKey: ['investments'],
        queryFn: fetchInvestments
    })

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 760px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 760px)'
    })

    const optionHeaders = {
        'active': 'Active Investments',
        'completed': 'Completed Investments',
        'cancelled': 'Cancelled Investments',
        'performance': 'Portfolio Performance'
    }

    const noDataMessages = {
        'active': 'No current active investments',
        'completed': 'No completed investments',
        'cancelled': 'No cancelled investments',
        'performance': 'No data available for portfolio performance'
    }

    // Filter investments into separate categories
    const activeInvestments = investments?.filter(investment => 
        investment?.investmentStatus === 'ACTIVE' && investment?.project?.projectStatus === 'FUNDING'
    )

    const completedInvestments = investments?.filter(investment => 
        (investment?.project?.projectStatus === 'FUNDED' || investment?.project?.projectStatus === 'COMPLETED') && investment?.investmentStatus !== 'CANCELLED'
    )

    const cancelledInvestments = investments?.filter(investment =>
        investment?.investmentStatus === 'CANCELLED'
    )

    const renderInvestments = (investmentArray) => {
        let skippedInvestments = 0;
        
        const renderedInvestments = investmentArray?.map(investment => {
            if (!investment || !investment.project) {
                console.error('Invalid investment data:', investment)
                skippedInvestments++
                return null // Skip this investment
            }
    
            return (
                <Project 
                    key={investment?._id}
                    projectName={investment?.project?.projectName}
                    amount={investment?.investmentAmount}
                    msg={investment?.investmentStatus === 'CANCELLED' ? 'You cancelled this investment on' : 'You invested in this project on'}
                    date={new Date(investment?.timeOfInvestment).toLocaleDateString()}
                    addMsg={investment?.project?.projectStatus === 'FUNDING' ? '30 days left' : (investment.project.projectStatus === 'COMPLETED' ? '$4.5M raised' : '')}
                    color={investment?.investmentStatus === 'CANCELLED' ? '#EE9322' : 'green'}
                    src={investment?.project?.coverImagePath}
                    strike={investment?.investmentStatus === 'CANCELLED'}
                />
            )
        }).filter(Boolean) // Remove null entries
    
        if (skippedInvestments > 0) {
            renderedInvestments.push(
                <div key="incomplete-data-message" style={{color: 'red', marginTop: '10px'}}>
                    {skippedInvestments} investment(s) couldn&apos;t be displayed due to incomplete data.
                </div>
            )
        }
    
        return renderedInvestments;
    }

    if (isLoading) {
        return (
            <div style={{ marginTop: 50 }}>
                <LoadingComponent />
            </div>
        )
    }

    if (isError) {
        return <div>Error: {error.message}</div>
    }

    return (
        <div>
            {isDesktopOrLaptop && (
            <div style={{ maxWidth: '1200px', overflowX: 'hidden', display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
                <LeftHalfWrapper>
                    <OptionButton
                        selected={selectedOption === 'active'}
                        onClick={() => setSelectedOption('active')}
                    >Active Investments</OptionButton>
                    <OptionButton
                        selected={selectedOption === 'completed'}
                        onClick={() => setSelectedOption('completed')}
                    >Completed Investments</OptionButton>
                    <OptionButton
                        selected={selectedOption === 'cancelled'}
                        onClick={() => setSelectedOption('cancelled')}
                    >Cancelled Investments</OptionButton>
                    <OptionButton
                        selected={selectedOption === 'performance'}
                        onClick={() => setSelectedOption('performance')}
                    >Portfolio Performance</OptionButton>
                </LeftHalfWrapper>
                <RightHalfWrapper>
                    <PortfolioHeader>{optionHeaders[selectedOption]}</PortfolioHeader>
                    {selectedOption === 'active' && (activeInvestments?.length > 0 ? renderInvestments(activeInvestments) : <div>{noDataMessages['active']}</div>)}
                    {selectedOption === 'completed' && (completedInvestments?.length > 0 ? renderInvestments(completedInvestments) : <div>{noDataMessages['completed']}</div>)}
                    {selectedOption === 'cancelled' && (cancelledInvestments?.length > 0 ? renderInvestments(cancelledInvestments) : <div>{noDataMessages['cancelled']}</div>)}
                </RightHalfWrapper>
            </div>
            )}
            {isTabletOrMobile && (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                {selectedOption === 'Menu' && (
                    <>
                        <OptionSection onClick={() => setSelectedOption('active')}>
                            <OptionInfo>Active Investments</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('completed')}>
                            <OptionInfo>Completed Investments</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('cancelled')}>
                            <OptionInfo>Cancelled Investments</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                        <OptionSection onClick={() => setSelectedOption('performance')}>
                            <OptionInfo>Portfolio Performance</OptionInfo>
                            <OptionInfo><MdChevronRight /></OptionInfo>
                        </OptionSection>
                    </>
                )}
                {selectedOption !== 'Menu' && <Back onClick={() => setSelectedOption('Menu')}><MdChevronLeft size={25} /> Back</Back>}
                <PortfolioHeader>{optionHeaders[selectedOption]}</PortfolioHeader>
                {selectedOption === 'active' && (activeInvestments?.length ? renderInvestments(activeInvestments) : <div>{noDataMessages['active']}</div>)}
                {selectedOption === 'completed' && (completedInvestments?.length > 0 ? renderInvestments(completedInvestments) : <div>{noDataMessages['completed']}</div>)}
                {selectedOption === 'cancelled' && (cancelledInvestments?.length > 0 ? renderInvestments(cancelledInvestments) : <div>{noDataMessages['cancelled']}</div>)}
            </div>
            )}
        </div>
    )
}

export default Portfolio