import React, { useState } from 'react'

import {
    InputWrapper,
    InputContainer,
    FormContainer,
    Title,
    SubTitle,
    ErrorContainer,
    ErrorMessage,
    Input,
    StepHeader,
    Button,
    StepsContainer,
    Next
} from './ArtistForms/ArtistFormsElements'

import { AiOutlineExclamationCircle } from 'react-icons/ai'

function StepOne({
    setCurrentStep,
    artistFirstName,
    setArtistFirstName,
    artistLastName,
    setArtistLastName,
    artistEmail,
    setArtistEmail,
    isUSCitizen,
    setIsUSCitizen
}) {
    const [isFirstNameInvalid, setIsFirstNameInvalid] = useState(false)
    const [isLastNameInvalid, setIsLastNameInvalid] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isUSCitizenInvalid, setIsUSCitizenInvalid] = useState(false)

    const handleNextStep = () => {
        if (!artistFirstName) {
            setIsFirstNameInvalid(true)
        } 

        if (!artistLastName) {
            setIsLastNameInvalid(true)
        }

        if (!artistEmail) {
            setIsEmailInvalid(true)
        }

        if (isUSCitizen === null) {
            setIsUSCitizenInvalid(true)
        }

        if (artistFirstName && artistLastName && artistEmail && isUSCitizen !== null) {
            setIsFirstNameInvalid(false)
            setIsLastNameInvalid(false)
            setIsEmailInvalid(false)
            setIsUSCitizenInvalid(false)
            setCurrentStep(prev => prev + 1)
        }
    }

    const handleChange = (e, setInput, setIsValid) => {
        if (e.target.value !== '') {
            setIsValid(false)
        }
        setInput(e.target.value)
    }

    const handleCitizenClick = (citizenStatus) => {
        setIsUSCitizenInvalid(false)
        setIsUSCitizen(citizenStatus)
    }

    return (
        <>
            <StepHeader>Basic Info</StepHeader>
                <FormContainer>
                    <InputContainer style={{ gap: '10px' }}>
                        <InputWrapper>
                            <SubTitle isInvalid={isFirstNameInvalid}>First Name</SubTitle>
                            <Input isInvalid={isFirstNameInvalid} placeholder='Akira' onChange={(e) => handleChange(e, setArtistFirstName, setIsFirstNameInvalid)} value={artistFirstName}/>
                            {isFirstNameInvalid && <ErrorContainer><ErrorMessage>First name is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                        </InputWrapper>
                        <InputWrapper>
                            <SubTitle isInvalid={isLastNameInvalid} >Last Name</SubTitle>
                            <Input isInvalid={isLastNameInvalid} placeholder='Kurosawa' onChange={(e) => handleChange(e, setArtistLastName, setIsLastNameInvalid)} value={artistLastName}/>
                            {isLastNameInvalid && <ErrorContainer><ErrorMessage>Last name is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper>
                            <SubTitle isInvalid={isEmailInvalid} >Email</SubTitle>
                            <Input isInvalid={isEmailInvalid} placeholder='FilmSamurai@mail.com' style={{ width: '95%' }} onChange={(e) => handleChange(e, setArtistEmail, setIsEmailInvalid)} value={artistEmail}/>
                            {isEmailInvalid && <ErrorContainer><ErrorMessage>Email is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper>
                            <Title isInvalid={isUSCitizenInvalid} style={isUSCitizenInvalid ? {} : { marginBottom: 20 }}>Are you a US citizen or do you currently reside in the US?</Title>
                            {isUSCitizenInvalid && <ErrorContainer><ErrorMessage>This question is required</ErrorMessage><AiOutlineExclamationCircle color='red' /></ErrorContainer>}
                            <div style={{ display: 'flex' }}>
                                <Button isActive={isUSCitizen === true} onClick={() => handleCitizenClick(true)} >Yes</Button>
                                <Button style={{ marginLeft: 10 }} isActive={isUSCitizen === false} onClick={() => handleCitizenClick(false)} >No</Button>
                            </div>
                        </InputWrapper>
                    </InputContainer>
                </FormContainer>
                <StepsContainer>
                    <div></div>
                    <Next onClick={handleNextStep}>Next: Works</Next>
                </StepsContainer>
        </>
    )
}

export default StepOne