import React, { useState } from 'react'

import { PictureContainer, Picture } from './ProfilePictureElements'
import { FaUser } from "react-icons/fa"

function ProfilePicture({ image, artistId }) {
    const [imageError, setImageError] = useState(false)

    const handleImageError = () => {
        setImageError(true)
    }

    return (
        <PictureContainer>
            {!imageError && image ? 
                <Picture 
                    src={`${image}`} 
                    alt='Filmmaker Profile' 
                    onError={handleImageError}
                /> :
                <FaUser size={70} color={'black'} />
            }
        </PictureContainer>
    )
}

export default ProfilePicture
