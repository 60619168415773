import React, { useRef, useState, useEffect, Suspense } from 'react'
import { AWS_URL } from '../../../../../config'
import { followUser, followArtist } from '../updatesApi'
import { useNavigate } from 'react-router-dom'
import LoadingComponent from '../../../../LoadingAsset'

import { 
    BodySection,
    TitleContainer,
    BodySectionTitle,
    BodySectionSubtitle,
    ProfilePic, 
    FollowButton,
    ImageError,
} from './LikesModalElements.js'

import { FaUser } from 'react-icons/fa'

const UserModal = React.lazy(() => import('./UserModal'))

const useHover = (ref) => {
    const [isHovered, setIsHovered] = useState(false)
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 })
    const hoverDelay = 500
    const offDelay = 300
    let hoverTimeout = null
    let leaveTimeout = null

    const on = (event) => {
        if (leaveTimeout) {
            clearTimeout(leaveTimeout)
        }
        hoverTimeout = setTimeout(() => {
            setIsHovered(true)
            if(ref.current){
                const rect = ref.current.getBoundingClientRect();
                setMousePos({ x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 })
            }
        }, hoverDelay)
    }
  
    const off = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout)
        }

        // Introduce a delay before setting isHovered to false
        leaveTimeout = setTimeout(() => {
            setIsHovered(false)
        }, offDelay) 
    }
  
    useEffect(() => {
      if (!ref.current) {
        return
      }
      const node = ref.current
  
      node.addEventListener('mouseenter', on)
      node.addEventListener('mouseleave', off)
  
      return () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout)
        }
        if (leaveTimeout) {
            clearTimeout(leaveTimeout)
        }
        node.removeEventListener('mouseenter', on)
        node.removeEventListener('mouseleave', off)
      }

      //eslint-disable-next-line
    }, [ref, leaveTimeout, hoverTimeout])
  
    return { isHovered, mousePos }
}

const LikeComponent = ({ updateId, page, limit, user, setUserProfiles, loggedInUser }) => {
    const [imageError, setImageError] = useState(false)
    const likeContainerRef = useRef(null)
    const userModalRef = useRef(null)
    const hoverRef = useRef(null)

    const navigate = useNavigate()

    const { isHovered, mousePos, } = useHover(likeContainerRef)

    const isBelowMidPoint = mousePos.y > window.innerHeight / 2

    const handleFollowClick = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (user.artist) {
          if (!user.isFollowed) {
            try {
              const response = await followArtist(user.artistSlug, updateId, page, limit)
              if (response) {
                // Update the userProfiles state by finding the matching user._id and setting isFollowed to true
                setUserProfiles((prevProfiles) => {
                  return prevProfiles.map((profile) => {
                    if (profile.userId === user.userId) {
                      return { ...profile, isFollowed: true }
                    }
                    return profile
                  })
                })
                // Optionally, you can show a success message or update the UI
              }
            } catch (error) {
              console.log(error)
              // Handle the error, show an error message, or update the UI
            }
          } else {
            try {
              const response = await followArtist(user.artistSlug, updateId, page, limit)
              if (response) {
                // Update the userProfiles state by finding the matching user._id and setting isFollowed to false
                setUserProfiles((prevProfiles) => {
                  return prevProfiles.map((profile) => {
                    if (profile.userId === user.userId) {
                      return { ...profile, isFollowed: false }
                    }
                    return profile
                  })
                })
                // Optionally, you can show a success message or update the UI
              }
            } catch (error) {
              console.log(error)
              // Handle the error, show an error message, or update the UI
            }
          }
        } else {
          if (!user.isFollowed) {
            try {
              const response = await followUser(user.username, updateId, page, limit)
              if (response) {
                // Update the userProfiles state by finding the matching user._id and setting isFollowed to true
                setUserProfiles((prevProfiles) => {
                  return prevProfiles.map((profile) => {
                    if (profile.userId === user.userId) {
                      return { ...profile, isFollowed: true }
                    }
                    return profile
                  })
                })
                // Optionally, you can show a success message or update the UI
              }
            } catch (error) {
              console.log(error)
              // Handle the error, show an error message, or update the UI
            }
          } else {
            try {
              const response = await followUser(user.username, updateId, page, limit)
              if (response) {
                // Update the userProfiles state by finding the matching user._id and setting isFollowed to false
                setUserProfiles((prevProfiles) => {
                  return prevProfiles.map((profile) => {
                    if (profile.userId === user.userId) {
                      return { ...profile, isFollowed: false }
                    }
                    return profile
                  })
                })
              }
            } catch (error) {
              console.log(error)
              // Handle the error, show an error message, or update the UI
            }
          }
        }
      }

      const handleProfileClick = () => {
        document.body.style.overflow = ''
        window.scrollTo(0, 0)
        if (user?.artist?._id)
            navigate(`/artist-profile/${user.artistSlug}`)
        else
            navigate(`/user-profile/${user.username}`)
      }
  
    return (
        <BodySection onClick={handleProfileClick}>
            <div style={{ display: 'flex', position: 'relative' }} ref={likeContainerRef}>
                <Suspense fallback={<LoadingComponent />}>
                    <UserModal 
                        isHovered={isHovered} 
                        isBelowMidPoint={isBelowMidPoint} 
                        mousePos={mousePos}
                        userModalRef={userModalRef} 
                        user={user} 
                        updateId={updateId}
                        page={page}
                        limit={limit}
                        hoverRef={hoverRef} 
                        loggedInUser={loggedInUser}
                        setUserProfiles={setUserProfiles}
                    />
                </Suspense>
                {!imageError ?
                    <ProfilePic 
                        src={`${AWS_URL}${user?.profilePicture}`} 
                        onError={() => setImageError(true)}
                    />
                :
                    <ImageError>
                        <FaUser />
                    </ImageError>
                }
                <TitleContainer>
                    <BodySectionTitle>{user?.artist ? user?.artist?.artistName : user?.username}</BodySectionTitle>
                    <BodySectionSubtitle>{user?.artist?._id ? 'Artist' : 'Investor'}</BodySectionSubtitle>
                </TitleContainer>
            </div>
            {loggedInUser?.id !== user?.userId && 
              <FollowButton 
                onClick={handleFollowClick}
                className={user?.isFollowed ? 'unfollow' : 'follow'}
              >
                {user?.isFollowed ? 'Unfollow' : 'Follow'}
              </FollowButton>
            }
        </BodySection>
    )
}

export default LikeComponent