import styled from 'styled-components'

export const StyledSelect = styled.select`
  width: 200px;
  height: 40px;
  margin-top: 20px;
  background: white;
  padding-left: 5px;
  font-size: 16px;
  border: none;
  border: 1px solid #eee;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`

export const FollowBarContainer = styled.div`
    margin-top: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'DM sans', serif;
`

export const FollowButton = styled.button`
    height: 30px;
    border: none;
    background: transparent;
    /* margin: 0 10px; */
    cursor: pointer;
    font-family: 'DM sans', serif;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border-bottom: ${props => props.selected ? '2px solid black' : 'none'};
`

export const FollowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; 
`

export const FollowContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 250px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;/* subtracting potential margins */
    margin-top: 10px;
 /* adjust margin as desired */

    &:hover {
        box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
        transform: translateY(-5px);
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`

export const ProfilePicture = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
`

export const NoImageWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    margin-right: 10px;
`

export const Name = styled.div`
    font-family: 'DM sans', serif;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
`

export const Description = styled.div`
    font-family: 'DM sans', serif;
    font-size: 14px;
    margin-top: 5px;
`

export const UnfollowButton = styled.button`
    width: 100%;
    height: 30px;
    border: none;
    background: black;
    color: white;
    margin-top: 10px;
    cursor: pointer;
    font-family: 'DM sans', serif;
    font-size: 14px;
    font-weight: 500;
    outline: none;

    &:hover {
        background-color: #333333;
    }
`

export const ShowMoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ShowMoreButton = styled.button`
  width: 300px;
  height: 40px;
  border: none;
  background: #f0f0f0;
  color: #333333;
  margin-top: 20px;
  cursor: pointer;
  font-family: 'DM sans', serif;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;