import React, { useState, useEffect } from 'react'

import { 
    Wrapper,
    ModalBg,
} from '../../ProjectProfile/Modal/EditModalElements'

import { 
    InfoCardTitle,
    FaqWrapper,
    FaqTitleWrapper,
    PdfName,
    FaqInfo,
    RotatedIcon,
    ProgressBarWrapper,
    ProgressBarAmount,
    ProgressBar, 
    Progress,
    MobileRiseModal, 
    ModalDropWrapper,
    ModalDropText
} from '../ProjectInvestElements'

import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

function MobileRiseMenu({ project, showMobileRiseMenu, setShowMobileRiseMenu, calculateProgressWidth, addCommasToNumber }) {
    const [openFaqs, setOpenFaqs] = useState([])
    const [modalActive, setModalActive] = useState(false)

    useEffect(() => {
        if (showMobileRiseMenu) {
            setModalActive(true)
        } else {
            setTimeout(() => {
                setModalActive(false)
            }, 500)
        }
    }, [showMobileRiseMenu])

    const handleClick = (index) => {
        if (openFaqs.includes(index)) {
            setOpenFaqs(prevState => prevState.filter(item => item !== index))
        } else {
            setOpenFaqs(prevState => [...prevState, index])
        }
    }

    const handleClose = () => {
        setModalActive(false)
        setTimeout(() => {
            setShowMobileRiseMenu(false)
        }, 300)
    }

    return (
        <Wrapper>
            <ModalBg>
                <MobileRiseModal className={modalActive ? 'active' : ''}>
                    <InfoCardTitle>Frequently Asked Questions</InfoCardTitle>
                    <ProgressBarWrapper>
                        <ProgressBarAmount>${addCommasToNumber(project?.currentFunds)}</ProgressBarAmount>
                        <ProgressBar >
                            <Progress style={{ width: calculateProgressWidth() }} />
                        </ProgressBar>
                        <ProgressBarAmount style={{ marginTop: 4, color: 'black' }}>of ${addCommasToNumber(project?.targetFundAmount)}</ProgressBarAmount>
                    </ProgressBarWrapper>
                    <FaqWrapper  
                        onClick={() => handleClick(0)}
                        style={{ width: '100%', marginTop: 20 }}
                    >
                        <FaqTitleWrapper>
                            <RotatedIcon
                                size={25} 
                                rotated={openFaqs.includes(0) ? 'rotated' : ''}
                            />
                            <PdfName>When will I be charged?</PdfName>
                        </FaqTitleWrapper>
                        {openFaqs.includes(0) &&
                            <FaqInfo>When you invest in a project, you will be charged immediately after clicking the finish investment button at the bottom of the page.</FaqInfo>
                        }
                    </FaqWrapper>
                    <FaqWrapper 
                        onClick={() => handleClick(1)} 
                        style={{ width: '100%', marginTop: 20 }}
                    >
                        <FaqTitleWrapper>
                            <RotatedIcon
                                size={25} 
                                rotated={openFaqs.includes(1) ? 'rotated' : ''}
                            />
                            <PdfName>Can I edit my investment amount?</PdfName>
                        </FaqTitleWrapper>
                        {openFaqs.includes(1) &&
                            <>
                                <FaqInfo>You can edit your investment amount up until 72 hours before the campaign closes.</FaqInfo>
                                <FaqInfo>You can change your investment by going to your profile page, locate the &apos;Your Investments&apos; tab and click &apos;Edit Investment&apos; on the desired project.</FaqInfo>
                            </>
                        }
                    </FaqWrapper>
                    <FaqWrapper 
                        onClick={() => handleClick(2)} 
                        style={{ width: '100%', marginTop: 20 }}
                    >
                        <FaqTitleWrapper>
                            <RotatedIcon
                                size={25} 
                                rotated={openFaqs.includes(2) ? 'rotated' : ''}
                            />
                            <PdfName>Can I cancel my investment?</PdfName>
                        </FaqTitleWrapper>
                        {openFaqs.includes(2) &&
                            <FaqInfo>You can cancel your investment up until 72 hours before the campaign closes. We will email you 7 days before the campaign ends (if you invest before then).</FaqInfo>
                        }
                    </FaqWrapper>
                    <FaqWrapper 
                        onClick={() => handleClick(3)} 
                        style={{ width: '100%', marginTop: 20 }}
                    >
                        <FaqTitleWrapper>
                            <RotatedIcon
                                size={25} 
                                rotated={openFaqs.includes(3) ? 'rotated' : ''}
                            />
                            <PdfName>What do I get from my investment?</PdfName>
                        </FaqTitleWrapper>
                        {openFaqs.includes(3) &&
                            <>
                                <FaqInfo>By investing now, you will receive an equity stake in this project. However, this does not mean you will acquire equity in the underlying company. Should the project offer perks, you will be eligible to receive them, provided your investment amount qualifies.</FaqInfo>
                                <FaqInfo>Please remember, such investments come with significant risks. We advise that you invest in a project not merely for potential returns but because it resonates with you and represents a story you&apos;re eager to support.</FaqInfo>
                            </>
                        }
                    </FaqWrapper>
                    <FaqWrapper 
                        onClick={() => handleClick(4)} 
                        style={{ width: '100%', marginTop: 20, marginBottom: 40 }}
                    >
                        <FaqTitleWrapper>
                            <RotatedIcon
                                size={25} 
                                rotated={openFaqs.includes(4) ? 'rotated' : ''}
                            />
                            <PdfName>How do I get a return on my investment?</PdfName>
                        </FaqTitleWrapper>
                        {openFaqs.includes(4) &&
                            <>
                                <FaqInfo>If it turns out the project you invested in is successful, you will split profits with the creators based upon your equity split. If the investor offers a bonus return, you&apos;ll receive all profits until the specified bonus return amount is reached.</FaqInfo>
                                <FaqInfo> While our platform provides essential details about your investments, it&apos;s the project creator&apos;s responsibility to issue updates on the investment&apos;s progress and ensure funds are distributed appropriately.</FaqInfo>
                            </>
                        }
                    </FaqWrapper>
                    <ModalDropWrapper onClick={handleClose}>
                        <ModalDropText>Close</ModalDropText>
                        <MdOutlineKeyboardArrowDown size={30} />
                    </ModalDropWrapper>
                </MobileRiseModal>
            </ModalBg>
        </Wrapper>
    )
}

export default MobileRiseMenu