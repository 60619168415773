import React, { useState, useRef } from 'react'
import { getCurrentInfoValue, isFieldFilled } from '../helpers/reducerHelper'

import { 
    Wrapper,
    ModalBg,
    Header,
    HeaderText,
} from '../../ProjectProfile/Modal/EditModalElements'

import { 
    Modal, 
    Body, 
    PlaidButton,
    HorizontalLine,
    DividerText,
    PaymentInfo,
    ManualWrapper,
    AdditionalInfo,
    InvestmentAmountWrapper,
    InvestmentInfo,
    RadioButtonWrapper,
    RadioButton,
    StyledLabel,
    BorderBottom,
    SubmitButton,
} from '../ProjectInvestElements'

import { IoClose } from 'react-icons/io5'

function BankAccountModal ({ state, dispatch, setShowBankModal }) {
    const modalRef = useRef(null)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''   
            dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Payment Type' } }) 
            setShowBankModal(false)
        }
    }

    const closeModalExit = e => {
        document.body.style.overflow = ''
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: '', label: 'Payment Type' } }) 
        setShowBankModal(false)
    }

    const areAllRequiredFieldsFilled = (state) => {
        const requiredFields = [
          'Account Type',
          'Name On Account',
          'Bank Name',
          'Routing Number',
          'Account Number',
        ]
      
        const invalidFields = requiredFields.filter(field => {
          const item = state.store.find(item => item.label === field)
          return item ? item.unFilled : false
        })
      
        return invalidFields
    }

    const handleSubmitClick = () => {
        const invalidFields = areAllRequiredFieldsFilled(state)
        setHasSubmitted(true)
        if (invalidFields.length === 0) {
            document.body.style.overflow = ''
            setShowBankModal(false)
        } else {
            return
        }
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal} >
                <Modal style={{ top: 50, }}>
                    <Header>
                        <HeaderText style={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 700 }}>Bank Account</HeaderText>
                        <IoClose size={30} onClick={closeModalExit} style={{ cursor: 'pointer' }} />
                    </Header>
                    <Body style={{ height: 650 }}>
                        <PlaidButton>Select with Plaid</PlaidButton>
                        <HorizontalLine><DividerText>or add manually</DividerText></HorizontalLine>
                        <InvestmentAmountWrapper style={{ marginTop: 10 }} >
                            <InvestmentInfo style={{ marginTop: 0 }} >Account Type</InvestmentInfo>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <RadioButtonWrapper onClick={() => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'checking', label: 'Account Type' }})}>
                                    <StyledLabel isInvalid={hasSubmitted && isFieldFilled('Account Type', state)} >
                                        <RadioButton
                                            type='radio'
                                            name='accountType'
                                            value='checking' 
                                            checked={getCurrentInfoValue('Account Type', state) === 'checking'}
                                            style={{ marginRight: 5 }}
                                            readOnly
                                        />
                                        Checking
                                    </StyledLabel>
                                </RadioButtonWrapper>
                                <RadioButtonWrapper style={{ marginLeft: 30 }} onClick={() => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: 'savings', label: 'Account Type' }})}>
                                    <StyledLabel isInvalid={hasSubmitted && isFieldFilled('Account Type', state)}>
                                        <RadioButton
                                            type='radio' 
                                            name='accountType'
                                            value='savings' 
                                            checked={getCurrentInfoValue('Account Type', state) === 'savings'}
                                            isInvalid={isFieldFilled('Account Type', state)} 
                                            style={{ marginRight: 5 }}
                                            readOnly
                                        />
                                        Savings
                                    </StyledLabel>
                                </RadioButtonWrapper>
                            </div>
                        </InvestmentAmountWrapper>
                        <ManualWrapper>
                            <PaymentInfo 
                                placeholder='Name on Account' 
                                value={getCurrentInfoValue('Name On Account', state)}
                                isInvalid={hasSubmitted && isFieldFilled('Name On Account', state)} 
                                onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Name On Account' } })}
                            />
                            <PaymentInfo 
                                placeholder='Bank Name' 
                                value={getCurrentInfoValue('Bank Name', state)}
                                isInvalid={hasSubmitted && isFieldFilled('Bank Name', state)} 
                                onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Bank Name' } })}
                            />
                            <div style={{ display: 'flex' }}>
                                <AdditionalInfo 
                                    placeholder='Routing Number' 
                                    maxLength={9}
                                    value={getCurrentInfoValue('Routing Number', state)}
                                    isInvalid={hasSubmitted && isFieldFilled('Routing Number', state)} 
                                    onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Routing Number' } })}
                                />
                                <AdditionalInfo 
                                    style={{ marginLeft: 10 }} 
                                    placeholder='Account Number' 
                                    maxLength={17}
                                    value={getCurrentInfoValue('Account Number', state)}
                                    isInvalid={hasSubmitted && isFieldFilled('Account Number', state)} 
                                    onChange={e => dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Account Number' } })}
                                />
                            </div>
                        </ManualWrapper>
                        <InvestmentAmountWrapper>
                            <InvestmentInfo>Your Investment</InvestmentInfo>
                            <InvestmentInfo style={{ fontWeight: 700 }}>$100</InvestmentInfo>
                        </InvestmentAmountWrapper>
                        <InvestmentAmountWrapper>
                            <InvestmentInfo>Escrow Agent Fee</InvestmentInfo>
                            <InvestmentInfo style={{ fontWeight: 700 }}>$0.00</InvestmentInfo>
                        </InvestmentAmountWrapper>
                        <BorderBottom />
                        <InvestmentAmountWrapper>
                            <InvestmentInfo>Total</InvestmentInfo>
                            <InvestmentInfo style={{ fontWeight: 700 }}>$100</InvestmentInfo>
                        </InvestmentAmountWrapper>
                        <SubmitButton 
                            onClick={handleSubmitClick}
                            disabled={!areAllRequiredFieldsFilled(state)}
                        >Submit</SubmitButton>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default BankAccountModal