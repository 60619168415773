import React from 'react'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from './LoadingElement'

const LoadingComponent = () => {
    return (
        <LoadContainer>
            <div style={{position: "relative"}}>
                <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                    <OuterPath cx="25" cy="25" r="20" fill="none" />
                </OuterSpinner>
                <Spinner width="50" height="50" viewBox="0 0 50 50">
                    <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                </Spinner>
            </div>
        </LoadContainer>
    )
}

export default LoadingComponent