import React, { forwardRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    Modal,
    Header,
    SectionContainer,
    Section
} from '../UserProfileModal/UserProfileElements'

import { timeFromNow } from '../../../helpers/TimeFromNow'

const NotificationModal = forwardRef((props, ref) => {
    const navigate = useNavigate()

    const handleNotificationClick = useCallback((projectId) => {
        navigate(`/project-profile/${projectId}`)
        props.setShowNotificationModal(false)
    }, [props, navigate])

    const notificationsMapping = props.notifications.map((notification) => (
        <Section 
            style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 10, height: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }} 
            key={notification._id}
            onClick={() => handleNotificationClick(notification.project)}
        >
            <p style={{ fontWeight: 'bold' }}>{notification.message}</p>
            <p style={{ color: '#6EACDA' }}>{timeFromNow(notification.createdAt)}</p>
            {/* <p>Status: {notification.read ? 'Read' : 'Unread'}</p> */}
        </Section>
    ))

    return (
        <Modal ref={ref} style={{ width: 300, height: 500, overflowY: 'scroll' }}>
            <Header>Notifications</Header>
            <SectionContainer >
                {notificationsMapping}
            </SectionContainer>
        </Modal>
    )
})

NotificationModal.displayName = 'NotificationModal'

export default NotificationModal