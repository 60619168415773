import React from 'react'
import { getTags } from './PostsApi'
import { useQuery } from '@tanstack/react-query'
import {
  Modal,
  Overlay,
  ModalHeader,
  ModalTitle,
  Body,
  ResetTagItem,
  TagItem,
} from './PostElements'

const TagModal = ({ isOpen, onClose, onSelectTag, selectedTag, tab }) => {
  // Use the useQuery hook to fetch tags when the modal is open
  const { data: tags, error, isLoading } = useQuery({
    queryKey: ['tags', tab],
    queryFn: () => getTags({ tab }),
    enabled: isOpen, // Fetch only when the modal is open
  })

  if (!isOpen) return null

  if (isLoading) {
    return (
      <>
        <Overlay onClick={onClose} />
        <Modal>
          <ModalHeader>
            <ModalTitle>Loading Tags...</ModalTitle>
          </ModalHeader>
        </Modal>
      </>
    )
  }

  if (error) {
    return (
      <>
        <Overlay onClick={onClose} />
        <Modal>
          <ModalHeader>
            <ModalTitle>Error Loading Tags</ModalTitle>
          </ModalHeader>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Overlay onClick={onClose} />
      <Modal>
        <ModalHeader>
          <ModalTitle>Select a Tag</ModalTitle>
        </ModalHeader>
            <Body>
                <ResetTagItem isSelected={!selectedTag} onClick={() => onSelectTag('')}>
                  Show All
                </ResetTagItem>
                {tags?.map(tag => (
                    <TagItem 
                      key={tag} 
                      isSelected={selectedTag === tag} 
                      onClick={() => onSelectTag(tag)}
                    >
                      {tag}
                    </TagItem>
                ))}
            </Body>
      </Modal>
    </>
  )
}

export default TagModal