//@dev increases input dynamically depending on text line height.
export const handleIncrease = (ref) => {
    ref.current.style.height = '25px'
    ref.current.style.paddingBottom = '5px'
    ref.current.style.height = ref.current.scrollHeight + 'px'
}

//@dev closes modal, resets comment state and resets text area height. 
export const handleCancel = (ref, setAddComment, setShowCommentButtons) => {
    setAddComment('')
    setShowCommentButtons(false)
    ref.current.value = ''
    ref.current.style.height = '25px'
} 