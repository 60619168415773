import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../authContext'
import axios from 'axios'
import { API_URL } from '../config'
import { useParams } from 'react-router-dom'
import LoadingComponent from '../components/LoadingAsset'

import NonMainNavbar from '../components/NonMainNav'

import UserProfileComponent from '../components/UserProfile'

const UserProfile = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [totalInvested, setTotalInvested] = useState(0)
    const [numberOfInvestedProjects, setNumberOfInvestedProjects] = useState(0)
    const [numberOfFollowers, setNumberOfFollowers] = useState(0)
    const [isFollowing, setIsFollowing] = useState(false)
    const [projects, setProjects] = useState([])
    const [expressedInterestProjects, setExpressedInterestProjects] = useState([])

    const { loggedInUser, setLoggedInUser } = useContext(AuthContext)
    const { username } = useParams()

    useEffect(() => {
        const getProfile = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(`${API_URL}/user-profile-page/${username}`, { withCredentials: true })
                
                setUser(response.data.user)
                setTotalInvested(response.data.totalInvestmentAmount)
                setNumberOfInvestedProjects(response.data.numberOfProjectsInvested)
                setNumberOfFollowers(response.data.numberOfFollowers)
                setProjects(response.data.projects)
                
                // Filter out expressedInterestProjects where project is null
                const filteredExpressedInterestProjects = response.data.expressedInterestProjects.filter(project => project.project !== null)
                setExpressedInterestProjects(filteredExpressedInterestProjects)
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false)
            }
        }
        getProfile()
    }, [username])

    useEffect(() => {
        const getIsFollowing = async () => {
            if (user && loggedInUser && loggedInUser.loggedInUser) {
                try {
                    const response = await axios.get(`${API_URL}/user-profile-page/isFollowing/${user._id}`, { withCredentials: true })

                    setIsFollowing(response.data.isFollowing)
                } catch (e) {
                    console.error(e)
                }
            }
        }

        getIsFollowing()
    }, [user, loggedInUser])

    if (isLoading) {
        return (
            <div>
                <NonMainNavbar loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} />
                <div style={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LoadingComponent />
                </div>
            </div>
        )
    }

    return (
        <div>
            <NonMainNavbar loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} />
            <UserProfileComponent 
                projects={projects}
                expressedInterestProjects={expressedInterestProjects}
                user={user ? user : {}} 
                isFollowing={isFollowing}
                setIsFollowing={setIsFollowing}
                totalInvested={totalInvested} 
                numberOfInvestedProjects={numberOfInvestedProjects}
                numberOfFollowers={numberOfFollowers}
            />
        </div>
    )
}

export default UserProfile