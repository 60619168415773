import styled, { keyframes } from 'styled-components'
import { PrimaryColor } from '../../../../colors'

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const InputEditContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Embed = styled.div`
    /* position: relative;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 10px; */

    /* &::before {
    content: "";
    display: block;
    padding-top: 56.25%; 
  } */
    position: relative;
    /* overflow: hidden; */
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
    border-radius: 10px;
`

export const EmbedContainer = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`

export const CaptionInput = styled.input`
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    resize: none;
    padding: 10px;
    font-size: 12px;
    color: grey;
    font-family: inherit;

    &:focus {
        outline: none;
    }
`

export const ImageWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    position: relative;

    &:focus {
        outline: none !important;
    }
`

export const NoOutlineImage = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus {
        outline: none !important;
    }
`

export const SelectedElementWrapper = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
`

export const ImageBorderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid ${PrimaryColor};
  box-sizing: border-box;
  pointer-events: none; // This ensures the border won't interfere with the click event
`

export const BeltWrapper = styled.div`
    height: 60px; 
    display: flex; 
    align-items: center;
`

export const StoryText = styled.div`
    margin: 10px 0;
`

export const StoryHeader = styled.div`
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
`

export const StoryContainer = styled.div`
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 650px;
`

export const AddButton = styled.button`
    height: 50px;
    width: 100px;
    background: white;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
`

export const HeaderText = styled.textarea`
    margin-top: 20px;
    margin-left: 20px;
    width: 200px;
    height: 75px;
    resize: none;
    border: none;

    &:focus {
        outline: none;
    }
`

export const StoryEdit = styled.textarea`
    margin-top: 30px;
    height: 300px;
    width: 450px;
    padding: 10px;
    font-size: 15px;
    resize: none;
`

export const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #B2B1B9;
    border-radius: 3px;
    height: 175px;
    width: 375px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
`

export const CaptionText = styled.textarea`
    resize: none;
    padding: 5px;
    width: 240px;
    height: 100px;
    border: none;

    &:focus {
        outline: none;
    }
`

export const ExitContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10; 
    margin-top: 10; 
    border-radius: 50%; 
    position: absolute; 
    background: white; 
    height: 20px; 
    width: 20px;
    border: 1px solid #EEEEEE;
    top: 20px;
    right: 10px;
`

export const StoryEditTextArea = styled.textarea`
    width: 90%;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
    font-size: 16px;
    border: none;
    font-family: 'DM Sans', serif;
    resize: none;
    &:focus {
        outline: none;
    }

    &.title {
        font-size: 30px;
        font-weight: 500;
        padding-top: 20px;
    }
`

export const EditableDiv = styled.div`
    width: 90%;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
    font-size: 16px;
    border: none;
    font-family: 'DM Sans', serif;

    &:focus {
        outline: none;
    }

    &.title {
        font-size: 30px;
        font-weight: 500;
        padding-top: 20px;
    }
`

export const StoryEditTitle = styled.textarea`
    width: 90%;
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
    margin-left: 15px;
    font-size: 28px;
    font-weight: bold;
    font-family: 'Source Serif Pro', serif;
    resize: none;

    &:focus {
        outline: none;
    }
`

export const StoryEmbed = styled.textarea`
    width: 90%;
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
    margin-left: 15px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Source Serif Pro', serif;
    resize: none;

    &:focus {
        outline: none;
    }
`

const BeltIconAnimation = keyframes`
    0% { opacity: 0; height: 0; width: 0; }
    100% { opacity: 1; height: 40px; width: 40px; } 
`

export const BeltIconContainer = styled.div`
    margin-right: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #EAEAEA;
    cursor: pointer;
    transition: all 0.25s ease;

    &.rise {
        animation: ${BeltIconAnimation} 0.1s ease-in forwards;
    }

    &.rise2 {
        animation: ${BeltIconAnimation} 0.15s ease-in forwards;
    }

    &.rise3 {
        animation: ${BeltIconAnimation} 0.2s ease-in forwards;
    }

    &.rotate {
        transform: rotate(45deg);
    }

    &.un-rotate {
        transform: rotate(0deg);
    }

    &:hover {
        border-color: black;
    }
`

export const VideoHoverElement = styled.div`
    position: absolute; 
 
    background: transparent;
    width: 100%; 
    height: 100%;
    top: 0;
`

export const EmbedCaptionInput = styled.textarea`
    width: 100%;
    display: block;
    text-align: center;
    font-size: 12px;
    color: grey;
    border: none;
    resize: none;

    &:focus {
        outline: none;
    }
`

export const PreviewImage = styled.img`
    width: 100%;
    object-fit: cover;
`

export const SaveButton = styled.button`
    padding: 10px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    background: #6D67E4;
    color: white;
    border: none;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background: #8D72E1;
    }
`

export const CaptionTextDisplay = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: grey;
`

export const EmbedOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`

export const VideoFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`

export const VideoWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;    
`

