import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import UserProfileModal from '../../../NonMainNav/UserProfileModal'

import { 
    IconWrapper,
} from '../../../NonMainNav/NonMainNavElements'

import { 
    LeftRightWrapper,
    LeftHalfPage, 
    RightHalfPage,
    RightHalfContainer,
    InputAbsoluteWrapper,
    Logo,
    SignupLogin,
    NextArea,
} from '../../CreateProjectElements'

import {
    Next
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import LeftHalfInfo from '../../LeftHalfInfo'
import MobileView from '../../MobileView'
import InputFields from './InputFields'

import { PiUserCircleThin } from 'react-icons/pi'
import { FaUserCircle } from 'react-icons/fa'

function BasicInfo({ 
    iconWrapperRef,
    modalRef,
    setLoggedInUser,
    handleNavigateHome,
    setCurrentStep,
    projectName, 
    setProjectName, 
    logline,
    setLogline, 
    scriptPages,
    setScriptPages,
    primaryGenre, 
    setPrimaryGenre, 
    secondaryGenre, 
    setSecondaryGenre 
}) {
    const [showProfileModal, setShowProfileModal] = useState(false)
    const [isProjectNameInvalid, setIsProjectNameInvalid] = useState(false)
    const [isLoglineInvalid, setIsLoglineInvalid] = useState(false)
    const [isScriptPagesInvalid, setIsScriptPagesInvalid] = useState(false)
    const [isPrimaryGenreInvalid, setIsPrimaryGenreInvalid] = useState(false)

    const StepDescription = "Let's start with the basics. What's the working title of your project? Additionally, can you provide a brief logline that encapsulates the premise of your project? And finally, which genre or genres best classify your project? This will help with discoverability."

    const handleGenreChangeTwo = (event) => {
        setSecondaryGenre(event.target.value)
    }

    const handleNext = () => {
        if (!projectName) { 
            setIsProjectNameInvalid(true)
        } 

        if (!logline) {
            setIsLoglineInvalid(true)
        }

        if (!primaryGenre) {
            setIsPrimaryGenreInvalid(true)
        }

        if (!scriptPages) {
            setIsScriptPagesInvalid(true)
        }

        if (projectName && logline && primaryGenre) {
            setCurrentStep(2)
        }
    }

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 801px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 800px)'
    })

    const mobileDescription = 'Please create a project name, logline, and genre. This can be changed later.'

    return (
        <>
        {isDesktopOrLaptop &&
            <LeftRightWrapper>
                <LeftHalfPage>
                    <Logo onClick={handleNavigateHome} >muugen</Logo>
                    <LeftHalfInfo step={1} title={'Basic Description'} description={StepDescription}/>
                </LeftHalfPage>
                <RightHalfPage style={{ paddingBottom: 90 }}>
                    <SignupLogin>
                        <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                            {!showProfileModal ? <PiUserCircleThin color='black' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='black' size={25} style={{ cursor: 'pointer' }} />}
                        </IconWrapper>
                    </SignupLogin>
                    {showProfileModal && <div style={{ position: 'absolute', right: 5 }}><UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} setLoggedInUser={setLoggedInUser} /></div>}
                    <RightHalfContainer>
                        <InputAbsoluteWrapper>
                        <InputFields
                            isProjectNameInvalid={isProjectNameInvalid}
                            projectName={projectName}
                            setProjectName={setProjectName}
                            setIsProjectNameInvalid={setIsProjectNameInvalid}
                            isLoglineInvalid={isLoglineInvalid}
                            logline={logline}
                            setLogline={setLogline}
                            scriptPages={scriptPages}
                            setScriptPages={setScriptPages}
                            setIsLoglineInvalid={setIsLoglineInvalid}
                            isScriptPagesInvalid={isScriptPagesInvalid}
                            setIsScriptPagesInvalid={setIsScriptPagesInvalid}
                            isPrimaryGenreInvalid={isPrimaryGenreInvalid}
                            primaryGenre={primaryGenre}
                            setPrimaryGenre={setPrimaryGenre}
                            setIsPrimaryGenreInvalid={setIsPrimaryGenreInvalid}
                            secondaryGenre={secondaryGenre}
                            handleGenreChangeTwo={handleGenreChangeTwo}
                        />
                        </InputAbsoluteWrapper>
                    </RightHalfContainer>
                    <NextArea>
                        <div></div>
                        <Next onClick={handleNext}>Next</Next>
                    </NextArea>
                </RightHalfPage>
            </LeftRightWrapper>
        }
        {isTabletOrMobile && 
        <MobileView step={1} title={'Basic Info'} description={mobileDescription} handleNext={handleNext} >
            <InputFields
                isProjectNameInvalid={isProjectNameInvalid}
                projectName={projectName}
                setProjectName={setProjectName}
                setIsProjectNameInvalid={setIsProjectNameInvalid}
                isLoglineInvalid={isLoglineInvalid}
                logline={logline}
                setLogline={setLogline}
                scriptPages={scriptPages}
                setScriptPages={setScriptPages}
                setIsLoglineInvalid={setIsLoglineInvalid}
                isScriptPagesInvalid={isScriptPagesInvalid}
                setIsScriptPagesInvalid={setIsScriptPagesInvalid}
                isPrimaryGenreInvalid={isPrimaryGenreInvalid}
                primaryGenre={primaryGenre}
                setPrimaryGenre={setPrimaryGenre}
                setIsPrimaryGenreInvalid={setIsPrimaryGenreInvalid}
                secondaryGenre={secondaryGenre}
                handleGenreChangeTwo={handleGenreChangeTwo}
            />
        </MobileView>
        }
        </>
    )
}   

export default BasicInfo