import React from 'react'
import LoadingComponent from '../LoadingAsset'

import { 
    ModalBg, 
    Wrapper,
} from '../ArtistProfile/Modal/ProfileEditModalElements'

const LazyLoaderModal = () => {
    return (
        <Wrapper>
            <ModalBg>
                <LoadingComponent />
            </ModalBg>
        </Wrapper>
    )
}

export default LazyLoaderModal