import React, { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { AWS_URL } from '../../../../../config'
import { DATABASE, NEW, CHANGE, DELETE } from '../../../../../constants'
import { useDropzone } from 'react-dropzone'
import { MdAddAPhoto } from 'react-icons/md'
import { IoMdAdd } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'

import { 
    ProfileWrapper, 
    ProfilePhoto, 
    Photo, 
    Name, 
    Role, 
    Description, 
    IconCircleDiv, 
    Add, 
    ProfilePhotoOverlay, 
} from '../ProjectDetailElements'

const Dropzone = ({ onImageSelected, teamMembers, setTeamMembers, index, isOverlay, slug }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (teamMembers[index].status === DATABASE) {
            setTeamMembers(prevTeam => prevTeam.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}`, oldImageName: item.imageFileName, status: CHANGE }
                }
                return item
            }))
        } else if (teamMembers[index].status === CHANGE) {
            setTeamMembers(prevTeam => prevTeam.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}`, status: CHANGE }
                }
                return item
            }))
        } else if (teamMembers[index].status === DELETE) {
            setTeamMembers(prevTeam => prevTeam.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}`, status: CHANGE }
                }
                return item
            }))
        } else if (teamMembers[index].status === NEW) {
            setTeamMembers(prevTeam => prevTeam.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}` }
                }
                return item
            }))
        }
      // Call the parent component's onImageSelected function with the selected file
      onImageSelected(acceptedFiles[0])
    }, [onImageSelected, index, setTeamMembers, teamMembers, slug])
  
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Allow only image files
        maxFiles: 1, // Limit to a single file
    })
  
    return (
      <>
        {isOverlay ? (
          <ProfilePhotoOverlay {...getRootProps()}>
            <input {...getInputProps()} />
          </ProfilePhotoOverlay>
        ) : (
          <ProfilePhoto {...getRootProps()}>
            <input {...getInputProps()} />
            <MdAddAPhoto size={30} />
          </ProfilePhoto>
        )}
      </>
    )
}

function TeamMemberCard({ 
    project,
    editTeam, 
    teamMembers, 
    setTeamMembers, 
    member, 
    index,
    deleteArray, 
    setDeleteArray, 
    imageFiles, 
    setImageFiles, 
    handleImageSelected, 
}) {

    const handleAddTopMemberClick = () => {
        setTeamMembers((prevTeamMembers) => [
            {
                imageFileName: '',
                previewFileName: '',
                name: '',
                role: '',
                description: '',
                imageFile: [],
                index: 0,
                status: 'NEW',
            },
            ...prevTeamMembers,
        ])
    }

    const handleAddClick = (index) => {
        setTeamMembers((prevTeamMembers) => [
            ...prevTeamMembers.slice(0, index + 1),
            {
                imageFileName: '',
                previewFileName: '',
                name: '',
                role: '',
                description: '',
                imageFile: [],
                index: index + 1,
                status: 'NEW',
            },
            ...prevTeamMembers.slice(index + 1),
        ])
    }

    const handleNameChange = (e, index) => {
        setTeamMembers((prevTeamMembers) =>
            prevTeamMembers.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          name: e.target.value,
                          status: member.status !== 'NEW' ? 'CHANGE' : member.status,
                      }
                    : member
            )
        )
    }

    const handleRoleChange = (e, index) => {
        setTeamMembers((prevTeamMembers) =>
            prevTeamMembers.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          role: e.target.value,
                          status: member.status !== 'NEW' ? 'CHANGE' : member.status,
                      }
                    : member
            )
        )
    }

    const handleMouseOver = debounce((event, index) => {
        setTeamMembers((prevTeamMembers) =>
            prevTeamMembers.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          isHovered: true,
                      }
                    : member
            )
        )
    }, 100)

    const handleMouseLeave = debounce((event, index) => {
        setTeamMembers(prevTeamMembers =>
            prevTeamMembers.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          isHovered: false,
                      }
                    : member
            )
        )
    }, 100)

    const handleDescriptionChange = (e, index) => {
        setTeamMembers(prevTeamMembers =>
            prevTeamMembers.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          description: e.target.value,
                          status: member.status !== 'NEW' ? 'CHANGE' : member.status,
                      }
                    : member
            )
        )
    }  

    const handleDeleteMember = (index) => {
        setTeamMembers((prevTeam) => {
          const tempArray = [...prevTeam]
          const tempDelete = [...deleteArray]
      
          if (tempArray.length === 1) {
            // Reset the values for index 1
            tempArray[index] = {
              ...tempArray[index],
              imageFileName: "",
              role: "",
              name: "",
              description: "",
              status: DELETE,
            }

            tempDelete.push(tempArray[index])
          } else {
            if (index !== 0) {
              tempArray.splice(index, 1)
            }
          }

          if (tempArray[index].status !== NEW) {
            tempArray[index].index = index
            tempDelete.push(tempArray[index])
            tempArray[index].status = DELETE
          }
      
          setDeleteArray(tempDelete)
          return tempArray
        })
    }

    return (
        <div style={{ marginTop: 10 }} onMouseOver={(event) => handleMouseOver(event, index)} onMouseLeave={event => handleMouseLeave(event, index)} >
            {index === 0 ?
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <Add onClick={index === 0 ? () => handleAddTopMemberClick() : () => handleAddClick(index)}><IoMdAdd /></Add>
            </div>
            : 
            null
            }
            <ProfileWrapper>
                <div>
                    {member.imageFile?.length > 0 || member.imageFileName ?
                    <ProfilePhoto style={{ position: 'relative' }}>
                        <Photo draggable='false' src={member.imageFile.length > 0 ? member.imageFile[0].preview : `${AWS_URL}${member?.imageFileName}`} />
                        <Dropzone isOverlay={true} onImageSelected={(files) => handleImageSelected(files, index)} teamMembers={teamMembers} setTeamMembers={setTeamMembers} imageFiles={imageFiles} setImageFiles={setImageFiles} index={index} slug={project?.slug}/>
                    </ProfilePhoto>
                    : 
                    <ProfilePhoto>
                        <Dropzone onImageSelected={(files) => handleImageSelected(files, index)} teamMembers={teamMembers} setTeamMembers={setTeamMembers} imageFiles={imageFiles} setImageFiles={setImageFiles} index={index} slug={project?.slug}/>
                    </ProfilePhoto>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Name placeholder='Enter a name...' value={member.name} onChange={e => handleNameChange(e, index)}></Name>
                        <Role placeholder='Enter a role...' value={member.role} onChange={e => handleRoleChange(e, index)}></Role>
                        {member.isHovered || member.showDeleteModal ?
                            <IconCircleDiv onClick={() => handleDeleteMember(index)} >
                                <MdDelete size={20} color='black' />
                            </IconCircleDiv>
                        :
                            null
                        }
                    </div>
                    <Description placeholder='Enter a description...' value={member.description} onChange={e => handleDescriptionChange(e, index)}></Description>
                </div>
            </ProfileWrapper>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <Add onClick={() => handleAddClick(index)}><IoMdAdd/></Add>
            </div>
        </div>
    )
}

export default React.memo(TeamMemberCard)