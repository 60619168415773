import React from 'react'
import DOMPurify from 'dompurify'

import { 
    ReviewTitle,
    ReviewInfo,
    ImageContainer,
    PreviewImage,
    IframeWrapper,
} from '../../CreateProjectElements'

function Fields({ 
    projectName, 
    logline,
    scriptPages,
    primaryGenre, 
    secondaryGenre,
    coverImage,
    videoEmbed,
    fundingAmount,
    isFixed,
    fundDuration,
    day,
    month,
    year,
    hour,
    minute,
    timeDesignation,
    projectCategory,
    projectFundingType
}) {

    const sanitizedHTML = DOMPurify.sanitize(videoEmbed, {
        ADD_TAGS: ['iframe']
    })
    const imageUrl = URL.createObjectURL(coverImage)

    let renderedCat
    
    if (projectCategory === 'funding')
        renderedCat = 'Funding New Project'
    else
        renderedCat = projectCategory

    return (
        <>
            <ReviewTitle>ProjectName</ReviewTitle>
            <ReviewInfo>{projectName}</ReviewInfo>
            <ReviewTitle>Logline</ReviewTitle>
            <ReviewInfo>{logline}</ReviewInfo>
            <ReviewTitle>Number of Script Pages</ReviewTitle>
            <ReviewInfo>{scriptPages}</ReviewInfo>
            <ReviewTitle>Primary Genre</ReviewTitle>
            <ReviewInfo>{primaryGenre}</ReviewInfo>
            <ReviewTitle>Secondary Genre</ReviewTitle>
            <ReviewInfo>{secondaryGenre}</ReviewInfo>
            <ReviewTitle>Cover Image</ReviewTitle>
            <ImageContainer>
                <PreviewImage src={imageUrl} />
            </ImageContainer>
            {videoEmbed &&
            <>
                <ReviewTitle>Video</ReviewTitle>
                <IframeWrapper dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
            </>
            }
            <ReviewTitle>Project Category</ReviewTitle>
            <ReviewInfo>{renderedCat}</ReviewInfo>
            <ReviewTitle>Project Funding Type</ReviewTitle>
            <ReviewInfo>{projectFundingType}</ReviewInfo>
            <ReviewTitle>Funding Amount</ReviewTitle>
            <ReviewInfo>${fundingAmount}</ReviewInfo>
            {isFixed ?
            <>
                <ReviewTitle>Funding Duration</ReviewTitle>
                <ReviewInfo>{fundDuration}</ReviewInfo>
            </>
            :
            <>
                <ReviewTitle>End Date</ReviewTitle>
                <ReviewInfo>{month}/{day}/{year} at {hour}:{minute} {timeDesignation}</ReviewInfo>
            </>
            }
        </>
    )
}

export default Fields