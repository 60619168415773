import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL, AWS_URL } from '../../../config'
import { useNavigate } from 'react-router-dom'
import { unFollowUser, followUser } from '../../DiscoverUsers/DiscoverUsersApi'

import {
    UnfollowButton,
    FollowContainer,
    ProfilePicture,
    NoImageWrapper,
    Name,
    Description,
} from './FollowerElements'

import { FaUser } from 'react-icons/fa'

const FollowCard = ({ type, setFollowingArray, follow, index }) => {
    const [imageErrorFollowing, setImageErrorFollowing] = useState(false)
    const [isFollowing, setIsFollowing] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const getIsFollowing = async () => {
            if (follow) {
                const response = await axios.get(`${API_URL}/user-profile-page/isFollowing/${follow._id}`, { withCredentials: true })

                setIsFollowing(response.data.isFollowing)
            }
        }

        getIsFollowing()
    }, [follow])

    const handleNavigate = () => {
        navigate(`/user-profile/${follow.username}`)
    }

    const handleImageError = (setImageError) => {
        setImageError(true)
    }

    const handleUnfollowClick = async (event) => {
        event.stopPropagation()
        try {
            await unFollowUser(follow._id)

            if (type === 'following') {
                setFollowingArray(prevFollowingArray => {
                    const updatedFollowingArray = {
                        ...prevFollowingArray,
                        followedUsers: prevFollowingArray.followedUsers.filter(f => f._id !== follow._id),
                        totalFollowedUsers: prevFollowingArray.totalFollowedUsers - 1
                    }

                    return updatedFollowingArray
                })
            }
            setIsFollowing(false)
        } catch (e) {
            console.error(e)
        }
    }

    const handleFollowClick = async (event) => {
        event.stopPropagation()

        await followUser(follow._id)
        setIsFollowing(true)
    }

    const truncatedMiniBio = follow?.miniBio && follow?.miniBio?.length > 250
        ? `${follow?.miniBio?.substring(0, 100)}...`
        : follow?.miniBio

    const imageUrl = `${AWS_URL}${follow.profilePicture}`

    return (
        <FollowContainer style={index > 0 ? { marginLeft: 10 } : {}} onClick={handleNavigate}>
            {!imageErrorFollowing ?
                <ProfilePicture src={imageUrl} alt={follow.artist.slug ? follow.artist.artistName : follow?.username} onError={() => handleImageError(setImageErrorFollowing)}/>
            :
                <NoImageWrapper>
                    <FaUser size={50} color='white' />
                </NoImageWrapper>
            }
            <Name>{follow?.displayName ? follow?.displayName : follow?.username}</Name>
            <Description>{truncatedMiniBio}</Description>
            <UnfollowButton onClick={isFollowing ? handleUnfollowClick : handleFollowClick} >{isFollowing ? 'Unfollow' : 'Follow'}</UnfollowButton>
        </FollowContainer>
    )
}

export default FollowCard