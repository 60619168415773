import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../config'

import {
    InputWrapper,
    SignupError,
    PasswordStrengthBar,
    PasswordStrengthText,
    ErrorListWrapper,
    ErrorList,
    ErrorListItem
    } from '../Signup/SignupElements.js'

import {
    Wrapper,
    PasswordWrapper,
    PasswordInput,
    EyeIcon,
    LoginWrapper, 
    LoginHeader,
    LoginButton 
} from '../Login/LoginElements.js'

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

const ResetPasswordComponent = () => {
    const [password, setPassword] = useState('')
    const [reenterPassword, setReenterPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [loginError, setLoginError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showReenterPassword, setShowReenterPassword] = useState(false)
    const [passwordStrength, setPasswordStrength] = useState('')
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [searchParams] = useSearchParams()
    const [isValidToken, setIsValidToken] = useState(true)
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false
    })

    const navigate = useNavigate()

    useEffect(() => {
        const token = searchParams.get('token')
        if (!token) {
            setIsValidToken(false)
            setLoginError('Invalid reset link. Please request a new password reset.')
        }
    }, [searchParams])

    const checkPasswordStrengthColorCode = (password) => {
        // First, ensure the password meets the basic criteria
        if (
            password.length < 8 ||
            !/[a-z]/.test(password) ||
            !/[A-Z]/.test(password) ||
            !/[0-9]/.test(password) ||
            !/[\W_]/.test(password)
        ) return 'too weak'
    
        // Password meets the basic criteria, now we can check additional factors for further strength

        let score = 0
    
        // Length: Longer passwords are generally better
        if (password.length >= 12) score++ // Increment score if password is 12 or more characters long
    
        // Check for the presence of multiple numbers, uppercase letters, or special characters
        if (password.replace(/[^0-9]/g, '').length > 1) score++
        if (password.replace(/[^A-Z]/g, '').length > 1) score++
        if (password.replace(/[\w]/g, '').length > 1) score++
    
        // Based on the score, determine if the password is "okay" or "strong"
        if (score < 2) return 'okay' // The password meets the basic criteria, so it's at least "okay"
        return 'strong' // The password goes above and beyond, making it "strong"
    }

    const handlePasswordChange = (event) => {
        const { value } = event.target
        setPassword(value)
        setPasswordRequirements({
            minLength: value.length >= 8,
            lowercase: /[a-z]/.test(value),
            uppercase: /[A-Z]/.test(value),
            number: /[0-9]/.test(value),
            specialChar: /[\W_]/.test(value)
        })
        setPasswordStrength(checkPasswordStrengthColorCode(value))
        if (isFormSubmitted && value === reenterPassword) {
            setPasswordError('')
        }
    }

    const handleReenterPasswordChange = (event) => {
        const { value } = event.target
        setReenterPassword(value)
        if (isFormSubmitted && value === password) {
            setPasswordError('')
        }
    }

    const checkPasswordStrength = (password) => {
        if (password.length < 8) return 'Password must be at least 8 characters long.'
        if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter.'
        if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter.'
        if (!/[0-9]/.test(password)) return 'Password must contain at least one number.'
        if (!/[\W_]/.test(password)) return 'Password must contain at least one special character.'
        return '' // If password meets all criteria
    }  

    const submitResetPassword = async () => {
        try {
            setIsFormSubmitted(true)

            const passwordStrengthMessage = checkPasswordStrength(password)
            if (passwordStrengthMessage) {
                setPasswordError(passwordStrengthMessage)
                return
            }

            if (password !== reenterPassword) {
                setPasswordError('Passwords do not match')
                return
            }

            const token = searchParams.get('token')
            if (!token) {
                setLoginError('Invalid reset link. Please request a new password reset.')
                return
            }

            const response = await axios.post(`${API_URL}/user-profile/reset-password/me`, {
                token: token,
                newPassword: password
            })

            if (response.data.success) {
                navigate('/login') // Redirect to login page after successful reset
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred while resetting password.'
            setLoginError(errorMessage)
            console.error('Password reset error:', error)
        }
    }

    if (!isValidToken) {
        return (
            <div>
                <Wrapper style={{ height: 'auto', marginTop: 50 }}>
                    <LoginWrapper>
                        <LoginHeader>Invalid Reset Link</LoginHeader>
                        <SignupError>
                            This password reset link is invalid or has expired. 
                            Please request a new password reset.
                        </SignupError>
                    </LoginWrapper>
                </Wrapper>
            </div>
        )
    }

    return (
        <div>
            <Wrapper style={{ height: 'auto', marginTop: 50 }}>
                <LoginWrapper>
                    <LoginHeader>Reset Password</LoginHeader>
                    <InputWrapper>
                    <PasswordWrapper>
                        <PasswordInput 
                            onChange={handlePasswordChange}
                            style={passwordError ? { borderColor: 'red' } : {}}
                            showPassword={showPassword} 
                            type={showPassword ? 'text' : 'password'} // Changed this line
                            id='password-input' 
                            placeholder='Password' 
                        />
                        <EyeIcon style={{ top: 37 }} onClick={() => setShowPassword(!showPassword)}>
                            {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                        </EyeIcon>
                        <SignupError>{passwordError}</SignupError>
                        {password && 
                            <div style={{ marginTop: 5 }}>
                                <PasswordStrengthText strength={passwordStrength} >{passwordStrength}</PasswordStrengthText>
                                <PasswordStrengthBar strength={passwordStrength} />
                            </div>
                        }
                    </PasswordWrapper>
                    </InputWrapper>
                    <PasswordWrapper>
                        <PasswordInput 
                            onChange={handleReenterPasswordChange}
                            style={passwordError ? { borderColor: 'red' } : {}}
                            showPassword={showReenterPassword} 
                            type={showReenterPassword ? 'text' : 'password'} // Changed this line
                            placeholder='Confirm Password' 
                        />
                        <EyeIcon style={{ top: 37 }} onClick={() => setShowReenterPassword(!showReenterPassword)}>
                            {!showReenterPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                        </EyeIcon>
                        <SignupError>{passwordError}</SignupError>
                    </PasswordWrapper>
                    <ErrorListWrapper>
                        <ErrorList>
                            <ErrorListItem isMet={passwordRequirements.minLength}>Password must be at least 8 characters long.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.lowercase}>Password must contain at least one lowercase letter.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.uppercase}>Password must contain at least one uppercase letter.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.number}>Password must contain at least one number.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.specialChar}>Password must contain at least one special character.</ErrorListItem>
                        </ErrorList>
                    </ErrorListWrapper>
                    <LoginButton onClick={submitResetPassword} >Reset Password</LoginButton>
                    <SignupError style={{ width: 300, textAlign: 'center', marginTop: 10 }}>{loginError}</SignupError>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default ResetPasswordComponent