import styled from 'styled-components'

export const Wrapper = styled.section`
    width: 100%;
    height: 100%;
    /* flex-direction: column;
    justify-content: center;
    align-items: center;  */
`

export const Container = styled.div`
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const FormContainer = styled.div`
    width: 670px;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    
    border: 1px solid rgb(230, 230, 230);
    background: white;
    margin-top: 10px;
`

export const Title = styled.div`
    color: ${props => props.isInvalid ? 'red' : 'black'};
`

export const SubTitle = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    color: ${props => props.isInvalid ? 'red' : 'black'};
`

export const Input = styled.input`
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid #bbbbbb'};
`

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ErrorMessage = styled.div`
    color: red;
    font-size: 12px;
    margin-right: 5px;
`

export const InputLabel = styled.div`
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: bold;
    color: ${props => props.isInvalid ? 'red' : 'black'};
`

export const ArtistAddressInput = styled.input`
    width: 400px;
    height: 50px;
    border-radius: 4px;
    padding: 15px;
    border-width: 1px;
    border-color: #bbbbbb;
    border-style: solid;
`

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 20px;
`

export const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Header = styled.div`
    font-weight: 400;
    font-size: 30px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 20px;
`

export const StepHeader = styled.div`
    font-weight: 400;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 10px;
`

export const CheckboxWrapper = styled.label`
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
`

export const CheckboxInput = styled.div`
    position: relative;
    height: 25px;
    width: 25px;
    margin-right: 10px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 4px;
    }

    &:hover input ~ span {
        background-color: #ccc;
    }

    input:checked ~ span {
        background-color: black;
    }

    span:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ span:after {
        display: block;
    }

    span:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
`

export const CheckboxLabel = styled.div`
    font-size: 13px;
`


export const Button = styled.button`
    width: 150px;
    margin-top: 20px;
    padding: 15px;
    /* background-color: transparent; */
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    background: ${({ isActive }) => (isActive ? 'black' : 'transparent')};
    color: ${({ isActive }) => (isActive ? 'white' : 'black')};
    border: ${({ isActive }) => (isActive ? '1px solid black' : '1px solid #bbbbbb')};

    &:hover {
        border-color: black;
    }
`

export const StepsContainer = styled.div`
    width: 40%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
`

export const Next = styled.button`
    width: 150px;
    margin-top: 20px;
    padding: 15px;
    background-color: #454545;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: black;
    }
`

export const Back = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px; 
    margin-top: 20px;
    padding: 15px;
    background-color: transparent;
    border: 1px solid #bbbbbb;
    color: black;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        border-color: black;
    }
`
