import React, { useState } from 'react'

import {
    AccountContainer,
    OptionSection,
    OptionInfo,
    NotificationTitle,
    ToggleWrapper, 
    ToggleCircle
} from '../AccountElements'

const Notifications = () => {
    const [toggles, setToggles] = useState({
        weeklyBlog: false,
        interestedGenres: false,
        investedProjects: false,
        watchListProjects: false,
        followedArtists: false,
    })

    const handleToggle = (key) => {
        setToggles((prevToggles) => ({
            ...prevToggles,
            [key]: !prevToggles[key],
        }))
    }

    return (
        <AccountContainer>
            <NotificationTitle>Email Notifications</NotificationTitle>
            <OptionSection onClick={() => handleToggle('weeklyBlog')}>
                <OptionInfo>Weekly Blog</OptionInfo>
                <ToggleWrapper toggled={toggles.weeklyBlog} >
                    <ToggleCircle toggled={toggles.weeklyBlog} />
                </ToggleWrapper>
            </OptionSection>
            <OptionSection onClick={() => handleToggle('interestedGenres')}>
                <OptionInfo>Films in my interested genres</OptionInfo>
                <ToggleWrapper toggled={toggles.interestedGenres} >
                    <ToggleCircle toggled={toggles.interestedGenres} />
                </ToggleWrapper>
            </OptionSection>
            <OptionSection onClick={() => handleToggle('investedProjects')}>
                <OptionInfo>Invested projects</OptionInfo>
                <ToggleWrapper toggled={toggles.investedProjects} >
                    <ToggleCircle toggled={toggles.investedProjects} />
                </ToggleWrapper>
            </OptionSection>
            <OptionSection onClick={() => handleToggle('watchListProjects')}>
                <OptionInfo>Watch list projects</OptionInfo>
                <ToggleWrapper toggled={toggles.watchListProjects} >
                    <ToggleCircle toggled={toggles.watchListProjects} />
                </ToggleWrapper>
            </OptionSection>
            <OptionSection style={{ borderBottom: 'none' }} onClick={() => handleToggle('followedArtists')}>
                <OptionInfo>Followed Artists</OptionInfo>
                <ToggleWrapper toggled={toggles.followedArtists} >
                    <ToggleCircle toggled={toggles.followedArtists} />
                </ToggleWrapper>
            </OptionSection>
        </AccountContainer>
    )
}

export default Notifications