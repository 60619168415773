import styled from 'styled-components'

export const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
`

export const HorizontalLine = styled.div`
    width: 350px;
    text-align: center;
    border-bottom: 1px solid #bbbbbb;
    line-height: 0.1em;
    margin: 10px 0 20px;
`

export const DividerText = styled.span`
    background: #fff;
    padding: 0 10px;
    color: #bbbbbb;
`

export const SignupHeader = styled.div`
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
`

export const InputWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`

export const InputLabel = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
`

export const SignupField = styled.input`
    width: 100%;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #bbbbbb;
    font-size: 16px;
`

export const CheckboxInfoWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
`

export const CheckboxWrapper = styled.div`
    display: flex;
    margin-top: 7px;
    margin-bottom: 20px;
    align-items: flex-start;
`

export const Checkbox = styled.input`
    width: 20px;
    height: 20px;
    border: 2px solid #bbbbbb;
    margin-right: 10px;
    border-radius: 5px;
`

export const CheckboxText = styled.div`
    font-size: 14px;
    font-weight: normal;
`

export const CheckboxLink = styled.a`
    color: #68B984;
    text-decoration: underline;
    cursor: pointer;
`

export const PrivacyPolicyText = styled.div`
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
`

export const Button = styled.button`
    width: 200px;
    margin-top: 20px;
    padding: 20px;
    background-color: #28df99;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #99f3bd;
    }
`

export const SignupError = styled.div`
    color: red;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    margin-top: 5px;
`

export const PasswordStrengthBar = styled.div`
    width: 100%;
    height: 10px;
    background-color: ${props => {
        switch (props.strength) {
            case 'strong': return '#B0D9B1';
            case 'okay': return '#FFF5C1';
            case 'too weak': return '#FFC1C1';
            default: return '#e0e0e0';
        }
    }};
    border-radius: 3px;
`

export const PasswordStrengthText = styled.div`
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    margin-top: 5px;
`

export const ErrorListWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #F5F5F5;
`

export const ErrorList = styled.ul`
    font-size: 12px;
    list-style: none;
`

export const ErrorListItem = styled.li`
    margin-bottom: 5px;
    text-decoration: ${props => props.isMet ? 'line-through' : 'none'};
`