import styled from 'styled-components'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { PrimaryColor } from '../../colors'

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    width: 100%;
    background: white;
    margin-bottom: 20px;
`

export const TitleName = styled.div`
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
`

export const ProjectName = styled.div`
    font-size: 25px;
    margin-bottom: 5px;
`

export const SubTitle = styled.div`
    font-size: 15px;
    color: grey;
`

export const ProjectCategory = styled.div`
    color: ${PrimaryColor};
    font-size: 15px;
    margin-top: 5px;
`

export const MobileTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background: white;
    margin-bottom: 20px;
    border-bottom: 1px solid lightgrey;
`

export const MobileTitleName = styled.div`
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 35px;
`

export const MobileProjectName = styled.div`
    font-size: 17px;
    margin-bottom: 5px;
    margin-left: 35px;
`

export const MobileSubTitle = styled.div`
    font-size: 13px;
    color: grey;
    margin-left: 35px;
`

export const MobileProjectCategory = styled.div`
    font-size: 14px;
    color: ${PrimaryColor};
    margin-bottom: 5px;
    margin-left: 35px;
`

export const MobileContainerDiv = styled.div`
    display: flex; 
    justify-content: center; 
    width: 100%; 
    padding-left: 35px; 
    padding-right: 35px;
`

//STEPS

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    width: 100%;
    background: white;
    margin-bottom: 20px;
`

export const Step = styled.div`
    display: flex;
    padding-bottom: 20px;
    width: 100%;
    background: white;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
`

export const StepMobile = styled.div`
    display: flex;
    padding-bottom: 20px;
    width: 100%;
    background: white;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 35px;
`

export const TwoInputWrapper = styled.div`
    display: flex;
    min-width: 475px;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 475px;
`

export const TwoInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const InputLabel = styled.div`
    margin-left: 1px;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    /* color: grey; */
    color: ${props => props.isInvalid ? 'red' : 'grey'};
`

export const Input = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    padding-left: 10px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    outline: none;
    &:focus {
        border: 1px solid #5C5CFF;
    }
`

export const SelectContainer = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
`

export const StyledSelect = styled.select`
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 30px; /* This is to ensure text doesn't overlap the arrow */
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    outline: none;
    border: 1px solid ${props => props.isInvalid ? 'red' : 'lightgrey'};
    border-radius: 4px;
    appearance: none;
    background: transparent;
    position: relative; 
    z-index: 1;
`

export const ArrowIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 56%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 2;
    color: grey;
`

export const MoneyInputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  min-width: 475px;
`

export const MoneySymbol = styled.span`
  position: absolute;
  bottom: 15px;
  left: 10px;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
`

export const MoneyInput = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    padding-left: 25px;
    border-radius: 5px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    outline: none;
    &:focus {
        border: 1px solid #5C5CFF;
    }
`

export const CheckboxInfoContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

export const QuestionBubble = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #68B984;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-left: 4px;
    font-size: 10px;
    cursor: pointer;
`

export const QuestionInfoBox = styled.div`
    position: absolute;
    width: 400px;
    top: -120px;
    left: 235px;
    background: #272829;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: 12px;
    padding: 10px;
    margin-left: 10px;
    font-family: 'DM Sans', sans-serif;
    z-index: 20;
`

export const QuestionInfo = styled.div`
    margin-top: 10px;
`

export const RadioDescription = styled.div`
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
`

export const CheckboxContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
`

export const CheckboxLabel = styled.label`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: #EEEEEE;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s;
  &::after {
    content: "";
    position: absolute;
    bottom: 4px;
    display: ${props => (props.checked ? "block" : "none")};
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

export const CheckboxInput = styled.input`
  display: none;
  &:checked + ${CheckboxLabel} {
    background: #68B984;
  }
`

export const CheckboxText = styled.span`
  margin-left: 5px;
`

export const PerkWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`

export const PerksContainer = styled.div`
    padding: 20px;
    margin-left: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background: #F5F5F5;
    width: 100%;
`

export const PerkInfo = styled.div`
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
`

export const PerkInfoDiv = styled.div`
    position: absolute;
    bottom: 100%;
    margin-bottom: 10px; 
    margin-left: 35px;
    width: 280px;
    background-color: black; 
    color: white;
    padding: 8px; 
    border-radius: 4px;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    z-index: 1;
`

export const SmallCheckMark = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    left: 20px;
    top: 20px;
    border-radius: 100%;
    background: #68B984;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 10px;
    font-size: 10px;
`

export const InvestContainer = styled.div`
    padding: 20px;
    margin-left: 35px;
    margin-bottom: 15px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    background: #F5F5F5;
    width: 100%;
`

export const InvestorLimitHeader = styled.div`
    font-size: 15px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 10px;
`

export const InvestorLimit = styled.div`
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    display: flex;
    justify-content: space-between;
`

export const InvestBottomBorder = styled.div`
    margin-top: 5px;
    border-bottom: 1px solid lightgrey;
`

export const InvestLimitAmount = styled.div`
    font-size: 16px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
`

export const TipTitle = styled.div`
    font-size: 15px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    margin-top: 10px;
`

export const TipInfo = styled.div`
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    margin-top: 10px;
    color: #5A5A5A;
`

export const TipEnds = styled.div`
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    color: #5A5A5A;
`

export const DragBarContainer = styled.div`
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: linear-gradient(to right, #68B984 0%, #68B984 40%, #E5E5E5 40%, #E5E5E5 100%);
`

export const Stop = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 10px;
    background-color: white;
`

export const InfoDiv = styled.div`
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    position: absolute;
    display: flex;
    bottom: calc(100% + 5px);
    left: 0;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #d1d1d1;
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 15px;
    white-space: nowrap; 
    transform: translateX(-50%);
    z-index: 2; 
`

export const DragCircle = styled.div`
    position: absolute;
    top: 50%;
    /* transform: translateY(-50%); */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #68B984;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 2px solid white;
    cursor: pointer;
    transform: translateY(-50%) translateX(-50%);
`

export const CustomButton = styled.div`
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    border: none;
    width: 180px;
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    background: none;
    color: #68B984;
    text-decoration: underline;
`

export const CustomTipInput = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    padding-left: 10px;
    font-size: 15px;

`

// LEGAL
export const LegalContainer = styled.div`
    padding: 20px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    margin-left: 35px;  
    border: 1px solid lightgrey;
    border-radius: 3px;
    width: 100%;
    min-width: 475px;
`

export const LegalInfo = styled.div`
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
    margin-top: 10px;
`

export const InputSignature = styled.input`
    width: 100%;
    min-width: 475px;
    height: 50px;
    font-size: 25px;
    margin-top: 15px;
    padding-left: 10px;
    border-radius: 5px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    margin-left: 35px;
    font-family: 'Cedarville Cursive', cursive;
`

export const PaymentContainer = styled.div`
    padding: 20px;
    min-width: 475px;
    margin-left: 35px;
    margin-bottom: 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;

    &:hover {
        background: #F5F5F5;
    }
`

export const AccTitle = styled.div`
    color: ${props => props.isInvalid ? 'red' : '#272829'};
    font-family: 'DM Sans', sans-serif;
`

export const RadioButton = styled.input`
    cursor: pointer;
`

export const RadioWrapper = styled.div`
    display: flex;
    margin-left: 15px;
    margin-top: 20px; 
    cursor: pointer;
`

export const RadioLabel = styled.label`
    margin-left: 10px;
    font-family: 'DM Sans', sans-serif; 
    color: ${props => props.isInvalid ? 'red' : '#272829'};
    cursor: pointer;
`

//INFO CARD
export const InfoCardWrapper = styled.div`
    display: flex;

    flex-direction: column;
    padding: 20px;
    width: 80%;
    background: white;
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin-bottom: 20px;
`

export const ProgressBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const ProgressBarAmount = styled.div`
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 4px;
    color: #68B984;
`

export const ProgressBar = styled.div`
    width: 100%;
    height: 10px;
    background: #EEEEEE;
    position: relative;
`

export const Progress = styled.div`
    width: 50%;
    height: 10px;
    background: #68B984;
    position: absolute;
`

export const InvestInfo = styled.div`
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    margin-top: 8px;
`

export const YourInvestment = styled.div`
    font-size: 30px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    margin-top: 8px;
`

export const InfoCardTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 10px;
`

export const PdfWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }
`

export const PdfName = styled.div`
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    margin-left: 10px;
`

export const RotatedIcon = styled(IoIosArrowDroprightCircle)`
    transform: ${({ rotate }) => rotate ? 'rotate(90deg)' : 'none'};
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
`

export const FaqWrapper = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: grey;
`

export const FaqTitleWrapper = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        color: black;
    }
`

export const FaqInfo = styled.div`
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    margin-top: 10px;
    margin-left: 35px;
`

//MODAL 

export const MobileRiseModal = styled.div`
    position: absolute;
    overflow-y: scroll;
    bottom: 0;
    width: 100%;
    height: 85%;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(100%); /* Start from the bottom */
    transition: transform 0.3s ease-in-out;

    &.active {
        transform: translateY(0);
    }
`

export const ModalDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;

    &:hover {
        cursor: pointer;
    }
`

export const ModalDropText = styled.div`
    font-size: 15px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 5px;
`

export const Modal = styled.div`
    background-color: white;
    width: 550px;
    height: auto;
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 3px;
    /* font-family: 'DM Sans', sans-serif; */
`


export const Body = styled.div`
    padding: 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CreditCardSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`

export const CreditCardTitle = styled.div`
    font-size: 17px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 10px;
`   

export const PaymentInfo = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    padding-left: 10px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
`

export const CreditCardWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
`

export const CreditCardInfo = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    padding-left: 35px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
`

export const CardIcon = styled.div`
    position: absolute;
    top: 55%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 20px; /* adjust size as necessary */
`

export const AdditionalInfo = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid lightgrey'};
    padding-left: 10px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
`

export const InvestmentAmountWrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const InvestmentInfo = styled.div`
    margin-top: 20px;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
`

export const BorderBottom = styled.div`
    margin-top: 20px;
    border-bottom: 1px solid lightgrey;
    width: 90%;
`

export const SubmitButton = styled.button`
    width: 90%;
    height: 50px;
    margin-top: 20px;
    border-radius: 3px;
    border: none;
    background: rgb(0, 0, 0);
    color: white;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;

    &:hover {
        background: rgb(0, 0, 0, 0.85);
    }
`

export const PlaidButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 35px;
    border: none;
    background: rgb(0, 0, 0);
    color: white;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
`

export const HorizontalLine = styled.div`
    width: 90%;
    text-align: center;
    border-bottom: 1px solid #bbbbbb;
    line-height: 0.1em;
    margin: 10px 0 20px;
`

export const DividerText = styled.span`
    background: #fff;
    padding: 0 10px;
    color: #bbbbbb;
    font-family: 'DM Sans', sans-serif;
`

export const ManualWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLabel = styled.label`
  cursor: pointer;
  /* color: red; */
  color: ${props => props.isInvalid ? 'red' : '#272829'};
  /* Further custom styling for label */
`

export const FinalInvestmentWrapper = styled.div`
    width: 100%;
    min-width: 475px;
    margin-left: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    border: 1px solid lightgrey;
`

export const InvestmentButton = styled.button`
    width: 100%;
    min-width: 475px;
    height: 60px;
    margin-top: 20px;
    margin-left: 35px;
    border-radius: 3px;
    border: none;
    background: #68B984;
    color: white;
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;

    &:hover {
        background: rgb(104,185,132, 0.85););   
    }
`

export const SuccessMessage = styled.div`
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    width: 90%;
`

export const WarningMessage = styled.div`
    font-size: 16px;
    text-align: center;
    color: red;
    margin-left: 40px;
    margin-top: 10px;
`

export const Anonymous = styled.div`
    
`

//MOBILE CONTINUE BUTTON

export const MobileWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    padding-bottom: 40px;
`

export const MobileButtonContainer = styled.div`
    width: 100%; 
    display: flex;
    padding-left: 35px; 
    padding-right: 35px; 
    padding-bottom: 35px;
`

export const MobileContinueButton = styled.button`
    width: 70%;
    padding-left: 35px;
    padding-right: 35px;
    height: 60px;
    margin-top: 20px;
    border-radius: 3px;
    border: none;
    background: #68B984;
    color: white;
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;

    &:hover {
        background: rgb(104,185,132, 0.85);
    }
`

export const MobileBackButton = styled.button`
    width: 30%;
    padding-left: 35px;
    padding-right: 35px;
    margin-right: 10px;
    height: 60px;
    margin-top: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: grey;
    color: white;
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;

    &:hover {
        background: RGB(128, 128, 128, 0.85);
    }
`

export const MobileInfoCard = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 15px;
`