import styled from 'styled-components'

export const Wrapper = styled.div`
    margin-left: 10px;
    width: 100px;
    height: 40px;
    background: white;
    color: black;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
`

export const HoveredDiv = styled.div`
    width: 100px;
    height: 40px;
    background: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;

    &:hover {
        background: #EEEEEE;
    }
`