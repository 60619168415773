import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import UserProfileModal from '../../../NonMainNav/UserProfileModal'

import { 
    IconWrapper,
} from '../../../NonMainNav/NonMainNavElements'

import {
    Next,
    Back
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    Logo,
    LeftRightWrapper,
    LeftHalfPage,
    RightHalfContainer,
    RightHalfPage,
    NextArea,
    SignupLogin,
    InputAbsoluteWrapper,
} from '../../CreateProjectElements'

import LeftHalfInfo from '../../LeftHalfInfo'
import InputFields from './InputFields'
import MobileView from '../../MobileView'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { PiUserCircleThin } from 'react-icons/pi'
import { FaUserCircle } from 'react-icons/fa'

function StepTwo({ 
    iconWrapperRef,
    modalRef,
    setShowProfileModal,
    setLoggedInUser,
    showProfileModal,
    handleNavigateHome, 
    setCurrentStep, 
    coverImage, 
    setCoverImage, 
    previewImage, 
    setPreviewImage 
}) {
    const [isInvalid, setIsInvalid] = useState(false)

    const handleNext = () => {
        if (!coverImage) {
            setIsInvalid(true)
        } else {
            setIsInvalid(false)
            setCurrentStep(3)
        }
    }

    const StepTwoDescription = 'Upload an image that represents your project. This will be the first thing people see when they visit your project page. It will also be used to represent your project in search results.'
    const StepTwoDescriptionTwo = 'Your Image should be at least 1920x1080 pixels. Images are cropped to a 16:9 ratio.'

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 801px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 800px)'
    })

    const mobileDescription = 'Please upload a cover photo. This will be the first thing people see when they visit your project page. It will also be used to represent your project in search results. Images are cropped to a 16:9 ratio.'

    return (
        <>
        {isDesktopOrLaptop &&
            <LeftRightWrapper>
                <LeftHalfPage>
                    <Logo onClick={handleNavigateHome}>muugen</Logo>
                    <LeftHalfInfo step={2} title={'Cover Photo'} description={StepTwoDescription} descriptionTwo={StepTwoDescriptionTwo} />
                </LeftHalfPage>
                <RightHalfPage>
                    <SignupLogin>
                        <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                            {!showProfileModal ? <PiUserCircleThin color='black' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='black' size={25} style={{ cursor: 'pointer' }} />}
                        </IconWrapper>
                    </SignupLogin>
                    {showProfileModal && <div style={{ position: 'absolute', right: 5 }}><UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} setLoggedInUser={setLoggedInUser} /></div>}
                    <RightHalfContainer style={{ paddingBottom: 130 }}>
                        <InputAbsoluteWrapper>
                            <InputFields 
                                coverImage={coverImage}
                                setCoverImage={setCoverImage}
                                previewImage={previewImage}
                                setPreviewImage={setPreviewImage}
                                isInvalid={isInvalid}
                                setIsInvalid={setIsInvalid}
                            />
                        </InputAbsoluteWrapper>
                    </RightHalfContainer>
                    <NextArea>
                        <Back onClick={() => setCurrentStep(1)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} /> Basic Info</Back>
                        <Next style={{ width: 170 }} onClick={handleNext} >Next: Video</Next>
                    </NextArea>
                </RightHalfPage>
            </LeftRightWrapper>
            }
            {isTabletOrMobile &&
                <MobileView step={2} title={'Cover Photo'} description={mobileDescription} handleNext={handleNext} setCurrentStep={setCurrentStep}>
                    <InputFields
                        coverImage={coverImage}
                        setCoverImage={setCoverImage}
                        previewImage={previewImage}
                        setPreviewImage={setPreviewImage}
                        isInvalid={isInvalid}
                        setIsInvalid={setIsInvalid}
                    />
                </MobileView>
            }
        </>
    )
}   

export default StepTwo