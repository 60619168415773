//one for every completed project
export const words = [
    'viciously', 
    'valiantly', 
    'daringly', 
    'audaciously', 
    'boldly', 
    'fiercely', 
    'passionately', 
    'fearlessly'
]