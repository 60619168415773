import React, { useState, useEffect } from 'react'

import { 
    Wrapper,
    ModalBg,
} from '../../ProjectProfile/Modal/EditModalElements'

import { 
    InfoCardTitle,
    PdfWrapper,
    PdfName,
    ProgressBarWrapper,
    ProgressBarAmount,
    ProgressBar, 
    Progress,
    MobileRiseModal, 
    ModalDropWrapper,
    ModalDropText
} from '../ProjectInvestElements'

import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

import { getOfferingApi } from '../../ProjectProfile/Body/Details/detailsApi'

function ContractsModal({ project, showContractsModal, setShowContractsModal, calculateProgressWidth, addCommasToNumber }) {
    const [modalActive, setModalActive] = useState(false)
   
    useEffect(() => {
        if (showContractsModal) {
            setModalActive(true)
        } else {
            setTimeout(() => {
                setModalActive(false)
            }, 500)
        }
    }, [showContractsModal])

    const handleClose = () => {
        setModalActive(false)
        setTimeout(() => {
            setShowContractsModal(false)
        }, 300)
    }

    const handlePdfClick = async (fileName) => {
        try {
        const response = await getOfferingApi(project.slug, fileName)

        const file = new Blob([response], { type: 'application/pdf' })

        const fileURL = URL.createObjectURL(file)

        window.open(fileURL, '_blank')
        } catch (e) {
            alert('there was an error: ' + e)
        }
    }

    const pdfMapping = project?.offerings?.map((offering, index) => {
        return (
            <PdfWrapper onClick={() => handlePdfClick(offering?.fileName)} key={offering?.title} style={{ marginTop: 20, width: '100%' }} >
                <BsFillFileEarmarkPdfFill size={23}/>
                <PdfName >{offering?.title}</PdfName>
            </PdfWrapper>
        )
    })

    return (
        <Wrapper>
            <ModalBg>
                <MobileRiseModal className={modalActive ? 'active' : ''}>
                    <InfoCardTitle>Contracts</InfoCardTitle>
                    <ProgressBarWrapper>
                        <ProgressBarAmount>${addCommasToNumber(project?.currentFunds)}</ProgressBarAmount>
                        <ProgressBar >
                            <Progress style={{ width: calculateProgressWidth() }} />
                        </ProgressBar>
                        <ProgressBarAmount style={{ marginTop: 4, color: 'black' }}>of ${addCommasToNumber(project?.targetFundAmount)}</ProgressBarAmount>
                    </ProgressBarWrapper>
                    {pdfMapping}
                    <ModalDropWrapper onClick={handleClose}>
                        <ModalDropText>Close</ModalDropText>
                        <MdOutlineKeyboardArrowDown size={30} />
                    </ModalDropWrapper>
                </MobileRiseModal>
            </ModalBg>
        </Wrapper>
    )
}

export default ContractsModal