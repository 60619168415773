import React from 'react';
import { MoneySelect } from './ModalElements';

const MoneyRangeSelector = ({ minData, maxData, setMinData, setMaxData, isLoading }) => {
    const amounts = [
        100, 250, 500, 1000, 2000, 5000, 10000, 25000, 50000, 100000, 250000
    ]

    const handleMinChange = (e) => {
        const value = e.target.value === 'null' ? null : Number(e.target.value)
        setMinData(value)
        if (value === null) {
            setMaxData(null)
        } else if (maxData !== null && value > maxData) {
            // If new min is greater than current max, set max to next available value
            const nextValidMax = amounts.find(amount => amount > value)
            setMaxData(nextValidMax || 250000)
        }
    }

    const handleMaxChange = (e) => {
        const value = e.target.value === 'null' ? null : Number(e.target.value)
        setMaxData(value)
        if (value === null) {
            setMinData(null)
        } else if (minData !== null && value < minData) {
            // If new max is less than current min, set min to previous available value
            const prevValidMin = [...amounts].reverse().find(amount => amount < value)
            setMinData(prevValidMin || 100)
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <MoneySelect 
                value={minData === null ? 'null' : minData || ''} 
                onChange={handleMinChange}
                disabled={isLoading}
            >
                <option value="null">No minimum</option>
                <option disabled>Min</option>
                {amounts.slice(0, -1).map(amount => (
                    <option 
                        key={amount} 
                        value={amount}
                        disabled={maxData !== null && amount > maxData}
                    >
                        ${amount.toLocaleString()}
                    </option>
                ))}
            </MoneySelect>

            <div style={{ fontFamily: "'DM Sans', serif", marginTop: 20 }}>to</div>

            <MoneySelect 
                value={maxData === null ? 'null' : maxData || ''} 
                onChange={handleMaxChange}
                disabled={isLoading}
            >
                <option value="null">No maximum</option>
                <option disabled>Max</option>
                {amounts.slice(1).map(amount => (
                    <option 
                        key={amount} 
                        value={amount}
                        disabled={minData !== null && amount < minData}
                    >
                        ${amount === 250000 ? '250,000+' : amount.toLocaleString()}
                    </option>
                ))}
            </MoneySelect>
        </div>
    )
}

export default MoneyRangeSelector