import axios from 'axios'
import { API_URL } from '../../../config'

export const expressInterest = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project-invest/expressInterest`, payload, { withCredentials: true })

        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const removeInterest = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project-invest/removeInterest`, payload, { withCredentials: true })

        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const launchProject = async (projectId) => {
    try {
        const response = await axios.post(`${API_URL}/project-invest/launch-project`, { projectId }, { withCredentials: true })

        return response.data
    } catch (err) {
        console.log(err)
    }
}