import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentInfoValue } from '../helpers/reducerHelper'
import { isFieldInvalid } from '../helpers/isFieldInvalid'

import {
    StepWrapper,
    Step,
    StepMobile,
    LegalContainer,
    LegalInfo,
    InputSignature
} from '../ProjectInvestElements'

function StepThree ({ state, dispatch }) {
    const handleSignature = (e) => {
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: e.target.value, label: 'Signature' } })
    }

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    if (!state || !state.store) {
        return null
    }  

    return (
        <StepWrapper>
            {isDesktopOrLaptop &&
                <Step><div style={{ marginRight: 20 }}>3.</div>Legal</Step>
            }
            {isTabletOrMobile &&
                <StepMobile>3. Legal</StepMobile>
            }
            <LegalContainer style={isTabletOrMobile ? { marginLeft: 0 } : {}}>
                <LegalInfo>
                    I understand that this investment involves a high degree of risk and that I may lose all of my investment.  
                </LegalInfo>
                <LegalInfo>
                    I understand that the securities I am purchasing are not publicly traded and are therefore illiquid.
                </LegalInfo>
                <LegalInfo>
                    I am complying with my annual investment limits set forth by the SEC.
                </LegalInfo>
                <LegalInfo>
                    I understand that I may cancel anytime until four days after making my investment or until my funds are disbursed, whichever is later.
                </LegalInfo>
                <LegalInfo>
                    I understand that Muugen Invest does not make investment recommendations, and no communication through this website or in any other medium should be construed as such. I am making an independent decision to invest.
                </LegalInfo>
                <LegalInfo>
                    I understand that if I&apos;m an accredited investor, I must verify my accreditation status before I can invest and I may be transitioned into a concurrent Reg D.
                </LegalInfo>
                <LegalInfo>
                    I agree to the contracts with my electronic signature and authorize Muugen Invest to debit my account.
                </LegalInfo>
            </LegalContainer>
            <InputSignature 
                isInvalid={isFieldInvalid('Signature', state)}
                placeholder='Enter your full name' 
                value={getCurrentInfoValue('Signature', state)}
                onChange={e => handleSignature(e)}
                style={isTabletOrMobile ? { marginLeft: 0 } : {}}
            />
        </StepWrapper>
    )
}

export default StepThree