import styled from 'styled-components'

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
`

export const AwardButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;

    &.show {
        display: none;
    }
`

export const AwardButton = styled.button`
    width: 125px;
    height: 50px;
    margin-top: 15px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #68B984;
    color: #68B984;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &.nomineeOrWinner {
        background: #68B984;
        color: white;
    }
`

export const PlusButton = styled.button`
    width: 30px;
    height: 30px;
    margin-top: 15px;
    padding: 7px;
    background-color: transparent;
    border: 1px solid #125d98;
    color: #125d98;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;

    &.no-show {
        display: none;
    }
`

export const DeleteButton = styled.button`
    width: 100%;
    height: 40px;
    margin-bottom: 5px;
    padding: 10px;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: rgba(255, 0, 0, 0.8);
        color: white;
    }
`

export const FormWrapper = styled.div`
    &.no-show {
        display: none;
    }
`

export const StyledTextArea = styled.textarea`
    width: 100%;
    height: 100px;
    margin-top: 15px;
    padding: 10px;
    background-color: transparent;
    border: 1px solid lightgrey;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    resize: none;
`

