import React, { useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body
} from '../../Modal/EditModalElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'

import { 
    InterestRemoveAmount,
    InfoContainer,
    SubmitWrapper,
    CancelButton,
    SubmitButton,
    SuccessMsg
} from './ExpressedInterestElements'

import { removeInterest } from '../BodyApi'

import { IoClose } from 'react-icons/io5'

const RemoveInterest = ({ 
    setShowRemoveInterestModal,
    setIsExpressedInterest, 
    expressedInterest, 
    projectInfo, 
    loggedInUser,
    setExpressedInterestId,
    expressedInterestId,
    setExpressedInterestAmount,
    expressedInterestAmount 
}) => {
    const queryClient = useQueryClient()
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''   
            setShowRemoveInterestModal(false)
        }
    }

    const handleCloseModal = () => {
        document.body.style.overflow = 'auto'
        setShowRemoveInterestModal(false)
    }

    const removeExpressedInterest = async (expressedInterestId) => {
        // Perform the API call to remove the interest
        const payload = {
          id: expressedInterestId,
        }
        const response = await removeInterest(payload)
      
        if (response) {
          // If the API call was successful, invalidate the user query
          queryClient.invalidateQueries(['user'])
          return true
        }
        return false
      }

      const handleRemoveInterest = async () => {
        setIsLoading(true)
        const success = await removeExpressedInterest(expressedInterestId)
        if (success) {
          setIsLoading(false)
          setIsExpressedInterest(false)
          setExpressedInterestId(null)
          setShowSuccessMsg(true)
        } else {
          setIsLoading(false)
          // Handle error case
        }
      }

    const handleCloseAndResetAmount = () => {
        handleCloseModal()
        setExpressedInterestAmount(0)
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText>Remove Interest</HeaderText>
                        <div onClick={handleCloseModal} style={{ cursor: 'pointer' }} >
                            <IoClose size={25} />
                        </div>
                    </Header>
                    <Body style={{ padding: 20 }}>
                        {isLoading ? (
                            <LoadContainer>
                            <div style={{ position: "relative" }}>
                                <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                                <OuterPath cx="25" cy="25" r="20" fill="none" />
                                </OuterSpinner>
                                <Spinner width="50" height="50" viewBox="0 0 50 50">
                                <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                </Spinner>
                            </div>
                            </LoadContainer>
                        ) : (
                            <>
                            {!showSuccessMsg ? (
                                <>
                                <InterestRemoveAmount>
                                    <p>${expressedInterestAmount}</p>
                                </InterestRemoveAmount>
                                <InfoContainer>
                                    <p>Are you sure you want to remove your interest from this project?</p>
                                </InfoContainer>
                                <SubmitWrapper>
                                    <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
                                    <SubmitButton onClick={handleRemoveInterest}>Remove Interest</SubmitButton>
                                </SubmitWrapper>
                                </>
                            ) : (
                                <>
                                <SuccessMsg>Your interest of ${expressedInterestAmount} has been removed.</SuccessMsg>
                                <SubmitWrapper>
                                    <CancelButton onClick={handleCloseAndResetAmount}>Close</CancelButton>
                                </SubmitWrapper>
                                </>
                            )}
                            </>
                        )}
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default RemoveInterest