import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CoverPhotoUploadContainer = styled.div`
    margin-left: 10px;
    width: 600px;
    aspect-ratio: 16 / 9;
    border: 1px dashed lightgray;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const CoverPhotoWrapper = styled.div`
    margin-left: 10px;
    position: relative;
    width: 600px;
    aspect-ratio: 16 / 9;
    margin-bottom: 20px;
`

export const CoverPhoto = styled.img`
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
`

export const CancelCoverButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        background-color: darkred;
    }
`

export const UploadText = styled.p`
    font-size: 14px;
    color: gray;
`

export const LearnWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const PostButton = styled.button`
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid lightgray;
    background: white;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 60px;
    height: 40px;
    cursor: pointer;

    &:hover {
        border: 1px solid black;
    }
`

export const Title = styled.input`
    margin-left: 8px;
    font-size: 30px;
    font-weight: 500;
    border: none;
    outline: none;
`

export const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Image = styled.img`
    width: 600px;
    object-fit: cover;
`

export const PlusButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Adjust as needed */
`

export const PlusButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SubmitButton = styled.button`
    height: 40px;
    width: 100%;
    border: none;
    background: black;
    cursor: pointer;
    margin-top: 20px;
    color: white;

    &:hover {
        background: rgb(0, 0, 0, 0.8);
    }
`

export const CancelButton = styled.button`
    height: 40px;
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-top: 5px;

    &:hover {
        background: #eee;
    }
`

export const DeleteButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;

  &:hover {
    background-color: darkred;
  }
`

export const CustomBody = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
`

export const CustomTag = styled.input`
    height: 30px;
    width: 100%;
    font-size: 14px;
    padding-left: 5px;
`

export const ContinueButton = styled.button`
    padding: 10px;
    border: none;
    background: #eee;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
        background: lightgrey;
    }

`