import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import UserProfileModal from '../../../NonMainNav/UserProfileModal'

import { 
    IconWrapper,
} from '../../../NonMainNav/NonMainNavElements'

import {
    Next,
    Back
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    Logo,
    LeftRightWrapper,
    LeftHalfPage,
    RightHalfContainer,
    RightHalfPage,
    NextArea,
    SignupLogin,
    InputAbsoluteWrapper,
} from '../../CreateProjectElements'

import LeftHalfInfo from '../../LeftHalfInfo'
import InputFields from './InputFields'
import MobileView from '../../MobileView'

import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { PiUserCircleThin } from 'react-icons/pi'
import { FaUserCircle } from 'react-icons/fa'

function CampaignDuration({ 
    setLoggedInUser,
    showProfileModal,
    setShowProfileModal,
    iconWrapperRef,
    modalRef,
    handleNavigateHome,
    setCurrentStep, 
    isFixed, 
    setIsFixed, 
    fundDuration, 
    setFundDuration, 
    fundTime, 
    setFundTime,
    setDay,
    setMonth,
    setYear,
    setHour,
    setMinute,
    hour,
    minute,
    day,
    month,
    year,
    timeDesignation,
    setTimeDesignation,
}) {
    const [isFixedInvalid, setIsFixedInvalid] = useState(false)
    const [isFixedError, setIsFixedError] = useState(false)
    const [isDateInvalid, setIsDateInvalid] = useState(false)
    const [isPastDate, setIsPastDate] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)

    const handleNext = () => {
        if (!isFixed) {
            if (month === '' || day === '' || year === '') {
                setIsEmpty(true)
                setIsDateInvalid(true)
                return
            } else if (isPastDate) {
                return
            } else if (isDateInvalid) {
                return
            } else if (month.length !== 2 || day.length !== 2 || year.length !== 4) {
                setIsDateInvalid(true)
                alert('Please be sure to format your date as MM/DD/YYYY')
                return
            }
        } else if (isFixed && (fundDuration === '' || isFixedError === 'LOW' || isFixedError === 'HIGH')) {
            setIsFixedInvalid(true)
            return
        }
    
        setCurrentStep(6)
    }

    const StepFiveDescription = 'Set the duration of your campaign. You can change the duration anytime before you launch your project.'
    const StepFiveDescriptionTwo = 'If you choose a fixed number of days, your campaign will end at 11:59pm on the last day. If you choose a specific date and time, your campaign will end at the time you select.'

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 801px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 800px)'
    })

    const mobileDescription = 'Set the duration of your campaign. You can change the duration anytime before you launch your project.'

    return (
        <>
        {isDesktopOrLaptop &&
            <LeftRightWrapper>
                <LeftHalfPage>
                    <Logo onClick={handleNavigateHome}>muugen</Logo>
                    <LeftHalfInfo step={5} title={'Campaign Duration'} description={StepFiveDescription} descriptionTwo={StepFiveDescriptionTwo} />
                </LeftHalfPage>
                <RightHalfPage>
                    <RightHalfContainer style={{ paddingBottom: 120 }} >
                    <SignupLogin>
                        <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                            {!showProfileModal ? <PiUserCircleThin color='black' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='black' size={25} style={{ cursor: 'pointer' }} />}
                        </IconWrapper>
                    </SignupLogin>
                    {showProfileModal && <div style={{ position: 'absolute', right: 5 }}><UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} setLoggedInUser={setLoggedInUser} /></div>}
                        <InputAbsoluteWrapper>
                            <InputFields 
                            isFixed={isFixed} 
                            setIsFixed={setIsFixed} 
                            fundDuration={fundDuration} 
                            setFundDuration={setFundDuration} 
                            fundTime={fundTime} 
                            setFundTime={setFundTime}
                            setDay={setDay}
                            setMonth={setMonth}
                            setYear={setYear}
                            setHour={setHour}
                            setMinute={setMinute}
                            hour={hour}
                            minute={minute}
                            day={day}
                            month={month}
                            year={year}
                            timeDesignation={timeDesignation}
                            setTimeDesignation={setTimeDesignation} 
                            isEmpty={isEmpty}
                            setIsEmpty={setIsEmpty}
                            isPastDate={isPastDate}
                            setIsPastDate={setIsPastDate}
                            isDateInvalid={isDateInvalid}
                            setIsDateInvalid={setIsDateInvalid}
                            isFixedInvalid={isFixedInvalid}
                            setIsFixedInvalid={setIsFixedInvalid}
                            isFixedError={isFixedError}
                            setIsFixedError={setIsFixedError}
                            />
                        </InputAbsoluteWrapper>
                    </RightHalfContainer>
                    <NextArea>
                        <Back onClick={() => setCurrentStep(4)}><LiaLongArrowAltLeftSolid style={{ marginRight: 2 }}size={20} />Funding</Back>
                        <Next onClick={handleNext} style={{ width: 170 }} >Next: Review</Next>
                    </NextArea>
                </RightHalfPage>
            </LeftRightWrapper>
        }
        {isTabletOrMobile &&
            <MobileView step={5} title={'Campaign Duration'} description={mobileDescription} handleNext={handleNext} setCurrentStep={setCurrentStep} >
                <InputFields 
                    isFixed={isFixed} 
                    setIsFixed={setIsFixed} 
                    fundDuration={fundDuration} 
                    setFundDuration={setFundDuration} 
                    fundTime={fundTime} 
                    setFundTime={setFundTime}
                    setDay={setDay}
                    setMonth={setMonth}
                    setYear={setYear}
                    setHour={setHour}
                    setMinute={setMinute}
                    hour={hour}
                    minute={minute}
                    day={day}
                    month={month}
                    year={year}
                    timeDesignation={timeDesignation}
                    setTimeDesignation={setTimeDesignation} 
                    isEmpty={isEmpty}
                    setIsEmpty={setIsEmpty}
                    isPastDate={isPastDate}
                    setIsPastDate={setIsPastDate}
                    isDateInvalid={isDateInvalid}
                    setIsDateInvalid={setIsDateInvalid}
                    isFixedInvalid={isFixedInvalid}
                    setIsFixedInvalid={setIsFixedInvalid}
                    isFixedError={isFixedError}
                    setIsFixedError={setIsFixedError}
                />
            </MobileView>
        }
        </>
    )
}   

export default CampaignDuration