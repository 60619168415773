import axios from 'axios'
import { API_URL } from '../../config'

export const getPosts = async ({ tab, tag, page, limit }) => {
  try {
    const response = await axios.get(`${API_URL}/posts/all`, {
      params: {
        tab,
        tag,
        page,
        limit,
      },
    })

    return {
      posts: response.data.posts,
      currentPage: response.data.currentPage,
      totalPages: response.data.totalPages,
    }
  } catch (e) {
    console.error(e)
    throw new Error('Failed to fetch posts')
  }
}

export const getTags = async ({ tab }) => {
  try {
    const response = await axios.get(`${API_URL}/posts/tags`, {
      params: {
        tab
      }
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Failed to fetch tags')
  }
}

export const getPost = async (slug) => {
  const response = await axios.get(`${API_URL}/posts/post/${slug}`)
  return response.data
}