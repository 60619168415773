import React, { useState } from 'react'
import Tags from './Tags'
import Review from './Review'

import {
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
    BodySection
} from '../../ProjectProfile/Modal/EditModalElements.js'

import { IoClose } from 'react-icons/io5'
import { BsFillFileTextFill } from 'react-icons/bs'
import { FaBook } from 'react-icons/fa'
import { FaBookBookmark } from 'react-icons/fa6'

const LearnPostModal = ({ coverPhoto, title, content, setShowPostModal }) => {
    const [showTagsModal, setShowTagsModal] = useState(false)
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [postType, setPostType] = useState('')
    const [tags, setTags] = useState('')

    const handleClose = () => {
        setShowTagsModal(false)
        setShowReviewModal(false)
        setShowPostModal(false)
    }

    const handlePostSelect = (type) => {
        setShowTagsModal(true)
        setPostType(type)
    }

    return (
        <ModalBg>
            {showTagsModal ? (
                <Tags 
                    setShowPostModal={setShowPostModal} 
                    setShowTagsModal={setShowTagsModal}
                    postType={postType}
                    setTags={setTags} 
                    setShowReviewModal={setShowReviewModal}
                />
            ) : showReviewModal ? (
                <Review 
                    title={title}
                    content={content}
                    postType={postType}
                    tags={tags}
                    setShowTagsModal={setShowTagsModal}
                    setShowReviewModal={setShowReviewModal}
                    setShowPostModal={setShowPostModal}
                    coverPhoto={coverPhoto}
                />
            ) : (
                <Modal>
                    <Header>
                        <HeaderText>What type of Post is this?</HeaderText>
                        <IoClose size={25} style={{ cursor: 'pointer' }} onClick={handleClose}/>
                    </Header>
                    <Body>
                        <BodySection onClick={() => handlePostSelect('blog')}>
                            <BsFillFileTextFill style={{ marginRight: 10 }} size={20} />
                            Blog Post
                        </BodySection>
                        <BodySection onClick={() => handlePostSelect('investor education')}>
                            <FaBook style={{ marginRight: 10 }} size={20} /> 
                            Investor Education Post
                        </BodySection>
                        <BodySection onClick={() => handlePostSelect('creator education')}>
                            <FaBookBookmark style={{ marginRight: 10 }} size={20} /> 
                            Creator Education Post
                        </BodySection>
                    </Body>
                </Modal>
            )}
        </ModalBg>
    )
}

export default LearnPostModal