import React from 'react'

import {
    DropdownMenu,
    LoglineInput,
} from '../../CreateProjectElements'

import {
    InputWrapper,
    InputContainer,
    SubTitle,
    ErrorContainer,
    ErrorMessage,
    Input,
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { handleChange } from '../../../../helpers/handleChange'

function InputFields({ 
    isProjectNameInvalid, 
    projectName, 
    setProjectName, 
    setIsProjectNameInvalid, 
    isLoglineInvalid, 
    logline, 
    setLogline, 
    scriptPages,
    setScriptPages,
    setIsLoglineInvalid, 
    isScriptPagesInvalid,
    setIsScriptPagesInvalid,
    isPrimaryGenreInvalid, 
    primaryGenre, 
    setPrimaryGenre, 
    setIsPrimaryGenreInvalid, 
    secondaryGenre, 
    handleGenreChangeTwo 
}) {
    const handleNumberChange = (e, setValue, setIsInvalid) => {
        const value = e.target.value
        if (value.length <= 3) { // Only allow up to 3 digits
            handleChange(e, setValue, setIsInvalid)
        }
    }

    return (
        <>
            <InputContainer>
                <InputWrapper>
                <SubTitle isInvalid={isProjectNameInvalid} >Project Name</SubTitle>
                    <Input
                        isInvalid={isProjectNameInvalid}
                        value={projectName}
                        placeholder='Seven Samurai'
                        onChange={(e) => handleChange(e, setProjectName, setIsProjectNameInvalid)}
                    />
                    {isProjectNameInvalid && <ErrorContainer><ErrorMessage>Project name is required</ErrorMessage></ErrorContainer>}
                </InputWrapper>
            </InputContainer>
            <InputContainer>
                <InputWrapper>
                <SubTitle isInvalid={isLoglineInvalid} >Project Logline</SubTitle>
                    <LoglineInput 
                        isInvalid={isLoglineInvalid}
                        value={logline}
                        placeholder='A poor village under attack by bandits recruits seven unemployed samurai to help them defend themselves.'
                        onChange={(e) => handleChange(e, setLogline, setIsLoglineInvalid)}
                    />
                    {isLoglineInvalid && <ErrorContainer><ErrorMessage>Logline is required</ErrorMessage></ErrorContainer>}
                </InputWrapper>
            </InputContainer>
            <InputContainer>
                <InputWrapper>
                    <SubTitle isInvalid={isScriptPagesInvalid} >Number of Script Pages</SubTitle>
                    <Input
                        isInvalid={isScriptPagesInvalid}
                        value={scriptPages}
                        placeholder='120'
                        type='number'
                        max='999'
                        onChange={(e) => handleNumberChange(e, setScriptPages, setIsScriptPagesInvalid)}
                    />
                    {isScriptPagesInvalid && <ErrorContainer><ErrorMessage>Number of script pages is required</ErrorMessage></ErrorContainer>}
                </InputWrapper>
            </InputContainer>
            <InputContainer>
                <InputWrapper>
                    <SubTitle isInvalid={isPrimaryGenreInvalid} >Primary Genre</SubTitle>
                    <DropdownMenu style={{ marginLeft: 0 }} value={primaryGenre} isInvalid={isPrimaryGenreInvalid} onChange={(e) => handleChange(e, setPrimaryGenre, setIsPrimaryGenreInvalid)}>
                        <option value="">Select genre...</option>
                        <option value="Action">Action</option>
                        <option value="Adventure">Adventure</option>
                        <option value="Biography">Biography</option>
                        <option value="Comedy">Comedy</option>
                        <option value="Crime">Crime</option>
                        <option value="Dark Comedy">Dark Comedy</option>
                        <option value="Drama">Drama</option>
                        <option value="Fantasy">Fantasy</option>
                        <option value="Historical">Historical</option>
                        <option value="Horror">Horror</option>
                        <option value="LGBTQ+">LGBTQ+</option>
                        <option value="Romance">Romance</option>
                        <option value="Science Fiction">Science Fiction</option>
                        <option value="Thriller">Thriller</option>
                        <option value="War">War</option>
                        <option value="Western">Western</option>
                    </DropdownMenu>
                    {isPrimaryGenreInvalid && <ErrorContainer><ErrorMessage>Primary genre is required</ErrorMessage></ErrorContainer>}
                </InputWrapper>
                <InputWrapper>
                    <SubTitle>Secondary Genre (optional)</SubTitle>
                    <DropdownMenu value={secondaryGenre} onChange={handleGenreChangeTwo}>
                        <option value="">Select genre...</option>
                        <option value="Action">Action</option>
                        <option value="Adventure">Adventure</option>
                        <option value="Biography">Biography</option>
                        <option value="Comedy">Comedy</option>
                        <option value="Crime">Crime</option>
                        <option value="Dark Comedy">Dark Comedy</option>
                        <option value="Drama">Drama</option>
                        <option value="Fantasy">Fantasy</option>
                        <option value="Historical">Historical</option>
                        <option value="Horror">Horror</option>
                        <option value="LGBTQ+">LGBTQ+</option>
                        <option value="Romance">Romance</option>
                        <option value="Science Fiction">Science Fiction</option>
                        <option value="Thriller">Thriller</option>
                        <option value="War">War</option>
                        <option value="Western">Western</option>
                    </DropdownMenu>
                </InputWrapper>
            </InputContainer>
        </>
    )
}

export default InputFields
