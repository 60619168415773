import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentInfoValue } from '../helpers/reducerHelper'
import { isFieldInvalid } from '../helpers/isFieldInvalid'

import {
    StepWrapper,
    Step,
    StepMobile,
    PaymentContainer,
    RadioButton
} from '../ProjectInvestElements'

import CreditCardModal from '../Modals/CreditCardModal'
import BankAccountModal from '../Modals/BankAccountModal'
import ECheckModal from '../Modals/ECheckModal'

import { BsCreditCardFill } from 'react-icons/bs'
import { AiFillBank } from 'react-icons/ai' 
import { FaMoneyCheckAlt } from 'react-icons/fa' 

function StepFour ({ state, dispatch }) {
    const [showCreditModal, setShowCreditModal] = useState(false)
    const [showBankModal, setShowBankModal] = useState(false)
    const [showECheckModal, setShowECheckModal] = useState(false)

    const handleOpenModal = (setShowModal, paymentMethod) => {
        dispatch({ type: 'UPDATE_INFO', payload: { newInfo: paymentMethod, label: 'Payment Type' } })
        document.body.style.overflow = 'hidden'
        setShowModal(true)
    }

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1048px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1047px)'
    })

    if (!state || !state.store) {
        return null
    }  

    return (
        <StepWrapper>
            {showCreditModal && <CreditCardModal state={state} dispatch={dispatch} setShowCreditModal={setShowCreditModal} />}
            {showBankModal && <BankAccountModal state={state} dispatch={dispatch} setShowBankModal={setShowBankModal} />}
            {showECheckModal && <ECheckModal state={state} dispatch={dispatch} setShowECheckModal={setShowECheckModal} />}
            {isDesktopOrLaptop &&
                <Step><div style={{ marginRight: 20 }}>4.</div>Payment</Step>
            }
            {isTabletOrMobile &&
                <StepMobile>4. Payment</StepMobile>
            }
            <PaymentContainer 
                style={isTabletOrMobile ? { marginLeft: 0 } : {}}
                isInvalid={isFieldInvalid('Payment Type', state)} 
                onClick={() => handleOpenModal(setShowBankModal, 'bank')}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AiFillBank size={28} style={{ marginRight: 10 }} />
                    Bank Account
                </div>
                <RadioButton
                    type='radio' 
                    name="paymentType" 
                    value="bank" 
                    checked={getCurrentInfoValue('Payment Type', state) === 'bank'} 
                    readOnly
                />
            </PaymentContainer>
            <PaymentContainer 
                style={isTabletOrMobile ? { marginLeft: 0 } : {}}
                isInvalid={isFieldInvalid('Payment Type', state)} 
                onClick={() => handleOpenModal(setShowCreditModal, 'creditCard')}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BsCreditCardFill size={28} style={{ marginRight: 10 }} />
                    Credit Card
                </div>
                <RadioButton
                    type='radio' 
                    name="paymentType" 
                    value="creditCard" 
                    checked={getCurrentInfoValue('Payment Type', state) === 'creditCard'}
                    readOnly 
                />
            </PaymentContainer>
            <PaymentContainer 
                style={isTabletOrMobile ? { marginLeft: 0 } : {}}
                isInvalid={isFieldInvalid('Payment Type', state)} 
                onClick={() => handleOpenModal(setShowECheckModal, 'eCheck')} 
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaMoneyCheckAlt size={28} style={{ marginRight: 10 }} />
                    E-Check
                </div>
                <RadioButton 
                    type='radio' 
                    name="paymentType" 
                    value="eCheck" 
                    checked={getCurrentInfoValue('Payment Type', state) === 'eCheck'}
                    readOnly 
                />
            </PaymentContainer>
        </StepWrapper>
    )
}

export default StepFour