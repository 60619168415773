import React, { forwardRef, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../authContext'
import { API_URL } from '../../../config'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import {
    Modal,
    SectionContainer,
    Section
} from './UserProfileElements'

import { CiLogout } from 'react-icons/ci'
import { PiUserSquare } from 'react-icons/pi'
import { FaPaintbrush } from 'react-icons/fa6'

const UserProfileModal = forwardRef((props, ref) => {
    const navigate = useNavigate()
    const { loggedInUser } = useContext(AuthContext)
    const queryClient = useQueryClient()

    const handleUserProfileClick = () => {
        navigate(`/my-investment-profile`)
    }

    const handleArtistProfileClick = () => {
        navigate(`/artist-profile/${loggedInUser.artistSlug}`)
    }

    const handleLogout = async () => {
        try {
            const response = await axios.post(`${API_URL}/login/logout`, {}, { withCredentials: true })
            queryClient.setQueryData(['user'], { loggedIn: false })
            
            if (response.data.error) {
                console.log(response.data.error)
            } else {
                // Set the user query data to a logged-out state
                
                // Clear any auth-related cookies or local storage here if needed
                
                props.setShowProfileModal(false)
                navigate('/') // Redirect to home page or login page
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal ref={ref}>
            <SectionContainer>
                <Section
                    onClick={handleUserProfileClick}
                    style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                >
                    <PiUserSquare style={{ marginRight: '10px' }} size={20} />
                    My Profile
                </Section>
                {loggedInUser?.artist &&
                    <Section
                        onClick={handleArtistProfileClick}
                    >
                        <FaPaintbrush style={{ marginRight: '10px' }} size={18} />
                        My Artist Profile
                    </Section>
                }
                <Section
                    style={{ fontWeight: '700', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}
                    onClick={handleLogout}
                >
                    <CiLogout style={{ marginRight: '10px' }} size={20} />
                    Log Out
                </Section>
            </SectionContainer>
        </Modal>
    )
})

UserProfileModal.displayName = 'UserProfileModal'
export default UserProfileModal