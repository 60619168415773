export const timeFromNow = (dateTime) => {
    const date = new Date(dateTime)
    const now = Date.now()

    const seconds = Math.floor(now / 1000 - date.getTime() / 1000)

    if (seconds < 60) 
        return seconds < 2 ? seconds.toString() + ' second ago' : seconds.toString() + ' seconds ago'

    const minutes = Math.floor(seconds / 60)

    if (minutes < 60) 
        return minutes < 2 ? minutes.toString() + ' minute ago' : minutes.toString() + ' minutes ago'

    const hours = Math.floor(minutes  / 60)

    if (hours < 24) 
        return hours < 2 ? hours.toString() + ' hour ago' : hours.toString() + ' hours ago'

    const days = Math.floor(hours / 24)

    if (days < 365)
        return days < 2 ? days.toString() + ' day ago' : days.toString() + ' days ago'

    const years = Math.floor(days / 365)

    return years.toString() + ' years ago'
}