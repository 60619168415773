import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 95%;
    position: relative;
    min-height: 95%;
    max-height: 90vh;
    overflow-y: auto;
    background: white;
    border-radius: 5px;

    @media only screen and (max-width: 850px) {
        width: 100%;
    }
`

export const CloseContainer = styled.div`
    position: absolute;
    width: 50px;
    height: 50px;
    right: 10px;
    top: 20px;
    cursor: pointer;

    @media only screen and (max-width: 850px) {
        right: 5px;
    }
`

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 100px 150px;

    @media only screen and (max-width: 850px) {
        padding: 100px 50px;
    }
`

export const ProjectName = styled.div`
    font-size: 16px;
    color: #8758FF;
`

export const TitleInput = styled.textarea`
    width: 100%;
    height: 40px;
    font-size: 30px;
    font-weight: 500;
    font-family:'DM Sans', sans-serif;
    resize: none;
    border: none;

    &:focus {
        outline: none;
    }
`

export const DescriptionContainer = styled.div`
    width: 100%;
    display: flex;
`

export const DescriptionInput = styled.textarea`
    width: 100%;
    font-size: 14px;
    resize: none;
`

export const CircleButton = styled.button`
    width: 50px;
    height: 50px;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    color: black;
    background: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;

    &:hover {
        border: 1px solid black;
    }
`

export const ImageCircleButton = styled(CircleButton)`
    margin-left: 10px;
`

export const EmbedCircleButton = styled(CircleButton)`
    margin-left: 10px;
`

export const EmbedInput = styled.input`
    width: 100%;
    font-size: 14px;
`

export const Toolbar = styled.div`
    position: absolute;
    background-color: white;
    width: 200px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    display: ${({ open }) => (open ? 'block' : 'none')};
`

export const CoverButton = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    background: #FFFFFF;
    color: #000000;
    border: none;
    padding: 20px 40px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    font-size: 16px;

    &:hover {
        background: #000000;
        color: #FFFFFF;
    }
`