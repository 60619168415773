import React, { useState, useRef } from 'react'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
} from '../ProjectProfile/Modal/EditModalElements'

import {
    ButtonWrapper,
    ConfirmButton,
    CancelButton,
    AdminInput,
} from './AdminElements'

import { addRole } from './AdminApi'

import { } from './AdminApi' 
import { IoClose } from 'react-icons/io5'

const availablePermissions = [
    'all_permissions',
    'create_project', 
    'delete_project',
    'add_artist', 
    'delete_artist',
    'add_admin', 
    'delete_admin',
    'add_artist_admin',
    'delete_artist_admin',
    'view_users',
    'edit_user',
    'delete_user',
]

const RoleModal = ({ showRoleModal, setShowRoleModal }) => {
    const [role, setRole] = useState('')
    const [hierarchyLevel, setHierarchyLevel] = useState(2)
    const [permissions, setPermissions] = useState([])

    const modalRef = useRef(null)



    const handleConfirm = () => {
        if (hierarchyLevel <= 1 ) {
            alert('Hierarchy level must be greater than 1')
            return
        }
            
        addRole(role, permissions, hierarchyLevel)
        
        setShowRoleModal(false)
    }

    const handlePermissionChange = (permission, isChecked) => {
        setPermissions(currentPermissions => {
            if (isChecked) {
                // Add permission if checked
                return [...currentPermissions, permission]
            } else {
                // Remove permission if unchecked
                return currentPermissions.filter(p => p !== permission)
            }
        })
    }

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''   
            setShowRoleModal(false)
        }
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText>Add Role</HeaderText>
                        <IoClose style={{ cursor: 'pointer' }} size={25} onClick={() => setShowRoleModal(false)} />
                    </Header>
                    <Body style={{ padding: 10 }}>
                        <p>Role Name</p>
                        <AdminInput placeholder='Role Name' onChange={e => setRole(e.target.value)} />
                        <p>Hierarchy Level</p>
                        <AdminInput type='number' value={hierarchyLevel} placeholder='hierarchy level' onChange={e => setHierarchyLevel(e.target.value)} />
                        {availablePermissions.map((permission) => (
                            <div key={permission}>
                                <input
                                    style={{ marginRight: 5, cursor: 'pointer' }}
                                    type="checkbox"
                                    id={permission}
                                    name={permission}
                                    value={permission}
                                    onChange={e => handlePermissionChange(permission, e.target.checked)}
                                />
                                <label htmlFor={permission}>{permission}</label>
                            </div>
                        ))}
                        <ButtonWrapper>
                            <CancelButton onClick={() => setShowRoleModal(false)}>Cancel</CancelButton>
                            <ConfirmButton onClick={handleConfirm} >Confirm</ConfirmButton>
                        </ButtonWrapper>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default RoleModal