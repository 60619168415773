import styled, { keyframes } from 'styled-components'
import { SecondaryColor } from '../../colors'

export const LoadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const spinnerAnimation = keyframes`
  to {transform: rotate(360deg);}
`

export const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

export const Spinner = styled.svg`
  position: relative;
  animation: ${spinnerAnimation} 1s linear infinite;
`

export const Path = styled.circle`
  stroke: ${SecondaryColor};
  stroke-linecap: round;
  animation: ${dashAnimation} 1.5s ease-in-out infinite;
`

// The outer "donut" shaped loading icon
export const OuterSpinner = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`

export const OuterPath = styled.circle`
  stroke: #eeeeee; // Change this to the color you want for the outer donut
  stroke-width: 5;
`