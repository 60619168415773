import styled from 'styled-components'
import { PrimaryColor, PrimaryHoverColor } from '../../../../colors'

export const Wrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
`

export const SectionWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`

export const VerticalLine = styled.div`
    position: absolute;
    top: 20px;
    left: 19px;
    border-left: 2px solid #F5F5F5;
    height: 150%;
`

export const CircleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #4D77FF;
    margin-right: 15px;
    margin-top: 10px;
    z-index: 2;
`

export const TimelineSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`

export const Header = styled.div`
    position: relative;
    padding: 20px;
    height: 80px;
    border-bottom: 1px solid #EFEFEF;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const HeaderText = styled.div`
    font-size: 15px;
`

export const Stage = styled.div`
    font-size: 13px;
    height: 25px;
    width: 80px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6BCB77;
    color: white;
`

export const Body = styled.div`
    padding: 20px;
`

export const BodySection = styled.div`
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
`

export const TooltipContainer = styled.div`
  position: absolute;
  display: inline-block;
`

export const TooltipText = styled.div`
  visibility: hidden;
  display: flex;
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
`

export const AddElementButton = styled.button`
  width: 50%;
  height: 40px;
  background: ${PrimaryColor};
  border: none;
  color: white;

  &:hover {
    background: ${PrimaryHoverColor}
  }

`

export const TooltipTarget = styled.div`
  width: 50%;
  &:hover + ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`

export const DeleteButton = styled.button`
  margin-top: 5px;
  height: 40px;
  width: 100%;
  background: rgba(231, 41, 41);
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
      background: rgba(231, 41, 41, 0.8);
  }
`