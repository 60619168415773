import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    StepHeader,
    Next,
} from './ArtistForms/ArtistFormsElements'

function StepFour({ setCurrentStep, setModalOpen }) {
    const navigate = useNavigate()

    const handleNextStep = () => {
        navigate('/')
    }

    return (
        <>
            <StepHeader style={{ textAlign: 'center', maxWidth: 500 }}>Success! Your work has been submitted. We&apos;ll reach out to you as soon as we can with the next steps.</StepHeader>
            <Next onClick={handleNextStep}>Return Home</Next>
        </>
    )
}

export default StepFour