import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'

import AdminComponent from '../../components/Admin'
import AdminNavbar from '../../components/Admin/AdminNavbar'

const Admin = () => {
    const [users, setUsers] = useState([])
    const [loggedInAdmin, setLoggedInAdmin] = useState({})

    useEffect(() => {
        // Fetch users and their roles
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${API_URL}/admin`, { withCredentials: true })
                setUsers(response.data.admins)
                setLoggedInAdmin(response.data.loggedInAdmin)
            } catch (e) {
                console.log(e)
            }
        }
        fetchUsers()
    }, [])

    return (
        <div>
            <AdminNavbar />
            <AdminComponent users={users} setUsers={setUsers} loggedInAdmin={loggedInAdmin} setLoggedInAdmin={setLoggedInAdmin} />
        </div>
    )
}

export default Admin