import React from 'react'
import { PaginationContainer, PageButton, ArrowButton } from './DiscoverElements';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 5

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <PageButton key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </PageButton>
        )
      }
    } else {
      let isPageNumberOutOfRange

      for (let i = 1; i <= totalPages; i++) {
        const isPageNumberFirst = i === 1
        const isPageNumberLast = i === totalPages
        const isCurrentPageWithinTwoPageNumbers = Math.abs(i - currentPage) <= 2

        if (
          isPageNumberFirst ||
          isPageNumberLast ||
          isCurrentPageWithinTwoPageNumbers
        ) {
          isPageNumberOutOfRange = false
          pageNumbers.push(
            <PageButton key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
              {i}
            </PageButton>
          )
        } else if (!isPageNumberOutOfRange) {
          isPageNumberOutOfRange = true
          pageNumbers.push(<span key={`ellipsis-${i}`}>...</span>)
        }
      }
    }

    return pageNumbers
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  return (
    <PaginationContainer>
      <ArrowButton onClick={handlePrevPage} disabled={currentPage === 1}>
        <MdOutlineKeyboardArrowLeft size={20} />
      </ArrowButton>
      {renderPageNumbers()}
      <ArrowButton onClick={handleNextPage} disabled={currentPage === totalPages}>
        <MdOutlineKeyboardArrowRight size={20} />
      </ArrowButton>
    </PaginationContainer>
  )
}

export default Pagination