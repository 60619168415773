import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../authContext'
import { API_URL } from '../config'
import axios from 'axios'

import NonMainNavbar from '../components/NonMainNav'
import DiscoverUsersComponent from '../components/DiscoverUsers'

const DiscoverUsers = () => {
    const [users, setUsers] = useState([])

    useEffect(
        () => {
            const fetchUsers = async () => {
                try {
                const response = await axios.get(`${API_URL}/discover-users/users`)
                setUsers(response.data)
                } catch (e) {
                    console.log(e)
                }
            }

            fetchUsers()

            
        }, []
    )

    const { loggedInUser } = useContext(AuthContext)

    return (
        <div>
            <NonMainNavbar loggedInUser={loggedInUser} />
            <DiscoverUsersComponent users={users} />
        </div>
    )
}

export default DiscoverUsers