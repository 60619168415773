import React, { useState } from 'react'

import UserProfileBar from './UserProfileBar'
import Portfolio from './Portfolio'
import ExpressedInterest from './ExpressedInterest'
import Followers from './Followers'
import Watchlist from './Watchlist'
import Account from './Account'

import Select from 'react-select'

import {
    DesktopContainer,
    MobileContainer
} from './InvestmentProfileElements'

const MyInvestmentProfile = ({ userPage, setUserPage }) => {
    const [selectedOption, setSelectedOption] = useState('portfolio')

    const options = [
        { value: 'portfolio', label: 'Portfolio' },
        { value: 'interest', label: 'Interest' },
        { value: 'followers', label: 'Followers' },
        { value: 'watchlist', label: 'Watchlist' },
        { value: 'account', label: 'Account' },
        // ... other options ...
    ]

    const customStyles = {
        control: (provided, state) => ({   
            ...provided,
            width: '95vw',
            height: '50px',
            borderRadius: '5px',
            border: '1px solid #E8E8E8',
            fontSize: '15px',
            fontFamily: "'DM sans', sans-serif",
            fontWeight: '500',
            outline: 'none',
            cursor: 'pointer',
            appearance: 'none',
        }),
    }

    return (
        <>
            <DesktopContainer className='desktop-laptop-view'>
                <UserProfileBar selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                {selectedOption === 'portfolio' && <Portfolio />}
                {selectedOption === 'interest' && <ExpressedInterest />}
                {selectedOption === 'followers' && <Followers />}
                {selectedOption === 'watchlist' && <Watchlist userPage={userPage} setUserPage={setUserPage} />}
                {selectedOption === 'account' && <Account userPage={userPage} setUserPage={setUserPage} />}
            </DesktopContainer>
            <MobileContainer className='tablet-mobile-view'>
                <Select 
                    options={options} 
                    value={options.find(option => option.value === selectedOption)}
                    onChange={(option) => setSelectedOption(option.value)}
                    styles={customStyles}
                />
                <div style={{ width: '95%', marginTop: 20 }}>
                    {selectedOption === 'portfolio' && <Portfolio />}
                    {selectedOption === 'interest' && <ExpressedInterest />}
                    {selectedOption === 'followers' && <Followers following={userPage.followedUsers} />}
                    {selectedOption === 'watchlist' && <Watchlist />}
                    {selectedOption === 'account' && <Account userPage={userPage} setUserPage={setUserPage} />}
                </div>
            </MobileContainer>
        </>
    )
}   

export default MyInvestmentProfile