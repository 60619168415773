import styled from 'styled-components'
import { PrimaryColor } from '../../../../colors'

export const Wrapper = styled.div`
    width: 100%;
`

export const StatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Status = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: ${PrimaryColor};
    margin-bottom: 10px;
`

export const StatusDescription = styled.div`
    font-size: 13px;
`

export const StatsBoard = styled.div`
    width: 100%;
    height: auto;
    padding: 30px;
    background: #F9F9F9;
    border: 1px solid #EEEEEE;
    border-radius: 3px;
`

export const StatSection = styled.div`
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
`

export const StatContainer = styled.div`
    position: relative;
    width: auto;
    padding: 10px;
    background: white;
    border: 1px solid #EEEEEE;
`

export const Header = styled.div`
    font-size: 14px;
    color: gray;
    display: flex;
    align-items: center;
`

export const Info = styled.div`
    font-size: 25px;
`

export const TransactionWrapper = styled.div`
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #EEEEEE; 
    border-radius: 3px;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ArrowButton = styled.button`
    border: none;
    margin-top: 2px;
    background: transparent;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background: #EAEAEA;
    }
`

export const DisabledArrowButton = styled.button`
    border: none;
    margin-top: 2px;
    background: transparent;
    padding: 10px;
    border-radius: 3px;
    cursor: default;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`

export const TableHeader = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`

export const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`