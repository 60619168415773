import React, { useState, useRef, useEffect } from 'react'
import LearnTextEditor from './LearnTextEditor'
import LearnPostModal from './LearnPostModal'
import ImageOrEditorModal from './ImageOrEditorModal'
import { 
  LearnWrapper,
  CoverPhotoUploadContainer,
  CoverPhotoWrapper,
  CoverPhoto,
  UploadText,
  PostButton,
  Title,
  Image, 
  ImageWrapper, 
  PlusButtonWrapper, 
  PlusButton,
  CancelCoverButton,
  DeleteButton 
} from './LearnElements'

import { BiUpload } from 'react-icons/bi'
import { useDropzone } from 'react-dropzone'

const NewLearnPost = () => {
  const [coverPhoto, setCoverPhoto] = useState(null)
  const [title, setTitle] = useState(false)
  const [content, setContent] = useState([{ id: Date.now(), ref: React.createRef(), content: '', type: 'editor' }])
  const [showModal, setShowModal] = useState(false)
  const [showPostModal, setShowPostModal] = useState(false)
  const latestEditorRef = useRef(null)
  const firstEditorRef = useRef(null)
  const currentFocusedIndexRef = useRef(0)
  const newEditorAdded = useRef(false)

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setCoverPhoto(acceptedFiles[0]) // Assuming single file upload
    },
  })

  const handleRemoveCoverImage = () => {
    setCoverPhoto(null)
  }

  const handleSubmitPost = async () => {
    setShowPostModal(true)
  }

  const addEditor = () => {
    const newEditorRef = React.createRef()
    const newEditor = { id: Date.now(), ref: newEditorRef, type: 'editor' }
    const updatedContent = [...content]
    updatedContent.splice(currentFocusedIndexRef.current + 1, 0, newEditor)
    setContent(updatedContent)
    latestEditorRef.current = newEditorRef
    newEditorAdded.current = true
  }

  const addImages = (newImages) => {
    const newImageElements = newImages.map((file, index) => ({
      id: Date.now() + index,
      file,
      content: file.path,
      ref: null, // No ref needed for images
      type: 'image'
    }))
    const updatedContent = [...content]
    updatedContent.splice(currentFocusedIndexRef.current + 1, 0, ...newImageElements)
    setContent(updatedContent)
  }

  useEffect(() => {
    // Focus the first editor when the component mounts
    if (content.length > 0 && content[0].ref && content[0].ref.current) {
      firstEditorRef.current = content[0].ref
      content[0].ref.current.focus()
    }

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newEditorAdded.current && latestEditorRef.current) {
      latestEditorRef.current.current.focus()
      newEditorAdded.current = false
    }
  }, [content])

  const handleTitleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (firstEditorRef.current && firstEditorRef.current.current) {
        firstEditorRef.current.current.focus()
      }
    }
  }

  const removeEditor = (index) => {
    const updatedContent = content.filter((_, i) => i !== index)
    setContent(updatedContent)
  }

  const removeImage = (index) => {
    const updatedContent = content.filter((_, i) => i !== index)
    setContent(updatedContent)
  }

  const handleFocus = (index) => {
    currentFocusedIndexRef.current = index
  }

  return (
    <LearnWrapper>
      <PostButton onClick={handleSubmitPost} >Post</PostButton>
      <>
        {coverPhoto ? (
          <CoverPhotoWrapper>
            <CoverPhoto src={URL.createObjectURL(coverPhoto)} alt="Cover" />
            <CancelCoverButton onClick={handleRemoveCoverImage}>Cancel</CancelCoverButton>
          </CoverPhotoWrapper>
        ) : (
          <CoverPhotoUploadContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <BiUpload size={40} color='gray'/>
            <UploadText>Click or drag to upload a cover photo. It will be cropped to a 16x9 aspect ratio.</UploadText>
          </CoverPhotoUploadContainer>
        )}
      </>
      {showModal && (
        <ImageOrEditorModal
          addEditor={addEditor}
          addImages={addImages}
          setShowModal={setShowModal}
        />
      )}
      {showPostModal && (
        <LearnPostModal 
          title={title}
          setShowPostModal={setShowPostModal}
          content={content}
          coverPhoto={coverPhoto}
        />
      )}
      <Title 
        placeholder='Enter a title' 
        onKeyPress={handleTitleKeyPress} 
        onChange={(e) => setTitle(e.target.value)}
      />
      {content.map((item, index) =>
        item.type === 'editor' ? (
          <LearnTextEditor
            key={item.id}
            ref={item.ref}
            addEditor={addEditor}
            setShowModal={setShowModal}
            onFocus={() => handleFocus(index)}
            index={index}
            setContent={setContent}
            removeEditor={removeEditor}
          />
        ) : (
          <ImageWrapper key={item.id}>
            <DeleteButton onClick={() => removeImage(index)}>Delete</DeleteButton>
            <Image src={URL.createObjectURL(item.file)} alt={`uploaded-${item.id}`} />
            <PlusButtonWrapper>
              <PlusButton onClick={() => {
                handleFocus(index)
                setShowModal(true)
              }}>+</PlusButton>
            </PlusButtonWrapper>
          </ImageWrapper>
        )
      )}
    </LearnWrapper>
  )
}

export default NewLearnPost