import React, { useState, useRef } from 'react'

import { 
    Wrapper, 
    ModalBg, 
    Modal, 
    Header, 
    HeaderText, 
    Body,
} from '../../../Modal/EditModalElements'

import { addFaq } from '../FaqApi'

import { FaqModalHeader, FaqModalQuestion, AddFaqButton, CancelButton } from '../FaqElements'
import { IoClose } from 'react-icons/io5'

function FaqModal({ setShowFaqModal, projectInfo, setProjectInfo }) {
    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')

    const modalRef = useRef(null)

    const closeModalClick = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''
            setShowFaqModal(false)
        }
    }

    const handleCancelClick = e => {
        document.body.style.overflow = ''
        setShowFaqModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const faq = {
            question: question,
            answer: answer
        }

        const response = await addFaq(projectInfo.project._id, faq)

        setProjectInfo(prevProjectInfo => {
            return {
                ...prevProjectInfo,
                project: {
                    ...prevProjectInfo.project,
                    faqs: response.data
                }
            }
        })
        document.body.style.overflow = ''
        setShowFaqModal(false)
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModalClick}>
                <Modal>
                    <Header>
                        <HeaderText>Add Faq</HeaderText>
                        <IoClose onClick={handleCancelClick} size={25} style={{ cursor: 'pointer' }} />
                    </Header>
                    <Body style={{ padding: 10 }}>
                            <FaqModalHeader>Question</FaqModalHeader>
                            <FaqModalQuestion placeholder='Enter question...' onChange={e => setQuestion(e.target.value)}/>
                            <FaqModalHeader>Answer</FaqModalHeader>
                            <FaqModalQuestion placeholder='Enter answer...' onChange={e => setAnswer(e.target.value)}/>
                            <AddFaqButton onClick={handleSubmit} >Add Faq</AddFaqButton>
                            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default FaqModal