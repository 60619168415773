import React, { useState, useEffect } from 'react'
import { Wrapper, Collapsible } from './FaqElements'
import { EditButton } from '../Perks/PerkElements'
import { IconCircleDiv } from '../Details/ProjectDetailElements'
import { NoInfo } from '../Details/ProjectDetailElements'
import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'
import FaqModal from './FaqModal'
import { deleteFaq } from './FaqApi'

import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'

function Faqs({ projectInfo, setProjectInfo, loggedInUser }) {
    const [isLoading, setIsLoading] = useState(true)
    const [showFaqModal, setShowFaqModal] = useState(false)
    const [faqArray, setFaqArray] = useState([])
    const [hoveredIndex, setHoveredIndex] = useState(-1)

    const handleShowModalClick = () => {
        document.body.style.overflow = 'hidden'
        setShowFaqModal(true)

    }

    useEffect(() => {
            let { faqs } = projectInfo?.project || {}
            for (let i=0; i < faqs?.length; i++) {
                faqs[i].show = false
            }

            setFaqArray(projectInfo?.project?.faqs)
            setIsLoading(false)
    }, [projectInfo])

    const handleFaqClick = (index) => {
        setFaqArray((prevFaqArray) =>
          prevFaqArray.map((faq, i) => (i === index ? { ...faq, show: !faq.show } : faq))
        )
    }

    const handleDeleteClick = async (index) => {
        if (!window.confirm('Are you sure you want to delete this faq? This cannot be undone.'))
            return 

        const response = await deleteFaq(projectInfo.project._id, projectInfo.project.faqs[index]._id)

        // Update projectInfo after deletion
        setProjectInfo(prevProjectInfo => {
            return {
                ...prevProjectInfo,
                project: {
                    ...prevProjectInfo?.project,
                    faqs: response.data
                }
            }
        })
    }

    const faqsMapping = faqArray?.map((faq, index) => {
        let style 
        if (index === 0 && faq.show)
            style = { marginBottom: 5 }
        else if (faq.show)
            style = { marginTop: 5, marginBottom: 5 }
        else 
            style = {}
        return (
            <div 
                style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 20 }} 
                key={faq._id} 
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(-1)} 
            >
                <Collapsible onClick={() => handleFaqClick(index)} style={style}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontWeight: 'bold', width: '95%' }}>
                                {faq.question}
                            </div>
                            <div>
                                {!faq.show ? <FiPlusCircle color='#82CD47'/> : <FiMinusCircle color='#FF6464'/>}
                            </div>
                        </div>
                        {faq.show ?
                        <div style={{ fontSize: 13, marginTop: 10 }}>
                            {faq.answer}
                        </div>
                        :
                        null
                        }
                    </div>
                </Collapsible>
                {(loggedInUser?.artist === projectInfo?.project?.artist._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id))  && 
                    <IconCircleDiv style={{ height: 50, width: 50 }} onClick={() => handleDeleteClick(index)} >
                        <MdDelete
                            size={20}
                            style={{
                                cursor: 'pointer',
                                display: index === hoveredIndex ? 'block' : 'none',
                            }}
                            />
                    </IconCircleDiv>
                }
            </div>
        )
    })

    return (
        <Wrapper>
            {isLoading 
            ?
            <LoadContainer>
                <div style={{position: "relative"}}>
                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                    </OuterSpinner>
                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </Spinner>
                </div>
            </LoadContainer>
            :
            <>
                {showFaqModal && <FaqModal setShowFaqModal={setShowFaqModal} projectInfo={projectInfo} setProjectInfo={setProjectInfo} />}
                {(loggedInUser?.artist === projectInfo?.project?.artist._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && (
                    <EditButton onClick={handleShowModalClick} style={{ marginBottom: 20 }}>
                        Add Faq
                    </EditButton>
                )}
                {faqsMapping}
                {faqArray?.length === 0 ? <NoInfo>No faqs yet.</NoInfo> : null}
            </>
            }
        </Wrapper>
    )
}

export default Faqs