import styled from 'styled-components'

export const GridWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 850px) {
        grid-template-columns: 1fr;
    }
`

//Left side elements
export const LeftHalfWrapper = styled.div`
    box-sizing: border-box;
    padding-top: 40px;
    padding-left: 30px;
    margin-right: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const OptionButton = styled.button`
    height: 40px;
    width: 200px;
    padding-left: 10px;
    /* background-color: transparent; */
    border: none;
    color: ${props => props.selected ? 'black' : 'grey'};
    background-color: ${props => props.selected ? '#F5F5F5' : 'transparent'};
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;

    &:hover {
        color: black;
    }
`

//Right side elements
export const RightHalfWrapper = styled.div`
    box-sizing: border-box;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden; /* or overflow-y: auto if you want it scrollable */
    position: relative;
`

export const PortfolioHeader = styled.div`
    width: 100%;
    display: flex;
    font-family: 'DM Sans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
`

export const NoInfoWrapper = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    color: grey;
    background: #F5F5F5;
`