import React from 'react'

import AdminLoginComponent from '../../components/AdminLogin'

const AdminLogin = () => {
    return (
        <div style={{ marginTop: 30 }}>
            <AdminLoginComponent />
        </div>
    )
}

export default AdminLogin
