import React from 'react'
import { post } from '../LearnApi'
import { useNavigate } from 'react-router-dom'

import {
    Modal,
    Header,
    HeaderText,
    Body,
} from '../../ProjectProfile/Modal/EditModalElements.js'

import {
    SubmitButton,
    CancelButton
} from '../LearnElements.js'

import { IoClose } from 'react-icons/io5'
import { IoIosArrowBack } from 'react-icons/io'

const Review = ({ title, content, postType, tags, setShowTagsModal, setShowReviewModal, setShowPostModal, coverPhoto }) => {
    const navigate = useNavigate()

    const handleClose = () => {
        setShowTagsModal(false)
        setShowReviewModal(false)
        setShowPostModal(false)
    }

    const handleBackClick = () => {
        setShowTagsModal(false)
        setShowReviewModal(false)
    }

    const handleSubmitPost = async () => {
        try {
          await post(title, content, postType, tags, coverPhoto)
          navigate('/admin/dashboard')
        } catch (error) {
          console.error('Failed to submit post:', error)
        }
    }

    return (
            <Modal>
                <Header>
                    <HeaderText onClick={handleBackClick} style={{ display: 'flex', alignItems: 'center' }}><IoIosArrowBack style={{ marginRight: 5 }} /> Review</HeaderText>
                    <IoClose onClick={handleClose} size={25} style={{ cursor: 'pointer' }} />
                </Header>
                <Body style={{ padding: 15 }}>
                    <p>Post Type: <strong>{postType}</strong></p>
                    <p style={{ marginTop: 5 }} >Tag: <strong>{tags}</strong></p>
                    <SubmitButton onClick={handleSubmitPost}>Submit</SubmitButton>
                    <CancelButton>Cancel</CancelButton>
                </Body>
            </Modal>
    )
}

export default Review