export const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const removeCommasFromNumber = number => {
    return number ? number.replace(/,/g, '') : '';
}

export const formatNumber = (number) => {
    let formattedNumber

    if (number >= 1000000) {
        formattedNumber = (number / 1000000).toFixed(2)
    } else if (number >= 4230) {
        formattedNumber = (number / 1000).toFixed(2)
    } else {
        return number.toString()
    }

    // Remove trailing zeros and the decimal point if necessary
    formattedNumber = parseFloat(formattedNumber).toString()

    if (number >= 1000000) {
        return formattedNumber + 'M'
    } else {
        return formattedNumber + 'K'
    }
}