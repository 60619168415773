import React, { useState } from 'react'

import {
    Modal,
    Header,
    HeaderText,
    Body,
    BodySection
} from '../../ProjectProfile/Modal/EditModalElements.js'

import {
    CustomBody,
    CustomTag,
    ContinueButton
} from '../LearnElements.js'

import { IoClose } from 'react-icons/io5'
import { PiGraduationCapFill } from 'react-icons/pi'
import { BsExclamationOctagon } from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'

const Tags = ({ setShowPostModal, setShowTagsModal, setTags, setShowReviewModal }) => {
    const [value, setValue] = useState('')

    const handleClose = () => {
        setShowTagsModal(false)
        setShowReviewModal(false)
        setShowPostModal(false)
    }

    const handleTagSelect = (tag) => {
        setTags(tag)
        setShowTagsModal(false)
        setShowReviewModal(true)
    }

    const handleBackClick = () => {
        setShowTagsModal(false)
        setShowReviewModal(false)
    }

    return (
        <Modal>
            <Header>
                <HeaderText onClick={handleBackClick} style={{ display: 'flex', alignItems: 'center' }}><IoIosArrowBack style={{ marginRight: 5 }} /> Attach a tag to the post</HeaderText>
                <IoClose size={25} style={{ cursor: 'pointer' }} onClick={handleClose} />
            </Header>
            <Body>
                <BodySection onClick={() => handleTagSelect('Risks')}>
                    <BsExclamationOctagon style={{ marginRight: 10 }} size={20} />
                    Risks
                </BodySection>
                <BodySection onClick={() => handleTagSelect('Education')}>
                    <PiGraduationCapFill style={{ marginRight: 10 }} size={20} /> 
                    Education
                </BodySection>
                <CustomBody>
                    <CustomTag placeholder='Or enter a custom tag...' onChange={e => setValue(e.target.value)}/>
                    <ContinueButton onClick={() => handleTagSelect(value)}>Continue</ContinueButton>
                </CustomBody>
            </Body>
        </Modal>
    )
}

export default Tags