import React, { useState, useEffect, lazy, Suspense } from 'react'
import LazyLoaderModal from '../../LazyLoaderModal'

import { 
    Wrapper,
    General,
    VideoWrapper,
    StyledIframe,
    Header,
    Awards,
    InfoContainer,
    InfoWrapper,
    InfoHeader,
    InfoText,
    AboutInfoWrapper,
    AwardsWrapper,
    HeaderContainer,
} from './AboutElements'

import { ButtonWrapper, Button } from '../ArtistProjects/ArtistProjectsElements'

import { FiEdit2 } from 'react-icons/fi'

import Award from '../Awards'

const AboutModal = lazy(() => import('../AboutModal'))

function About({ 
    embedVideo,
    miniBio,
    projectsNumber,
    followCount,
    setArtistAssets, 
    awards,
    loggedInUser,
    artistId
}) {
    const [showAboutModal, setShowAboutModal] = useState(false)
    const [src, setSrc] = useState('')

    // Move the extractSrc function outside of the useEffect
    function extractSrc(iframeString) {
        const match = iframeString.match(/src="([^"]+)"/)
        return match ? match[1] : null
    }

    useEffect(() => {
        if (embedVideo) {
            setSrc(extractSrc(embedVideo))
        }
    }, [embedVideo])

    const handleShowAboutClick = e => {
        e.preventDefault()
        document.body.style.overflow = showAboutModal ? 'auto' : 'hidden'
        setShowAboutModal(prev => !prev)
    }

    const formatFollowCount = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(2) + 'M'
        } else if (count >= 1000) {
            return (count / 1000).toFixed(2) + 'K'
        } else {
            return count.toString()
        }
    }

    return (
        <Wrapper>
            <Suspense fallback={LazyLoaderModal}>
                {showAboutModal && (
                    <AboutModal 
                        src={src} 
                        embedVideo={embedVideo}
                        awards={awards} 
                        miniBio={miniBio} 
                        setArtistAssets={setArtistAssets}
                        showAboutModal={showAboutModal} 
                        setShowAboutModal={setShowAboutModal} 
                    />
                )}
            </Suspense>
            {loggedInUser?.artist === artistId &&
            <ButtonWrapper>
                <Button onClick={handleShowAboutClick} >
                    <FiEdit2 size={15} />
                    <div style={{ marginLeft: 6 }}>Update</div>
                </Button>
            </ButtonWrapper>
            }
            <AboutInfoWrapper>
            {(embedVideo || miniBio) && (
                <General>
                    {embedVideo && (
                    <VideoWrapper>
                        <StyledIframe src={src} />
                    </VideoWrapper>
                    )}
                    {miniBio}
                </General>
            )}
            </AboutInfoWrapper>
            {awards.length > 0 &&
                <AwardsWrapper>
                    <HeaderContainer>
                        <Header>Awards</Header>
                    </HeaderContainer>
                    <Awards>
                        <Award 
                            awards={awards} 
                            artistId={artistId}
                            loggedInUser={loggedInUser}
                            setArtistAssets={setArtistAssets}
                        />
                    </Awards>
                </AwardsWrapper>
            }
            <InfoContainer>
                <InfoWrapper>
                    <InfoHeader>Followers</InfoHeader>
                    <InfoText>{formatFollowCount(followCount)}</InfoText>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoHeader>Projects</InfoHeader>
                    <InfoText>{projectsNumber}</InfoText>
                </InfoWrapper>
                {/* <InfoWrapper>
                    <InfoHeader>Total Funds Raised</InfoHeader>
                    <InfoText>$5M</InfoText>
                </InfoWrapper> */}
            </InfoContainer>
        </Wrapper>
    )
}

export default About
