import axios from 'axios'

import { API_URL } from '../../../../config'

export const deleteFaq = async (projectId, faqId) => {
    const payload = { projectId, faqId }
    return axios.post(`${API_URL}/project/delete-faq/me`, payload, { withCredentials: true })
}

export const addFaq = async (projectId, faq) => {
    const payload = { projectId, faq }
    return axios.post(`${API_URL}/project/add-faq/me`, payload, { withCredentials: true })
}