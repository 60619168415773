import React, { useState, useEffect, useRef, useContext } from 'react'
import { API_URL } from '../config'
import { AuthContext } from '../authContext'
import { useParams, useLocation } from 'react-router-dom'

import axios from 'axios'

import Header from '../components/ProjectProfile/Header'
import Body from '../components/ProjectProfile/Body'
// import NonMainNavbar from '../components/NonMainNav'
import NavBar from '../components/Navbar'

function ProjectProfile() {
    const { projectId } = useParams()
    const offSetRef = useRef(window.pageYOffset)

    const [projectInfo, setProjectInfo] = useState({})

    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const onScroll = () => {
            offSetRef.current = window.pageYOffset
        }
        // clean up code
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    useEffect(() => {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0)
        }
    
        let didCancel = false
    
        const getProjectInfo = async () => {
            try {
                const response = await axios.get(`${API_URL}/project/${projectId}`, { withCredentials: true })
                if (!didCancel) {
                    setProjectInfo(response.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getProjectInfo()
    
        return () => {
            didCancel = true
        }
    }, [projectId, location])

    const { loggedInUser } = useContext(AuthContext)

    return (
        <div>
            <NavBar 
                loggedInUser={loggedInUser} 
            />
            <Header 
                projectInfo={projectInfo} 
                setProjectInfo={setProjectInfo} 
                loggedInUser={loggedInUser}
            />
            <Body 
                projectInfo={projectInfo} 
                setProjectInfo={setProjectInfo} 
                loggedInUser={loggedInUser} 
                offSet={offSetRef} 
            />
        </div>
    )
}

export default ProjectProfile