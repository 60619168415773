import React, { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { useDropzone } from 'react-dropzone'
import { MdAddAPhoto } from 'react-icons/md'
import { DATABASE, CHANGE, NEW, DELETE } from '../../../../../constants'
import { AWS_URL } from '../../../../../config'
import { IoMdAdd } from 'react-icons/io'
import DeleteModal from '../DeleteModal'
import { MdDelete } from 'react-icons/md'

import { 
    ProfileWrapper, 
    ProfilePhoto, 
    Photo, 
    Name, 
    Role, 
    Description, 
    IconCircleDiv, 
    Add, 
    ProfilePhotoOverlay, 
} from '../ProjectDetailElements'

const Dropzone = ({ onImageSelected, cast, setCast, index, isOverlay, slug }) => {
    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles)

        if (cast[index].status === DATABASE) {
            setCast(prevCast => prevCast.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}`, oldImageName: item.imageFileName, status: CHANGE }
                }
                return item
            }))
        } else if (cast[index].status === CHANGE) {
            setCast(prevCast => prevCast.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}`, status: CHANGE }
                }
                return item
            }))
        } else if (cast[index].status === DELETE) {
            setCast(prevCast => prevCast.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}`, status: CHANGE }
                }
                return item
            }))
        } else if (cast[index].status === NEW) {
            setCast(prevCast => prevCast.map((item, idx) => {
                if (idx === index) {
                    return { ...item, imageFileName: `projects/${slug}/details/${acceptedFiles[0].path}` }
                }
                return item
            }))
        }
      // Call the parent component's onImageSelected function with the selected file
      onImageSelected(acceptedFiles[0])
    }, [onImageSelected, cast, index, setCast, slug])
  
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Allow only image files
        maxFiles: 1, // Limit to a single file
    })
  
    return (
      <>
        {isOverlay ? (
          <ProfilePhotoOverlay {...getRootProps()}>
            <input {...getInputProps()} />
          </ProfilePhotoOverlay>
        ) : (
          <ProfilePhoto {...getRootProps()}>
            <input {...getInputProps()} />
            <MdAddAPhoto size={30} />
          </ProfilePhoto>
        )}
      </>
    )
}

function CastMemberCard({ 
    project,
    editCast, 
    cast, 
    setCast, 
    member, 
    index,
    deleteArray, 
    setDeleteArray, 
    imageFiles, 
    setImageFiles, 
    handleImageSelected, 
}) {
    
    const handleAddTopMemberClick = () => {
        setCast((prevCast) => [
            {
                imageFileName: '',
                previewFileName: '',
                name: '',
                role: '',
                description: '',
                imageFile: [],
                index: 0,
                status: 'NEW',
            },
            ...prevCast,
        ])
    }

    const handleAddClick = (index) => {
        setCast((prevCast) => [
            ...prevCast.slice(0, index + 1),
            {
                imageFileName: '',
                previewFileName: '',
                name: '',
                role: '',
                description: '',
                imageFile: [],
                index: index + 1,
                status: 'NEW',
            },
            ...prevCast.slice(index + 1),
        ])
    }

    const handleNameChange = (e, index) => {
        setCast((prevCast) =>
            prevCast.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          name: e.target.value,
                          status: member.status !== 'NEW' ? 'CHANGE' : member.status,
                      }
                    : member
            )
        )
    }

    const handleRoleChange = (e, index) => {
        setCast((prevCast) =>
            prevCast.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          role: e.target.value,
                          status: member.status !== 'NEW' ? 'CHANGE' : member.status,
                      }
                    : member
            )
        )
    }

    const handleMouseOver = debounce((event, index) => {
        setCast((prevCast) =>
            prevCast.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          isHovered: true,
                      }
                    : member
            )
        )
    }, 100)

    const handleMouseLeave = debounce((event, index) => {
        setCast(prevCast =>
            prevCast.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          isHovered: false,
                      }
                    : member
            )
        )
    }, 100)

    const handleDeleteMember = (index) => {
        console.log(index)
        setCast((prevCast) => {
          const tempArray = [...prevCast]
          const tempDelete = [...deleteArray]
          console.log(tempArray)
      
          if (tempArray.length === 1) {
            // Reset the values for the last remaining member
            tempArray[index] = {
              ...tempArray[index],
              imageFileName: "",
              role: "",
              name: "",
              description: "",
              status: DELETE,
            }

            tempDelete.push(tempArray[index])
          } else {
            if (tempArray[index].status !== NEW) {
              tempArray[index].index = index
              tempDelete.push(tempArray[index])
              tempArray[index].status = DELETE
            }
            tempArray.splice(index, 1)
          }
      
          setDeleteArray(tempDelete)
          return tempArray
        })
    }

    const handleDescriptionChange = (e, index) => {
        setCast(prevCast =>
            prevCast.map((member, i) =>
                i === index
                    ? {
                          ...member,
                          description: e.target.value,
                          status: member.status !== 'NEW' ? 'CHANGE' : member.status,
                      }
                    : member
            )
        )
    }  

    return (
        <div style={{ marginTop: 10 }} onMouseOver={(event) => handleMouseOver(event, index)} onMouseLeave={event => handleMouseLeave(event, index)} >
            {index === 0 ?
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <Add onClick={index === 0 ? () => handleAddTopMemberClick() : () => handleAddClick(index)}><IoMdAdd /></Add>
            </div>
            : 
            null
            }
            <ProfileWrapper>
                <div>
                    {member.imageFile?.length > 0 || member.imageFileName ?
                    <ProfilePhoto style={{ position: 'relative' }}>
                        <Photo draggable='false' src={member.imageFile.length > 0 ? member.imageFile[0].preview : `${AWS_URL}${member.imageFileName}`} />
                        <Dropzone isOverlay={true} onImageSelected={(files) => handleImageSelected(files, index)} cast={cast} setCast={setCast} imageFiles={imageFiles} setImageFiles={setImageFiles} index={index} slug={project?.slug}/>
                    </ProfilePhoto>
                    : 
                    <ProfilePhoto>
                        <Dropzone onImageSelected={(files) => handleImageSelected(files, index)} cast={cast} setCast={setCast} imageFiles={imageFiles} setImageFiles={setImageFiles} index={index} project={project} />
                    </ProfilePhoto>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Name placeholder='Enter a name...' value={member.name} onChange={e => handleNameChange(e, index)}></Name>
                        <Role placeholder='Enter a role...' value={member.role} onChange={e => handleRoleChange(e, index)}></Role>
                        {member.isHovered || member.showDeleteModal ?
                            <IconCircleDiv onClick={() => handleDeleteMember(index)} >
                                <MdDelete size={20} color='black' />
                            </IconCircleDiv>
                        :
                            null
                        }
                        {member.showDeleteModal ?
                            <DeleteModal teamMembers={cast} setTeamMembers={setCast} index={index} deleteArray={deleteArray} setDeleteArray={setDeleteArray} ></DeleteModal>
                        :
                            null
                        }
                    </div>
                    <Description placeholder='Enter a description...' value={member.description} onChange={e => handleDescriptionChange(e, index)}></Description>
                </div>
            </ProfileWrapper>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <Add onClick={() => handleAddClick(index)}><IoMdAdd/></Add>
            </div>
        </div>
    )
}

export default React.memo(CastMemberCard)