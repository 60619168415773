import styled from 'styled-components'

export const Wrapper = styled.section`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* flex-direction: column;
    justify-content: center;
    align-items: center;  */
`

export const LeftRightWrapper = styled.div`
    display: flex;
    height: 100%;
    overflow-x: hidden;
`

export const LoglineInput = styled.textarea`
    width: 100%;
    height: 100px;
    border-radius: 4px;
    padding: 15px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid #bbbbbb'};
    margin-bottom: 10px;
    resize: none;
`

export const DropzoneContainer = styled.div`
    width: 100%;
    height: 400px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    border: ${props => props.isInvalid ? '1px dashed red' : '1px dashed #888'};
`

export const DropzoneText = styled.p`
    text-align: center;
`

export const DropzoneSubtext = styled.p`
    text-align: center;
    color: #888;
`

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* for a 16:9 aspect ratio, where 56.25 = 9/16 * 100% */
    overflow: hidden;
`

export const PreviewImage = styled.img`
    /* width: 100%;
    height: 377px; */
    position: absolute;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
`

export const DropdownMenu = styled.select`
    width: 95%;
    height: 50px;
    padding: 5px;
    margin-left: 5%;
    font-size: 13px;
    border-radius: 5px;
    border: ${props => props.isInvalid ? '1px solid red' : '1px solid #bbbbbb'};
`

export const InputWithDollarSign = styled.div`
    position: relative;
    width: 60%;
    input {
        padding-left: 20px; // or adjust this as needed
    }
    &::before {
        position: absolute;
        content: '$';
        left: 15px; // or adjust this as needed
        top: 15px;
        /* transform: translateY(-50%); */
    }

    &::after {
        position: absolute;
        content: 'USD';
        right: 15px; // or adjust this as needed
        top: 15px;
        /* transform: translateY(-50%); */
    }
`

export const InputWithPercentSign = styled.div`
    position: relative;
    width: 60%;
    input {
        padding-left: 20px; // or adjust this as needed
    }

    &::after {
        position: absolute;
        content: '%';
        right: 15px; // or adjust this as needed
        top: 14px;
        /* transform: translateY(-50%); */
    }
`

export const Select = styled.select`
  color: black;
  width: 75px;
  height: 50px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* Add other styles as desired */
`

export const TimeSelect = styled.select`
  color: black;
  height: 50px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* Add other styles as desired */
`

export const VideoWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    width: 100%;
    max-width: 560px; /* or whatever maximum width you want */
    margin: auto; 
`

export const StyledIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`

export const IframeWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    /* max-width: 560px; or whatever maximum width you want */
    margin: auto; 

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
`

export const FinalMessage = styled.p`
    margin-top: 20px;
    font-size: 20px;
    color: red;
`

export const LeftHalfPage = styled.div`
    width: 35%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: #FAF7F0; */
    background: #F8F6F4;
    overflow-y: hidden;
`

export const RightHalfPage = styled.div`
    width: 65%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`

export const RightHalfContainer = styled.div`
    width: 100%; 
    height: 100%; 
    overflow-y: scroll;
    padding-bottom: 100px;
    overflow-x: hidden;
`

export const Logo = styled.h1`
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 20px;
    cursor: pointer;
`

export const SignupLogin = styled.div`
    position: absolute;
    font-family: 'DM Sans', sans-serif;
    top: 20px;
    right: 20px;
    font-size: 16px;
    cursor: pointer;
`

export const StepContainer = styled.div`
    position: absolute;
    top: 200px;
    left: 14%;
    width: 250px;
    display: flex;
    flex-direction: column;
`

export const Step = styled.div`
    font-family: 'DM Sans', sans-serif;
`

export const TitleContainer = styled.div`
    position: absolute;
    width: 250px;
    top: 250px;
    left: 14%;
`

export const StepTitle = styled.h1`
    font-size: 20px;
    font-weight: 300;
    font-family: 'DM Sans', sans-serif;
`

export const DescriptionContainer = styled.div`
    position: absolute;
    width: 70%;
    top: 300px;
    left: 14%;
`

export const StepDescription = styled.p`
    font-size: 16px;
    font-weight: 300;
    font-family: 'DM Sans', sans-serif;
`

export const InputAbsoluteWrapper = styled.div`
    width: 100%;
    margin-top: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NextArea = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding-right: 50px;
    padding-left: 50px;
    bottom: 0;
    width: 100%;
    height: 150px;
    border-top: 1px solid #EAEAEA;
    z-index: 100;
`

export const InfoBubble = styled.div`
    width: 60%;
    top: 200px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 20px;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
`

export const Info = styled.p`

`

export const ReviewTitle = styled.h1`
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #888;
    font-family: 'DM Sans', sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
`

export const ReviewInfo = styled.div`  
    width: 100%;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 20px;
`


// MOBILE LAYOUT

export const MobileWrapper = styled.section`
    width: 100%;
    height: 100%;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    /* flex-direction: column;
    justify-content: center;
    align-items: center;  */
`

export const MobileTitle = styled.h1`
    font-size: 30px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    margin-top: 30px;
    margin-bottom: 20px;
`

export const MobileDescription = styled.p`
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 20px;
    color: #888;
`

export const MobileButton = styled.button`
    width: 100%;
    height: 50px;
    background: rgb(82, 95, 225); //#525FE1
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        background: rgb(92, 105, 235);
    }
`

export const MobileBackButton = styled.button`
    width: 100%;
    height: 50px;
    background: rgb(216, 217, 218); //#525FE1
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        background: rgb(226, 227, 228);
    }
`

export const DropdownMenuWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 20px;
`

export const DropdownFundingMenu = styled.select`
    width: 60%;
    height: 50px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #bbbbbb;
    background-color: white;
    color: black;

    &:focus {
        outline: none;
        border-color: #888;
    }
`