import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../../../config'
import { handleMoneyChange } from '../helpers/moneyHelper'
import { IoClose } from 'react-icons/io5'

import { 
    ModalBg, 
    Modal,
    Header,
    HeaderText,
    Body,
    ModalTitleInfo, 
    MoneyInputWrapper,
    MoneyInput,
    ButtonWrapper,
    CancelButton,
    SaveButton,
    Container,
    Heading,
    SubHeading,
    StyledList,
    ListItem,
    Paragraph,
    AccreditedWrapper,
    AccreditedContainer,
    AccreditedText,
    ToggleWrapper,
    ToggleCircle,
    ToggleText
} from '../Modal/ModalElements'

import { RadioButton, RadioWrapper, RadioLabel } from '../../../ProjectInvest/ProjectInvestElements'

const StatusMessage = ({ message, type }) => (
    <div 
        style={{
            padding: '12px',
            marginBottom: '16px',
            borderRadius: '4px',
            backgroundColor: type === 'error' ? '#FEE2E2' : 
                           type === 'success' ? '#DCFCE7' : 
                           '#DBEAFE',
            color: type === 'error' ? '#991B1B' : 
                   type === 'success' ? '#166534' : 
                   '#1E40AF',
        }}
    >
        {message}
    </div>
)

const LoadingSpinner = () => (
    <div 
        style={{
            display: 'inline-block',
            width: '16px',
            height: '16px',
            marginRight: '8px',
            border: '2px solid #ffffff',
            borderTopColor: 'transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
        }}
    />
)

const LimitModal = ({ modalType, setShowModal, investmentInfo, setInvestmentInfo }) => {
    const [inputValue, setInputValue] = useState('')
    const [toggle, setToggle] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [updateStatus, setUpdateStatus] = useState({ message: '', type: '' })
    const [accreditedStatus, setAccreditedStatus] = useState({
        accredited: false,
        accreditedType: ''
    })

    const modalRef = useRef()

    useEffect(() => {
        // Add keyframe animation for spinner
        const styleSheet = document.createElement("style")
        styleSheet.textContent = `
            @keyframes spin {
                to { transform: rotate(360deg); }
            }
        `
        document.head.appendChild(styleSheet)

        if (modalType === 'Accredited Status') {
            setToggle(investmentInfo.accreditationStatus)
            setAccreditedStatus({
                accredited: investmentInfo.accreditationStatus,
                accreditedType: investmentInfo.accreditationType
            })
        } else if (modalType === 'Annual Income') {
            setInputValue(investmentInfo.annualIncome)
        } else if (modalType === 'Net Worth') {
            setInputValue(investmentInfo.netWorth)
        }

        return () => {
            document.head.removeChild(styleSheet)
        }
    }, [investmentInfo, modalType])

    const handleClose = (e) => {
        if (modalRef.current === e.target) {
            setShowModal(false)
        }
    }

    const handleToggle = (key) => {
        setToggle(!toggle)
        if (!toggle)
            setAccreditedStatus({
                accredited: true,
                accreditedType: ''
            })
        else
            setAccreditedStatus({
                accredited: false,
                accreditedType: ''
            })
    }

    const handleAccreditorTypeChange = (event) => {
        setAccreditedStatus({
            ...accreditedStatus,
            accreditedType: event.target.value
        })
    }

    const handleSubmitAnnualIncome = async () => {
        const cleanedInputValue = inputValue.replace(/,/g, '')
        const annualIncome = parseInt(cleanedInputValue, 10)

        if (isNaN(annualIncome)) {
            setUpdateStatus({ message: 'Invalid annual income provided.', type: 'error' })
            return
        }

        try {
            setIsLoading(true)
            setUpdateStatus({ message: 'Updating annual income...', type: 'info' })

            const payload = { annualIncome }
            const response = await axios.post(
                `${API_URL}/user-profile/update-annual-income/me`, 
                payload, 
                { withCredentials: true }
            )

            setInvestmentInfo({ ...investmentInfo, annualIncome: response.data.annualIncome })
            setUpdateStatus({ message: 'Annual income updated successfully!', type: 'success' })
            
            setTimeout(() => {
                setShowModal(false)
            }, 1500)
        } catch (error) {
            setUpdateStatus({ 
                message: error.response?.data?.message || 'Failed to update annual income', 
                type: 'error' 
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleNetWorth = async () => {
        const cleanedInputValue = inputValue.replace(/,/g, '')
        const netWorth = parseInt(cleanedInputValue, 10)

        if (isNaN(netWorth)) {
            setUpdateStatus({ message: 'Invalid net worth provided.', type: 'error' })
            return
        }

        try {
            setIsLoading(true)
            setUpdateStatus({ message: 'Updating net worth...', type: 'info' })

            const payload = { netWorth }
            const response = await axios.post(
                `${API_URL}/user-profile/update-net-worth/me`, 
                payload, 
                { withCredentials: true }
            )

            setInvestmentInfo({ ...investmentInfo, netWorth: response.data.netWorth })
            setUpdateStatus({ message: 'Net worth updated successfully!', type: 'success' })
            
            setTimeout(() => {
                setShowModal(false)
            }, 1500)
        } catch (error) {
            setUpdateStatus({ 
                message: error.response?.data?.message || 'Failed to update net worth', 
                type: 'error' 
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmitAccreditation = async () => {
        if (toggle && !accreditedStatus.accreditedType) {
            setUpdateStatus({ 
                message: 'Please select what qualifies you as an accredited investor', 
                type: 'error' 
            })
            return
        }

        try {
            setIsLoading(true)
            setUpdateStatus({ message: 'Updating accreditation status...', type: 'info' })

            const payload = {
                accreditationStatus: accreditedStatus.accredited,
                accreditationType: accreditedStatus.accreditedType
            }

            const response = await axios.post(
                `${API_URL}/investment-profile/update-accreditation-status/me`, 
                payload, 
                { withCredentials: true }
            )

            setInvestmentInfo({ 
                ...investmentInfo, 
                accreditationStatus: response.data.accreditationStatus, 
                accreditationType: response.data.accreditationType 
            })
            setUpdateStatus({ message: 'Accreditation status updated successfully!', type: 'success' })
            
            setTimeout(() => {
                setShowModal(false)
            }, 1500)
        } catch (error) {
            setUpdateStatus({ 
                message: error.response?.data?.message || 'Failed to update accreditation status', 
                type: 'error' 
            })
        } finally {
            setIsLoading(false)
        }
    }

    let buttonTitle = modalType === 'Legal Investment Limit' || modalType === 'Verification Status' 
        ? 'Close' 
        : 'Cancel'

    let onClickFunction = null

    if (modalType === 'Annual Income') {
        onClickFunction = handleSubmitAnnualIncome
    } else if (modalType === 'Net Worth') {
        onClickFunction = handleNetWorth
    } else if (modalType === 'Accredited Status') {
        onClickFunction = handleSubmitAccreditation
    }

    return (
        <ModalBg ref={modalRef} onClick={handleClose}>
            <Modal>
                <Header>
                    <HeaderText>{modalType}</HeaderText>
                    <IoClose 
                        size={30} 
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }} 
                        onClick={() => !isLoading && setShowModal(false)} 
                    />
                </Header>
                <Body>
                    {updateStatus.message && (
                        <StatusMessage 
                            message={updateStatus.message} 
                            type={updateStatus.type} 
                        />
                    )}
                    
                    {modalType === 'Legal Investment Limit' ? (
                        <>
                            <Container>
                                <Heading>Regulation Crowdfunding: SEC Investment Limits</Heading>
                                
                                <SubHeading><span>1.</span> If your annual income or net worth is below $124,000:</SubHeading>
                                <StyledList>
                                    <ListItem>You can invest the greater of $2,500 or 5% of the greater of your annual income or net worth over 12 months.</ListItem>
                                </StyledList>

                                <SubHeading><span>2.</span> If both your annual income and net worth are $124,000 or above:</SubHeading>
                                <StyledList>
                                    <ListItem>You can invest up to 10% of the greater of your annual income or net worth.</ListItem>
                                    <ListItem>Your total investment cannot exceed $124,000 over 12 months.</ListItem>
                                </StyledList>

                                <Paragraph><span>3.</span> Your income and net worth can be jointly calculated with your spouse, but the combined investment limit must not be exceeded.</Paragraph>
                                <Paragraph><span>4.</span> Investment limits are for every 12-month period. This applies to all Regulation Crowdfunding offerings you invest in during the 12-month period, not just this one.</Paragraph>
                                <Paragraph><span>5.</span> Accredited investors have no set limit.</Paragraph>
                            </Container>
                        </>
                    ) : modalType === 'Annual Income' ? (
                        <>
                            <ModalTitleInfo>What is your annual income?</ModalTitleInfo>
                            <MoneyInputWrapper>
                                <MoneyInput 
                                    placeholder={0} 
                                    value={inputValue}
                                    onChange={e => handleMoneyChange(e.target.value, setInputValue)}
                                    disabled={isLoading}
                                />
                            </MoneyInputWrapper>
                        </>
                    ) : modalType === 'Net Worth' ? (
                        <>
                            <ModalTitleInfo>What is your net worth?</ModalTitleInfo>
                            <MoneyInputWrapper>
                                <MoneyInput 
                                    placeholder={0} 
                                    value={inputValue}
                                    onChange={e => handleMoneyChange(e.target.value, setInputValue)}
                                    disabled={isLoading}
                                />
                            </MoneyInputWrapper>
                        </>
                    ) : modalType === 'Accredited Status' ? (
                        <>
                            <AccreditedWrapper>
                                <AccreditedContainer>
                                    <AccreditedText>Are you an accredited investor?</AccreditedText>
                                    <ToggleWrapper 
                                        toggled={toggle} 
                                        onClick={() => !isLoading && handleToggle('watchListProjects')}
                                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                                    >
                                        <ToggleText toggled={toggle}>{toggle ? 'Yes' : 'No'}</ToggleText>
                                        <ToggleCircle toggled={toggle} />
                                    </ToggleWrapper>
                                </AccreditedContainer>
                                {toggle && (
                                    <>
                                        <AccreditedText style={{ marginTop: 20 }}>
                                            What qualifies you as an accredited investor? (Select One)
                                        </AccreditedText>
                                        {/* Radio buttons with disabled state */}
                                        <RadioWrapper>
                                            <RadioButton
                                                id="5millionInvestments"
                                                type='radio' 
                                                name='AccreditorType'
                                                value='5 million in investments'
                                                onChange={handleAccreditorTypeChange}
                                                checked={accreditedStatus.accreditedType === "5 million in investments"}
                                                disabled={isLoading}
                                            />
                                            <RadioLabel htmlFor="5millionInvestments">
                                                I have at least $5 million in investments
                                            </RadioLabel>
                                        </RadioWrapper>
                                        {/* ... other radio buttons with similar disabled state ... */}
                                    </>
                                )}
                            </AccreditedWrapper>
                        </>
                    ) : modalType === 'Verification Status' ? (
                        <>
                            <Container>
                                <SubHeading>
                                    Invest up to $2,500 instantly. For larger investments, we&apos;ll verify your identity during the investment process.
                                </SubHeading>
                            </Container>
                        </>
                    ) : null}
                    
                    <ButtonWrapper>
                        <CancelButton 
                            onClick={() => !isLoading && setShowModal(false)}
                            disabled={isLoading}
                            style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                        >
                            {buttonTitle}
                        </CancelButton>
                        
                        {(modalType !== 'Legal Investment Limit' && modalType !== 'Verification Status') && (
                            <SaveButton 
                                onClick={onClickFunction}
                                disabled={isLoading}
                                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                            >
                                {isLoading ? (
                                    <>
                                        <LoadingSpinner />
                                        Saving...
                                    </>
                                ) : 'Save'}
                            </SaveButton>
                        )}
                    </ButtonWrapper>
                </Body>
            </Modal>
        </ModalBg>
    )
}

export default LimitModal