export const initialState = {
    store: [
        { info: '', label: 'First Name' },
        { info: '', label: 'Last Name' },
        { info: '', label: 'Email' },
        { info: '', label: 'Phone Number' },
        { info: '', label: 'DOB' },
        { info: '', label: 'Address' },
        { info: '', label: 'City' },
        { info: 'United States', label: 'Country' },
        { info: '', label: 'State' },
        { info: '', label: 'Zip Code' },
        { info: false, label: 'isAccredited' },
        { info: false, label: 'isOrg' },
        { info: '', label: 'Investment Amount' },
        { info: '', label: 'Tip Amount' },
        { info: '', label: 'Accreditor Type' },
        { info: '', label: 'Yearly Income' },
        { info: '', label: 'Net Worth' },
        { info: '', label: 'Yearly Max Investment' },
        { info: '', signed: false, label: 'Signature' },
        { info: '', unFilled: true, label: 'Payment Type' },
        { info: '', unFilled: true, label: 'Bank Name' },
        { info: '', unFilled: true, label: 'Account Type' },
        { info: '', unFilled: true, label: 'Name On Account' },
        { info: '', unFilled: true, label: 'Routing Number' },
        { info: '', unFilled: true, label: 'Account Number' },
        { info: '', unFilled: true, label: 'Name On Card' },
        { info: '', unFilled: true, label: 'Card Number' },
        { info: '', unFilled: true, label: 'Expiration Date' },
        { info: '', unFilled: true, label: 'CVC' },
        { info: false, label: 'isAnonymous' },
        { info: -1, label: 'All Relevant Fields Filled' }
    ]
}

export const investReducer = (state, action) => {
    switch(action.type) {
        case 'UPDATE_INFO': {
            const newStore = state.store.map(item => {
                if (item.label === action.payload.label) {
                    // Special handling for 'Signed Name' label
                    if(item.label === 'Signature') {
                        return {
                            ...item,
                            info: action.payload.newInfo,
                            signed: action.payload.newInfo.length > 0
                        }
                    }
                    return {
                        ...item,
                        info: action.payload.newInfo,
                        unFilled: action.payload.newInfo.length === 0,
                    }
                }
                return item
            })
            return { store: newStore }
        }
        default:
            return state
    }
}