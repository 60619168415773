import React, { useRef, useEffect } from 'react'
import { deleteComment } from '../discussionApi'
import { handleInputIncreases } from '../helpers/inputHelpers'

import { EditModalWrapper, EditSection } from '../DiscussionElements'
import { FiEdit2 } from 'react-icons/fi'
import { BsTrash2 } from 'react-icons/bs'

function EditModal({ 
  comments, 
  setComments, 
  index, 
  editRef, 
  commentRefs, 
  setCommentsLength, 
  setPageCount, 
  setCommentAdds,
  setShowEditModal,
  route,
  setUpdates,
  updateId
  }) {
    const modalRef = useRef(null)

    const handleClickOutside = (event) => {
      // Close user profile modal if click is outside of it
      if (modalRef.current && !modalRef.current.contains(event.target) && editRef.current && !editRef.current.contains(event.target)) {
          setShowEditModal(false)
      }
    }

    useEffect(() => {
      // Attach the listeners to the document
      document.addEventListener("mousedown", handleClickOutside)
      
      // Cleanup the listener when the component unmounts
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      }
  
    //eslint-disable-next-line
    }, [])

    //@dev changes current comment and closes edit modal. 
    const handleEditComment = () => {
        let tempArray = [...comments]
        tempArray[index].editComment = true
        tempArray[index].showEditModal = false
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
        handleInputIncreases(commentRefs, 0)
    }

    //@dev deletes comment if confirmed and removes comment from current array and
    //subtracts from the commentAdds counter to update pagination "view" of start index
    //project-comments for project, updates for updates.
    const handleDeleteComment = async () => {
        if (window.confirm('Are you sure you want to delete this comment? This cannot be undone.')) {
          await deleteComment(route, comments[index]._id)
            
          setCommentsLength(prev => prev - 1)
          setCommentAdds(prev => prev - 1)
          setComments(comments => comments.filter((_, idx) => idx !== index))

          if (route === 'updates') {
            setUpdates(prevUpdates => prevUpdates.map(update => 
              update._id === updateId ? {
                  ...update,
                  comments: Math.max(0, (update.comments || 0) - 1)
              } : update
            ))
          }
        }
    }

    return (
        <EditModalWrapper ref={modalRef}>
            <EditSection onClick={handleEditComment} ><FiEdit2 size={18} style={{ marginRight: 10 }}/> Edit</EditSection>
            <EditSection onClick={handleDeleteComment}><BsTrash2 size={18} style={{ marginRight: 10 }}/> Delete</EditSection>
        </EditModalWrapper>
    )
}

export default EditModal