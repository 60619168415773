import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`

export const Collapsible = styled.div`
    border: 1px solid #DDDDDD;
    margin-top: -1px;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ExpandContainer = styled.div`

`

export const AddFaqButton = styled.button`
    width: 100%;
    height: 40px;
    background: black;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`

export const CancelButton = styled.button`
    margin-top: 10px;
    width: 100%;
    height: 40px;
    background: transparent;
    color: black;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`

export const FaqModalHeader = styled.div`
    font-size: 15px;
    font-weight: bold;
`

export const FaqModalQuestion = styled.textarea`
    margin-top: 10px;
    margin-left: 2px;
    height: 50px;
    width: 100%;
    border: none;
    resize: none;

    &:focus {
        outline: none;
    }
`