import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import { toast } from 'react-toastify'

import {
    AdminArtistsContainer,
    Table,
    Th,
    Td,
    AcceptReject,
} from '../AdminArtists/AdminArtistsElements'

import {
    SearchBar,
    SelectInput,
} from './AdminProjectElements'

import ProjectModal from './ProjectModal'
import { MdOutlineArrowRightAlt } from "react-icons/md"

const AdminProjectComponent = () => {
    const [allProjects, setAllProjects] = useState([])
    const [displayProjects, setDisplayProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showProjectModal, setShowProjectModal] = useState(false)
    const [selectedProjectIndex, setSelectedProjectIndex] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')

    const projectStatuses = ['DRAFT', 'GAUGING_INTEREST', 'FUNDING', 'FUNDED', 'COMPLETED', 'PAUSED', 'CANCELLED']

    const fetchProjects = useCallback(async (search = '', status = '') => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/admin/projects`, {
                params: { searchName: search, status },
                withCredentials: true
            })
    
            setAllProjects(response.data)
            setDisplayProjects(response.data)
        } catch (e) {
            console.error('Error fetching projects:', e)
            toast.error('Failed to fetch projects. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchProjects()
    }, [fetchProjects])

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchValue(value)
        fetchProjects(value, selectedStatus)
    }

    const handleStatusChange = (e) => {
        const status = e.target.value
        setSelectedStatus(status)
        fetchProjects(searchValue, status)
    }

    const handleEdit = (index) => {
        setShowProjectModal(true)
        setSelectedProjectIndex(index)
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return {
            date: date.toLocaleDateString("en-US", {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }),
            time: date.toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            })
        }
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <AdminArtistsContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <SearchBar onChange={handleSearch} placeholder="Search for projects by name" />
                <SelectInput onChange={handleStatusChange} value={selectedStatus}>
                    <option value="">All Statuses</option>
                    {projectStatuses.map(status => (
                        <option key={status} value={status}>{status}</option>
                    ))}
                </SelectInput>
            </div>
            {showProjectModal && selectedProjectIndex !== null && 
            <ProjectModal 
                project={allProjects[selectedProjectIndex]} 
                setDisplayProjects={setDisplayProjects} 
                setAllProjects={setAllProjects} 
                setShowProjectModal={setShowProjectModal} 
            />}
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>{displayProjects.length} project{displayProjects.length !== 1 ? 's' : ''}</h3>
                <MdOutlineArrowRightAlt style={{ fontSize: 50, color: 'black', cursor: 'pointer', float: 'right' }} />
            </div>
            <Table>
                <thead>
                    <tr>
                        <Th>Project Name</Th>
                        <Th>Project ID</Th>
                        <Th>Project Status</Th>
                        <Th>Artist Name</Th>
                        <Th>Artist (Creator) ID</Th>
                        <Th>Total Expressed Interest</Th>
                        <Th>Target Fund</Th>
                        <Th>Equity Given</Th>
                        <Th>Campaign End Date</Th>
                        <Th>Campaign End Time</Th>
                        <Th>Edit</Th>
                    </tr>
                </thead>
                <tbody>
                    {displayProjects.length > 0 ? (
                        displayProjects.map((project, index) => {
                            const { date: endDate, time: endTime } = formatDate(project.campaignEndDate);
                            return (
                                <tr key={project._id}>
                                    <Td>{project.projectName}</Td>
                                    <Td>{project._id}</Td>
                                    <Td>{project.projectStatus}</Td>
                                    <Td>{project.artist?.artistName}</Td>
                                    <Td>{project.artist?._id}</Td>
                                    <Td>${project.totalExpressedInterest}</Td>
                                    <Td>${project.targetFundAmount}</Td>
                                    <Td>{project.equityGiven}%</Td>
                                    <Td>{endDate}</Td>
                                    <Td>{endTime}</Td>
                                    <Td><AcceptReject className="edit" onClick={() => handleEdit(index)}>Edit</AcceptReject></Td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><Td colSpan="11" style={{ textAlign: 'center' }}>No projects found</Td></tr>
                    )}
                </tbody>
            </Table>
        </AdminArtistsContainer>
    )
}

export default AdminProjectComponent