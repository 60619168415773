//@dev sets cursor @ the end of comment text.
export const handleFocus = (e, refs) => {
    const value = e.target.value
    e.target.value = ''
    e.target.value = value
}

//@dev pushes reference element to ref array and sets input height to text line height.
export const addToRef = (element, refs) => {
    if (element && !refs.current.includes(element)) {
        refs.current = [element]
        refs.current[0].style.paddingBottom = '5px'
        refs.current[0].style.height = refs.current[0].scrollHeight + 'px'
    }
}

//@dev increases input dynamically on new lines of text
export const handleInputIncreases = (refs, index) => {
    if (refs.current[0]) {
        refs.current[0].style.paddingBottom = '5px'
        refs.current[0].style.height = refs.current[0].scrollHeight + 'px'
    }
}