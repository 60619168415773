import React, { useState, useContext, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../authContext'

import BasicInfo from './Steps/BasicInfo'
import CoverPhoto from './Steps/CoverPhoto'
import VideoEmbed from './Steps/VideoEmbed'
import Funding from './Steps/Funding'
import CampaignDuration from './Steps/CampaignDuration'
import Review from './Steps/Review'

import { Wrapper } from './CreateProjectElements'

function CreateProjectMain() {
    const [currentStep, setCurrentStep] = useState(1)
    const [isFixed, setIsFixed] = useState(true)
    const [projectName, setProjectName] = useState('')
    const [logline, setLogline] = useState('')
    const [scriptPages, setScriptPages] = useState('')
    const [primaryGenre, setPrimaryGenre] = useState('')
    const [secondaryGenre, setSecondaryGenre] = useState('None')
    const [previewImage, setPreviewImage] = useState()
    const [coverImage, setCoverImage] = useState(null)
    const [videoEmbed, setVideoEmbed] = useState('')
    const [fundingAmount, setFundingAmount] = useState('')
    const [equityAmount, setEquityAmount] = useState('')
    const [bonusReturn, setBonusReturn] = useState('0')
    const [fundDuration, setFundDuration] = useState('30')
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [hour, setHour] = useState('01')
    const [minute, setMinute] = useState('00')
    const [timeDesignation, setTimeDesignation] = useState('AM')
    const [projectCategory, setProjectCategory] = useState('funding')
    const [projectFundingType, setProjectFundingType] = useState('REG CF')
    const [showProfileModal, setShowProfileModal] = useState(false)

    const { setLoggedInUser } = useContext(AuthContext)

    const iconWrapperRef = useRef(null)
    const modalRef = useRef(null)

    const navigate = useNavigate()

    const handleNavigateHome = () => {
        navigate('/')
    }

    const handleClickOutside = (event) => {
        // Check if the click originated from the User IconWrapper
        // if (iconWrapperRef.current && iconWrapperRef.current.contains(event.target)) {
        //     setShowNotificationModal(false)  // Close notification modal when user icon is clicked
        //     return
        // }
    
        // Check if the click originated from the Notification Icon
        // if (notificationIconRef.current && notificationIconRef.current.contains(event.target)) {
        //     setShowProfileModal(false)  // Close profile modal when notification icon is clicked
        //     return
        // }
    
        // Close user profile modal if click is outside of it
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowProfileModal(false)
        }
    
        // Close notification modal if click is outside of it
        // if (notificationModalRef.current && !notificationModalRef.current.contains(event.target)) {
        //     setShowNotificationModal(false)
        // }
    }  

    useEffect(() => {
        // Attach the listeners to the document
        document.addEventListener("mousedown", handleClickOutside)

        // Cleanup the listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        // eslint-disable-next-line
    }, [])

    return (
        <Wrapper>
            {currentStep === 1 ?
                <BasicInfo 
                    iconWrapperRef={iconWrapperRef}
                    modalRef={modalRef}
                    setLoggedInUser={setLoggedInUser}
                    handleNavigateHome={handleNavigateHome}
                    setCurrentStep={setCurrentStep} 
                    projectName={projectName}
                    setProjectName={setProjectName}
                    logline={logline}
                    setLogline={setLogline}
                    scriptPages={scriptPages}
                    setScriptPages={setScriptPages}
                    primaryGenre={primaryGenre}
                    setPrimaryGenre={setPrimaryGenre}
                    secondaryGenre={secondaryGenre}
                    setSecondaryGenre={setSecondaryGenre}
                />
                : currentStep === 2 ?
                <CoverPhoto
                    showProfileModal={showProfileModal}
                    setShowProfileModal={setShowProfileModal}
                    iconWrapperRef={iconWrapperRef}
                    modalRef={modalRef}
                    setLoggedInUser={setLoggedInUser}
                    handleNavigateHome={handleNavigateHome}
                    setCurrentStep={setCurrentStep} 
                    coverImage={coverImage}
                    setCoverImage={setCoverImage}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                />
                : currentStep === 3 ?
                <VideoEmbed
                    showProfileModal={showProfileModal}
                    setShowProfileModal={setShowProfileModal}
                    iconWrapperRef={iconWrapperRef}
                    modalRef={modalRef}
                    setLoggedInUser={setLoggedInUser}
                    handleNavigateHome={handleNavigateHome}
                    setCurrentStep={setCurrentStep} 
                    videoEmbed={videoEmbed}
                    setVideoEmbed={setVideoEmbed}
                />
                : currentStep === 4 ?
                <Funding
                    showProfileModal={showProfileModal}
                    setShowProfileModal={setShowProfileModal}
                    iconWrapperRef={iconWrapperRef}
                    modalRef={modalRef}
                    setLoggedInUser={setLoggedInUser}
                    handleNavigateHome={handleNavigateHome}
                    setCurrentStep={setCurrentStep} 
                    fundingAmount={fundingAmount}
                    setFundingAmount={setFundingAmount}
                    equityAmount={equityAmount}
                    setEquityAmount={setEquityAmount}
                    bonusReturn={bonusReturn}
                    setBonusReturn={setBonusReturn}
                    projectCategory={projectCategory}
                    setProjectCategory={setProjectCategory}
                    projectFundingType={projectFundingType}
                    setProjectFundingType={setProjectFundingType}
                />
                : currentStep === 5 ?
                <CampaignDuration
                    handleClickOutside={handleClickOutside}
                    showProfileModal={showProfileModal}
                    setShowProfileModal={setShowProfileModal}
                    iconWrapperRef={iconWrapperRef}
                    modalRef={modalRef}
                    setLoggedInUser={setLoggedInUser}
                    handleNavigateHome={handleNavigateHome}
                    currentStep={currentStep} 
                    setCurrentStep={setCurrentStep} 
                    isFixed={isFixed} 
                    setIsFixed={setIsFixed} 
                    fundDuration={fundDuration}
                    setFundDuration={setFundDuration}
                    setDay={setDay}
                    setMonth={setMonth}
                    setYear={setYear}
                    setHour={setHour}
                    setMinute={setMinute}
                    day={day}
                    month={month}
                    year={year}
                    minute={minute}
                    hour={hour}
                    timeDesignation={timeDesignation}
                    setTimeDesignation={setTimeDesignation}
                />
                : currentStep === 6 ?
                <Review
                    showProfileModal={showProfileModal}
                    setShowProfileModal={setShowProfileModal}
                    iconWrapperRef={iconWrapperRef}
                    modalRef={modalRef}
                    setLoggedInUser={setLoggedInUser}
                    handleNavigateHome={handleNavigateHome}
                    setCurrentStep={setCurrentStep}
                    projectName={projectName}
                    logline={logline}
                    scriptPages={scriptPages}
                    primaryGenre={primaryGenre}
                    secondaryGenre={secondaryGenre}
                    coverImage={coverImage}
                    videoEmbed={videoEmbed}
                    fundingAmount={fundingAmount}
                    equityAmount={equityAmount}
                    bonusReturn={bonusReturn}
                    isFixed={isFixed}
                    fundDuration={fundDuration}
                    day={day}
                    month={month}
                    year={year}
                    hour={hour}
                    minute={minute}
                    timeDesignation={timeDesignation}
                    projectCategory={projectCategory}
                    projectFundingType={projectFundingType}
                /> : null
                }
        </Wrapper>
    )
}

export default CreateProjectMain