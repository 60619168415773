import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../config'
import AuthCode from 'react-auth-code-input'

import {
    Wrapper,
    LoginContainer,
    LoginInput,
    PasswordInput,
    LoginButton,
} from './AdminLoginElements'

import { 
    ModalTitleInfo, 
    TwoFAWrapper,
    GlobalStyles,
} from '../MyInvestmentProfile/Account/Modal/ModalElements'

const AdminLogin = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [qrCode, setQRCode] = useState('')
    const [twoFactorAuth, setTwoFactorAuth] = useState(false)
    const [token, setToken] = useState('')
    const [adminId, setAdminId] = useState('')

    const navigate = useNavigate()

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${API_URL}/admin/login`, {
                email,
                password
            }, { withCredentials: true })

            setAdminId(response.data._id)

            if (response.data && !response.data.hasTwoFactorAuthEnabled) {
                const qrCodeResponse = await axios.get(`${API_URL}/admin/enable-2fa/${response.data._id}`) 

                setQRCode(qrCodeResponse.data.qrCode)
            } else if (response.data && response.data.hasTwoFactorAuthEnabled) {
                setTwoFactorAuth(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const verify2FA = async () => {
        try {
            await axios.post(`${API_URL}/admin/verify-2fa/me`, { token }, { withCredentials: true })

            navigate('/admin/dashboard')
        } catch (error) {
            alert('Incorrect 2FA code.')
            console.error("Error verifying 2FA:", error)
        }
    }

    const check2FA = async () => {
        try {
            const payload = {
                token,
                adminId
            }

            await axios.post(`${API_URL}/admin/check-2fa/me`, payload,  { withCredentials: true })

            navigate('/admin/dashboard')
        } catch (error) {
            console.error("Error checking 2FA:", error)
        }
    }

    const handleOnChange = (value) => {
        if (value.length === 6) {
            setToken(value)
        }
    }

    return (
        <Wrapper>
            <h1>Admin Login</h1> 
            {twoFactorAuth ?
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ModalTitleInfo>Please enter 2 factor authentication code to continue.</ModalTitleInfo>
                <TwoFAWrapper>
                    <GlobalStyles />
                    <AuthCode 
                        inputClassName='custom-auth-input'
                        allowedCharacters='numeric' 
                        onChange={handleOnChange}
                    />
                </TwoFAWrapper>
                <LoginButton style={{ marginTop: 20 }} onClick={check2FA} >Verify</LoginButton>
            </div>
            :
            !qrCode ?
            <LoginContainer>           
                <LoginInput placeholder='Email' onChange={e => setEmail(e.target.value)} />
                <PasswordInput placeholder='Password' onChange={e => setPassword(e.target.value)} />
                <LoginButton onClick={handleLogin} >Login</LoginButton>
            </LoginContainer>
            : qrCode &&
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={qrCode} alt="Scan this QR code with your authentication app" />
                <ModalTitleInfo>Verify the two factor authentication code from your authentication app.</ModalTitleInfo>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TwoFAWrapper>
                        <GlobalStyles />
                        <AuthCode 
                            inputClassName='custom-auth-input'
                            allowedCharacters='numeric' 
                            onChange={handleOnChange}
                        />
                    </TwoFAWrapper>
                    <LoginButton style={{ marginTop: 20 }} onClick={verify2FA} >Verify</LoginButton>
                </div>
            </div>
            }
        </Wrapper>
    )
}

export default AdminLogin