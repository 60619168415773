import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Wrapper,
    InfoContainer,
    InfoWrapper,
    InfoHeader,
    Header,
} from './FooterElements'

function Footer() {
    const navigate = useNavigate()

    const handleBlogClick = () => {
        navigate('/posts')
    }

    return (
        <Wrapper>
            <InfoContainer>
                <InfoWrapper>
                    <InfoHeader>Company</InfoHeader>
                    <Header>Our Story</Header>
                    <Header>Contact Us</Header>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoHeader>Resources</InfoHeader>
                    <Header onClick={handleBlogClick}>Blog</Header>
                    <Header>FAQ</Header>
                    <Header>Support</Header>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoHeader>Legal</InfoHeader>
                    <Header>Terms of Service</Header>
                    <Header>Privacy Policy</Header>
                    <Header>Cookie Policy</Header>
                </InfoWrapper>
            </InfoContainer>
        </Wrapper>
    )
}

export default Footer
