import axios from 'axios'
import queryString from 'query-string'
import { API_URL } from '../../config'

export const searchFunded = async (payload) => {
    const { genreOneSelect, genreTwoSelect, budgetSelect, fundingSelect, sortSelect, searchTerm, limit, page } = payload

    const queryParams = {
      genreOneSelect,
      genreTwoSelect,
      budgetSelect,
      fundingSelect,
      sortSelect,
      searchTerm,
      page,
      limit,
    }

    const url = `${API_URL}/discover/projects/search-funded?${queryString.stringify(queryParams)}`

    const response = await axios.get(url)
    
    return response
}

export const searchGaugingInterest = async (payload) => {
  try {
      const { genreOneSelect, genreTwoSelect, budgetSelect, sortSelect, searchTerm, limit, page } = payload

      const queryParams = {
        genreOneSelect,
        genreTwoSelect,
        budgetSelect,
        sortSelect,
        searchTerm,
        page,
        limit,
      }

      const response = await axios.get(`${API_URL}/discover/projects/search-gauging-interest?${queryString.stringify(queryParams)}`)

      return response
  } catch (error) {
      console.log(error)
      throw error
  }
}

export const searchLicensing = async (payload) => {
  try {
    const { genreOneSelect, genreTwoSelect, budgetSelect, durationSelect, sortSelect, searchTerm, limit, page } = payload

    const queryParams = {
      genreOneSelect,
      genreTwoSelect,
      budgetSelect,
      durationSelect,
      sortSelect,
      searchTerm,
      page,
      limit,
    }

    const response = await axios.get(`${API_URL}/discover/projects/search-licensing?${queryString.stringify(queryParams)}`)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const searchRevenueShare = async (payload) => {
  try {
    const { genreOneSelect, genreTwoSelect, sortSelect, searchTerm, limit, page } = payload
    const queryParams = {
      genreOneSelect,
      genreTwoSelect,
      sortSelect,
      searchTerm,
      page,
      limit,
    }
    const response = await axios.get(`${API_URL}/discover/projects/search-revenue-share?${queryString.stringify(queryParams)}`)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}