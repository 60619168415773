import React, { useState, useEffect, useRef } from 'react'
import { followArtist } from '../../ArtistProfile/ArtistApi'

import { 
    Wrapper, 
    CoverImage, 
    ImageContainer, 
    ProfileHeaderDetailContainer, 
    ProfileInfoWrapper, 
    FollowButtonContainer,
    ProfilePictureWrapper
} from './ProfileHeaderElements'

import { 
    FilmmakerName, 
    ArtistMiniBio, 
    IconBar, 
    LocationContainer, 
    Location,
    RoleWrapper,
    EditInfo,
} from '../ProfileInfo/ProfileInfoElements'

import ProfilePicture from '../../ProfilePicture'
import FollowModal from './FollowModal'

import { GrLocation } from 'react-icons/gr'
import { FaFacebookSquare, FaImdb, FaVimeoSquare, FaInstagramSquare } from 'react-icons/fa'
import { FaSquareXTwitter, FaYoutube } from 'react-icons/fa6'
import { PiLinkFill } from 'react-icons/pi'
import { RiArrowDownSLine } from "react-icons/ri"

function ProfileHeader({ 
    setShowEditModal,
    artistId,
    slug,
    artistLocation,
    artistRoles,
    artistMiniBio,
    artistName,
    profileImage,
    coverImage,
    twitterLink,
    facebookLink,
    vimeoLink,
    instagramLink,
    youtubeLink,
    imdbLink,
    websiteLink,
    loggedInUser,
    isFollowing,
    setIsFollowing
}) {
    const [imageError, setImageError] = useState(false)
    const [showFollowModal, setShowFollowModal] = useState(false)

    const followRef = useRef(null)
    const modalRef = useRef(null)

    const handleOutsideClick = (event) => {
        if (followRef.current && followRef.current.contains(event.target)) {
            return
        }

        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowFollowModal(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }

        // eslint-disable-next-line
    }, [])

    const handleImageError = () => {
        setImageError(true)
    }

    const handleEditProfileClick = e => {
        //CHANGE TO SLUG
        if (slug) {
            e.preventDefault()
            document.body.style.overflow = 'hidden'
            setShowEditModal(prev => !prev)
        } else {
            alert('You are not authorized to edit this profile')
        }
    }

    const handleFollowClick = async e => {
        e.preventDefault()
        if (!isFollowing) {
            try {
                const response = followArtist(slug)

                if (response) {
                    setIsFollowing(true)
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            setShowFollowModal(!showFollowModal)
        }
    }

    const renderProfileEdit = 
        loggedInUser?.artist === artistId
            ? 
                <EditInfo onClick={handleEditProfileClick} >Edit Profile</EditInfo> 
            : 
                <EditInfo 
                    ref={followRef} 
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
                    onClick={handleFollowClick} >
                        {!isFollowing ? 'Follow' : 'Following'} 
                        {isFollowing && <RiArrowDownSLine size={15} />}
                </EditInfo>

    let rolesList
    if (artistRoles) {
        rolesList = artistRoles.map((role, index) => 
            <React.Fragment key={index}>
                <div>{role}</div>
                {index < artistRoles.length - 1 ? <div>&nbsp;|&nbsp;</div> : ''}
            </React.Fragment>
        )
    }

    return (
        <Wrapper style={!imageError && coverImage ? {} : { marginTop: 75 }}>
            {!imageError && coverImage && (
                <ImageContainer>
                    <CoverImage
                        src={coverImage}
                        alt='Filmmaker cover image'
                        onError={handleImageError}
                    />
                </ImageContainer>
            )}
            <ProfileHeaderDetailContainer>
                <ProfilePictureWrapper>
                    <ProfilePicture image={profileImage} artistId={slug} />
                </ProfilePictureWrapper>
                <ProfileInfoWrapper>
                    <FilmmakerName>{artistName}</FilmmakerName>
                    <RoleWrapper>{rolesList}</RoleWrapper>
                    <LocationContainer>
                        <Location><GrLocation color='black' size={15}/>{artistLocation}</Location>
                    </LocationContainer>
                    <ArtistMiniBio>{artistMiniBio}</ArtistMiniBio>
                    <IconBar>
                        {twitterLink && <a href={`${twitterLink}`} target="_blank" rel="noreferrer"><FaSquareXTwitter color='#272829' size={25} /></a>}
                        {facebookLink && <a href={`${facebookLink}`} target="_blank" rel="noreferrer"><FaFacebookSquare color='#272829' size={25} /></a>}
                        {vimeoLink && <a href={`${vimeoLink}`} target="_blank" rel="noreferrer"><FaVimeoSquare color='#272829' size={25} /></a>}
                        {instagramLink && <a href={`${instagramLink}`} target="_blank" rel="noreferrer"><FaInstagramSquare color='#272829' size={25} /></a>}
                        {imdbLink && <a href={`${imdbLink}`} target="_blank" rel="noreferrer"><FaImdb color='#272829' size={25} /></a>}
                        {youtubeLink && <a href={`${youtubeLink}`} target="_blank" rel="noreferrer"><FaYoutube color='#272829' size={25} /></a>}
                        {websiteLink && <a href={`${websiteLink}`} target="_blank" rel="noreferrer"><PiLinkFill color='#272829' size={25} /></a>}
                    </IconBar>
                </ProfileInfoWrapper>
                <FollowButtonContainer>
                    {renderProfileEdit}
                </FollowButtonContainer>
            </ProfileHeaderDetailContainer>
            {showFollowModal && (
                <FollowModal
                    showFollowModal={showFollowModal}
                    setShowFollowModal={setShowFollowModal}
                    slug={slug}
                    setIsFollowing={setIsFollowing}
                    ref={modalRef}
                />
            )}
        </Wrapper>
    )
}

export default ProfileHeader