import React, { useContext } from 'react'
import { AuthContext } from '../authContext'

import NonMainNavbar from '../components/NonMainNav'
import ResetPasswordComponent from '../components/ResetPassword'

const ResetPassword = () => {
    const { loggedInUser, setLoggedInUser } = useContext(AuthContext)

    return (
        <div>
            <NonMainNavbar loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} />
            <ResetPasswordComponent />
        </div>
    )

}

export default ResetPassword