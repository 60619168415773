import styled from 'styled-components'
import { TbHeart } from 'react-icons/tb'
import { PrimaryColor } from '../../../../colors'

export const StyledTbHeart = styled(TbHeart)`
  transition: fill 0.10s ease-in-out;
`

export const Wrapper = styled.div`
    width: 100%;
`

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
`

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
`

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
        color: gray;
        &:hover {
            color: black; 
        }
    }
`

export const Count = styled.div`
    margin-left: 5px;
    color: gray;
    user-select: none;

    &:hover {
        color: black;
    }
`

export const CreatorContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
` 

export const Name = styled.div`
    font-size: 12px;
    margin-left: 10px;
`

export const DateUploaded = styled.div`
    font-size: 12px;
    margin-left: 10px;
    color: gray;
`

export const Icon = styled.div`
    width: 40px;
    height: 40px;
    background-color: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const IconImage = styled.img`
    width: 40px;
    height: 40px;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const Header = styled.div`
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
`

export const BottomBorder = styled.div`
    margin-top: 50px;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
`

export const CoverImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
    cursor: pointer;
`

export const Likes = styled.div`
    margin-left: 10px;
    font-size: 20px;
    color: gray;
    cursor: pointer;

    &:hover {
        color: black;
    }
`

export const CreatorType = styled.div`
    color: ${PrimaryColor};
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
`