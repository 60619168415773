import React, { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import { API_URL, CLOUDFRONT_URL } from '../../../../config'
import { formatNumberToK } from '../../../../helpers/formatNumberToK'

import {
    ProfilePictureDrop,
    ProfilePicture,
    PictureDropInfo,
    AccountContainer,
    OptionSection,
    OptionInfo,
    PrivacyCheckboxWrapper,
    PrivacyCheckboxLabel,
    PrivacyCheckboxInput,
    UserContainer
} from '../AccountElements'

import AccountModal from '../Modal'

import { FaUser } from 'react-icons/fa'

const Profile = ({ userPage, setUserPage }) => {
    const [showModal, setShowModal] = useState(false)
    const [activeModal, setActiveModal] = useState(null)
    const [imageData, setImageData] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)
    const [imageError, setImageError] = useState(false)
    const navigate = useNavigate()

    const onDrop = useCallback(async (acceptedFiles) => {
        if (userPage.artist) {
            // Prevent the default file upload behavior
            // Display an alert or show a modal/pop-up
            alert('Our records show you are an artist. Please change your profile picture on your artist profile page.')
            return
        }

        setImageData(acceptedFiles[0])
        const previewURL = URL.createObjectURL(acceptedFiles[0])
        setImagePreview(previewURL)
        setActiveModal('profilePicture')
        setShowModal(true)
    }, [userPage.artist])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png' // Optional: limit the types of files
    })

    const handleModalOpen = (modalType) => {
        setActiveModal(modalType)
        setShowModal(true)
    }

    const handleArtistStatusClick = () => {
        if (userPage.artist)
            return
        else
            navigate('/artist-registry')
    }

    const handlePrivateClick = async () => {
        try {
            await axios.post(`${API_URL}/user-profile/hide-investments/me`, {}, { withCredentials: true })
        } catch (e) {
            console.log(e)
        }
    }

    // Use profilePictureUrl if available, fallback to constructing from profilePicture
    const imageUrl = userPage?.profilePictureUrl || 
    (userPage?.profilePicture && userPage.profilePicture !== 'null' ? 
        `${CLOUDFRONT_URL}/${userPage.profilePicture}` 
        : null
    )
    
    return (
        <AccountContainer>
            {showModal && 
                <AccountModal 
                    modalType={activeModal} 
                    setShowModal={setShowModal} 
                    userPage={userPage} 
                    setUserPage={setUserPage} 
                    imageData={imageData}
                    setImageData={setImageData}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    artist={userPage.artist}
                />
            }
            <OptionSection onClick={() => handleModalOpen(userPage?.artist ? 'artist' : 'displayName')}>
                <OptionInfo>Display Name</OptionInfo>
                <OptionInfo>{userPage.artist ? userPage?.artistProfile?.artistName : userPage?.displayName}</OptionInfo>
            </OptionSection>
            <OptionSection>
                <OptionInfo>Username</OptionInfo>
                <OptionInfo>{userPage?.username}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={handleArtistStatusClick}>
                <OptionInfo>Artist Status</OptionInfo>
                <OptionInfo>{userPage?.artist ? 'Artist' : 'Not Artist'}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('miniBio')}>
                <OptionInfo>Mini Bio</OptionInfo>
                <OptionInfo>{userPage?.miniBio}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('favoriteGenres')}>
                <OptionInfo>Favorite Genres</OptionInfo>
                <OptionInfo>{userPage && userPage?.favoriteGenres.join(', ')}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('investmentThesis')}>
                <OptionInfo>Project Investment Thesis</OptionInfo>
                <OptionInfo>{userPage?.investmentThesis}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('investmentSize')}>
                <OptionInfo>Typical Investment Size</OptionInfo>
                <OptionInfo>${formatNumberToK(userPage?.investmentSizeMin)} - ${formatNumberToK(userPage?.investmentSizeMax)}</OptionInfo>
            </OptionSection>
            <OptionSection style={{ borderBottom: 0 }}>
                <OptionInfo>Privacy</OptionInfo>
                <OptionInfo>
                    <PrivacyCheckboxWrapper>
                        <PrivacyCheckboxLabel>Hide investments</PrivacyCheckboxLabel>
                        <PrivacyCheckboxInput defaultChecked={userPage.hideInvestments} type="checkbox" onClick={handlePrivateClick} />
                    </PrivacyCheckboxWrapper>
                </OptionInfo>
            </OptionSection>
            <ProfilePictureDrop {...getRootProps()} >
                <input {...getInputProps()} />
                {!imageError && imageUrl ?
                    <ProfilePicture 
                        onError={() => setImageError(true)}
                        src={imageUrl} 
                    />
                :
                    <UserContainer>
                        <FaUser size={40} />
                    </UserContainer>
                }
                <PictureDropInfo>Drag and drop a picture here or click to update. 1024px by 1024px recommended.</PictureDropInfo>
            </ProfilePictureDrop>
        </AccountContainer>
    )
}

export default Profile