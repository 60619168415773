import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CLOUDFRONT_URL, API_URL } from '../../config'
import { followUser, unFollowUser } from './DiscoverUsersApi'
import { formatNumber } from '../../helpers/numberConvert'

import {
    UserCardContainer,
    UserCardMainContent,
    UserCardFooter,
    UserCardNoImageContainer,
    UserCardImage,
    UserCardName,
    UserMiniBio,
    Divider,
    InfoWrapper,
    FollowButton,
    Followers,
    Invested
} from './DiscoverUsersElements'

import { FaUser } from 'react-icons/fa'

const UserCard = ({ user }) => {
    const { loggedInUser } = useContext(AuthContext)

    const [imageError, setImageError] = useState('')
    const [isFollowing, setIsFollowing] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const getIsFollowing = async () => {
          if (user && loggedInUser && loggedInUser.id) {
            try {
              const response = await axios.get(`${API_URL}/user-profile-page/isFollowing/${user._id}`, { withCredentials: true })
              setIsFollowing(response.data.isFollowing)
            } catch (error) {
              console.error("Error checking follow status:", error)
              // Handle error (e.g., set isFollowing to false)
              setIsFollowing(false)
            }
          } else {
            // If user is not logged in, set isFollowing to false
            setIsFollowing(false)
          }
        }
        getIsFollowing()
    }, [user, loggedInUser])

    const handleNavigate = () => {
        navigate(`/user-profile/${user?.username}`)
    }

    const handleImageError = () => {
        setImageError(true)
    }

    const handleFollowButtonClick = async (event) => {
        event.stopPropagation()
        if (!loggedInUser || !loggedInUser.id) {
          // If user is not logged in, redirect to login page or show login modal
          navigate('/login')  // or show login modal
          return
        }
        try {
          if (isFollowing) {
            await unFollowUser(user._id)
            setIsFollowing(false)
          } else {
            await followUser(user._id)
            setIsFollowing(true)
          }
        } catch (error) {
          console.log(error)
          // Handle the error and display an error message to the user if needed
        }
    }

    //add elipsis to miniBio if it's too long
    const truncatedMiniBio = user?.miniBio && user?.miniBio?.length > 100
      ? `${user?.miniBio?.substring(0, 100)}...`
      : user?.miniBio

    const imageUrl = user?.profilePictureUrl || 
    (user?.profilePicture && user.profilePicture !== 'null' ? 
        `${CLOUDFRONT_URL}/${user.profilePicture}` 
        : null
    )

    return (
        <UserCardContainer onClick={handleNavigate}>
            <UserCardMainContent>
                {!imageError && imageUrl ?
                    <UserCardImage src={imageUrl} alt={user?.username} onError={handleImageError} />
                    :
                    <UserCardNoImageContainer>
                        <FaUser size={50} color='white' />
                    </UserCardNoImageContainer>
                }
                <UserCardName>{user?.displayName ? user?.displayName : user?.username}</UserCardName>
                <UserMiniBio>{truncatedMiniBio}</UserMiniBio>
            </UserCardMainContent>
            <UserCardFooter>
                <Divider />
                {loggedInUser && loggedInUser.id && loggedInUser.id !== user._id && (
                    <FollowButton onClick={handleFollowButtonClick} className={isFollowing ? 'unfollow' : 'follow'}>
                        {isFollowing ? 'Following' : 'Follow'}
                    </FollowButton>
                )}
                <InfoWrapper>
                    <Followers>{user.followersCount} followers</Followers>
                    {user.totalInvestment > 0 && <Invested><div style={{ color: 'black', marginRight: 4 }}>${formatNumber(user.totalInvestment)}</div> invested</Invested>}
                </InfoWrapper>
            </UserCardFooter>
        </UserCardContainer>
    )
}

export default UserCard