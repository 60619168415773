import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { API_URL } from '../../config'
import NonMainNav from '../NonMainNav'

import {
    CheckboxText,
    CheckboxLink,
    InputWrapper,
    SignupError,
} from '../Signup/SignupElements.js'

import {
    Wrapper,
    LoginWrapper,
    LoginHeader,
    LoginInput,
    LoginButton
} from '../Login/LoginElements.js'

function ForgotPasswordComponent() {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [submitError, setSubmitError] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const { loggedInUser } = useContext(AuthContext)

    useEffect(() => {
        if (loggedInUser && loggedInUser.loggedIn) {
            navigate('/')
        }
    }, [loggedInUser, navigate])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setEmailError('')
        setSubmitError('')
        setSuccessMessage('')

        try {
            // Validate email
            if (!email) {
                setEmailError('Please enter your email.')
                setIsLoading(false)
                return
            }

            // Use the new auth route for forgot password
            const response = await axios.post(
                `${API_URL}/api/forgot-password/send-email`, 
                { email }, 
                { withCredentials: true }
            )

            if (response.data.success) {
                setSuccessMessage('If an account exists with this email, a password reset link will be sent.')
                setEmail('')
            } else {
                setSubmitError(response.data.message || 'An error occurred. Please try again.')
            }
        } catch (error) {
            console.error('There was an error during password reset request:', error)
            
            if (error.response?.status === 429) {
                // Rate limit error
                setSubmitError('Too many password reset attempts. Please try again later.')
            } else if (error.response?.data?.message) {
                setSubmitError(error.response.data.message)
            } else {
                setSubmitError('An error occurred. Please try again.')
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <NonMainNav loggedInUser={loggedInUser} />
            <Wrapper>
                <LoginWrapper>
                    <LoginHeader>Forgot Password</LoginHeader>
                    <CheckboxText style={{ marginBottom: 20, textAlign: 'center' }}>
                        Enter your email address and we&apos;ll send you a link to reset your password.
                    </CheckboxText>
                    <InputWrapper>
                        <LoginInput 
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                            style={emailError ? { borderColor: 'red' } : {}}
                            placeholder='Email'
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit(e)
                                }
                            }}
                        />
                        <SignupError>{emailError}</SignupError>
                    </InputWrapper>
                    <LoginButton onClick={handleSubmit} type="submit" disabled={isLoading}>
                        {isLoading ? 'Sending...' : 'Send Reset Link'}
                    </LoginButton>
                    <SignupError>{submitError}</SignupError>
                    {successMessage && (
                        <CheckboxText style={{ color: 'green', marginTop: 10, textAlign: 'center' }}>
                            {successMessage}
                        </CheckboxText>
                    )}
                </LoginWrapper>
                <LoginWrapper>
                    <CheckboxText>
                        Remember your password? <CheckboxLink onClick={() => navigate('/login')}>Log in</CheckboxLink>
                    </CheckboxText>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default ForgotPasswordComponent