import React, { useRef, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import {
    InputContainer,
    ErrorContainer,
    ErrorMessage,
} from '../../../ArtistRegistry/ArtistForms/ArtistFormsElements'

import { 
    DropzoneContainer,
    DropzoneText,
    DropzoneSubtext, 
    ImageContainer,
    PreviewImage
} from '../../CreateProjectElements'

import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { BsCardImage } from 'react-icons/bs'
import { FaRegWindowClose } from 'react-icons/fa'

function InputFields({ setCoverImage, previewImage, setPreviewImage, isInvalid }) {
    const imageContainerRef = useRef(null)

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreviewImage(reader.result)
            setCoverImage(file)
        }
        if (file) {
            reader.readAsDataURL(file)
        }
    }, [setPreviewImage, setCoverImage])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const resetImage = () => {
        setPreviewImage(null)
        setCoverImage(null)
    }

    return (
        <>
            <InputContainer>
                {!previewImage 
                ? 
                <DropzoneContainer isInvalid={isInvalid} {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <BsCardImage size={50} style={{ marginBottom: 10 }} />
                    <DropzoneText>Drop an image, or click to select a file</DropzoneText>
                    <DropzoneSubtext>Image should be at least 1920 x 1080 pixels.</DropzoneSubtext>
                    <DropzoneSubtext>Images are cropped to a 16:9 ratio.</DropzoneSubtext>
                    <DropzoneSubtext>Max file size: 2 MB</DropzoneSubtext>
                    {isInvalid && <ErrorContainer><ErrorMessage style={{ fontSize: '17px' }}>Please upload a cover photo.</ErrorMessage><AiOutlineExclamationCircle color='red' size={20} /></ErrorContainer>}
                    </div>
                </DropzoneContainer>
                : 
                <div style={{ width: '100%' }}>
                <FaRegWindowClose onClick={resetImage} color='red' size={30} style={{ cursor: 'pointer' }} />
                <ImageContainer ref={imageContainerRef}>
                    <PreviewImage src={previewImage} />
                </ImageContainer>
                </div>
                }
            </InputContainer>
        </>
    )
}

export default InputFields