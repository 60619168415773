import React from 'react'

import { 
    InfoCardWrapper, 
    InfoCardTitle,
    PdfWrapper,
    PdfName
} from '../ProjectInvestElements'

import { getOfferingApi } from '../../ProjectProfile/Body/Details/detailsApi'

import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'

function ContractsCard ({ project }) {

    const handlePdfClick = async (fileName) => {
        try {
        const response = await getOfferingApi(project.slug, fileName)

        const file = new Blob([response], { type: 'application/pdf' })

        const fileURL = URL.createObjectURL(file)

        window.open(fileURL, '_blank')
        } catch (e) {
            alert('there was an error: ' + e)
        }
    }

    const pdfMapping = project?.offerings?.map((offering, index) => {
        return (
            <PdfWrapper onClick={() => handlePdfClick(offering?.fileName)} key={offering?.title} style={index > 0 ? { marginTop: 10 } : {}}>
                <BsFillFileEarmarkPdfFill size={23}/>
                <PdfName>{offering?.title}</PdfName>
            </PdfWrapper>
        )
    })

    return (
        <InfoCardWrapper>
            <InfoCardTitle>Contracts</InfoCardTitle>
            {pdfMapping}
        </InfoCardWrapper>
    )
}

export default ContractsCard